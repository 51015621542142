import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "../../../components/controls/Button";
import Typography from "@material-ui/core/Typography";

// import openingScreen from "../../../assets/profile.png";
// import l1image from "../../../assets/Videocall with product owner.png";
// import l2image from "../../../assets/Team Product research.png";
// import l3PresentationImage from "../../../assets/Packing product.png";
// import l3ProfileImage from "../../../assets/profile.png";
// import l3CodingChallengeImage from "../../../assets/Group 160.png";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import quiz from "../../../redux/actions/quiz";
import { useSelector, useDispatch } from "react-redux";
import auth from "../../../redux/actions/auth";
import AppConfig from "../../../constants/AppConfig";
import randomString from "random-string";
import { Text } from "../../../styles/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import logo from "../../../assets/logo.svg";
import * as globalService from "../../../services/globalService";

import Grid from "@material-ui/core/Grid";

import { ImagebaseUrl } from "../../../services/globalService";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {},
  },
  section: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  section1: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    zIndex: 1,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  cardTwo: {
    marginTop: "64px",
    marginBottom: "40px",
    justifyContent: "center",
  },
  cardThree: {
    marginBottom: 24,
  },
  cardFour: {
    marginBottom: 40,
  },
  cardFive: {
    "&>button": {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up("tablet")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  cardSix: {
    marginTop: "24px",
    paddingBottom: "20px",
  },
  cardTwoH1: {
    lineHeight: "28px",
    [theme.breakpoints.up("tablet")]: {},
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      whiteSpace: "pre-wrap",
    },
  },
  cardtext: {
    [theme.breakpoints.up("tablet")]: {
      fontSize: "23px",
    },
  },
  openingScreenDescription: {
    fontSize: "14px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    },
  },
  openingScreen: {
    maxWidth: 210,
    [theme.breakpoints.up("tablet")]: {
      maxWidth: 308,
    },
  },
  fontProfile: {
    [theme.breakpoints.up("tablet")]: {
      fontSize: "15.9px",
    },
  },
  minText: {
    //fontWeight: '500',
    marginBottom: "5px",
  },
}));

function OpeningScreen(props) {
  const dispatch = useDispatch();
  const { location } = props;
  let questionCode = "QuestionCode";
  let level = "";
  let levelInfo = "";
  const authState = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  const sessionByLevel = useSelector((state) => state.quiz.sessionByLevel);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    dispatch(quiz.resetQuizSummery());
    dispatch(quiz.resetQuizQuestions());
    dispatch(quiz.setSelectedQuesNdAns([]));
    globalService.removeAnswers();
    dispatch(quiz.resetElapsedTime(0));
    sessionStorage.removeItem("remainigTime");
    dispatch(quiz.resetTestResult());

  }, []);

  useEffect(() => {
    if (
      location &&
      location?.pathname == "/OpeningScreen" &&
      authState?.userProfile &&
      authState?.userProfile?.email &&
      quizState?.isFromEditProfile == true
    ) {
      dispatch(auth.userMetaDataReq({ email: authState.userProfile.email }));
    }
  }, [location, authState.userProfile]);

  useEffect(() => {
    if (
      authState?.userProfile &&
      authState?.userProfile?.email &&
      authState?.userMetaData &&
      authState?.userMetaData[0]?.result &&
      authState?.userMetaData[0]?.result[0] &&
      quizState?.isFromEditProfile == true
    ) {
      dispatch(quiz.setSelectedQuesNdAns(JSON.parse(authState?.userMetaData[0]?.result[0])));
    }
  }, [authState?.userMetaData, authState?.userProfile]);

  useEffect(() => {
    console.log("quizState.sessionByLevel: ", location, sessionByLevel);

    if (location && sessionByLevel != "" && sessionByLevel) {
      if (location.pathname == "/OpeningScreen") {
        dispatch(quiz.getL0Questions());
      }
      if (location.pathname == "/L1OpeningScreen") {
        dispatch(quiz.getL1Questions());
      }
      if (location.pathname == "/L2OpeningScreen") {
        dispatch(quiz.getL2Questions());
      }
      if (location.pathname == "/L3PresentationOpeningScreen") {
        dispatch(quiz.getL3PresentationQuestions());
      }
      if (
        location.pathname == "/L3ProfileOpeningScreen" ||
        location.pathname == "/L3CodingOpeningScreen"
      ) {
        dispatch(quiz.getL3Questions());
      }
    }
  }, [sessionByLevel]);

  useEffect(() => {
    if (quizState.questions && quizState.questions.length > 0) {
      if (location && location.pathname == "/OpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL0));
        history.push("/QuizQuestions/" + AppConfig.LEVEL0 + "/1");
      }
      if (location && location.pathname == "/L1OpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL1));
        history.push("/QuizQuestions/" + AppConfig.LEVEL1 + "/1");
      }
      if (location && location.pathname == "/L2OpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL2));
        history.push("/QuizQuestions/" + AppConfig.LEVEL2 + "/1");
      }
      if (location && location.pathname == "/L3PresentationOpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL3_PRESENTATION));
        history.push("/QuizQuestions/" + AppConfig.LEVEL3 + "/1");
      }
      if (location && location.pathname == "/L3ProfileOpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL3_PROFILE));
        history.push({ pathname: "/ProfileQuestions/" });
      }
      if (location && location.pathname == "/L3CodingOpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL3_CODING));
        history.push("/CodingQuestions/1");
      }
    }
  }, [quizState.questions]);

  // useEffect(() => {
  if (location && location.pathname == "/OpeningScreen") {
    questionCode = "l0QuestionCode";
    level = "0";
    levelInfo = AppConfig.LEVEL0;
  }
  if (location && location.pathname == "/L1OpeningScreen") {
    questionCode = "l1QuestionCode";
    level = "1";
    levelInfo = AppConfig.LEVEL1 + " " + quizState.skills;
  }
  if (location && location.pathname == "/L2OpeningScreen") {
    questionCode = "l2QuestionCode";
    level = "2";
    // levelInfo = AppConfig.LEVEL2 + " " + quizState.skills;
    levelInfo = AppConfig.LEVEL2;
  }
  if (location && location.pathname == "/L3PresentationOpeningScreen") {
    questionCode = "l3QuestionCode";
    level = "3";
    levelInfo = "presentation";
  }
  if (location && location.pathname == "/L3ProfileOpeningScreen") {
    questionCode = "l3QuestionCode";
    level = "3";
    levelInfo = "profile";
  }
  if (location && location.pathname == "/L3CodingOpeningScreen") {
    questionCode = "l3QuestionCode";
    level = "3";
    levelInfo = "coding";
  }

  const handleClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const onIamReadyClick = function () {
    if (navigator.onLine) {

      if (level == "3") {
        dispatch(auth.updateQuizLevel(AppConfig.LEVEL3));
      } else if (level == "1") {
        dispatch(auth.updateQuizLevel(AppConfig.LEVEL1));
      } else {
        dispatch(auth.updateQuizLevel(levelInfo));
      }

      let UID;
      if (authState && authState.user && authState.user.uid) {
        UID = authState.user.uid;
      } else if (
        authState &&
        authState.userProfile &&
        authState.userProfile.uid
      ) {
        UID = authState.userProfile.uid;
      } else {
        UID = "notregistered";
      }

      let code = randomString();
      AppConfig[questionCode] = code;
      AppConfig.code = code;
      let body = {
        level,
        code,
        uid: UID,
        email: authState.userProfile
          ? authState.userProfile.email
          : "notregistered",
        levelInfo,
      };
      dispatch(quiz.createSessionRequested(JSON.stringify(body)));
    }
    else {
      console.log("offline data")
      setOpenSnackBar(true);
    }
  };

  const networkToast = () => {
    if (navigator.onLine) {
      history.push("/")
    }
    else {
      setOpenSnackBar(true)
    }
  }

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section1}   ${classes.cardOne}  ${classes.center}`}
      >
        <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
      </div>

      <Grid container className={`${classes.section} ${classes.cardTwo}`}>
        {isNotMobile && <Grid item xs={3}></Grid>}
        <Grid item xs={isNotMobile ? 6 : 12}>
          {location && location.pathname == "/OpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              We need a few things for your profile.{" "}
              {isNotMobile ? <br /> : null}
              Let’s start putting the pieces together for the job you’re
              seeking.
            </Typography>
          )}
          {location && location.pathname == "/L1OpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.cardtext}`}
            >
              Let’s get your initial evaluation! {isNotMobile ? <br /> : null}
              We’ll start with technical questions and basic background details
              about you.
            </Typography>
          )}
          {location && location.pathname == "/L2OpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              {/* We’re getting to your detailed evaluation.
              <br />
              Let’s get deeper in your technical expertise to narrow your score… */}
              You’re about to begin the technical evaluation! {""}
              {isNotMobile ? <br /> : null}
              This customized quiz covers topics relevant to your target role.
            </Typography>
          )}
          {location && location.pathname == "/L3PresentationOpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              {/* We’re getting to your detailed evaluation.
              <br />
              Let’s get deeper in your technical expertise to narrow your score… */}
              This section tests your presentation skills.{" "}
              {isNotMobile ? <br /> : null}
              Are you ready for your next interview?
            </Typography>
          )}
          {location && location.pathname == "/L3ProfileOpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              {/* Next, show us your digital presence and quality of your resume.
              <br />
              GitHub, Medium, anything you have… */}
              Show us your digital presence and quality of your resume.
              LinkedIn, GitHub, Medium, anything you have...
            </Typography>
          )}
          {location && location.pathname == "/L3CodingOpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Here you’ll be solving a few coding problems.
              {isNotMobile ? <br /> : null}
              Ready your keyboard!
            </Typography>
          )}
          {location && location.pathname == "/CodingSectionUnavailable" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Sorry, this section is
              <br />
              unavailable for this device
            </Typography>
          )}
        </Grid>
        {isNotMobile && <Grid item xs={3}></Grid>}
      </Grid>

      <Grid container className={`${classes.section} ${classes.cardThree}`}>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid item xs={isNotMobile ? 4 : 12} className={classes.center}>
          {location && location.pathname == "/OpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="openingScreen"
              src={`${ImagebaseUrl}assets/profile.png`}
            />
          )}
          {location && location.pathname == "/L1OpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="l1image"
              src={`${ImagebaseUrl}assets/Videocall with product owner.png`}
            />
          )}
          {location && location.pathname == "/L2OpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="l2image"
              src={`${ImagebaseUrl}assets/Team Product research.png`}
            />
          )}
          {location && location.pathname == "/L3PresentationOpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="l3PresentationImage"
              src={`${ImagebaseUrl}assets/Packing product.png`}
            />
          )}
          {location && location.pathname == "/L3ProfileOpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="l3ProfileImage"
              src={`${ImagebaseUrl}assets/profile.png`}
            />
          )}
          {location && location.pathname == "/L3CodingOpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="openingScreen"
              src={`${ImagebaseUrl}assets/Group 160.png`}
            />
          )}
          {location && location.pathname == "/CodingSectionUnavailable" && (
            <img
              className={`${classes.openingScreen}`}
              alt="openingScreen"
              src={`${ImagebaseUrl}assets/Group 160.png`}
            />
          )}
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      <Grid container className={`${classes.section} ${classes.cardFour}`}>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid item xs={isNotMobile ? 4 : 12} className={classes.center}>
          {location && location.pathname == "/OpeningScreen" && (
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.openingScreenDescription}`}
            >
              We will ask you a few simple questions to understand the job
              you’re aiming for and will give you a target score needed for that
              position. 
              Are you ready to find out?
            </Typography>
          )}
          {location && location.pathname == "/L1OpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText}`}
              >
                25 multiple-choice questions in 20 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}`}
              >
                {/* Once the timer has started it cannot be paused, if your session
            times out, you’ll have to restart it. Ready to begin? */}
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward or
                refresh) during quiz.
                Ready to begin?
              </Typography>
            </Grid>
          )}
          {location && location.pathname == "/L2OpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText}`}
              >
                30 multiple-choice questions in 20 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}`}
              >
                {/* Once the timer has started it cannot be paused, if your session
              times out, you’ll have to restart it. Ready to begin? */}
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward or
                refresh) during quiz.
                Ready to begin?
              </Typography>
            </Grid>
          )}
          {location && location.pathname == "/L3PresentationOpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText}`}
              >
                15 multiple-choice questions in 5 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}`}
              >
                {/* Once the timer has started it cannot be paused, if your session
            times out, you’ll have to restart it. Ready to begin? */}
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward or
                refresh) during quiz.
                Ready to begin?
              </Typography>
            </Grid>
          )}
          {location && location.pathname == "/L3ProfileOpeningScreen" && (
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.fontProfile}`}
            >
              Have your resume handy to upload. We will be evaluating the
              quality of your resume and your contributions to online
              collaboration platforms. {isNotMobile ? <br /> : null}
              Ready to complete your profile?
            </Typography>
          )}
          {location && location.pathname == "/L3CodingOpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText}`}
              >
                3 programming challenges in 45 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}`}
              >
                {/* Once the timer has started it cannot be paused, if your session
               times out, you’ll have to restart it. Ready to begin? */}
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward or
                refresh) during quiz.
                Ready to begin?
              </Typography>
            </Grid>
          )}
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      {location && location.pathname == "/CodingSectionUnavailable" ? (
        <Grid container className={`${classes.section} ${classes.cardFive}`}>
          {isNotMobile && <Grid item xs={4}></Grid>}
          <Grid item xs={isNotMobile ? 4 : 12} className={classes.center}>
            <Button
              onClick={() => {
                history.push("/Dashboard");
              }}
              color="btnPrimary"
              style={{
                width: "100%",
              }}
              text="Return to home"
              data-rt-opening-returnhome = "returnhome"
            ></Button>
          </Grid>
          {isNotMobile && <Grid item xs={4}></Grid>}
        </Grid>
      ) : (
        <div>
          <Grid container className={`${classes.section} ${classes.cardFive}`}>
            {isNotMobile && <Grid item xs={4}></Grid>}
            <Grid item xs={isNotMobile ? 4 : 12} className={classes.center}>
              <Button
                onClick={onIamReadyClick}
                color="btnPrimary"
                style={{
                  width: "100%",
                }}
                text="Yes, I’m ready to get started!"
                data-rt-opening-start = "start"
              ></Button>
            </Grid>
            {isNotMobile && <Grid item xs={4}></Grid>}
          </Grid>
          <Grid container className={`${classes.section} ${classes.cardSix}`}>
            {isNotMobile && <Grid item xs={4}></Grid>}
            <Grid item xs={isNotMobile ? 4 : 12}>
              <Link onClick={networkToast}
                data-rt-opening-notreadytostart = "notreadytostart"
              >
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "link1" : "link2"}
                  align="center"
                >
                  I’m not ready to start, return to home
                </Typography>
              </Link>
            </Grid>
            {isNotMobile && <Grid item xs={4}></Grid>}
          </Grid>
        </div>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default OpeningScreen;
