import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "./controls/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import authState from "../redux/actions/auth";
import quiz from "../redux/actions/quiz";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import topNavIcon from "../assets/top-nav-icon.png";
import logo from "../assets/logo.svg";
import SwipeableTemporaryDrawer from "../components/controls/SwipeableTemporaryDrawer";
import { removeAnswers } from "../services/globalService";

// import feedbackImg from "../assets/feedback.svg";
// import logoutImg from "../assets/logout.svg";
// import inviteImg from "../assets/invite.svg";
import Popup from "./Popup";
import InviteFriendContent from "../components/dialogContent/InviteFriendContent";

import { Text } from "../styles/theme";
import SimpleSnackbar from "./controls/ToastMessage";
import { ImagebaseUrl } from "../services/globalService";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    width: 240,
  },
  login: {
    marginRight: "16px",
  },
  toolBar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  navBar: {
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    backgroundColor: theme.palette.primary.light,
    height:'60px',
    [theme.breakpoints.up("tablet")]:{
      height:"80px"
    }

  },
  containerCls: {
    [theme.breakpoints.up("tablet")]: {
      paddingRight: "64px !important",
      paddingLeft: "64px !important",
    },
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    fontWeight: theme.typography.b1Medium.fontWeight
  },
  labelGray: {
    color: theme.palette.btnPrimary.tertiaryText,
    fontWeight: theme.typography.b1Medium.fontWeight
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
    '&:hover': {
      cursor: "pointer"
    }
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  topNavIcon: {
    height: "8px",
    width: "16px",
  },
  iconImg: {
    marginLeft: 8,
  },
}));

export default function NavBar(props) {
  const {} = props;
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [open, setOpen] = useState(false);

  const onClickLogout = () => {
    if(navigator.onLine){
    dispatch(authState.clearState());
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    dispatch(quiz.clearState());

    history.push({
      pathname: "/",
    });
  }
  else {
    setOpenSnackBar(true)
  }
  };
  const handleDialog = (val) => {
    if(navigator.onLine){
    setOpenPopup(val);
    }
    else {
      setOpenSnackBar(true)
    }
  };
  const ToastClick = () => {
    setOpen(true);
  };

  const HandleClickLink = () => {
    if(navigator.onLine){
    window.open("https://www.surveymonkey.com/r/YourTechScore","_blank")
    }
    else{
      setOpenSnackBar(true)
    }
  }

  const NavButtons = (link) => {
    if(navigator.onLine){
    history.push(link)
    }
    else {
      setOpenSnackBar(true)
    }
  }

  return (
    <AppBar elevation={0} className={classes.navBar}>
      {console.log("auth.user", auth.user)}
      {isNotMobile && (
        <Container maxWidth="xl" className={`${classes.containerCls}`}>
          <Toolbar className={classes.toolBar}>
            {/* <Typography
                        variant="h6"
                        className={`${classes.title}`}
                        align="left"
                    >
                        Your Tech Score
                    </Typography> */}
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls}  onClick={()=> history.push("/")}/>
            {!auth.user && (
              <Grid container justifyContent="flex-end">
                {/* <div className={classes.login}> */}
                <Button
                  onClick={() => NavButtons("/CreateAccount")}
                  color="btnTertiaryTwo"
                  width="140px"
                  height="40px"
                  text="Join Now"
                  data-rt-header-join = "joinbtn"
                ></Button>
                <Button
                  onClick={() => NavButtons("/Login")}
                  color="btnSecondary"
                  width="140px"
                  text="Log In"
                  data-rt-header-login = "loginbtn"
                ></Button>
                {/* </div> */}
              </Grid>
            )}
            {auth.user && (
              <Grid
                container
                justifyContent="flex-end"
                spacing={3}
                alignItems="center"
              >
                <Grid item>
                  <Link onClick={() => HandleClickLink()}>
                    <Grid container alignItems="center">
                      <Typography
                        component={Text}
                        variant2="link1"
                        align="center"
                        className={`${classes.labelBlue}`}
                        data-rt-header-feedback = "feedbackbtn"
                      >
                        Give Feedback
                      </Typography>
                      <span className={classes.iconImg}>
                        <img src={`${ImagebaseUrl}assets/feedback.svg`} />
                      </span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item>
                  <Link onClick={() => handleDialog(true)}>
                    <Grid container alignItems="center">
                      <Typography
                        component={Text}
                        variant2="link1"
                        align="center"
                        className={`${classes.labelBlue}`}
                        data-rt-header-invite = "invitebtn"
                      >
                        Invite a Friend
                      </Typography>
                      <span className={classes.iconImg}>
                        <img src={`${ImagebaseUrl}assets/invite.svg`} />
                      </span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item alignContent="center">
                  <Link onClick={() => onClickLogout()}
                    data-rt-header-logout = "logoutbtn"
                  >
                    <Grid container alignItems="center">
                      <Typography
                        component={Text}
                        variant2="link1"
                        align="center"
                        className={`${classes.labelGray}`}
                      >
                        Log Out
                      </Typography>
                      <span className={classes.iconImg}>
                        <img src={`${ImagebaseUrl}assets/logout.svg`} />
                      </span>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </Container>
      )}
      {!isNotMobile && (
        <Container className={`${classes.containerCls}`}>
          <SwipeableTemporaryDrawer handleDialog={() => handleDialog(true)} ></SwipeableTemporaryDrawer>
        </Container>
      )}
      
      {/* <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}  > */}
        <InviteFriendContent
          // hint={quizProp.quesObject.Hint}
          //showButtonArea={false}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          ToastClick={ToastClick}
        ></InviteFriendContent>
      {/* </Popup> */}
      <SimpleSnackbar open={open} setOpen={setOpen} />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClose={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </AppBar>
  );
}
