var selectedAnswers = [];


var progess = 0;



function calculateElapsedTime(remainingTime , state){
 if(state?.config?.Time_Mins){
   let totalSeconds = state?.config?.Time_Mins * 60;
   return remainingTime < totalSeconds ? (totalSeconds - remainingTime) : (totalSeconds - 1)
 }
 return 0;
}


function removeAnswers() {
  selectedAnswers = [];
  localStorage.removeItem("selectedAnswers");
}


function insertAnswer(ansObj, total) {
  if (ansObj && total) {
    selectedAnswers.push(ansObj);
    if (selectedAnswers.length == 0 && localStorage.selectedAnswer) {
      selectedAnswers =
        localStorage.selectedAnswers &&
        JSON.parse(localStorage.selectedAnswers);
    }
    if(selectedAnswers){
      selectedAnswers = getUnique(selectedAnswers, 'questionID');
    }
    if (selectedAnswers.length != 0) {
      selectedAnswers = selectedAnswers.filter((obj) => {
        return obj && obj.answer && obj.answer.length != 0;
      });
      localStorage.selectedAnswers = selectedAnswers;
    }



    if (selectedAnswers) {
      progess = (100 * selectedAnswers.length) / total;
      progess = Math.round(progess);
      console.log("progess", progess);
    }
  }
}

function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e[comp])

    .map((e, i, final) => final.lastIndexOf(e) === i && i)

    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

function getObject(key, value, array) {
  let obj = null;
  if (array) {
      obj = array.find((item) => {
          return item[key] == value;
      });
  } 
  return obj;
}

function getObjectThatContains(key, value, array) {
  let obj = null;
  if (array) {
      obj = array.find((item) => {
          return item[key].includes(value);
      });
  } 
  return obj;
}

function getIdx(key, value, array) {
  if (array) {
    var ln = array.length;
    for (var i = 0; i < ln; i++) {
      if (array[i][key] == value) {
        return i;
      }
    }
  }
  return -1;
}

function saveQuestionWiseAnswers(questionId, answers) {
  console.log('saveQuestionWiseAnswers: ', questionId, answers);
}

 const ImagebaseUrl = "https://d2po7vz9ilj7b0.cloudfront.net/"

export { removeAnswers, insertAnswer, progess, getObjectThatContains, getUnique, getObject, getIdx, saveQuestionWiseAnswers, calculateElapsedTime, ImagebaseUrl};
