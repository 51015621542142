import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimeOutCaptureIcon from "../assets/timeOutCaptureIcon.svg";
import { useSelector, useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";

import { useHistory } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Link from "@material-ui/core/Link";

import logo from "../assets/logo.svg";
import tickIcon from "../assets/svg/tickIcon.svg";
import timerIcon from "../assets/svg/timerIcon.svg";
import Grid from "@material-ui/core/Grid";
import Button from "../components/controls/Button";
import { Text } from "../styles/theme";

import AppConfig from "../constants/AppConfig";
import quiz from "../redux/actions/quiz";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { removeAnswers, ImagebaseUrl } from "../services/globalService";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,

    [theme.breakpoints.up("tablet")]: {},
  },
  section: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  section1: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    zIndex: 1,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  cardTwo: {
    marginTop: "64px",
    marginBottom: "40px",
    justifyContent: "center",
  },
  cardThree: {
    marginBottom: 24,
  },
  cardFour: {
    marginBottom: 40,
  },
  cardFive: {
    "&>button": {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up("tablet")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  cardSix: {
    marginTop: "24px",
    paddingBottom: "20px",
  },
  cardTwoH1: {
    lineHeight: "28px",
    [theme.breakpoints.up("tablet")]: {},
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  openingScreenDescription: {
    [theme.breakpoints.up("tablet")]: {},
  },
  openingScreen: {
    maxWidth: 210,
    [theme.breakpoints.up("tablet")]: {
      maxWidth: 308,
    },
  },
  spacerText: {
    width: "535px",
    height: "32px",
    fontFamily: "Rational",
    fontSize: "24px",
    fontWeight: "bold",
  },
  marginTop25: {
    marginTop: "25px",
  },
  tickIcon: {
    width: "24px",
    height: "26px",
    marginRight: "28px",
  },
  timerIcon: {
    width: "24px",
    height: "28px",
    marginRight: "30px",
  },
  marginRight12: {
    marginRight: "12px",
  },
  TimeOutDescriptionText: {},
  retakeButton: {
    width: "424px",
    height: "48px",
    borderRadius: "4px",
    "@media (max-width:420px)": {
      width: "351px",
      height: "40px",
    },
  },
  linkArea: {
    marginTop: "2%",
    color: "#1789FC",
    "@media (max-width:420px)": {
      marginTop: "6%",
    },
    cursor: "pointer",
  },
  marginBetweenItems: {
    marginTop: "5%",
  },
  tmCText: {
    // width: "868px",
    // height: "96px",
  },
  tmCTextB: {
    margin: "30px auto",
    color: "#2F2F2F",
    width: "100%",
    height: "72px",
    [theme.breakpoints.up("tablet")]: {
      width: "424px"
    },
  },
  h3Text: {
    fontSize: "40px",
    fontWeight: "bold",
    lineHeight: "48px",
    letterSpacing: "0.2",
  },
  TimeOutmargin: {
    marginTop: "75px",
  },
  viewTime:{
    paddingLeft:'16px',
    paddingRight:'16px',
    [theme.breakpoints.up("tablet")]:{
      padding:"10px"
    }
  }
}));

function TimeOutCaptureScreen(props) {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const authObj = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  const [openSnackBar,setOpenSnackBar] = React.useState(false)
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickRetake = () => {
    let tempPath = "";
    switch (quizState.levelIndicator) {
      case AppConfig.LEVEL0:
        tempPath = "/OpeningScreen";
        break;
      case AppConfig.LEVEL1:
        tempPath = "/L1OpeningScreen";
        break;
      case AppConfig.LEVEL2:
        tempPath = "/L2OpeningScreen";
        break;
      case AppConfig.LEVEL3_PRESENTATION:
        tempPath = "/L3PresentationOpeningScreen";
        break;
      case AppConfig.LEVEL3_CODING:
        tempPath = "/L3CodingOpeningScreen";
        break;
      default:
    }
    if(navigator.onLine){
    dispatch(quiz.resetQuizSummery());
    dispatch(quiz.resetQuizQuestions());
    dispatch(quiz.setSelectedQuesNdAns([]));
    // dispatch(quiz.resetTestResult());
    removeAnswers();
    history.push(tempPath);
    }
    else {
      setOpenSnackBar(true)
    }
  };
  const onClickCancel = () => {
    if(navigator.onLine){
    removeAnswers();
    dispatch(quiz.setSelectedQuesNdAns([]));
    dispatch(quiz.clearState());
    history.push("/");
    }
    else {
      setOpenSnackBar(true)
    }
    
  };
  const handleClose = () => {
    setOpenSnackBar(false)
  }

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section1}   ${classes.cardOne}  ${classes.center}`}
      >
        <img alt="logoImage" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
      </div>
      <Grid container className={`${classes.center}`}>
        {isNotMobile && <Grid item xs={2}></Grid>}
        <Grid item xs={isNotMobile ? 8 : 12} className={classes.viewTime}>
          <Grid className={`${classes.center} ${classes.TimeOutmargin}`}>
            <div>
              <img alt="TimeOutCaptureIcon" src={`${ImagebaseUrl}assets/timeOutCaptureIcon.svg`} />
            </div>
          </Grid>
          <Grid
            className={`${classes.center} ${classes.tmCText} ${classes.marginBetweenItems}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "h2" : "h3"}
              align="center"
              className={`${classes.cardFourH1} ${classes.center} ${classes.openingScreenDescription}`}
            >
              Sorry, we couldn’t capture all your answers.
              {isNotMobile ? <br /> : null} Your session expired.
              {/* Sorry, we couldn’t estimate your score. {isNotMobile ? <br/> : null}Your session expired. */}
            </Typography>
          </Grid>
          <Grid
            className={`${classes.center} ${classes.tmCTextB} ${classes.marginBetweenItems}`}
          >
            <Typography
              component={Text}
              variant2="b1Regular"
              align="center"
              className={`${classes.cardFourH1} ${classes.openingScreenDescription}`}
            >
              Now you have a better idea on timing for this quiz. Come back
              later and retry when you have time. Don’t wait too long to find
              out your score!
            </Typography>
          </Grid>
          <Grid className={`${classes.center}  ${classes.marginBetweenItems}`}>
            <Button
              color="btnPrimary"
              text="I’m ready to retake the quiz"
              type="submit"
              className={`${classes.retakeButton}`}
              onClick={onClickRetake}
              data-rt-timeoutcapture-retakebtn = "retakebtn"
            ></Button>
          </Grid>
          <Grid
            className={`${classes.center} ${classes.marginBetweenItemsItems}`}
          >
            <Link onClick={onClickCancel} className={`${classes.linkArea}`}
              data-rt-timeoutcapture-backhome = "backhome"
            >
              <Typography variant2="link2">
                I’ll do it later. Back to home
              </Typography>
            </Link>
          </Grid>
        </Grid>
        {isNotMobile && <Grid item xs={2}></Grid>}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default TimeOutCaptureScreen;
