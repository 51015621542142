import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import dfltWorkshopImg from "../../assets/image workshop.png";

import { Text } from "../../styles/theme";
import { useHistory } from "react-router-dom";

import YouTubeLightBox from "../../components/YouTubeLightBox";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  workshopItem: {
    position: "relative",
  },
  workshopLink: {
    position: "absolute",
    bottom: 0,
  },
  sectionTitle: {
    marginBottom: 16,
    // fontSize: "16px",
    // lineHeight: "24px",
    [theme.breakpoints.up('tablet')]: {
      // fontSize: "24px",
      // lineHeight: "32px"
    }
  },
  eventTitle: {
    marginTop: 8,
    marginBottom: 8,
  },
  workshops: {
    marginBottom: 80,
  },
  workshopDetailText: {
    color: "#787878",
    paddingBottom: 12
  },
  source: {
    color: theme.palette.neutrals.grayDark    
  }
}));

function WorkshopCard(props) {
  const { data } = props;

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('tablet'));

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  return (
    <div className={classes.workshops}>
      <Typography component={Text} variant2="h4" className={classes.sectionTitle} align="left">
        Recommended Videos
      </Typography>
      <Grid container className="" spacing={isNotMobile ? 3 : 3}>
        {data && data.map(
          (rec, i) =>
            <Grid item xs={isNotMobile ? 3 : 6} className={classes.workshopItem} alignItems="center"  key={i}>
              <YouTubeLightBox url={rec['Link']}></YouTubeLightBox>
              <Typography
                component={Text}
                variant2="b2Medium"
                className={classes.eventTitle}
                align="left"
               
              >
                {rec['Recommended_Video']}
              </Typography>
              <Typography component={Text}
                variant2="b2Regular" className={classes.source}>
                Source: {rec['Source']}
              </Typography>
            </Grid>
        )}
      </Grid>
    </div>
  );
}
export default WorkshopCard;
