import React from "react";
import "../pages/home/Home.css";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: theme.palette.btnPrimary.tertiaryText,
    cursor:'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
    },
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up('tablet')]: {
      height: 100
    },
  },
  footerRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  footerLeftAlign: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },
  aboutLeft: {
    marginRight: "40px",
    [theme.breakpoints.down('sm')]: {
      margin: "24px auto 10px auto",
    },
  },
  divider: {
    margin: "0 10px",
    color: "#787878",
  },
  containerCls: {
    paddingRight: "24px",
    paddingLeft: "24px",
    [theme.breakpoints.up("tablet")]: {
      paddingRight: "64px !important",
      paddingLeft: "64px !important",
    },
  },
  terms:{
    [theme.breakpoints.down('sm')]: {
      marginBottom: "10px",
      marginTop: "33px",
    },
  },
  policy:{
    marginBottom: "33px",
  },
  companyName:{
    marginBottom: "24px",
  },
  footerLeftDrawer:{
    position:'fixed',
    bottom:'48px',
    '& .MuiGrid-container':{
      justifyContent: 'center',
    },
    '@media (orientation: landscape)': {
      position:'unset',
      marginBottom:'48px'
    }
  }
}));

export default function Footer(props) {
  const { leftDrawer } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  console.log("isNotMobile", isNotMobile);

  return (
    <div className={classes.footer}>
      {leftDrawer && (
     <Container maxWidth="xl"  className={`${classes.footerLeftDrawer} ${classes.containerCls}`}>
     <Grid container className="Home-hero">
       <Grid item >
         <div className={classes.footerLeftAlign}>
               <Link onClick={() => history.push("/FAQ")} data-rt-div-faq = "faqlink">
                 <Typography variant="body2" className={classes.title}>
                   FAQ
                 </Typography>
               </Link>
               <b className={classes.divider}>|</b>
         </div>
       </Grid>
       <Grid item >
           <div className={classes.footerRight}>
             <Link onClick={() => history.push("/Terms&Conditions")} data-rt-div-tc = "tclink">
               <Typography variant="body2" className={classes.title}>
                 Terms & Conditions
               </Typography>
             </Link>
         </div>
       </Grid>
       <Grid item >
           <div className={classes.footerRight}>
             <b className={classes.divider}>|</b>
             <Link onClick={() => history.push("/Privacypolicy")} data-rt-div-privacypolicy = "privacypolicylink">
               <Typography variant="body2" className={classes.title}>
               Privacy Policy
               </Typography>
             </Link>
         </div>
       </Grid>
     </Grid>
   </Container>
      )}


      {(isNotMobile && !leftDrawer )&& (
        <Container maxWidth="xl"  className={`${classes.footer} ${classes.containerCls}`}>
        <Grid container className="Home-hero">
          <Grid item xs={3}>
            <div className={classes.floatLeft}v>
            <div className={classes.footerLeftAlign}>
              <div className={classes.aboutLeft}>
                  <Link onClick={() => history.push("Aboutus")} data-rt-div-about = "aboutlink">
                    <Typography variant="body2" className={classes.title}>
                      About Us
                    </Typography>
                  </Link>
              </div>
              <div>
                  <Link onClick={() => history.push("/FAQ")} data-rt-div-faq = "faqlink">
                    <Typography variant="body2" className={classes.title}>
                      FAQ
                    </Typography>
                  </Link>
              </div>
            </div>
            </div>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={4}>
            <div className={classes.floatRight}>
              <div className={classes.footerRight}>
                <Link onClick={() => history.push("/Terms&Conditions")} data-rt-div-tc = "tclink">
                  <Typography variant="body2" className={classes.title}>
                    Terms & Conditions
                  </Typography>
                </Link>
                <b className={classes.divider}>|</b>
                <Link onClick={() => history.push("/Privacypolicy")} data-rt-div-privacypolicy = "privacypolicylink">
                  <Typography variant="body2" className={classes.title}>
                  Privacy Policy
                  </Typography>
                </Link>
              </div>
              <div className={classes.company}>
                <Typography variant="body2" className={`${classes.title} ${classes.floatRight}`}>
                  &copy;2021 YourTechScore
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      )}

      {(!isNotMobile&& !leftDrawer) && (
        <Container
          maxWidth="xl"
          className={`${classes.footer} ${classes.containerCls}`}
        >
          <Grid container className="Home-hero">
            <Grid item xs={12}>
              <div className={classes.floatLeft} v>
                <div className={classes.footerLeftAlign}>
                  <div className={classes.aboutLeft}>
                    <Link onClick={() => history.push("/Aboutus")} data-rt-div-about = "aboutlink">
                      <Typography variant="body2" className={classes.title}>
                        About Us
                      </Typography>
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Link onClick={() => history.push("/FAQ")} data-rt-div-faq = "faqlink">
                  <Typography variant="body2" className={classes.title}>
                    FAQ
                  </Typography>
                </Link>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.floatLeft} v>
                <div className={classes.footerLeftAlign}>
                  <div className={`${classes.aboutLeft}  ${classes.terms}`}>
                  <Link onClick={() => history.push("/Terms&Conditions")} data-rt-div-tc = "tclink">
                    <Typography variant="body2" className={classes.title}>
                      Terms & Conditions
                    </Typography>
                  </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.policy}>
                  <Link onClick={() => history.push("/Privacypolicy")} data-rt-div-privacypolicy = "privacypolicylink">
                    <Typography variant="body2" className={classes.title}>
                    Privacy Policy
                    </Typography>
                  </Link>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.companyName}>
                  {/* <Link href="#"> */}
                    <Typography variant="body2" className={classes.title}>
                    &copy;2021 YourTechScore
                    </Typography>
                  {/* </Link> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}
