
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ImagebaseUrl } from "../../services/globalService";
import Link from "@material-ui/core/Link";
import { Text } from "../../styles/theme";
import auth from "../../redux/actions/auth";
import quiz from "../../redux/actions/quiz"
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { useHistory } from "react-router-dom";

import { visuallyHidden } from '@mui/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px",
      paddingRight: "64px",
    },
  },
  item1: {
    marginBottom: "32px",
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  exit: {
    color: theme.palette.neutrals.grayDark,
  },
  labelGray: {
    color: theme.palette.btnPrimary.tertiaryText,
    fontWeight: theme.typography.b1Medium.fontWeight
  },
  iconImg: {
    marginLeft: 8,
  },
  logoutText: {
    display: 'flex'
  }
}))

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {


  // if (orderBy === 'date') {
  //   return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
  // }
  // if (b[orderBy] < a[orderBy]) {
  //   console.log("value1")
  //   return -1;

  // }
  // if (b[orderBy] > a[orderBy]) {
  //   console.log("value2")
  //   return 1;
  // }
  // return 0;
  if (orderBy === "LastLogin") {
    // if (b.LastLogin < a.LastLogin) 
    // console.log(b.LastLogin)
    // return -1;

    // if (b.LastLogin > a.LastLogin) 
    // return 1;
    return (new Date(a[orderBy]).valueOf() - new Date(b[orderBy]).valueOf());
  }
  if (orderBy === "memberSince") {
    return (new Date(a[orderBy]).valueOf() - new Date(b[orderBy]).valueOf());
  }
  if (orderBy === "firstName" || orderBy === "lastName" || orderBy === "targetRole" || orderBy === "primaryLanguage") {
    let ab = a[orderBy].toLowerCase();
    let ba = b[orderBy].toLowerCase();
    if (ba < ab) {
      return -1;

    }
    if (ba > ab) {
      return 1;
    }
  }
  else {
    if (b[orderBy] < a[orderBy]) {
      console.log("value1")
      return -1;

    }
    if (b[orderBy] > a[orderBy]) {
      console.log("value2")
      return 1;
    }
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  console.log("gfghfsd", array)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
  },
  {
    id: 'lastName',
    numeric: true,
    disablePadding: false,
    label: 'Last Name',
  },{
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'memberSince',
    numeric: true,
    disablePadding: false,
    label: 'Member Since',
  },
  {
    id: 'LastLogin',
    numeric: true,
    disablePadding: false,
    label: 'Last Login',
  },
  {
    id: 'targetRole',
    numeric: true,
    disablePadding: false,
    label: 'Target Role',
  },
  {
    id: 'primaryLanguage',
    numeric: true,
    disablePadding: false,
    label: 'Primary Language',
  },
  {
    id: 'yearsOfExperience',
    numeric: true,
    disablePadding: false,
    label: 'Years of Experience',
  },
  {
    id: 'targetScore',
    numeric: true,
    disablePadding: false,
    label: 'Target Score',
  },
  {
    id: 'initialScoreRange',
    numeric: true,
    disablePadding: false,
    label: 'Initial Score',
  },
  {
    id: 'ResumeScore',
    numeric: true,
    disablePadding: false,
    label: 'Resume Score',
  },
  {
    id: 'onlineProfileScore',
    numeric: true,
    disablePadding: false,
    label: 'Online Profile Score',
  },
  {
    id: 'technicalScore',
    numeric: true,
    disablePadding: false,
    label: 'Technical Score',
  },
  {
    id: 'codingScore',
    numeric: true,
    disablePadding: false,
    label: 'Coding Score',
  },
  {
    id: 'presentationScore',
    numeric: true,
    disablePadding: false,
    label: 'Presentation Score',
  },
  {
    id: 'totalScore',
    numeric: true,
    disablePadding: false,
    label: 'Total Score',
  },
  {
    id: 'detail',
    numeric: true,
    disablePadding: false,
    label: 'View Details',
  },
];


function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ width: "158px" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) =>
//             alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//         }),
//       }}
//     >
//       {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: '1 1 100%' }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : (
//         <Typography
//           sx={{ flex: '1 1 100%' }}
//           variant="h6"
//           id="tableTitle"
//           component="div"
//         >
//           Nutrition
//         </Typography>
//       )}

//       {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )}
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

export default function Adminpage() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('LastLogin');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([])
  const classes = useStyles();
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    dispatch(auth.adminDetails())
  }, [])
  const rowsdata = authState.admindetails || []

  React.useEffect(() => {
    const values = rowsdata.sort((a, b) => {
      return a.LastLogin === b.LastLogin ? 0 : a.LastLogin ? -1 : 1
    })
    setRows(values)
  }, [rowsdata]);

  const onClickLogout = () => {
    dispatch(auth.clearState());
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    dispatch(quiz.clearState());

    history.push({
      pathname: "/",
    });
  };

  const ViewDetails = (email) => {

    dispatch(auth.adminViewEmail(email))
    window.open('/admin/detail-report')
    // history.push("/admin/detail-report")
    // history.push("#")
  }

  // React.useEffect(() => {
  //   if(authState.adminviewdetails){
  //     history.push("/admin/detail-report")
  //   }
  // },[authState.adminviewdetails])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <div>
      <div className={`${classes.item1} ${classes.center} container-fluid`}>
        <Container
          maxWidth="xl"
          className={classes.container}
        //   style={{ width: "70%" }}
        >
          <div className={classes.main}>
            <Grid container className="Home-hero" alignItems="center">
              <Grid item xs={4}>
                <img
                  src={`${ImagebaseUrl}assets/logo.svg`}
                  className={classes.logoCls}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4} className={classes.exitBtn}>
                <Link onClick={onClickLogout}>
                  {/* <Typography
                      component={Text}
                      variant2="link2"
                      align="right"
                      className={classes.exit}
                    >
                      Exit
                    </Typography> */}
                  <Grid alignItems="center" className={classes.logoutText}>
                    <Typography
                      component={Text}
                      variant2="link1"
                      align="center"
                      className={`${classes.labelGray}`}
                    >
                      Log Out
                    </Typography>
                    <span className={classes.iconImg}>
                      <img src={`${ImagebaseUrl}assets/logout.svg`} />
                    </span>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      {/* <Box sx={{ width: '100%' }}> */}
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer style={{ overflowX: "initial" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}

          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows.slice().sort(getComparator(order, orderBy))
                .map((row, index) => {

                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        /> */}
                      </TableCell>
                      {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}

                      <TableCell component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      //style={{marginLeft:'15px'}}
                      //align="right"
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell align="right">{row.lastName}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{moment(row.memberSince).format("DD-MM-YYYY")}</TableCell>
                      <TableCell align="right">{moment(row.LastLogin).format("DD-MM-YYYY")}</TableCell>
                      <TableCell align="right">{row.targetRole}</TableCell>
                      <TableCell align="right">{row.primaryLanguage}</TableCell>
                      <TableCell align="right">{row.yearsOfExperience}</TableCell>
                      <TableCell align="right">{row.targetScore}</TableCell>
                      <TableCell align="right">{row.initialScoreRange}</TableCell>
                      <TableCell align="right">{row.ResumeScore}</TableCell>
                      <TableCell align="right">{row.onlineProfileScore}</TableCell>
                      <TableCell align="right">{row.technicalScore}</TableCell>
                      <TableCell align="right">{row.codingScore}</TableCell>
                      <TableCell align="right">{row.presentationScore}</TableCell>
                      <TableCell align="right">{row.totalScore}</TableCell>
                      <TableCell align="right"><Link component={Text}
                        variant2={"link1"}
                        onClick={() => { ViewDetails(row.email) }}
                        style={{ marginRight: '15px' }}
                      >
                        {/* {row.detail} */}
                        View

                      </Link></TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>

      {/* </Box> */}
    </div>
  );
}
