import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Profile from "../../components/Profile/Profile";
import AreaOfStrength from "../../components/AreaOfStrength/AreaOfStrength";
import AreaOfImprovement from "../../components/AreaOfImprovement/AreaOfImprovement";
import PersonalHistory from "../../components/PersonalHistory/PersonalHistory";
import ScoreCard from "../../components/ScoreCard/ScoreCard";

import ScoreCalculationDimension from "../../components/ScoreCalculationDimension/ScoreCalculationDimension";
import arrowImg from "../../assets/Arrow 10.png";
import { Text } from "../../styles/theme";
import { useHistory } from "react-router-dom";
import GraphScoreMeter from "../../components/GraphScoreMeter/GraphScoreMeter";
import AppConfig from "../../constants/AppConfig";
import quiz from "../../redux/actions/quiz";
import authMethod from "../../redux/actions/auth";
import randomString from "random-string";

import YouTubeLightBox from "../../components/YouTubeLightBox";
import Tooltip from "@material-ui/core/Tooltip";
import Resume from "../../components/Resume";
import WorkshopCard from "../../components/WorkshopCard/WorkshopCard";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { removeAnswers, ImagebaseUrl } from "../../services/globalService";

import SubmitConfirmationDialog from "../../components/SubmitConfirmationDialog";
import warningicon from "../../assets/svg/warningicon.svg";
import ScrollButton from "../../components/controls/ScrollButton";
import Vectordashboardsuccess from "../../assets/svg/Vectordashboardsuccess.svg";
import { firebaseAuthApp } from "../../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RoundedLoadingIndicator from "../../components/RoundedLoadingIndicator";
const Accordion = withStyles({
  root: {
    borderTop: "1px dashed rgba(0, 0, 0, .125)",
    // borderBottom: '1px dashed rgba(0, 0, 0, .125)',
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
    padding: 0,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
  },
  profileActiveSince: {
    color: "#787878",
  },
  profileImg: {
    marginBottom: 16,
    // width: 50,
    height: 50,
    borderRadius: 25,
  },
  workshopItem: {
    position: "relative",
  },
  workshopLink: {
    position: "absolute",
    bottom: 0,
  },
  factorsLink: {
    marginTop: 16,
    fontSize: 14,
    lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  historyTitle: {
    marginBottom: 24,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    // fontSize: 14,
    // lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      // fontSize: 16,
      // lineHeight: "24px",
    },
  },
  companyTitle: {
    color: theme.palette.neutrals.grayDark,
  },
  nextStepTitle: {
    marginBottom: 28,
  },
  sectionTitle: {
    marginBottom: 16,
    // fontSize: "16px",
    // lineHeight: "24px",
    [theme.breakpoints.up("tablet")]: {
      // fontSize: "24px",
      // lineHeight: "32px",
    },
  },
  eventTitle: {
    marginTop: 8,
    marginBottom: 8,
  },
  welcome: {
    flexGrow: 1,
    marginTop: 114,
    // marginBottom: 64,
  },
  sampleJobs: {
    marginTop: 16,
    marginBottom: 35,
    [theme.breakpoints.up("tablet")]: {
      marginTop: 40,
      marginBottom: 40,
    },
  },
  feedback: {
    color: "#1789FC",
    backgroundColor: "#EBEFF8",
    padding: 40,
    marginBottom: 80,
  },
  feedbackText: {
    color: "#1789FC",
  },
  profile: {
    // borderWidth: 1,
    // borderColor: "#EBEFF8",
    // borderStyle: "solid",
    // borderRadius: 4
  },
  scoreCard: {
    backgroundColor: theme.palette.primary.light,
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  link: {
    color: "#1789FC",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
  },
  scoresCalculatedByCardTitle: {
    marginBottom: 8,
  },
  whatsNext: {
    backgroundColor: theme.palette.primary.light,
    // height: 200,
    alignItems: "center",
    padding: "16px",
    borderRadius: 4,
    marginTop: 24,
    marginBottom: 30,
    [theme.breakpoints.up("tablet")]: {
      padding: "48px 48px 48px 30px",
      marginTop: 0,
    },
  },
  whatsNextLink: {
    fontSize: 24,
    lineHeight: "24px",
    fontFamily: "Rational",
    fontStyle: "normal",
    marginBottom: 12,
    [theme.breakpoints.up("tablet")]: {
      fontSize: 24,
      lineHeight: "32px",
    },
    [theme.breakpoints.down("sm")] : {
      lineHeight : "32px",
      fontSize : 21
    },
    [theme.breakpoints.down("xs")] : {
      lineHeight : "32px",
      fontSize : 17
    }
  },
  arrowImg: {
    marginLeft: 8,
  },
  jobCt: {
    "&:hover": {
      cursor: "pointer",
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "16px",
    },
  },
  jobsCard: {
    // borderWidth: 1,
    // borderColor: "#EBEFF8",
    // borderStyle: "solid",
    alignItems: "center",
    // borderRadius: 4,
    //marginBottom: theme.spacing(2),
    //marginRight: theme.spacing(2)
  },
  factors: {
    marginTop: 24,
  },
  factorsTitle: {
    fontFamily: "Rational",
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0em",
    marginBottom: 16,
  },
  jobsScore: {
    backgroundColor: theme.palette.primary.light,
    alignItems: "center",
    padding: "24px 12px 24px 12px",
    borderRadius: 4,
  },
  jobScoreCard: {
    // fontFamily: "Rational",
    color: theme.palette.primary.main,
    // fontSize: "16px",
    // fontStyle: "normal",
    // lineHeight: "20px",
    // letterSpacing: "0em",
    textAlign: "center",
  },
  jobScoreCardText: {
    fontFamily: "Rational",
    color: "#787878",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  grayText: {
    color: "#787878",
    // fontSize: 14,
    // lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      // fontSize: 16,
      // lineHeight: "24px",
    },
  },
  workshops: {
    marginBottom: 80,
  },
  workshopDetailText: {
    color: "#787878",
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    height: 136,
  },
  containerCls: {
    paddingRight: "64px",
    paddingLeft: "64px",
  },
  leftTop: {
    paddingBottom: 36,
    borderBottom: "1px solid #EBEFF8",
  },
  leftBottom: {
    paddingTop: 36,
  },
  scoreLeftGray: {
    color: "#787878",
    textAlign: "center",
  },
  whatsScore: {
    color: theme.palette.primary.main,
    fontFamily: "Rational",
    fontSize: "48px",
    fontStyle: "normal",
    lineHeight: "48px",
    letterSpacing: "-0.05em",
    textAlign: "center",
  },
  score: {
    fontFamily: "Rational",
    fontSize: "48px",
    fontStyle: "normal",
    lineHeight: "48px",
    letterSpacing: "-0.05em",
    textAlign: "center",
  },
  scoreArea: {
    border: `24px solid ${theme.palette.primary.light}`,
    backgroundColor: "#ffffff",
    // height: 200,
    alignItems: "center",
    borderRadius: 4,
    // padding: 24,
    marginBottom: 40,
  },
  scoreLeftArea: {
    padding: 36,
  },
  scoreMeter: {
    marginTop: -120,
  },
  blueText: {
    color: "#1789FC",
  },
  fileInput: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadProfileImagePlusIcon: {
    marginTop: "34px",
    marginLeft: "-16px",
    height: "18px",
    width: "18px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadProfileImageSection: {
    position: "relative",
    width: "66px",
    height: "60px",
    display: "flex",
  },
  content: {
    marginTop: "56px",
    [theme.breakpoints.up("tablet")]: {
      // marginTop: "64px",
    },
  },
  mobileContent: {
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  horizontalList: {
    flexWrap: "nowrap",
  },
  history_border: {
    borderBottom: `1.5px solid #ddd `,
    borderTop: `1.5px solid #ddd`,
    marginTop: "20px",
  },
  VerifyColor: {
    backgroundColor: "black",
    borderRadius: "8px",
    height: "190px",
    padding: "16px",
  },
  ImageView: {
    textAlign: "center",
    //marginTop:"30px"
  },
  imgIcon: {
    marginTop: "30px",
  },
  titleValue: {
    flexGrow: 1,
    fontSize: 14,
    lineHeight: "20px",
    marginTop: "10px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: 15.5,
      lineHeight: "24px",
      marginTop: "10px",
    },
  },
  VerifyEmail: {
    backgroundColor: "black",
    alignItems: "center",
    padding: "16px",
    borderRadius: 8,
    marginTop: 24,
    marginBottom: 40,
    [theme.breakpoints.up("tablet")]: {
      padding: "48px 48px 48px 30px",
      marginTop: 0,
    },
  },
  lastText: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F8F8F8",
  },
  lastText1: {
    textAlign: "center",
    backgroundColor: "#F8F8F8",
  },
  Loader:{
    position:'absolute',
    zIndex:1,
    left:'50%',
    top:'50%'
  },
  contentvalue:{
    paddingLeft:"12px",
    paddingRight:"12px"
  }
}));

function Dashboard(props) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const { location } = props;
  // let { email, firstName, lastName, level, phone } = location.state || {};
  // console.log("state", location.state, firstName, level);

  const classes = useStyles();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);

  const [isShowSubmitDialog, setShowSubmitDialog] = useState(false);
  const [isProfileReset, setProfileReset] = useState(false);
  const [isEmailVerifiedUpdated, setEmailVerifiedUpdated] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  let { email, firstName, lastName, level, phone } = auth;

  if (auth.user && auth.user.displayName) {
    firstName = auth.user.displayName.split(" ")[0];
  }

  useEffect(() => {
    dispatch(quiz.resetElapsedTime(0));
    sessionStorage.removeItem("remainigTime");
    if (!auth.userExists) {
      dispatch(quiz.resetQuizSummery());
      dispatch(quiz.resetQuizQuestions());
      dispatch(quiz.resetTestResult());
    }
    dispatch(quiz.quizSelectionPopupStatus(false));
    dispatch(quiz.quizSelectionPopupDisable(false));
    dispatch(quiz.quizSelectionTopic(""));
    dispatch(quiz.setSelectedQuesNdAns([]));
    removeAnswers();
    dispatch(quiz.setL0TestResult(null));
  }, []);

  useEffect(() => {
    if (auth.userProfile && !isEmailVerifiedUpdated) {
      const userDetails = firebaseAuthApp.currentUser ;
      const emailverify = auth?.verifyemail?.status == 0 ? true : userDetails?.emailVerified
      if (userDetails) {
        dispatch(
          authMethod.createProfile({
            email: auth.userProfile?.email,
            emailVerified: emailverify,
          })
        );
        setEmailVerifiedUpdated(true);
      }
    }
    if (auth.userProfile && isEmailVerifiedUpdated) {
      dispatch(authMethod.getAoSI({ email: auth.userProfile.email }));
      dispatch(
        authMethod.getRecommendations({ email: auth.userProfile.email })
      );
      dispatch(authMethod.getSampleJobs({ email: auth.userProfile.email }));
      dispatch(authMethod.getScoreHistory({ email: auth.userProfile.email }));
    }
  }, [auth.userProfile, isEmailVerifiedUpdated]);

  useEffect(() => {
    if (auth && auth.userProfile && auth.userProfile.email) {
      dispatch(authMethod.getProfile({ email: auth.userProfile.email }));
    }
  }, [auth.userProfile.email]);

  useEffect(() => {
    if(quizState.level == AppConfig.LEVEL0 || quizState.level == AppConfig.LEVEL1){
      if (auth.userExists && quizState.testResult && !auth.isEditProfileQuiz) {
        setProfileReset(false);
        setShowSubmitDialog(true);
        dispatch(authMethod.isEditProfileQuiz(false));

      } else {
        dispatch(quiz.resetQuizSummery());
        dispatch(quiz.resetQuizQuestions());
        dispatch(quiz.resetTestResult());
      }
    }
  }, [auth.userExists]);

  useEffect(() => {
    if (isProfileReset) {
      dispatch(quiz.resetQuizSummery());
      dispatch(quiz.resetQuizQuestions());
      dispatch(quiz.resetTestResult());
      dispatch(authMethod.updateUserExists(false));
      setProfileReset(false);
    }
  }, [isProfileReset]);

  // useEffect(() => {}, [auth.userProfile.user_level]);

  const onConfirm = () => {
    if(navigator.onLine){
    let userMetaDataReq = {
      email: auth.userProfile.email,
      code: `${AppConfig?.l0QuestionCode}`,
      result: quizState?.userMetaDataReq,
    };
    let user = {
      uid: auth.userProfile.uid,
      email: auth.userProfile.email,
      firstName: auth.userProfile.firstName,
      lastName: auth.userProfile.lastName,
      displayName: auth.userProfile.displayName,
      AuthMethod: "Google",
      profileId: 1,
      photoUrl: auth.userProfile.photoURL,
      phonenumber: auth.userProfile.phoneNumber,
      emailVerified: auth.userProfile.emailVerified,
      createdAt: auth.userProfile.createdAt,
      lastLoginAt: auth.userProfile.lastLoginAt,
      user_level: quizState?.level,
      targetScore: quizState?.testResult?.targetScore,
      targetRole: quizState?.testResult?.role,
      targetCompany: quizState?.testResult?.targetCompany,
      primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
      scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
      scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
      skills: quizState?.testResult?.skills,
    };
    let testResult = {
      email: auth.userProfile?.email,
      code: quizState?.testSubmissionReq?.code,
      result: quizState?.testSubmissionReq?.result,
    };
    dispatch(
      quiz.recreateUserAndSubmitResults(
        testResult,
        user,
        userMetaDataReq,
        quizState?.testResult?.role
      )
    );

    setShowSubmitDialog(false);
    setProfileReset(true);
      }
      else {
        setOpenSnackBar(true)
      }
  };
  const onClosePopup = () => {
    if(navigator.onLine){
    dispatch(quiz.resetQuizSummery());
    dispatch(quiz.resetQuizQuestions());
    dispatch(quiz.resetTestResult());
    dispatch(authMethod.updateUserExists(false));

    setProfileReset(false);
    setShowSubmitDialog(false);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  useEffect(() => {
    // if (!isNotMobile) {
    scrollToTop();
    // }
  }, []);
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto",
        /* you can also use 'auto' behaviour
                  in place of 'smooth' */
      });
    }, 500)
  };

  const handleLink = (link) => {
    dispatch(authMethod.isEditProfileQuiz(true));
    if(navigator.onLine){
      history.push(link)
    }
    else {
      setOpenSnackBar(true)
    }
  }

  const jobLink = (joblink) => {
    if(navigator.onLine){
      window.open(joblink, "_blank")
    }
    else {
      setOpenSnackBar(true)
    }

  }

  const handleClose = () => {
    setOpenSnackBar(false)
  }

  return (
    <div className={classes.root}>
      
      <SubmitConfirmationDialog
        open={isShowSubmitDialog}
        text="A profile already exists for you. You can continue to use that profile, or reset it with your most recent changes. Resetting the profile clears all your old scores and account history. Do you wish to reset?"
        action=""
        width="540px"
        yesBtnWidth={isNotMobile ? "186px" : "151px"}
        succesBtnLabel={isNotMobile ? "Yes, reset my profile" : "Yes, reset"}
        onSubmit={onConfirm}
        onClose={onClosePopup}
        TimeClose = {() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>

      <NavBar></NavBar>

      {isNotMobile && (
        <>
          <Container maxWidth="xl" className={`${classes.containerCls}`}>
            <Typography variant="h2" className={classes.welcome} align="left">
              Welcome back, {auth.userProfile.firstName}! 👋
            </Typography>
          </Container>

          <div className={classes.content}>
            <Container
              maxWidth="xl"
              className={`${classes.content} ${classes.containerCls}`}
            >
              <Grid container  spacing={4}>
                <Grid item xs={4}>
                  <Profile></Profile>
                  {auth.scoreHistory && auth.scoreHistory.length != 0 && (
                    <>
                      {auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                        auth.scoreHistory &&
                        auth.scoreHistory.length && (
                          <PersonalHistory
                            data={auth.scoreHistory}
                          ></PersonalHistory>
                        )}
                    </>
                  )}
                </Grid>
                <Grid item xs={8}>
                  {auth.userProfile.emailVerified === false && (
                    <Grid className={classes.VerifyColor}>
                      <Grid className={classes.ImageView}>
                        <img
                          src={`${ImagebaseUrl}assets/svg/warningicon.svg`}
                          className={classes.imgIcon}
                        />
                        <Typography
                          component={Text}
                          variant2="b2Regular"
                          className={classes.titleValue}
                          style={{ color: "white" }}
                          align="center"
                        >
                          Unverified account. Check your email for verification.
                          Account will be deactivated if not verified within 48
                          hours of creation. Please contact us if you’re
                          having issues.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {auth.userProfile.user_level === "" &&
                    auth.userProfile.emailVerified === true && (
                      <Grid>
                        <Grid className={classes.whatsNext}>
                          <Link onClick={() => handleLink("/OpeningScreen")} data-rt-dashboard-starttargetProfile = "starttargetProfile">
                            <Typography
                              component={Text}
                              variant2="link2"
                              className={classes.whatsNextLink}
                              align="left"
                            >
                              Start your target profile
                              <span className={classes.arrowImg}>
                                <img
                                  src={`${ImagebaseUrl}assets/Arrow10.svg`}
                                />
                              </span>
                            </Typography>
                          </Link>
                          <Typography
                            component={Text}
                            variant2="b2Regular"
                            className={classes.title}
                            align="left"
                          >
                            Complete a few simple profile questions to determine
                            your target score in 5 minutes or less.
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  {auth.userProfile.user_level == AppConfig.LEVEL0 && (
                    <Grid className={classes.whatsNext}>
                      <Link onClick={() => handleLink("/L1OpeningScreen")} data-rt-dashbaord-L1initialevalution = "L1initialevalution">
                        <Typography
                          component={Text}
                          variant2="link2"
                          className={classes.whatsNextLink}
                          align="left"
                        >
                          Complete Your Initial Evaluation
                          <span className={classes.arrowImg}>
                            <img src={`${ImagebaseUrl}assets/Arrow10.svg`} />
                          </span>
                        </Typography>
                      </Link>
                      <Typography
                        component={Text}
                        variant2="b2Regular"
                        className={classes.title}
                        align="left"
                      >
                        You’ll need to complete all assessment sections to
                        receive a raw score range. A total of approx. 20 minutes
                        for 25 questions that cover technical, profile and
                        presentation sections.
                      </Typography>
                    </Grid>
                  )}
                  {auth.userProfile.user_level == AppConfig.LEVEL1 && (
                    <Grid
                      className={classes.whatsNext}
                      level={AppConfig.LEVEL1}
                    >
                      <Link onClick={() => handleLink("/L2OpeningScreen")} data-rt-dashboard-L2startdetails = "L2startdetails">
                        <Typography
                          component={Text}
                          variant2="link2"
                          className={classes.whatsNextLink}
                          align="left"
                        >
                          Start Detailed Evaluation
                          <span className={classes.arrowImg}>
                            <img src={`${ImagebaseUrl}assets/Arrow10.svg`} />
                          </span>
                        </Typography>
                      </Link>
                      <Typography
                        component={Text}
                        variant2="b2Regular"
                        className={classes.title}
                        align="left"
                      >
                        You'll need to complete all assessment sections to
                        receive your comprehensive career score.
                      </Typography>
                    </Grid>
                  )}
                  {auth.userProfile.totalScore >=
                    auth.userProfile.targetScore &&
                    (auth.userProfile.user_level == AppConfig.LEVEL2 ||
                      auth.userProfile.user_level == AppConfig.LEVEL3 ||
                      auth.userProfile.user_level == AppConfig.LEVEL3_CODING ||
                      auth.userProfile.user_level ==
                      AppConfig.LEVEL3_PRESENTATION ||
                      auth.userProfile.user_level == AppConfig.LEVEL3_PROFILE ||
                      auth.userProfile.user_level ==
                      AppConfig.LEVEL3_RESUME) && (
                      <Grid
                        className={`${classes.whatsNext} ${classes.lastText}`}
                        level={AppConfig.LEVEL1}
                      >
                        <Typography
                          component={Text}
                          variant2="link2"
                          className={classes.whatsNextLink}
                          align="left"
                          style={{ cursor: "auto" }}
                        >
                          Congratulations! Your hardwork paid off.
                          <br />
                          You’re ready to take your career to the next level.
                        </Typography>
                        <Grid>
                          <img
                            alt="Vectordashboardsuccess"
                            src={`${ImagebaseUrl}assets/svg/Vectordashboardsuccess.svg`}
                            style={{ width: "53.33px" }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  <ScoreCard></ScoreCard>
                  {auth.userProfile.user_level !== "" &&
                    auth.userProfile.user_level !== null &&
                    auth.userProfile.user_level !== undefined && (
                      <div>
                        <ScoreCalculationDimension></ScoreCalculationDimension>
                        {/* {auth.userProfile.user_level !== AppConfig.LEVEL0 && auth.userProfile.user_level !== AppConfig.LEVEL1 ? (
                          <ImageList rowHeight={172} cols={2} gap={20}>
                            <ImageListItem>
                              {
                                auth.aos && (
                                  <AreaOfStrength
                                    data={auth.aos}
                                  ></AreaOfStrength>
                                )}
                            </ImageListItem>
                            <ImageListItem>
                              {
                                auth.aoi && (
                                  <AreaOfImprovement
                                    data={auth.aoi}
                                  ></AreaOfImprovement>
                                )}
                            </ImageListItem>
                          </ImageList>
                        ) 
                        : null} */}
                        <div className={classes.sampleJobs}>
                          <Typography
                            component={Text}
                            variant2="h4"
                            className={classes.sectionTitle}
                            align="left"
                          >
                            Sample Jobs with Expected Scores
                          </Typography>
                          <ImageList rowHeight={120} cols={2}
                            //spacing={20}
                            gap={20}
                          >
                            {auth &&
                              auth.sampleJobs &&
                              auth.sampleJobs.map((job) => (
                                <ImageListItem key={job["_id"]}>
                                  <Card
                                    className={classes.jobCt}
                                    variant="outlined"
                                    onClick={() => jobLink(job.Link)}
                                  >
                                    <CardContent>
                                      <Grid
                                        container
                                        className={classes.jobsCard}

                                      >
                                        <Grid item xs={9}>
                                          <Typography
                                            component={Text}
                                            variant2="b1Medium"
                                            className={classes.title}
                                            align="left"
                                          >
                                            {job["Sample_Job"]}
                                          </Typography>
                                          <Typography
                                            component={Text}
                                            variant2="b2Medium"
                                            className={classes.companyTitle}
                                            align="left"
                                          >
                                            {job["Company"]}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <div className={classes.jobsScore}>
                                            <Typography
                                              component={Text}
                                              variant2="h3"
                                              className={classes.jobScoreCard}
                                              align="left"
                                            >
                                              {job["Reqd_Score"]}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              className={
                                                classes.jobScoreCardText
                                              }
                                              align="left"
                                            ></Typography>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </ImageListItem>
                              ))}
                          </ImageList>
                        </div>
                        {auth.recommendations && (
                          <WorkshopCard
                            data={auth.recommendations}
                          ></WorkshopCard>
                        )}
                      </div>
                    )}
                </Grid>
              </Grid>
            </Container>
          </div>
        </> 
      )}
      {!isNotMobile && (
        <div className={`${classes.content}`}>
          {/* {auth.isLoading && 
           <RoundedLoadingIndicator></RoundedLoadingIndicator>
          } */}
          <Profile></Profile>
          <div>
            <div className={`${classes.mobileContent}`}>
              {auth.userProfile.emailVerified === false && (
                <Grid className={classes.VerifyEmail}>
                  <Grid className={classes.ImageView}>
                    <img
                      src={`${ImagebaseUrl}assets/svg/warningicon.svg`}
                      className={classes.imgIcon}
                    />
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.titleValue}
                      style={{ color: "white" }}
                      align="center"
                    >
                      Unverified account. Check your email for verification.
                      Account will be deactivated if not verified within 48
                      hours of creation. Please contact us if you’re having
                      issues.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {auth.userProfile.user_level === "" &&
                auth.userProfile.emailVerified === true && (
                  <Grid className={classes.whatsNext}>
                    <Link onClick={() => handleLink("/OpeningScreen")} >
                      <Typography
                        component={Text}
                        variant2="link1"
                        className={classes.whatsNextLink}
                        align="left"
                      >
                        {/* Get Target Score */}
                        Start your target profile
                        <span className={classes.arrowImg}>
                          <img src={`${ImagebaseUrl}assets/Arrow10.svg`} />
                        </span>
                      </Typography>
                    </Link>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.title}
                      align="left"
                    >
                      Complete a few simple profile questions to determine
                      target score in 5 minutes or less.
                    </Typography>
                  </Grid>
                )}
              {auth.userProfile.user_level == AppConfig.LEVEL0 && (
                <Grid className={classes.whatsNext}>
                  <Link onClick={() => handleLink("/L1OpeningScreen")}>
                    <Typography
                      component={Text}
                      variant2="link1"
                      className={classes.whatsNextLink}
                      align="left"
                    >
                      Complete Your Initial Evaluation
                      <span className={classes.arrowImg}>
                        <img src={`${ImagebaseUrl}assets/Arrow10.svg`} />
                      </span>
                    </Typography>
                  </Link>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.title}
                    align="left"
                  >
                    You’ll need to complete all assessment sections to receive a
                    raw score range. A total of approx. 20 minutes for 25
                    questions that cover technical, profile and presentation
                    sections.
                  </Typography>
                </Grid>
              )}
              {auth.userProfile.user_level == AppConfig.LEVEL1 && (
                <Grid className={classes.whatsNext} level={AppConfig.LEVEL1}>
                  <Link onClick={() => handleLink("/L2OpeningScreen")}>
                    <Typography
                      component={Text}
                      variant2="link1"
                      className={classes.whatsNextLink}
                      align="left"
                    >
                      Start Detailed Evaluation
                      <span className={classes.arrowImg}>
                        <img src={`${ImagebaseUrl}assets/Arrow10.svg`} />
                      </span>
                    </Typography>
                  </Link>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.title}
                    align="left"
                  >
                    You'll need to complete all assessment sections to receive
                    your comprehensive career score.
                  </Typography>
                </Grid>
              )}
              {auth.userProfile.totalScore >= auth.userProfile.targetScore &&
                (auth.userProfile.user_level == AppConfig.LEVEL2 ||
                  auth.userProfile.user_level == AppConfig.LEVEL3 ||
                  auth.userProfile.user_level == AppConfig.LEVEL3_CODING ||
                  auth.userProfile.user_level ==
                  AppConfig.LEVEL3_PRESENTATION ||
                  auth.userProfile.user_level == AppConfig.LEVEL3_PROFILE ||
                  auth.userProfile.user_level == AppConfig.LEVEL3_RESUME) && (
                  <Grid
                    className={`${classes.whatsNext} ${classes.lastText1}`}
                    level={AppConfig.LEVEL1}
                  >
                    <Grid>
                      <img
                        src={`${ImagebaseUrl}assets/svg/Vectordashboardsuccess.svg`}
                        style={{ width: "33.3px" }}
                      />
                    </Grid>
                    <Typography
                      component={Text}
                      variant2="link1"
                      className={classes.whatsNextLink}
                      align="center"
                    >
                      Congratulations!
                      <br /> Your hardwork paid off. You’re ready to take your
                      career to the next level.
                    </Typography>
                  </Grid>
                )}
              <ScoreCard></ScoreCard>
              {auth.userProfile.user_level !== "" &&
                auth.userProfile.user_level !== undefined && (
                  <>
                    <div style={{ marginBottom: "10px" }}>
                      <ScoreCalculationDimension></ScoreCalculationDimension>
                    </div>
                    {/* {auth.userProfile.user_level !== AppConfig.LEVEL0 && auth.userProfile.user_level !== AppConfig.LEVEL1 &&
                      auth.aos && (
                        <AreaOfStrength data={auth.aos}></AreaOfStrength>
                      )}
                    {auth.userProfile.user_level !== AppConfig.LEVEL0 && auth.userProfile.user_level !== AppConfig.LEVEL1 &&
                      auth.aoi && (
                        <AreaOfImprovement data={auth.aoi}></AreaOfImprovement>
                      )} */}
                  </>
                )}
            </div>
            {auth.userProfile.user_level !== "" && (
              <>
                <div className={classes.history_border}>
                  {auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                    auth.scoreHistory &&
                    auth.scoreHistory.length && (
                      <div className={classes.mobileContent}>
                        <PersonalHistory
                          data={auth.scoreHistory}
                        ></PersonalHistory>
                      </div>
                    )}
                </div>
                <div className={classes.mobileContent}>
                  <div className={classes.sampleJobs}>
                    <Typography
                      component={Text}
                      variant2="b1Medium"
                      className={classes.sectionTitle}
                      align="left"
                    >
                      Sample Jobs with Expected Scores
                    </Typography>
                    <ImageList
                      cols={1}
                      rowHeight={120}
                      gap={12}
                      className={classes.horizontalList}
                    >
                      {auth &&
                        auth.sampleJobs &&
                        auth.sampleJobs.map((job) => (
                          <ImageListItem key={job["_id"]}>
                            <Card
                              className={classes.jobCt}
                              variant="outlined"
                              onClick={() => jobLink(job.Link)}
                            >
                              <CardContent>
                                <Grid container className={classes.jobsCard} >
                                  <Grid item xs={9}>
                                    <Typography
                                      component={Text}
                                      variant2="b1Medium"
                                      className={classes.title}
                                      align="left"
                                    >
                                      {job["Sample_Job"]}
                                    </Typography>
                                    <Typography
                                      component={Text}
                                      variant2="b2Medium"
                                      className={classes.companyTitle}
                                      align="left"
                                    >
                                      {job["Company"]}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div className={classes.jobsScore}>
                                      <Typography
                                        component={Text}
                                        variant2="h3"
                                        className={classes.jobScoreCard}
                                        align="left"
                                      >
                                        {job["Reqd_Score"]}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        className={classes.jobScoreCardText}
                                        align="left"
                                      ></Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </ImageListItem>
                        ))}
                    </ImageList>
                  </div>
                  {auth.recommendations && (
                    <WorkshopCard data={auth.recommendations}></WorkshopCard>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Footer></Footer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default Dashboard;
