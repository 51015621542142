import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import OpeningScreen from "./pages/quiz/OpeningScreen/OpeningScreen";
import QuizQuestions from "./pages/quiz/QuizQuestions/QuizQuestions";
import ScoreScreen from "./pages/quiz/ScoreScreen/ScoreScreen";
import CreateAccountWithEmail from "./pages/CreateAccountWithEmail";
import CreateAccount from "./pages/CreateAccount";
import LNextSection from "./pages/quiz/LNextSection";
import ProfileQuestions from "./pages/quiz/ProfileQuestions";
import Profile from "../src/components/Profile/Profile";
import ScoreCard from "../src/components/ScoreCard/ScoreCard";
import TransitionScreen from "./components/TransitionScreen/TransitionScreen";
import ErrorScreen from "./pages/ErrorScreen";
import TimeoutDialog from "./components/TimeoutDialog";
import LoadingIndicator from "./components/LoadingIndicator";
import RoundedLoadingIndicator from "./components/RoundedLoadingIndicator";
import Button from "./components/controls/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AppConfig from "./constants/AppConfig";

import CodeEditor from "./components/CodeEditor";
import authAction from "./redux/actions/auth";

import VerifiedAccount  from './pages/Login/VerifiedAccount';
import SavePassword  from './pages/Login/SavePassword';
import Sections from './components/section/Sections';

import "./firebase";
import CodingQuestions from "./pages/quiz/CodingQuestions/CodingQuestions";
import { ProtectedRoute } from "../src/components/protected.route";
import Login from "./pages/Login/Login";
import LoginWithEmail from "./pages/Login/LoginWithEmail";
import ResetPassword from "./pages/Login/Resetpassword";
import SuccessScreen from "./pages/Login/SuccessScreen";
import CreateVerifyScreen from "./pages/createVerify";
import FAQ from "./components/FAQ";
import AboutPage from "./components/About";
import InviteFriendContent from "./components/dialogContent/InviteFriendContent";
import TimerTest from "./pages/TimerTest";
import PrivacyPolicy from "./pages/LegalPages/Privacy";
import TermsConditions from "./pages/LegalPages/Terms&Conditons";
import EditprofileModal from "./components/EditprofileModel";
import TimeOutCaptureScreen from "./pages/TimeOutCaptureScreen";
import SimpleSnackbar from "./components/controls/ToastMessage";
import UnsupportedBrowser from "./components/UnsupportedBrowser";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import ColoredLinearProgress from "./components/controls/coloredLinearProgressbar";
import Adminpage from "./pages/Adminpage/Adminpage";
import AdminViewDetails from "./pages/Adminpage/Adminviewdetails";
import LineChart from "./components/GraphScoreMeter/LineChart";

// appRouter

function AppRouter(props) {

  let apiError = useSelector((state) => state?.auth?.apiError);
  let authloading = useSelector((state) => state?.auth?.isLoading);
  let authuser = useSelector((state) => state?.auth?.user)

  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [networkToast, setNetworkToast] = useState(false)
  const quizState = useSelector((state) => state.quiz.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  let currentPathname = null;
  let currentSearch = null;

  useLayoutEffect(() => {
    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== currentPathname ||
          newLocation.search !== currentSearch
        ) {
          currentPathname = newLocation?.pathname;
          currentSearch = newLocation?.search;
          history.push({
            ...newLocation,
          });
        }
        AppConfig.goBackFlag = false;
      } else if (action === "POP" && AppConfig.goBackButton == true) {
        history.goBack();
        AppConfig.goBackButton = false;
        AppConfig.goBackFlag = true;
      } else if (!AppConfig.goBackFlag && action === "POP") {
        history.go(1);
        setOpen(true);
      }
    });

    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    localStorage.removeItem("previousCategory");
  }, []);

  useEffect(() => {
    if (process.env.React_App_ENV === "production") {
      //window.addEventListener("contextmenu", e => e.preventDefault());
      document.addEventListener('contextmenu', event => event.preventDefault());
    }
  }, [])
  const handleDialogClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (apiError) {
      if (apiError?.message) {
        setToastMessage(apiError?.message);
      } else {
        setToastMessage('Internal server error!');
      }
      setOpenToast(true);
      networkError()
    }
  }, [apiError]);

  function setOpenFunc() {
    setOpenToast(false);
    dispatch(authAction.apiError(""));
  }

  const handleClose = () => {
    setNetworkToast(false)
  }

  useEffect(() => {
    networkError()
    //  console.log("fdgfgfh")
    //     let online = window.navigator.onLine ? "online" : "offline"
    //     if(online == "online"){
    //       setNetworkToast(false)
    //     }
    //     else{
    //       setNetworkToast(true)
    //     }
    window.addEventListener('online', networkError);
    window.addEventListener('offline', networkError);
    return () => {
      window.removeEventListener('online', networkError);
      window.removeEventListener('offline', networkError)
    }
  }, [])

  const networkError = () => {
    //let online = window.navigator.onLine ? "online" : "offline"
    if (navigator.onLine) {
      setNetworkToast(false)
    }
    else {
      setNetworkToast(true)
    }
  }

  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/LoginWithEmail" component={LoginWithEmail} />
        <Route exact path="/ResetPassword" component={ResetPassword} />
        <Route exact path="/EmailSuccessScreen" component={SuccessScreen} />
        <Route exact path="/ResetpasswordSuccessScreen" component={SuccessScreen}/>
        <ProtectedRoute exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/OpeningScreen" component={OpeningScreen} />
        <Route exact path="/L1OpeningScreen" component={OpeningScreen} />
        <Route exact path="/L2OpeningScreen" component={OpeningScreen} />
        <Route exact path="/FAQ" component={FAQ} />
        <Route exact path="/Aboutus" component={AboutPage} />
        <Route exact path="/InviteFriend" component={InviteFriendContent} />
        <Route exact path="/EditProfile" component={EditprofileModal} />
        <Route exact path="/Privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/Terms&Conditions" component={TermsConditions} />
        <Route exact path="/VerifiedAccount" component={VerifiedAccount} />
        <Route exact path="/SavePassword" component={SavePassword} />
        <Route exact path="/Sections" component={Sections} />
        <Route exact path="/LineChart" component={LineChart} />
        <Route
          exact
          path="/L3PresentationOpeningScreen"
          component={OpeningScreen}
        />
        <Route exact path="/admin/summary-report" component={Adminpage}/>
        <Route exact path="/admin/detail-report" component={AdminViewDetails}/>
        <Route exact path="/GeneratingProfile" component={TransitionScreen} />
        <Route exact path="/CalculatingScore" component={TransitionScreen} />
        <Route exact path="/UpdatingDashboard" component={TransitionScreen} />
        <Route exact path="/ScoreScreen" component={ScoreScreen} />
        <Route
          exact
          path="/QuizQuestions/:path/:qIndex"
          component={QuizQuestions}
        />
        <Route exact path="/CreateAccount" component={CreateAccount} />
        <Route
          exact
          path="/CreateAccountWithEmail"
          component={CreateAccountWithEmail}
        />
        <Route
          exact
          path="/CreateEmailSuccess"
          component={CreateVerifyScreen}
        />
        <Route
          exact
          path="/CreateverifySuccess"
          component={CreateVerifyScreen}
        />
        <Route
          exact
          path="/PresentationEvaluation"
          component={TransitionScreen}
        />
        <Route exact path="/TechnicalEvaluation" component={TransitionScreen} />
        <Route exact path="/CodingEvaluation" component={TransitionScreen} />
        <Route exact path="/CodeEditor" component={CodeEditor} />
        <Route exact path="/LNextSection" component={LNextSection} />

        <Route exact path="/LoadingIndicator" component={LoadingIndicator} />
        <Route
          exact
          path="/RoundedLoadingIndicator"
          component={RoundedLoadingIndicator}
        />

        <Route exact path="/L3CodingOpeningScreen" component={OpeningScreen} />
        <Route
          exact
          path="/CodingSectionUnavailable"
          component={OpeningScreen}
        />
        <Route
          exact
          path="/TimeOutCaptureScreen"
          component={TimeOutCaptureScreen}
        />
        <Route
          exact
          path="/CodingQuestions/:qIndex"
          component={CodingQuestions}
        />
        <Route exact path="/L3ProfileOpeningScreen" component={OpeningScreen} />
        <Route exact path="/ProfileQuestions" component={ProfileQuestions} />
        <Route
          exact
          path="/ProfileOpeningScreen"
          component={TransitionScreen}
        />
        <Route exact path="/L2NextOpeningScreen" component={TransitionScreen} />
        <Route exact path="/Profile" component={Profile} />
        <Route exact path="/ScoreCard" component={ScoreCard} />
        <Route exact path="TransitionScreen" component={TransitionScreen} />
        <Route exact path="/UnsupportedBrowser" component={UnsupportedBrowser} />
        <Route exact path="/ServerError" component={ErrorScreen} />
        <Route exact path="/TimerTest" component={TimerTest} />
        <Route component={ErrorScreen} />
        <Route exact path="/TimeoutDialog" component={TimeoutDialog} />
      </Switch>

      <div>
        <SimpleSnackbar open={openToast} setOpen={() => setOpenFunc()} vertical={'bottom'} horizontal={'left'} severity="error" message={toastMessage} />
      </div>
      {/* <Backdrop
       className={props.className}
        open={quizState}
      > */}
      {quizState && (
        <div className={props.loader}>
          <ColoredLinearProgress />
        </div>
      )}
      {/* </Backdrop>
         <Backdrop
        className={props.className}
        open={authloading && authuser}
      > */}
      {authloading && authuser && (
        <div className={props.loader}>
          {/* <LinearProgress  className={props.progressBar}/> */}
          <ColoredLinearProgress />
        </div>
      )}
      {/* </Backdrop> */}
      <div>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={networkToast}
          //autoHideDuration={6000}
          //onClose={handleClose}
          message={"Application seems to be offline. Please check your internet connection."}
          style={{ top: '95px' }}
        // action={
        //   <React.Fragment>
        //     <IconButton
        //       size="small"
        //       aria-label="close"
        //       color="inherit"
        //       onClick={handleClose}
        //     >
        //       <CloseIcon fontSize="small" />
        //     </IconButton>
        //   </React.Fragment>
        // }
        />
      </div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        onBackdropClick={() => handleDialogClose()}
        PaperProps={{
          style: {
            width: "350px",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogContent dividers>
          <Typography variant2="b2Medium" align="center">
            Please do not use the navigation buttons (back, forward or refresh)
            of the browser.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="btnPrimary"
            text="OK"
            width="120px"
            // height="30px"
            onClick={() => handleDialogClose()}
          ></Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AppRouter;
