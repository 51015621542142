import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../components/controls/Button";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/controls/TextInput";
import { Container } from "@material-ui/core";
import Checkbox from "../../components/controls/Checkbox";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Text } from "../../styles/theme";
import backArrowImg from "../../assets/Arrow 10-2.png";
import BackArrow from "../../assets/svg/BackArrow.svg";
import logo from "../../assets/logo.svg";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import auth from "../../redux/actions/auth";
import { ClassSharp } from "@material-ui/icons";
import { ImagebaseUrl } from "../../services/globalService";
import { firebaseAuthApp } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
    marginTop: "48px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
  },
  cardSix: {
    marginTop: "200px",
    width: "100%",
    justifyContent: "space-between",
    // paddingBottom: "126px",
    // marginLeft: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      // width: 458,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "200px",
    },

    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    cursor: "pointer",
  },
  cardTwoH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {},
  },

  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formControl: {
    marginTop: 24,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
  },
  telField: {
    minHeight: 48,
    marginTop: 8,
    margin: "8px 0px 0px 0px",
    backgroundColor: theme.palette.background.light,
  },
  phonewithCode: {
    display: "flex",
    alignItems: "flex-end",
  },
  phoneNo: {
    width: "100%",
  },
  countryCode: {
    backgroundColor: theme.palette.background.level2,
  },
  submit: {
    marginTop: "24px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("tablet")]: {
      // width: 500,
    },
    "& button": {
      [theme.breakpoints.up("tablet")]: {
        marginLeft: "0",
        marginRight: "0",
      },
    },
  },
  arrowImg: {
    marginLeft: 8,
  },
  backArrowImg: {
    marginRight: 8,
  },
  phoneErr: {
    marginLeft: "23%",
    marginTop: "2%",
  },
  createAccountDescriptionArea: {
    // fontSize: "13px",
    lineHeight: "24px",
    marginTop: "30px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  font15: {
    fontSize: "12px",
  },
  policycolor: {
    color: theme.palette.btnPrimary.main,
  },
  contentArea: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  passwordText: {
    display: "flex",
    justifyContent: "space-between",
  },
  charvalue: {
    color: "#787878",
  },
  accountText: {
    color: "#787878",
    // fontFamily: "Roboto",
    // fontStyle: "normal",
    // fontWeight: "normal",
    // fontSize: "14px",
    // lineHeight: "20px",
    // [theme.breakpoints.up("tablet")]: {
    //   fontSize: "16px",
    //   lineHeight: "24px",
    // },
  },
  TechScore: {
    fontFamily: "Frank Ruhl Libre",
    fontStyle: " normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
  },
}));

function ResetPassword(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showSnackBar, setShowSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const onSubmit = (values) => {
    values.level = location.level;
    console.log("Form data", values);
  };

  const onClickResetPassword = () => {
    if (formik.values && formik.values.email) {
      // firebaseAuthApp
      //   .sendPasswordResetEmail(formik.values.email)
      //   .then((user) => {
      //     history.push({
      //       pathname: "/EmailSuccessScreen",
      //     });
      //   })
      //   .catch((error) => {
      //     setErrorMsg(error.message);
      //     setOpenSnackbar(true);
      //   });
      let payload = {email:formik.values.email}
      dispatch(auth.forgotPassword(payload))
      setTimeout(() => {
        history.push({
          pathname:"/EmailSuccessScreen"
        })
      },500)
     
    }
  };

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter a valid format"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img
          alt="logo"
          src={`${ImagebaseUrl}assets/logo.svg`}
          className={classes.logoCls}
        />
      </div>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}

        <Grid item xs={isNotMobile ? 4 : 12} className={classes.contentArea}>
          <div className={`${classes.section} ${classes.cardTwo}`}>
            <Typography
              component={Text}
              variant2={isNotMobile ? "h3" : "h4"}
              align="center"
              className={`${classes.cardTwoH1} ${classes.title}`}
            >
              Reset Password
            </Typography>
          </div>
          <div
            className={`${classes.section}  ${classes.card} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
            >
              Please enter your email address, we will send you a link to change
              your password
            </Typography>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <Container className={classes.inputCt}>
              <div className={classes.formControl}>
                <label htmlFor="email">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Email
                  </Typography>
                </label>
                <TextInput
                  name="email"
                  // placeholder="hellofy@gmail.com"
                  className={classes.field}
                  id="email"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email && formik.errors.email
                      ? Boolean(formik.errors.email)
                      : null
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                ></TextInput>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.email}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </Container>

            <div
              className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
            >
              <Button
                disabled={!(formik.isValid && formik.dirty && formik.values)}
                type="submit"
                color="btnPrimary"
                width="100%"
                text="Reset Password"
                onClick={onClickResetPassword}
                data-rt-resetpassword-resetsubmit = "resetsubmit"
              ></Button>
            </div>

            <Grid
              container
              className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link onClick={() => history.push("/LoginWithEmail")} data-rt-resetpassword-backarrow = "backarrow">
                  <Typography
                    component={Text}
                    variant2="link1"
                    align="left"
                    className={`${classes.labelBlue}`}
                  >
                    <span className={classes.backArrowImg}>
                      <img
                        alt="BackArrow"
                        src={`${ImagebaseUrl}assets/svg/BackArrow.svg`}
                      />
                    </span>
                    Back
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                  align="right"
                  className={classes.accountText}
                >
                  Don't have an account? &nbsp;
                  <Link
                    onClick={() => history.push("/CreateAccountWithEmail")}
                    variant={isNotMobile ? "link1" : "link2"}
                    className={`${classes.labelBlue}`}
                    data-rt-resetpassword-signupbtn = "signupbtn"
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message={errorMsg}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default ResetPassword;
