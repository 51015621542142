import React, { useState } from "react";
import Button from "./controls/Button";
import MuiDialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Text } from "../styles/theme";
import Typography from "@material-ui/core/Typography";
import auth from "../redux/actions/auth";
import AppConfig from "../constants/AppConfig";
import quiz from "../redux/actions/quiz";
import { useSelector, useDispatch } from "react-redux";
import { CallMissedSharp } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  next: {
    "& button": {
      height: "40px",
    },
  },
}));
const Dialog = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: "23px !important",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: "20px",
  },
}))(MuiDialogActions);

export default function DeleteDialog(props) {
  const { open, text, succesBtnLabel, actionInfo, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const onClickSubmit = () => {
    props?.OnDelete();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.onClose}
        onBackdropClick="false"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            //width: "450px",
            //height: "203px",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Typography
            component={Text}
            variant2="b1Regular"
            className=""
            align="center"
          >
            {/* {text} */}Wish to delete your account?
            This will erase all your scores and login information. {isNotMobile ? <br /> : null}
            You will have to create a new account to proceed.
             Are you sure?
            {/* Do you really want to delete your account?
            <br />
            This will erase all your scores and login information.
            <br />
            You will have to create a new account to continue.
            {isNotMobile ? <br /> : null} Are you sure? */}
          </Typography>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            color="btnPrimary"
            text="Cancel"
            width={"120px"}
            onClick={props.onClose}
            data-rt-deleteprofile-cancel = "cancel"
          ></Button>
          <Button
            color="btnSecondary"
            text={"Yes"}
            width={"120px"}
            onClick={() => onClickSubmit()}
            data-rt-deleteprofile-yes = "yes"
          ></Button>
          <Grid className={classes.next}></Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
