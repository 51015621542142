import React, { useEffect } from "react";
import AppRouter from "./AppRouter";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import "./App.css";
import store from "./redux/store";
import { theme } from "./styles/theme";
import { ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import "./firebase";

import { useHistory } from "react-router-dom";
import { SettingsPowerRounded } from "@material-ui/icons";
import { set } from "lodash";
import AppConfig from "./constants/AppConfig";

const useStyles = makeStyles((them) => ({
  mainCls: {
    width: "100%",
    height: "100vh",
    backgroundColor: theme.palette.background.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    background:'transparent'
  },
  loader:{
    position: "fixed",
    top: "60px",
    width:'100%',
    zIndex:1400,
    [theme.breakpoints.up("tablet")]:{
      top:'80px',
      zIndex:1,
    },
  },
  
}));

function App() {
  const classes = useStyles();
  const history = useHistory();

  // window.history.pushState(null, "", window.location.href);
  const [open, setOpen] = React.useState(false);

  // window.onpopstate = () => {
  //   if (!AppConfig.goBackButton) {
  //     setOpen(true);
  //     window.history.go(1);
  //   }
  //   setTimeout(() => {
  //     AppConfig.goBackButton = false;
  //   }, 5000);
  // };

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <div id="mainDiv" className={classes.mainCls}>
              <AppRouter className={classes.backdrop} loader={classes.loader} ></AppRouter>
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
