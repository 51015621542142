import axios from "axios";
import store from '../redux/store';
import authAction from "../redux/actions/auth";
const axiosInstance = axios.create({
  baseURL:"https://tz6osgiqne.execute-api.us-east-2.amazonaws.com/prodAPI/",
  // baseURL:"https://a7692787a31054a9792aa4bb5e0f91cc-1852846544.us-east-2.elb.amazonaws.com/api/",
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.Authorization,
  },
  timeout: 30000
});

axiosInstance.interceptors.response.use(
  (response) => {
      if(response?.data?.status !== 0){
        let tempMsg = response?.data?.message
        if(tempMsg && typeof tempMsg == "object"){
        tempMsg = tempMsg.message || "Unknown API error"
        }
        store.dispatch(authAction.apiError({message: tempMsg}));
      }else{
        return response.data;
      }
  },
  (error) => {
    if(error){
      if(error.message.includes("timeout of")){
        store.dispatch(authAction.apiError({
          message: "Sorry! It took longer than expected. Please try again."
        }));
      }
      else if(error.message.includes("Network Error")){
        store.dispatch(authAction.apiError({
          message: "Network error. Please check your connection and retry."
        }));
      }
      else{
        store.dispatch(authAction.apiError(error));
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
