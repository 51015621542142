import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import "./Home.css";
import { makeStyles } from "@material-ui/core/styles";

import { Text } from "../../styles/theme";
import Container from "@material-ui/core/Container";

import Button from "../../components/controls/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import authState from "../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";

// import bashboardImg from "../../assets/images.svg";
// import playVideoImg from "../../assets/image with plants-2.png";
// import imagewithplants from "../../assets/image with plants.png";
// import aboutUsImg from "../../assets/about-us.png";
// import landingTargetImg from "../../assets/landingTargetImg.PNG";
// import howLong1Img from "../../assets/howlong_1.png";
// import howLong2Img from "../../assets/howlong_2.png";
// import howLong3Img from "../../assets/howlong_3.png";
// import DashboardMobile from "../../assets/DashboardMobile.jpg";
// import mDashboardMock from "../../assets/mDashboardMock.png";
// import desktopDashboard from "../../assets/desktopDashboard.png";
import quizAction from "../../redux/actions/quiz";


import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import { removeAnswers, ImagebaseUrl } from "../../services/globalService";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RoundedLoadingIndicator from "../../components/RoundedLoadingIndicator";

const { detect } = require('detect-browser');
const browser = detect();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginBottom: "24px",
  },
  stepTitle: {
    flexGrow: 1,
    marginBottom: "32px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "10px",
      // marginLeft: "-5px",
    }
  },
  heroTitle: {
    flexGrow: 1,
    fontFamily: theme.typography.h1.fontFamily,
    // fontSize: 64,
    // lineHeight: "64px",
    // fontStyle: "normal",
    // fontWeight: 900,
  },
  hero: {
    alignItems: "center",
    marginTop: 56,
    [theme.breakpoints.up('tablet')]: {
      height: 504,
    },
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.primary.light,
      background: `linear-gradient(180deg, ${theme.palette.background.main} 12.38%, ${theme.palette.background.paper} 78.96%), ${theme.palette.background.paper}`
    }
  },
  why: {
    backgroundColor: theme.palette.background.level2,
    [theme.breakpoints.up('tablet')]: {
      height: 300,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.neutrals.grayLight,
    }
  },
  knowYourCareer: {
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up('tablet')]: {
      height: 434,
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiContainer-root': {
        paddingRight: "14px",
        paddingLeft: "12px",
      }
    }
  },
  howLong: {
    // backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('tablet')]: {
      height: 742,
      paddingTop: 64
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 48,
      backgroundColor: theme.palette.background.paper,
    }
  },
  setpText: {
    [theme.breakpoints.up('tablet')]: {
      padding: 24
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px  25px',
      textalign: 'left',
      // marginLeft:'10%'
    }
  },
  aboutUs: {
    backgroundColor: "rgb(243,243,254)",
    [theme.breakpoints.up('tablet')]: {
      paddingTop: '48px',
      paddingBottom: '48px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "rgb(243,243,254)",
      paddingBottom: '38px',
    }
  },
  heroImgcls: {
    // height: "430px",
    [theme.breakpoints.down('sm')]: {
      width: '164px',
      height: '310px',
      margin: 'auto',
      marginTop: '16px',
      boxShadow: "0px 6.54417px 32.7208px rgba(196, 196, 196, 0.2)"
    },
    [theme.breakpoints.up('sm')]: {
      width: "100%",
      float: "right",
    }
  },
  whyLeftCls: {
    [theme.breakpoints.up('tablet')]: {
      marginRight: "4%",
    }
  },
  whyVideo: {
    [theme.breakpoints.up('tablet')]: {
      float: "right",
    }
  },

  aboutUsImgCls: {
    [theme.breakpoints.up('tablet')]: {
      float: "right",
      width: 505
    }
  },

  homeHeroRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  itemsLeftAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "row",
  },
  knowYourCareerLeft: {
    marginLeft: "64px",
    textAlign: "center",
  },
  scoreInfo: {
    marginTop: "24px",
    marginBottom: "40px",
  },
  scoreCard: {
    // margin: "40px 5% 60px 0px",
  },
  checkScore: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "0px",
    paddingLeft: "0px",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      marginTop: "24px",
      marginBottom: "32px",
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: "flex-start",
    }
  },
  heroParaOne: {
    marginTop: "24px",
    marginBottom: "24px",
    [theme.breakpoints.up('tablet')]: {
      marginTop: "24px",
      marginBottom: "24px",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "8px",
      marginBottom: "0px",
    }
  },
  heroParaTwo: {
    marginTop: "24px",
    marginBottom: "32px",
    [theme.breakpoints.up('tablet')]: {
      marginBottom: "24px",
    }
  },
  parentDiv: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "center",
    marginTop: "24px",
    [theme.breakpoints.up('tablet')]: {
      marginBottom: "40px"
    }
  },
  howLongH2: {
    // paddingTop: "64px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "16px",
    }
  },
  howLongBtn: {
    [theme.breakpoints.up('tablet')]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: "40px"
    }
  },
  homeHeroLeft: {
    textAlign: "center",
    [theme.breakpoints.up('tablet')]: {
      // marginRight: "3%"
    }
  },
  h3Subtitle: {
    // lineHeight: "20px",
  },
  connector: {
    borderTop: "2px solid red",
  },
  alignItem: {
    [theme.breakpoints.up('tablet')]: {
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'fit-content'
    }
  },
  horizontalLine: {
    [theme.breakpoints.up('tablet')]: {
      width: "100%",
      borderBottom: `8px solid ${theme.palette.background.light}`,
    }
  },
  space: {
    width: "100%",
  },
  containerCls: {
    [theme.breakpoints.up('tablet')]: {
      paddingRight: "64px !important",
      paddingLeft: "64px !important",
    },
    [theme.breakpoints.up('mobile')]: {
      paddingRight: "24px",
      paddingLeft: "24px",
    }
  },
  techCarrrierText: {
    // width: "480px",
    // height: "96px",
    // fontWeight: 700,
    marginTop: '80px',
    [theme.breakpoints.up('tablet')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '18px',
    }
  },
  robotoFont: {
    fontFamily: "Roboto",
    // fontWeight: 500,
    // lineHeight: "26px",
    // fontSize: "18px",
    // width: "450px",
    // height: "78px",
    // letterSpacing: "0.005em",
  },
  inspireText: {
    width: "auto",
    height: "52px",
    [theme.breakpoints.up('tablet')]: {
      width: "535px !important",
    }
  },
  determineProfessionalInfoText: {
    // width: "535px",
    // height: "78px",
    // lineHeight: "26px",
    // fontSize: "18px",
    [theme.breakpoints.down('sm')]: {
      // fontSize: "14px",
      // lineHeight: "20px",
      marginTop: "24px",
      marginBottom: "0px"
    }
  },
  howLongItDoesTextArea: {
    width: "auto",
    // lineHeight: "26px",
    // fontSize: "18px",
    [theme.breakpoints.up('tablet')]: {
      width: "750px !important",
      height: "78px"
    },
    [theme.breakpoints.down('sm')]: {
      // fontSize: "14px",
      // lineHeight: "20px",
      marginBottom: "32px"
    }
  },
  scoreTextArea: {
    width: "auto",
    // height: "52px",
    lineHeight: "24px",
    fontSize: "16px",
    // marginTop: "30px",
    [theme.breakpoints.up('tablet')]: {
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: "16px"
    }
  },
  carrierInspirationText: {
    // width: "500px",
    // height: "52px",
    [theme.breakpoints.up('tablet')]: {
    }
  },
  marginTop40: {
    marginTop: "24px",
    [theme.breakpoints.down('sm')]: {
      marginTop: "0px"
    }
  },
  fontWeight400: {
  },
  aboutTextArea: {
    // width: "466px",
    // height: "130px",
    // marginTop: "30px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "8px"
    }
  },
  letterSpacing2: {
    letterSpacing: "-0.02em",
  },
  letterSpacing05: {
    // letterSpacing: "0.05em",
  },
  letterSpacing1: {
    letterSpacing: "0.01em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
      lineHeight: "20px",
    }
  },
  fontSize40: {
  },
  rationalFont: {
    color: "#2F2F2F",
  },
  colorNeutrals: {
    color: "#2F2F2F",
  },
  videoEl: {
    width: '100% !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '48px !important',
      marginBottom: '22px !important'
    }
  },
  applyMargin: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-30px !important',
    }
  },
  heroParaOneMob: {
  },
  whyLeftClsMobile: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  knowYourCareerMob: {
    margin: '48px auto 0px auto'
  },
  aboutMob: {
    margin: '48px auto 16px auto !important',
    [theme.breakpoints.down('sm')]: {
      margin: '32px auto 16px auto !important',
    }
  },
  borderLeftCls: {
    // borderLeft: `4px solid ${theme.palette.background.light} !important`,
  },
  borderLeft: {
    borderLeft: `4px solid ${theme.palette.background.light} !important`,
    height: 102,
    width: 'fit-content',
    margin: 'auto'
  },
  borderLeft3: {

    [theme.breakpoints.down('sm')]: {
      marginBottom: '40px',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '14px',
      height: '158px'
    },
  },
  desktopDashboard: {
    width: '100%',
    // height:'429px'
  },
  containerOne: {
    height: 'auto !important',
    marginTop: '56px',
    alignItems: 'center'
  },
  myImgwithPlants: {
    width: '500px'
  },
  getMyScoreWrapper : {
      marginTop: "24px"
  }

}));

function Home(props) {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const routeParams = useParams();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(quizAction.resetElapsedTime(0));
    sessionStorage.removeItem("remainigTime");
    // dispatch(authState.clearState());
    dispatch(quizAction.quizSelectionPopupStatus(false))
    dispatch(quizAction.quizSelectionPopupDisable(false));
    dispatch(quizAction.quizSelectionTopic(""));
    if (auth && auth.user && auth.user.email) {
      dispatch(authState.getProfile({ email: auth.user.email }));
    } else if (auth.userProfile) {
      history.push("/Dashboard");
    }
    dispatch(quizAction.setSelectedQuesNdAns([]));
    removeAnswers();
  }, []);


  useEffect(() => {
    if (auth.userProfile) {
      history.push("/Dashboard")
    } else {
      dispatch(authState.clearState());
    }
  }, [auth.userProfile]);

  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto",
        /* you can also use 'auto' behaviour
                  in place of 'smooth' */
      });
    }, 500)
  };

  const networkError = () => {
    if (navigator.onLine) {
      history.push("/OpeningScreen")
    }
    else {
      setOpenSnackBar(true)
    }
  }

  const handleClose = () => {
    setOpenSnackBar(false)
  }

  useEffect(() => {
    var isSupported = true;
    var majorVer;
    if (browser && browser.version) {
      console.log('Detected browser: ', browser);
      majorVer = parseInt(browser.version.split(".")[0]);
    }

    switch (browser && browser.name) {
      case 'chrome':
        if (majorVer < 85) {
          isSupported = false;
        }
        break;

      case 'firefox':
        if (majorVer < 69) {
          isSupported = false;
        }
        break;
    
      case 'edge':
        if (majorVer < 18) {
          isSupported = false;
        }
        break;

      case 'ie':
          isSupported = false;
          break;
      
      case 'safari':
        if (majorVer < 11) {
          isSupported = false;
        }
        break;

      case 'safari':
          if (majorVer < 11) {
            isSupported = false;
          }
          break;
    }

    if (!isSupported) {
      console.log('not supported ', browser.name, browser.version, majorVer);
      history.push("/UnsupportedBrowser");
    } else {
      console.log('supported ', browser.name, browser.version, majorVer);
    }
  }, [routeParams]);

  return (
    <>
      {isNotMobile && (
        <div className={`${classes.root}`}>
          <NavBar></NavBar>
          {/* {auth.isLoading &&
           <RoundedLoadingIndicator></RoundedLoadingIndicator>
          } */}

          <div>
            <Container
              maxWidth="xl"
              className={`${classes.containerCls} ${classes.containerOne}`}
            >
              <Grid container className="Home-hero" spacing={3}>
                <Grid item xs={5}>
                  <div className={classes.homeHeroLeft}>
                    <Typography
                      component={Text}
                      variant2="dataViz2"
                      className={`${classes.heroTitle} ${classes.techCarrrierText} ${classes.colorNeutrals}`}
                      align="left"
                    >
                      Tired of your job search?
                    </Typography>
                    <div className={`${classes.carrierInspirationText}`}>
                      <Typography
                        component={Text}
                        variant2="h6"
                        className={`${classes.title}  ${classes.heroParaOne} ${classes.colorNeutrals}`}
                        align="left"
                      >
                        Hiring managers evaluate you based on your resume, technical 
                        knowledge, coding skills and interview presentation. 
                        Do you know where you stand? Check your score to know.
                      </Typography>
                    </div>

                    <div className={`${classes.getMyScoreWrapper}`}>
                      <Button
                       onClick={() => networkError()}
                        color="btnPrimary"
                        text="Get My Score"
                        width="80%"
                        data-rt-div-getmyscore = "getmyscore"
                      ></Button>
                    </div>

                    <Typography
                      component={Text}
                      variant2="b1Regular"
                      className={`${classes.title}  ${classes.heroParaTwo} ${classes.colorNeutrals}`}
                      align="left"
                    >
                      We will show you which areas you need to improve to reach your target.
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={7} className={classes.whyVideo}>
                  <img
                    className={`${classes.desktopDashboard}`}
                    alt="dashboard"
                    // src={`${ImagebaseUrl}desktopDashboard.e6953350.png`}
                    src={`${ImagebaseUrl}assets/desktopDashboard.png`}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>

          <div className={classes.why}>
            <Container
              maxWidth="xl"
              className={`${classes.why} ${classes.containerCls}`}
            >
              <Grid container className="Home-hero">
                <Grid item xs={5}>
                  <div>
                    <Typography
                      component={Text}
                      variant2="h6"
                      className={`${classes.title}  ${classes.whyLeftCls} ${classes.colorNeutrals}`}
                      align="left"
                    >
                      Your career score is like a credit score for your career,
                      giving you an idea of where you’re at professionally and
                      how to navigate to the next level.
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={7} className="">
                  <img
                    className={`${classes.whyVideo}  ${classes.myImgwithPlants}`}
                    alt="video"
                    // src={imagewithplants}
                    src={`${ImagebaseUrl}assets/image with plants.png`}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>

          <div className={classes.knowYourCareer}>
            <Container
              maxWidth="xl"
              className={`${classes.knowYourCareer} ${classes.containerCls}`}
            >
              <Grid container className="Home-hero">
                <Grid item xs={5}>
                  <div className={classes.homeHeroLeft}>
                    <Typography
                      component={Text}
                      variant2="h2"
                      className={`${classes.title} ${classes.colorNeutrals}`}
                      align="left"
                    >
                      Know Your Career Score
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="h6"
                      className={`${classes.title} ${classes.scoreInfo} ${classes.determineProfessionalInfoText} ${classes.letterSpacing05} ${classes.colorNeutrals}`}
                      align="left"
                    >
                      We measure your score to determine your professional readiness
                      against your target score for the future position. Our
                      questions mimic the entire interview process, from inception
                      to selection.
                    </Typography>
                    <div className={classes.checkScore}>
                      <Button
                        onClick={() => networkError()}
                        color="btnPrimary"
                        width="80%"
                        text="Check Score"
                        data-rt-div-checkscore = "checkscore"
                      ></Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  {/* <img
                 className={classes.whyVideo}
                 alt="dashboard"
                 src={landingTargetImg}
               /> */}
                  <div className={classes.whyVideo}>
                    <ImageCarousel></ImageCarousel>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>

          <div className={classes.howLong}>
            <Container maxWidth="xl" className={`${classes.containerCls}`}>
              <Typography
                component={Text}
                variant2="h2"
                className={`${classes.title}  ${classes.howLongH2} ${classes.colorNeutrals}`}
                align="center"
              >
                How long does it take?
              </Typography>

              <div className={`${classes.parentDiv} ${classes.center}`}>
                <div className={classes.clOne}></div>
                <div className={`${classes.clTwo} ${classes.center}`}>
                  <Typography
                    component={Text}
                    variant2="h6"
                    className={`${classes.title} ${classes.howLongItDoesTextArea} ${classes.colorNeutrals}`}
                    align="center"
                  >
                    There are 3 simple steps to get your career score based on a
                    desired position and target timeline. We will first provide a
                    score range with an initial evaluation. You can narrow the range
                    with detailed evaluations and get your career score.
                  </Typography>
                </div>
                <div className={classes.clThree}></div>
              </div>

              {/* <div className={classes.connector}></div> */}

              <Grid container className="how-long-main">
                <Grid item xs={4}>
                  <div>
                    <Typography
                      component={Text}
                      variant2="h4"
                      className={classes.stepTitle}
                      align="center"
                    >
                      <span
                        className={`${classes.h3Subtitle} ${classes.colorNeutrals}`}
                      >
                        Step 1 - Target Score
                      </span>
                    </Typography>
                    <div className={classes.alignItem}>
                      <div className={classes.space}></div>
                      <img className="stepImg" alt="step1" src={`${ImagebaseUrl}assets/howlong_1.png`} />
                      <div className={classes.horizontalLine}></div>
                    </div>
                    <div className={`${classes.scoreTextArea} ${classes.center}`}>
                      <Typography
                        component={Text}
                        variant2="b1Regular"
                        className={`${classes.setpText} ${classes.colorNeutrals}`}
                        align="center"
                      >
                        Few simple profile questions to determine <br /> target score
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <Typography
                      component={Text}
                      variant2="h4"
                      className={classes.stepTitle}
                      align="center"
                    >
                      <span
                        className={`${classes.h3Subtitle}`}
                      >
                        Step 2 - Score Range
                      </span>
                    </Typography>
                    <div className={classes.alignItem}>
                      <div className={classes.horizontalLine}></div>
                      <img className="stepImg" alt="step1"
                        //src={howLong2Img}
                        src={`${ImagebaseUrl}assets/howlong_2.png`}
                      />
                      <div className={classes.horizontalLine}></div>
                    </div>

                    <div className={`${classes.scoreTextArea} ${classes.center}`}>
                      <Typography
                        component={Text}
                        variant2="b1Regular"
                        className={classes.setpText}
                        align="center"
                      >
                        25 Questions, 20 Minutes
                        <br /> Questions related to your background, <br />technical
                        expertise, presentation skills
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <Typography
                      component={Text}
                      variant2="h4"
                      className={classes.stepTitle}
                      align="center"
                    >
                      <span
                        className={`${classes.h3Subtitle}`}
                      >
                        Step 3 - Career Score
                      </span>
                    </Typography>
                    <div className={classes.alignItem}>
                      <div className={classes.horizontalLine}></div>
                      <img className="stepImg" alt="step1" src={`${ImagebaseUrl}assets/howlong_3.png`} />
                      <div className={classes.space}></div>
                    </div>
                    <div className={`${classes.scoreTextArea} ${classes.center}`}>
                      <Typography
                        component={Text}
                        variant2="b1Regular"
                        className={classes.setpText}
                        align="center"
                      >
                        Multiple evaluation sections covering <br /> technical questions,
                        coding challenges, <br /> your resume and online profile
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className={`${classes.center} ${classes.howLongBtn}`}>
                <Button
                  onClick={() => networkError()}
                  color="btnPrimary"
                  width="313px"
                  text="Get Started"
                  data-rt-div-getstarted = "getstarted"
                ></Button>
              </div>
            </Container>
          </div>

          <div className={classes.aboutUsCls}>
            <Container
              maxWidth="xl"
              className={`${classes.aboutUs} ${classes.containerCls}`}
            >
              <Grid container className="Home-hero">
                <Grid item xs={5}>
                  <div className={classes.homeHeroLeft}>
                    <Typography
                      component={Text}
                      variant2="h2"
                      className={classes.title} align="left">
                      About Us
                    </Typography>
                    <div className={`${classes.aboutTextArea} ${classes.center}`}>
                      <Typography
                        component={Text}
                        variant2="h6"
                        className={`${classes.title} ${classes.scoreCard} ${classes.letterSpacing1} ${classes.colorNeutrals}`}
                        align="left"
                      >
                        Your Tech Score believes better information can reduce the
                        frustration in software engineering career changes. The
                        Career Score has been built with decades of industry
                        experiences, and with data from thousands of actual users
                        just like you.
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={7} className={classes.aboutUscls}>
                  <div>
                    <img
                      className={classes.aboutUsImgCls}
                      alt="about us"
                      src={`${ImagebaseUrl}assets/about-us.png`}
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>

          <Footer></Footer>
        </div>
      )}

      {!isNotMobile && (
        <div className={`${classes.root}`}>
          <NavBar></NavBar>

          <div className={`${classes.hero}`}>
            <Container
              maxWidth="xl"
              className={`${classes.hero} ${classes.containerCls}`}
            >
              <Grid container className="Home-hero">
                <Grid item xs={12}>
                  <div className={classes.homeHeroLeft}>
                    <Typography
                      component={Text}
                      variant2="h3"
                      className={`${classes.heroTitle} ${classes.techCarrrierText} ${classes.letterSpacing2} ${classes.colorNeutrals}`}
                      align="center"
                    >
                      Tired of your job search?
                    </Typography>
                    <div className={`${classes.carrierInspirationText}`}>
                      <Typography
                        component={Text}
                        variant2="b2Regular"
                        className={`${classes.title}  ${classes.heroParaOne} ${classes.colorNeutrals}  ${classes.heroParaOneMob}`}
                        align="center"
                      >
                        Hiring managers evaluate you based on your resume, technical knowledge, 
                        coding skills and interview presentation. Do you know where you stand? 
                        Check your score to know.
                      </Typography>
                    </div>

                    <div className={classes.whyVideo}>
                      <img
                        className={`${classes.heroImgcls}`}
                        alt="dashboard"
                        src={`${ImagebaseUrl}assets/mDashboardMock.png`}
                      />
                    </div>

                    <div className={`${classes.marginTop40} ${classes.applyMargin}`}>
                      <Button
                        onClick={() => networkError()}
                        color="btnPrimary"
                        text="Get My Score"
                        width="313px"
                        data-rt-div-getmyscore = "getmyscore"
                      ></Button>
                    </div>

                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={`${classes.title}  ${classes.heroParaTwo} ${classes.colorNeutrals}`}
                      align="center"
                    >
                      We will show you which areas you need to improve to reach your target.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>

          <div className={classes.why}>
            <Container
              maxWidth="xl"
              className={`${classes.why} ${classes.containerCls}`}
            >
              <Grid container className="Home-hero">
                <Grid item xs={12}>
                  <div className="align-right">
                    <img
                      className={`${classes.whyVideo}  ${classes.videoEl}`}
                      alt="video"
                      src={`${ImagebaseUrl}assets/image with plants-2.png`}
                    />
                  </div>
                  <div>
                    <Typography
                      component={Text}
                      variant2="b1Medium"
                      className={`${classes.title}  ${classes.whyLeftCls} ${classes.letterSpacing05} ${classes.colorNeutrals}  ${classes.whyLeftClsMobile}`}
                      align="center"
                    >
                      Your career score is like a credit score for your career,
                      giving you an idea of where you’re at professionally and
                      how to navigate to the next level.
                    </Typography>
                  </div>
                </Grid>

              </Grid>
            </Container>
          </div>

          <div className={classes.knowYourCareer}>
            <Container
              maxWidth="xl"
              className={`${classes.knowYourCareer} ${classes.containerCls}`}
            >
              <Grid container className="Home-hero">
                <Grid item xs={12}>
                  <div className={classes.homeHeroLeft}>
                    <Typography
                      component={Text}
                      variant2="h3"
                      className={`${classes.title} ${classes.colorNeutrals}  ${classes.knowYourCareerMob}`}
                      align="center"
                    >
                      Know Your Career Score
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={`${classes.title} ${classes.scoreInfo} ${classes.determineProfessionalInfoText} ${classes.letterSpacing05} ${classes.colorNeutrals}`}
                      align="center"
                    >
                      We measure your score to determine your professional
                      readiness against your target score for the future
                      position. Our questions mimic the entire interview
                      process, from inception to selection.
                    </Typography>
                    <div>
                      <div className={classes.whyVideo}>
                        <ImageCarousel width="338px" height="315px" contentWidth="355px !important"></ImageCarousel>
                      </div>
                    </div>
                    <div className={classes.checkScore}>
                      <Button
                        onClick={() => networkError()}
                        color="btnPrimary"
                        width="313px"
                        text="Check Score"
                        data-rt-div-checkscore = "checkscore"
                      ></Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>

          <div className={classes.howLong}>
            <Container maxWidth="xl" className={`${classes.containerCls}`}>
              <Typography
                component={Text}
                variant2="h3"
                className={`${classes.title} ${classes.colorNeutrals}`}
                align="center"
              >
                How long does it take?
              </Typography>

              <div className={`${classes.parentDiv} ${classes.center}`}>
                <div className={classes.clOne}></div>
                <div className={`${classes.clTwo} ${classes.center}`}>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={`${classes.title} ${classes.howLongItDoesTextArea} ${classes.colorNeutrals}`}
                    align="center"
                  >
                    There are 3 simple steps to get your career score based on a
                    desired position and target timeline. We will first provide
                    a score range with an initial evaluation. You can narrow the
                    range with detailed evaluations and get your career score.
                  </Typography>
                </div>
                <div className={classes.clThree}></div>
              </div>

              {/* <div className={classes.connector}></div> */}

              <Grid container className="how-long-main-mob">
                <Grid item xs={12}>
                  <div>
                    <div className={classes.alignItem}>
                      <div className={classes.space}></div>
                      <img className="stepImgMob" alt="step1" src={`${ImagebaseUrl}assets/howlong_1.png`} />
                      <div className={classes.horizontalLine}></div>
                    </div>
                    <Typography
                      component={Text}
                      variant2="h4"
                      className={classes.stepTitle}
                      align="center"
                    >
                      {/* <img className="ellipse" alt="Ellipse" src={ellipse44} /> */}
                      <span
                        className={`${classes.colorNeutrals}`}
                      >
                        Step 1 - Target Score
                      </span>
                    </Typography>

                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <div className={`${classes.borderLeftCls}`}>
                      <div
                        className={`${classes.scoreTextArea} ${classes.center}`}
                      >
                        <Typography
                          component={Text}
                          variant2="b2Regular"
                          className={`${classes.setpText} ${classes.colorNeutrals}`}
                          align="center"
                        >
                          Few simple profile questions to determine target score
                        </Typography>
                      </div>
                      <div className={classes.borderLeft}></div>
                      <div className={classes.alignItem}>
                        <div className={classes.horizontalLine}></div>
                        <img className="stepImgMob" alt="step1" src={`${ImagebaseUrl}assets/howlong_2.png`} />
                        <div className={classes.horizontalLine}></div>
                      </div>
                    </div>
                    <Typography
                      component={Text}
                      variant2="h4"
                      className={classes.stepTitle}
                      align="center"
                    >
                      {/* <img className="ellipse" alt="Ellipse" src={ellipse44} /> */}
                      <span
                        className={`${classes.h3Subtitle} ${classes.rationalFont}`}
                      >
                        Step 2 - Score Range
                      </span>
                    </Typography>

                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <div className={`${classes.borderLeftCls}`}>
                      <div
                        className={`${classes.scoreTextArea} ${classes.center}`}
                      >
                        <Typography
                          component={Text}
                          variant2="b2Regular"
                          className={classes.setpText}
                          align="center"
                        >
                          25 Questions, 20 Minutes
                          <br /> Questions related to your background, technical
                          expertise, presentation skills
                        </Typography>
                      </div>
                      <div className={classes.borderLeft}></div>
                      <div className={classes.alignItem}>
                        <div className={classes.horizontalLine}></div>
                        <img className="stepImgMob" alt="step1" src={`${ImagebaseUrl}assets/howlong_3.png`} />
                        <div className={classes.space}></div>
                      </div>
                    </div>
                    <Typography
                      component={Text}
                      variant2="h4"
                      className={classes.stepTitle}
                      align="center"
                    >
                      {/* <img className="ellipse" alt="Ellipse" src={ellipse44} /> */}
                      <span
                        className={`${classes.h3Subtitle} ${classes.rationalFont}`}
                      >
                        Step 3 - Career Score
                      </span>
                    </Typography>
                    <div className={`${classes.borderLeftCls} ${classes.borderLeft3}`}>
                      <div
                        className={`${classes.scoreTextArea} ${classes.center}`}
                      >
                        <Typography
                          component={Text}
                          variant2="b2Regular"
                          className={classes.setpText}
                          align="center"
                        >
                          Multiple evaluation sections covering technical
                          questions, coding challenges, your resume and online
                          profile
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className={`${classes.center} ${classes.howLongBtn}`}>
                <Button
                 onClick={() => networkError()}
                  color="btnPrimary"
                  width="313px"
                  text="Get Started"
                  data-rt-div-getstarted = "getstarted"
                ></Button>
              </div>
            </Container>
          </div>

          <div className={classes.aboutUs}>
            <Container
              maxWidth="xl"
              className={`${classes.aboutUs} ${classes.containerCls}`}
            >
              <Grid container className="Home-hero">
                <Grid item xs={12}>
                  <div className={classes.homeHeroLeft}>
                    <Typography
                      component={Text}
                      variant2="h3"
                      className={`${classes.title}  ${classes.aboutMob}`}
                      align="center"
                    >
                      About Us
                    </Typography>
                    <div
                      className={`${classes.aboutTextArea} ${classes.center}`}
                    >
                      <Typography
                        component={Text}
                        variant2="b2Regular"
                        className={`${classes.title} ${classes.scoreCard} ${classes.colorNeutrals}`}
                        align="center"
                      >
                        Your Tech Score believes better information can reduce
                        the frustration in software engineering career changes.
                        The Career Score has been built with decades of industry
                        experiences, and with data from thousands of actual
                        users just like you.
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <img
                      className={classes.aboutUsImgCls}
                      alt="about us"
                      src={`${ImagebaseUrl}assets/about-us.png`}
                      width='351px'
                      height='282px'
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>

          <Footer></Footer>
        </div>
      )}
       <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}
export default Home;
