import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/controls/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Text } from "../../styles/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ImagebaseUrl } from "../../services/globalService";
import auth from '../../redux/actions/auth'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as QueryString from "query-string"
import RoundedLoadingIndicator from "../../components/RoundedLoadingIndicator";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.light
    },
    section: {
        width: "100%"
    },
    title: {
        flexGrow: 1,
        marginTop: "20px",
        [theme.breakpoints.up("tablet")]: {
            marginTop: "40px"
        }
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    cardOne: {
        height: "60px",
        borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
        position: "sticky",
        top: "0px",
        zIndex: 1,
        backgroundColor: theme.palette.background.main,
        [theme.breakpoints.up("tablet")]: {
            height: "80px"
        }
    },
    cardTwo: {
        marginTop: "16px",
        [theme.breakpoints.up("tablet")]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    card: {
        marginBottom: 24
    },
    submit: {
        marginTop: "34px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        "& button": {
            [theme.breakpoints.up("tablet")]: {
                marginLeft: "0",
                marginRight: "0"
            }
        }
    },
    createAccountDescriptionArea: {
        fontSize: "14px",
        lineHeight: "24px",
        marginTop: "30px",
        [theme.breakpoints.up("tablet")]: {
            fontSize: "16px"
        }
    },
    logoCls: {
        height: "24px",
        [theme.breakpoints.up("tablet")]: {
            height: "30px"
        }
    },
    contentArea: {
        paddingLeft: 16,
        paddingRight: 16
    },
    imgStyle: {
        textAlign: "center",
        marginTop: "80px",
        [theme.breakpoints.up("tablet")]: {
            marginTop: "40px"
        }
    }
}));
function VerifiedAccount(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { location } = props;

    const history = useHistory();
    const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
    const authState = useSelector((state) => state.auth)
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [message, setMessage] = useState(false);
    const dispatch = useDispatch()
    const quizState = useSelector((state) => state.quiz);

    const handleClose = (event, reason) => {
        setOpenSnackBar(false);
    };

    // const {uid} = useParams()
    // const uid = props.location.search


    const queryvalues = QueryString.parse(props.location.search)

    useEffect(() => {
        if (queryvalues.uid) {
            dispatch(auth.verifyEmail({ uid: queryvalues.uid }))
        }

    }, [queryvalues.uid])
    useEffect(() => {
        if (authState.firebaseLoginDetails && authState.firebaseLoginDetails.user) {
            const { user } = authState.firebaseLoginDetails;
            let payload = {
                uid: user?.uid,
                email: user?.email,
                firstName: authState.userProfile.firstName,
                lastName: authState.userProfile.lastName,
                displayName: user?.displayName || (authState.userProfile.firstName + ' ' + authState.userProfile.lastName),
                emailVerified: user?.emailVerified,
                AuthMethod: "Native",
                profileId: 1,
                photoUrl: user?.photoURL,
                phonenumber: user?.phoneNumber,
                createdAt: user?.metadata?.creationTime,
                lastLoginAt: user?.metadata?.lastSignInTime,
                user_level: authState?.level,
                targetScore: quizState?.testResult?.targetScore,
                targetRole: quizState?.testResult?.role,
                targetCompany: quizState?.testResult?.targetCompany,
                primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
                scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
                scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
                skills: quizState?.testResult?.skills,
            };
            dispatch(auth.createProfile(payload));
        }
    }, [authState.firebaseLoginDetails]);

    const onGotoDashboard = () => {
        if (authState.userProfile.uid) {
            let userpassword = localStorage.getItem("userpassword")
            console.log("location.state", userpassword)
            dispatch(auth.firebaseUserLoginRequested({ email: authState.userProfile.email, password: userpassword }));
        } else {
            setOpenSnackBar(true);
            setMessage("User profile not available");
        }
    };

    useEffect(() => {
        if (authState.firebaseLoginDetails && authState.firebaseLoginDetails.user) {
            history.push("/Dashboard");
            dispatch(auth.getProfile({ email: authState.userProfile.email }))
        }
    }, [authState.userProfile]);

    useEffect(() => {
        if (authState.userProfile && authState.verifyemail.status == 0) {
            setTimeout(() => {
                onGotoDashboard()
            }, 12000)
        }
    }, [authState.userProfile && authState.verifyemail.status])

    useEffect(() => {
        if (authState.verifyemail.status == 0) {
            setOpenSnackBar(true);
            setMessage(authState.verifyemail.message);
            dispatch(auth.getProfile({ email: authState.userProfile.email }))

        }
    }, [authState.verifyemail.status])
    return (
        <div className={classes.root}>

            <div>
                <div>
                    <div
                        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
                    >
                        <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
                    </div>
                    {authState.isLoading ?

                        //  <p>Data Loading ...</p>
                        <RoundedLoadingIndicator></RoundedLoadingIndicator> :

                        <Grid container>
                            {isNotMobile && <Grid item xs={4}></Grid>}
                            <Grid
                                item
                                xs={isNotMobile ? 4 : 12}
                                className={classes.contentArea}
                            >
                                <div className={`${classes.section} ${classes.cardTwo}`}>
                                    <div className={classes.imgStyle}>
                                        <img src={`${ImagebaseUrl}assets/successcheck.svg`} />
                                    </div>
                                    <Typography
                                        component={Text}
                                        variant2="h3"
                                        align="center"
                                        className={`${classes.title}`}
                                    >
                                        Verified Your Account
                                    </Typography>
                                </div>
                                <div
                                    className={`${classes.section}  ${classes.card} ${classes.center}`}
                                >
                                    <Typography
                                        component={Text}
                                        variant2="b1Regular"
                                        align="center"
                                        className={`${classes.createAccountDescriptionArea}`}
                                    >
                                        Thank you for validating your account.
                                        You’ll now be redirected to your dashboard…
                                        Use the link below if it doesn’t redirect.

                                    </Typography>
                                </div>

                                <div
                                    className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
                                >
                                    <Button
                                        onClick={onGotoDashboard}
                                        type="submit"
                                        color="btnPrimary"
                                        width="100%"
                                        text="Take me to the dashboard"
                                        data-rt-createverifysuccess-takemetodashboard="takemetodashboard"
                                    ></Button>
                                </div>
                            </Grid>
                            {isNotMobile && <Grid item xs={4}></Grid>}
                        </Grid>
                    }
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
}
export default VerifiedAccount;
