import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ButtonReact from "@material-ui/core/Button";

import Button from "../../components/controls/Button";
import Typography from "@material-ui/core/Typography";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/controls/TextInput";
import { Container } from "@material-ui/core";
import Checkbox from "../../components/controls/Checkbox";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Text } from "../../styles/theme";
import ButtonMaterial from "@material-ui/core/Button";

// import arrowImg from "../../assets/Arrow 10-3.png";
// import backArrowImg from "../../assets/Arrow 10-2.png";
// import BackArrow from "../../assets/svg/BackArrow.svg";
// import logo from "../../assets/logo.svg";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import auth from "../../redux/actions/auth";
import AppConfig from "../../constants/AppConfig";
import { ImagebaseUrl } from "../../services/globalService";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
    marginTop: "48px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
  },
  cardSix: {
    marginTop: "90px",
    width: "100%",
    justifyContent: "space-between",
    // paddingBottom: "126px",
    // marginLeft: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      // width: 458,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "100px",
    },

    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    cursor: "pointer",
  },
  cardTwoH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {},
  },

  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formControl: {
    marginTop: 24,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  telField: {
    minHeight: 48,
    marginTop: 8,
    margin: "8px 0px 0px 0px",
    backgroundColor: theme.palette.background.light,
  },
  phonewithCode: {
    display: "flex",
    alignItems: "flex-end",
  },
  phoneNo: {
    width: "100%",
  },
  countryCode: {
    backgroundColor: theme.palette.background.level2,
  },
  submit: {
    marginTop: "24px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("tablet")]: {
      // width: 500,
    },
    "& button": {
      [theme.breakpoints.up("tablet")]: {
        marginLeft: "0",
        marginRight: "0",
      },
    },
  },
  arrowImg: {
    marginLeft: 8,
  },
  backArrowImg: {
    marginRight: 8,
  },
  phoneErr: {
    marginLeft: "23%",
    marginTop: "2%",
  },
  createAccountDescriptionArea: {
    // fontSize: "13px",
    lineHeight: "24px",
    marginTop: "30px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  font15: {
    fontSize: "12px",
  },
  policycolor: {
    color: theme.palette.btnPrimary.main,
  },
  contentArea: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  passwordText: {
    display: "flex",
    justifyContent: "space-between",
  },
  charvalue: {
    color: "#787878",
  },
  accountText: {
    color: "#787878",
    // fontFamily: "Roboto",
    // fontStyle: "normal",
    // fontWeight: "normal",
    // fontSize: "14px",
    // lineHeight: "20px",
    // [theme.breakpoints.up("tablet")]: {
    //   fontSize: "16px",
    //   lineHeight: "24px",
    // },
  },
  TechScore: {
    fontFamily: "Frank Ruhl Libre",
    fontStyle: " normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
  },
  cursorIsuue: {
    cursor: "default",
  },
  restpasswordfont: {
    fontWeight: 500,
  },
}));

function LoginWithEmail(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);

  const dispatch = useDispatch();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showSnackBar, setShowSnackbar] = useState(false);
  const [isShowSnackBar,setIsShowSnackBar] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordError, setShowhowPasswordError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(true);
  };
  const onSubmit = (values) => {
    console.log("values",values)
    if(values.email === "admin@yourtechscore.com" && values.password === "admin123"){
      history.push("/admin/summary-report")
    }
    else {
    authState.firebaseLoginDetails = {};
    values.level = location.level;
    console.log("Form data", values);
    dispatch(auth.firebaseUserLoginRequested(values));
    setShowSnackbar(true);
    }
  };

  const initialValues = {
    email: location?.state ? location?.state.email : "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("This field cannot be empty"),
    password: Yup.string()
      .required("This field cannot be empty")
      .matches(
        /^.*(?=.{6,})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/,
        "Strong passwords have at least 6 characters and a mix of letter and numbers"
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (
      authState.firebaseLoginDetails &&
      authState.firebaseLoginDetails.error &&
      showSnackBar
    ) {
      if (
        !authState.firebaseLoginDetails.error.includes(
          "The password is invalid"
        )
      ) {
        setErrorMsg(authState.firebaseLoginDetails.error);
        setOpenSnackbar(true);
      } else {
        setShowhowPasswordError(true);
      }
    }
    if (
      authState.firebaseLoginDetails &&
      authState.firebaseLoginDetails.user &&
      showSnackBar
    ) {
      let userMetaData = "";
      if (quizState?.userMetaDataReq) {
        userMetaData = {
          email: authState?.firebaseLoginDetails?.user?.email,
          code: `${AppConfig?.l0QuestionCode}`,
          result: quizState?.userMetaDataReq,
        };
      }

      dispatch(
        auth.createProfile(
          {
            uid: authState?.firebaseLoginDetails?.user?.uid,
            email: authState?.firebaseLoginDetails?.user?.email,
            emailVerified: authState?.firebaseLoginDetails?.user?.emailVerified,
            AuthMethod: "Native",
            profileId: 1,
            photoUrl: authState?.firebaseLoginDetails?.user?.photoUrl,
            phonenumber: authState?.firebaseLoginDetails?.user?.phonenumber,
            createdAt:
              authState?.firebaseLoginDetails?.user?.metadata.creationTime,
            lastLoginAt:
              authState?.firebaseLoginDetails?.user?.metadata?.lastSignInTime,
          },
          userMetaData
        )
      );
    }
  }, [authState.firebaseLoginDetails]);

  useEffect(() => {
    if (authState.userProfile) {
      setTimeout(() => {
        history.push("/Dashboard");
      }, 1500);
    }
  }, [authState.userProfile]);

  const LoginBack = (link) => {
    if(navigator.onLine){
    history.push(link)
    }
    else {
      setIsShowSnackBar(true)
    }
  }

  const handleCloseSnackbar = () => {
    setIsShowSnackBar(false)
  }

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img
          alt="logo"
          src={`${ImagebaseUrl}assets/logo.svg`}
          className={classes.logoCls}
        />
      </div>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}

        <Grid item xs={isNotMobile ? 4 : 12} className={classes.contentArea}>
          <div className={`${classes.section} ${classes.cardTwo}`}>
            <Typography
              component={Text}
              variant2={isNotMobile ? "h3" : "h4"}
              align="center"
              className={`${classes.cardTwoH1} ${classes.title}`}
            >
              Log In
            </Typography>
          </div>
          <div
            className={`${classes.section}  ${classes.card} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
            >
              Welcome back to your saved score, track progress, and get access
              to training materials, workshops, and our community of
              professionals.
            </Typography>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <Container className={classes.inputCt}>
              <div className={classes.formControl}>
                <label htmlFor="email">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Email
                  </Typography>
                </label>
                <TextInput
                  name="email"
                  // placeholder="hellofy@gmail.com"
                  className={classes.field}
                  id="email"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email && formik.errors.email
                      ? Boolean(formik.errors.email)
                      : null
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                ></TextInput>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.email}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div className={classes.formControl}>
                <label htmlFor="password" className={classes.passwordText}>
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Password{" "}
                    <span className={classes.charvalue}>
                      (min 6 characters)
                    </span>
                  </Typography>
                  <Link
                    onClick={() => LoginBack("/ResetPassword")}
                    component={Text}
                    variant2={isNotMobile ? "link1" : "link2"}
                    className={classes.restpasswordfont}
                    data-rt-loginwithemail-resetpassword = "resetpassword"
                  >
                    Reset Password
                  </Link>
                </label>
                <TextInput
                  name="password"
                  placeholder=""
                  className={`${classes.field} parentclassname`}
                  id="password"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && formik.errors.password
                      ? Boolean(formik.errors.password)
                      : null
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  passwordText={"PasswordIcon"}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                ></TextInput>
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.password}
                    </Typography>
                  </div>
                ) : null}
                {formik.touched.password && showPasswordError ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      Incorrect password&nbsp;
                      <Link
                        onClick={() => LoginBack("/ResetPassword")}
                        color="inherit"
                        underline="always"
                        className={classes.cursorIsuue}
                        data-rt-loginwithemail-forgotpassword = "forgotpassword"
                      >
                        Forgot Password? Reset it
                      </Link>
                    </Typography>
                  </div>
                ) : null}
              </div>
            </Container>

            <div
              className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
            >
              <Button
                disabled={!(formik.isValid && formik.dirty && formik.values)}
                type="submit"
                color="btnPrimary"
                width="100%"
                text="Sign In"
                data-rt-loginwithemail-signin = "signin"
              ></Button>
            </div>
            <Grid
              container
              className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link onClick={() => LoginBack("/CreateAccount")} data-rt-loginwithemail-backarrow = "backarrow">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "link1" : "link2"}
                    align="left"
                    className={`${classes.labelBlue}`}
                  >
                    <span className={classes.backArrowImg}>
                      <img
                        alt="BackArrow"
                        src={`${ImagebaseUrl}assets/svg/BackArrow.svg`}
                      />
                    </span>
                    Back
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                  align="right"
                  className={classes.accountText}
                >
                  Don't have an account? &nbsp;
                  <Link
                    onClick={() => LoginBack("/CreateAccountWithEmail")}
                    variant={isNotMobile ? "link1" : "link2"}
                    className={`${classes.labelBlue}`}
                    data-rt-loginwithemail-signup = "signup"
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message={errorMsg}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <ButtonMaterial
              color="secondary"
              size="small"
              onClick={handleCloseSnackbar}
            ></ButtonMaterial>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default LoginWithEmail;
