import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
  Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Text } from "../../styles/theme";
import AppConfig from "../../constants/AppConfig";

import Button from "../controls/Button";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: "16px 8px",
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    // paddingRight: "0px",
    // paddingLeft: "16px",
    padding:'30px 30px 10px'
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "10px",
    color: theme.palette.btnPrimary.tertiaryText,
  },
  content: {
    // paddingLeft: "16px",
    // paddingRight: "16px",
    // paddingTop: "24px",
    padding:'10px 30px 30px',
    borderTop: "1px solid #F8F8F8",
    borderBottom: "1px solid #F8F8F8",
  },
  title: {},
  justifyActions: {
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "56px",
  },
  description: {
    paddingTop: "16px",
  },
  desContainer: {
    paddingLeft: "0px",
  },
  hint:{
    whiteSpace: 'pre-line'
  }
}));

export default function LOneExampleContent(props) {
  const { title, children, openPopup, setOpenPopup, hint, showButtonArea } =
    props;
  const classes = useStyles();

  const [pageIndex, setPageIndex] = useState(0);

  const onClickNext = (val) => {
    if (val == "back") {
      setPageIndex(0);
    } else {
      setPageIndex(pageIndex + 1);
    }
  };

  const onClickPrev = () => {
    setPageIndex(pageIndex - 1);
  };

  return (
    <>
      <DialogTitle
        className={classes.dialogTitle}
        id="customized-dialog-title"
        onClose={() => {
          setOpenPopup(false);
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography
            variant="subtitle2"
            component="div"
            style={{ flexGrow: 1 }}
          >
            {/* {`Question Hint (${pageIndex + 1}/${AppConfig.jobExamples.length})`} */}
            {`Question Hint`}
          </Typography>

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <div>
          {/* <div>
            <Typography variant="h5" className={classes.title} align="left">
              {job.title}
            </Typography>
          </div> */}
          <Container className={classes.desContainer}>
            {/* <div>
              <div className={classes.description}>
                <Typography
                  variant="body1"
                  className={classes.title}
                  align="left"
                >
                  Description
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body2"
                  className={classes.title}
                  align="left"
                >
                  {job.description}
                </Typography>
              </div>
            </div> */}
            <div>
              {/* <div className={classes.description}>
                <Typography
                  variant="body1"
                  className={classes.title}
                  align="left"
                >
                  Responsibilities
                </Typography>
              </div> */}
              <div>
                <Typography
                  variant="body2"
                  className={`${classes.title} ${classes.hint}`}
                  align="left"
                  id="no-copy"
                >
                  {hint}
                </Typography>
              </div>
            </div>
          </Container>
        </div>
      </DialogContent>
      {/* <DialogActions className={classes.justifyActions}>
        {pageIndex > 0 ? (
          <Typography
            component={Text}
            variant2="ag"
            align="left"
            onClick={() => onClickPrev()}
          >
            Previous
          </Typography>
        ) : (
          null
        )}

        {showButtonArea && (
          <div>
            {pageIndex + 1 < AppConfig.jobExamples.length ? (
              <Typography
                component={Text}
                variant2="agLink"
                align="right"
                onClick={() => onClickNext()}
              >
                Next Example
              </Typography>
            ) : (
              <Typography
                component={Text}
                variant2="agLink"
                align="right"
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                Back To Question
              </Typography>
            )}
          </div>
        )}
      </DialogActions> */}
    </>
  );
}
