import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "../../../pages/home/Home.css";
import Grid from "@material-ui/core/Grid";
import Button from "../../../components/controls/Button";
import TextInput from "../../../components/controls/TextInput";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import * as globalService from "../../../services/globalService";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "../../../styles/theme";
import quiz from "../../../redux/actions/quiz";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  item1: {
    "& div": {
      borderRadius: "4px",
      marginTop: "16px",
      minHeight: "52px",
      backgroundColor: theme.palette.background.light,
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      "&:hover": {
        boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
        cursor: "pointer",
      },
    },
  },
  item2: {
    marginTop: "24px",
    minHeight: "56px",
    backgroundColor: theme.palette.background.light,
    "&:hover": {
      boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
    },
  },
  answer: {
    padding: "16px",
    whiteSpace: "pre-wrap",
  },
  textInput: {
    marginTop: "0px !important",
    marginBottom: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
}));

function Answers(props, ref) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const quizState = useSelector((state) => state.quiz);
  const authObj = useSelector((state) => state.auth);
  let selectedQuesNdAns = useSelector((state) => state?.quiz?.selectedQuesNdAns);

  const { answers, quesObject, qIndex, ...other } = props;

  const [value, setvalue] = useState({
    answer: "",
    selectedIndex: -1,
  });

  const [multiAnswer, setMultiAnswers] = useState({
    storeMultiAns: [],
    storeMultiAnsIds: [],
    storeMultiIndexes: [],
    input: "",
  });

  const [maxChoices, setMaxChoices] = useState(1);

  const classes = useStyles();

  React.useImperativeHandle(ref, () => ({
    setDefaultIndex: () => {
      setvalue({ ...value, answer: "", selectedIndex: -1 });

      setMultiAnswers({
        ...multiAnswer,
        storeMultiAns: [],
        storeMultiAnsIds: [],
        storeMultiIndexes: [],
        input: "",
      });

      let ansObj = {
        answer: "",
        ansId: "",
      };
      props.userAnsFn(ansObj);
    },
    callBackOnClickAns: (eventData) => {
      let answer = eventData?.answer;
      let i = eventData?.i;
      let val = eventData?.val;
      let event = eventData?.event;
      if (eventData.type == "Single-Select") {
        onClickAnswer(answer, i, val, event, true);
      } else {
        onSelectMultiAns(answer, i, val, true);
      }
    },
    getMultiAnswer: () => {
      return multiAnswer;
    },
    getSingleAnswer: () => {
      return value;
    },
  }));

  const onClickAnswer = (answer, i, val, event, isCallBack) => {
    if (
      value.answer.length > 0 &&
      quizState?.level == "L0" &&
      ["Major", "Minor"].includes(quesObject?.Topic) &&
      !isCallBack &&
      authObj?.userProfile &&
      authObj?.userProfile?.email &&
      authObj?.userMetaData &&
      authObj?.userMetaData[0]?.result &&
      quizState.quizSelectionPopupDisable == false &&
      !["Major"].includes(quizState.quizSelectionTopic) &&
      quizState.quizSelectionTopic != quesObject?.Topic
    ) {
      let obj = {
        answer,
        i,
        val,
        event,
        type: "Single-Select",
      };
      props.handleTopicDialog(obj);
      return;
    }
    event && event.stopPropagation();
    if (quesObject.Question_Type == "Single-Select") {
      if (value.selectedIndex == i && val != "input") {
        i = -1;
        answer = "";
      }
      setvalue({
        ...value,
        answer,
        selectedIndex: i,
      });

      let ansObj = {
        answer,
        ansId: i + 1,
      };
      props.userAnsFn(ansObj);

      if (answer.length == 0) {
        let object = {
          answer: "",
          selectedIndex: i,
        };
        let obj = { object, Question_ID: quesObject.Question_ID };
        let payload = selectedQuesNdAns;
        payload[qIndex] = obj;
        dispatch(quiz.setSelectedQuesNdAns(payload));
      }
    } else {
      setMultiAnswers({
        ...multiAnswer,
        storeMultiAns: [
          ...new Set([
            ...multiAnswer.storeMultiAns,
            { i: i, answer, id: i + 1, type: val },
          ]),
        ],
        storeMultiAnsIds: [
          ...new Set([...multiAnswer.storeMultiAnsIds, i + 1]),
        ],
        storeMultiIndexes: [...new Set([...multiAnswer.storeMultiIndexes, i])],
      });

      let ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      props.userAnsFn(ansObj);
    }
  };

  const onSelectMultiAns = (answer, i, val, isCallBack) => {
    if (
      multiAnswer.storeMultiAns.length > 1 &&
      quizState?.level == "L0" &&
      multiAnswer.storeMultiAnsIds.length > 1 &&
      ["Major", "Minor"].includes(quesObject?.Topic) &&
      !isCallBack &&
      authObj?.userProfile &&
      authObj?.userProfile?.email &&
      authObj?.userMetaData &&
      authObj?.userMetaData[0]?.result &&
      quizState.quizSelectionPopupDisable == false &&
      !["Major"].includes(
        quizState.quizSelectionTopic &&
        quizState.quizSelectionTopic != quesObject?.Topic
      )
    ) {
      let obj = {
        answer,
        i,
        val,
        event: "",
        type: "Multi-Select",
      };
      props.handleTopicDialog(obj);
      return;
    }

    if (
      multiAnswer?.storeMultiIndexes?.length >= maxChoices &&
      !multiAnswer?.storeMultiIndexes?.includes(i)
    ) {
      return;
    }

    if (answer != "Other (Please enter)") {
      if (
        multiAnswer.storeMultiIndexes &&
        multiAnswer.storeMultiIndexes.includes(i) &&
        val != "input"
      ) {
        setMultiAnswers({
          ...multiAnswer,
          storeMultiAns: multiAnswer.storeMultiAns.filter(
            (obj, index) => i !== obj.i
          ),
          storeMultiAnsIds: multiAnswer.storeMultiAnsIds.filter(
            (item, index) => i + 1 !== item
          ),
          storeMultiIndexes: multiAnswer.storeMultiIndexes.filter(
            (item, index) => i !== item
          ),
        });
      } else {
        setMultiAnswers({
          ...multiAnswer,
          storeMultiAns: [
            ...new Set([
              ...multiAnswer.storeMultiAns,
              { i: i, id: i + 1, answer, type: val },
            ]),
          ],
          storeMultiAnsIds: [
            ...new Set([...multiAnswer.storeMultiAnsIds, i + 1]),
          ],
          storeMultiIndexes: [
            ...new Set([...multiAnswer.storeMultiIndexes, i]),
          ],
        });

        if (val == "input") {
          setMultiAnswers({
            ...multiAnswer,
            storeMultiAns: [
              ...new Set([
                ...multiAnswer.storeMultiAns,
                { i: i, id: i + 1, answer, type: val },
              ]),
            ],
            storeMultiAnsIds: [
              ...new Set([...multiAnswer.storeMultiAnsIds, i + 1]),
            ],
            storeMultiIndexes: [
              ...new Set([...multiAnswer.storeMultiIndexes, i]),
            ],
            input: answer,
          });

          if (answer.length == 0) {
            setMultiAnswers({
              ...multiAnswer,
              storeMultiAns: multiAnswer.storeMultiAns.filter(
                (obj, index) => i !== obj.i
              ),
              storeMultiAnsIds: multiAnswer.storeMultiAnsIds.filter(
                (item, index) => i + 1 !== item
              ),
              storeMultiIndexes: multiAnswer.storeMultiIndexes.filter(
                (item, index) => i !== item
              ),
              input: "",
            });
          }
        }
      }

      let ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      props.userAnsFn(ansObj);
    }
  };

  const onChangeText = (e, i) => {
    if (quesObject.Question_Type == "Single-Select") {
      let answer = e.target.value;
      onClickAnswer(answer, i, "input");
    } else {
      let answer = e.target.value;
      onSelectMultiAns(answer, i, "input");
    }
  };

  useEffect(() => {
    if (
      quesObject.Question_Type != "Single-Select" &&
      multiAnswer.storeMultiAnsIds.length != 0
    ) {
      let obj = { value: multiAnswer, Question_ID: quesObject.Question_ID };

      let payload = selectedQuesNdAns;
      payload[qIndex] = obj;
      dispatch(quiz.setSelectedQuesNdAns(payload));
      let ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      props.userAnsFn(ansObj);
    }
  }, [
    multiAnswer,
    quesObject,
    multiAnswer.storeMultiAnsIds,
    multiAnswer.storeMultiAns,
    multiAnswer.storeMultiIndexes,
    multiAnswer.input,
  ]);

  useEffect(() => {
    if (
      quesObject.Question_Type == "Single-Select" &&
      value.selectedIndex > -1
    ) {
      let obj = { value, Question_ID: quesObject.Question_ID };
      let payload = selectedQuesNdAns;
      payload[qIndex] = obj;
      dispatch(quiz.setSelectedQuesNdAns(payload));

      let ansObj = {
        answer: value.answer,
        ansId: value.selectedIndex + 1,
      };
      props.userAnsFn(ansObj);
    }
  }, [quesObject, value.selectedIndex, value.answer]);

  useEffect(() => {
    let maximumChoices = quesObject?.Max_Choices || 1;
    setMaxChoices(maximumChoices);
    if (quesObject.Question_Type == "Single-Select") {
      let globalValue = selectedQuesNdAns[qIndex]?.value;
      let quesId = selectedQuesNdAns[qIndex]?.Question_ID;
      if (
        globalValue &&
        globalValue.hasOwnProperty("answer") &&
        globalValue.selectedIndex > -1 &&
        quesId &&
        quesId == quesObject.Question_ID
      ) {
        setvalue({ ...globalValue });
      } else {
        setvalue({ answer: "", selectedIndex: -1 });
        let ansObj = {
          answer: "",
          ansId: "",
        };
        props.userAnsFn(ansObj);
      }
    } else {
      let globalMultiAnswers = selectedQuesNdAns[qIndex]?.value;
      let quesId = selectedQuesNdAns[qIndex]?.Question_ID;

      if (
        globalMultiAnswers &&
        globalMultiAnswers.hasOwnProperty("storeMultiIndexes") &&
        globalMultiAnswers.storeMultiIndexes.length > 0 &&
        quesId &&
        quesId == quesObject.Question_ID
      ) {
        setMultiAnswers({ ...globalMultiAnswers });
      } else {
        setMultiAnswers({
          storeMultiAns: [],
          storeMultiAnsIds: [],
          storeMultiIndexes: [],
          input: "",
        });
        let ansObj = {
          answer: "",
          ansId: "",
        };
        props.userAnsFn(ansObj);
      }
    }
  }, [quesObject, qIndex]);

  const renderAnswers = () => {
    if (quesObject.Question_Type == "Single-Select") {
      return (
        <div className={classes.item1}>
          {answers.map(
            (answer, i) =>
              answer &&
              answer.length > 0 && (
                <div
                  key={i}
                  className={classes.id}
                  id={i}
                  onClick={() => onClickAnswer(answer, i)}
                  data-rt-quiz-option={i}
                  style={{
                    backgroundColor:
                      value.selectedIndex == i
                        ? theme.palette.primary.mid
                        : theme.palette.background.light,
                  }}
                >
                  {answer == "Other (Please enter)" ? (
                    <TextInput
                      className={`${classes.textInput}`}
                      placeholder="Enter my own answer"
                      name="EnterMyOwnAnswer"
                      fullWidth={true}
                      value={(value.selectedIndex == i && value.answer) || ""}
                      onChangeText={(e) => onChangeText(e, i)}
                      onClick={(e) =>
                        onClickAnswer(
                          (answer = e.target.defaultValue),
                          (i = i),
                          "input",
                          e
                        )
                      }
                      id="no-copy"
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }} onCopy={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                    ></TextInput>
                  ) : (
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={`${classes.title}   ${classes.answer}`}
                      align="left"
                      style={{
                        color:
                          value.selectedIndex == i
                            ? theme.palette.common.white
                            : theme.palette.common.gray,
                      }}
                      id="no-copy"
                    >
                      {answer}
                    </Typography>
                  )}
                </div>
              )
          )}
        </div>
      );
    } else {
      return (
        <div className={classes.item1}>
          {answers.map(
            (answer, i) =>
              answer &&
              answer.length > 0 && (
                <div
                  key={i}
                  className={classes.id}
                  id={i}
                  onClick={() => onSelectMultiAns(answer, i)}
                  data-rt-quiz-option={i}
                  style={{
                    backgroundColor:
                      multiAnswer.storeMultiIndexes &&
                        multiAnswer.storeMultiIndexes.includes(i)
                        ? theme.palette.primary.mid
                        : theme.palette.background.light,
                  }}
                >
                  {answer == "Other (Please enter)" ? (
                    <TextInput
                      className={`${classes.textInput}`}
                      disabled={
                        multiAnswer?.storeMultiIndexes?.length >= maxChoices &&
                          !multiAnswer?.storeMultiIndexes?.includes(i)
                          ? true
                          : false
                      }
                      placeholder="Enter my own answer"
                      name="EnterMyOwnAnswer"
                      fullWidth={true}
                      value={multiAnswer.input}
                      onChangeText={(e) => onChangeText(e, i, "input")}
                      id="no-copy"
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }} onCopy={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                    ></TextInput>
                  ) : (
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={`${classes.title}   ${classes.answer}`}
                      align="left"
                      id="no-copy"
                      style={{
                        color:
                          multiAnswer.storeMultiIndexes &&
                            multiAnswer.storeMultiIndexes.includes(i)
                            ? theme.palette.common.white
                            : theme.palette.common.gray,
                      }}
                    >
                      {answer}
                    </Typography>
                  )}
                </div>
              )
          )}
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.answers}  >{renderAnswers()}</div>
    </div>
  );
}

export default React.forwardRef(Answers);
