import React, { useState, useEffect, useRef } from "react";
import Editor from "@monaco-editor/react";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";

import { useSelector, useDispatch } from "react-redux";
import quiz from "../redux/actions/quiz";

import { Tab, Tabs, Typography } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import AppConfig from "../constants/AppConfig";
import { getIdx, getObject } from "../services/globalService";
import { Text } from "../styles/theme";

const useStyles = makeStyles((theme, props) => ({
  root: {},

  bottomToolbar: {
    "&:nth-child(0)": {
      marginRight: "12px",
    },
  },

  programOutput: {
    whiteSpace: "pre-wrap",
    padding: "10px",
    height: "13vh",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
  },
  inputText: {
    height: "13vh !important",
    width: "95%",
    border: "none",
    outline: "none",
    resize: "none",
    padding: "10px 0px 4px 12px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0em",
    borderRadius: "4px",
  },
  contGrid: {
    marginTop: "20px",
    height: 132,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  },
  inputTab: {
    borderRight: `1px solid ${theme.palette.background.borderLight}`,
  },
  textArea: {
    // borderRight: `1px solid ${theme.palette.background.borderLight}`,
    border: `1px solid #EBEFF8`,
    borderRadius: "4px",
    height: "116px",
    overflow: "hidden"
  },
  lightColor: {
    backgroundColor: theme.palette.background.paper,
  },
  darkcolor: {
    backgroundColor: "#2F2F2F",
    //color:'white'
  },
  tabLabel: {
    textAlign: "left",
    textTransform: "capitalize",
    alignItems: "flex-end",
    paddingBottom: "0px",
    justifyContent: "flex-start",
  },
  codeEditor: {
    // height: "100% !important",
    width: "100% !important",
    border: "1px solid #EBEFF8",
    borderRadius: "4px",
  },
  editorCt: {
    borderRadius: "4px",
    //border:"1px solid #ddd"
  },
  valueText: {
    color: "#787878",
    marginBottom: "10px",
    whiteSpace: "nowrap",
  },
  outValue: {
    height: "116px !important",
    //margin:"auto",
    //marginRight:'-10px'
  },
  InputText: {
    color: "#C4C4C4",
    "&::placeholder": {
      color: "#C4C4C4",
    },
  },
  darkcolorText: {
    color: "#C4C4C4",
  },
}));

export default function CodeEditor(props) {
  const { question } = props;
  const classes = useStyles(props);

  console.log("isFullSubmit: ", props.isFullSubmit);

  //Height calculation logic: innerHeight - header - footer - lang selection area - input/out - labels & gaps
  let editorHt = window.innerHeight - 80 - 80 - 48 - 120 - 92;

  const authState = useSelector((state) => state.auth);
  const quizRes = useSelector((state) => state.quiz);

  const dispatch = useDispatch();
  const codingChallengeSubmissionId = useSelector(
    (state) => state.quiz.codingChallengeSubmissionId
  );
  const codingChallengeSubmissionDetail = useSelector(
    (state) => state.quiz.codingChallengeSubmissionDetail
  );

  const oldAns = getObject(
    "Question_ID",
    question.Question_ID,
    quizRes.selectedAnswers
  );
  let userTpl, oldInput;

  //if we have an answer for the same language, use that code rather than the template returned by the API
  if (oldAns && authState.codingLang.cid == oldAns.compilerId) {
    userTpl = oldAns.source;
    oldInput = oldAns.input;
  }
  const codeTpl =
    userTpl ||
    question[AppConfig.compilerToTplFieldMap[authState.codingLang.lang]];
  // const input = quizRes.codingInput || oldInput || "";
  const input = quizRes.codingInput || "";

  console.log("setting codingInput: ", input);

  const [state, setState] = useState({
    input: input,
    cid: 10,
    lang: "java",
    langId: 10,
    theme: "vs-dark",
    themeId: "1",
    output: "",
    compilationStatus: "",
    codeTpl: codeTpl,
  });

 

  function handleEditorWillMount(monaco) {
    console.log("handleEditorWillMount");
  }

  function handleEditorDidMount(editor, monaco) {
    if(navigator.onLine){
    props.monacoRef.current = editor;
    }
  }
  
  useEffect(() => {
    console.log("setting codingInput[3]: ", input);
    dispatch(quiz.setCodingInput(input));
  }, [input]);

  useEffect(() => {
    if(navigator.onLine){
    dispatch(quiz.setCodingText(codeTpl));
    }
  }, [codeTpl]);

  useEffect(() => {
    if (authState.codingTheme) {
      setState({
        ...state,
        theme: authState.codingTheme.theme,
        themeId: authState.codingTheme.themeId,
      });
    }
  }, [authState.codingTheme]);

  useEffect(() => {
    if (authState.codingLang) {
      setState({
        ...state,
        lang: authState.codingLang.lang,
        langId: authState.codingLang.langId,
        cid: authState.codingLang.cid,
        codeTpl: authState.codingLang.tpl,
      });
    }
  }, [authState.codingLang]);

  const onCodeChange = (newValue) => {
    if(navigator.onLine){
      console.log("code change",newValue)
    dispatch(quiz.updateCodingSubmissionDetail(null));
    setState({ ...state, codeTpl: newValue });
    dispatch(quiz.setCodingText(newValue));
    }
  };

  const onInputChange = (e) => {
    if(navigator.onLine){
    console.log("setting codingInput[2]: ", e.target.value);
    setState({ ...state, input: e.target.value });
    dispatch(quiz.setCodingInput(e.target.value));
    dispatch(quiz.updateCodingSubmissionDetail(null));
    }
  };

  useEffect(() => {
    if (codingChallengeSubmissionId) {
      setTimeout(() => {
        dispatch(
          quiz.getCodingSubmissionDetail({
            id: codingChallengeSubmissionId,
          })
        );
      }, 5000);
    }
  }, [codingChallengeSubmissionId]);

  useEffect(() => {}, [codingChallengeSubmissionDetail]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function updateDimensions() {
    editorHt = window.innerHeight - 80 - 80 - 48 - 120 - 92;
  }

  // const styles = {
  //   slideContainer: {
  //     //height: 100,
  //     WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
  //     webkitScrollbarWidth:'5px'
  //   },
  // }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={0}
      className={classes.root}
    >
      <Grid container className={classes.editorCt} alignItems="stretch">
        <Editor
          className={`${classes.codeEditor}`}
          height={editorHt}
          theme={state.theme}
          language={state.lang}
          value={codeTpl}
          onChange={onCodeChange}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          options={{
            // minimap: {
            //   enabled: false,
            // },
            fontSize: 14,
            cursorStyle: "block",
            wordWrap: "on",
          }}
        />
      </Grid>
      <Grid container className={`${classes.contGrid}`} spacing={2}>
        <Grid item xs={4}>
          <Typography
            component={Text}
            variant2="b2Medium"
            className={classes.valueText}
          >
            Input to your program (stdin)
          </Typography>
          <div
            className={`${classes.textArea} ${
              state.theme == "vs-dark" ? classes.darkcolor : classes.lightColor
            } `}
          >
            <TextareaAutosize
              rows={4}
              rowsMax={4}
              placeholder="Enter input value(s)"
              aria-label="input"
              onChange={onInputChange}
              className={`${classes.inputText} 
              ${state.theme == "vs-dark" ? classes.InputText : null}
                  ${
                    state.theme == "vs-dark"
                      ? classes.darkcolor
                      : classes.lightColor
                  } swiper`}
              value={input}
            ></TextareaAutosize>
          </div>
        </Grid>
        <Grid item xs={8} className={classes.outValue}>
          <Typography
            component={Text}
            variant2="b2Medium"
            className={classes.valueText}
          >
            Output from your program (stdout)
          </Typography>
          <div
            
          >
            <SwipeableViews
              index={
                codingChallengeSubmissionDetail &&
                codingChallengeSubmissionDetail.status === "compilation error"
                  ? 0
                  : 0
              }
              className={`${classes.textArea} ${
                state.theme == "vs-dark" ? classes.darkcolor : classes.lightColor
              }`}
            >
              <div
                className={`${classes.programOutput} ${
                  state.theme == "vs-dark" ? classes.darkcolorText : null
                } swiper`}
              >
                {props?.quizState?.isLoading && !props?.isFullSubmit
                  ? "Compiling code..."
                  : codingChallengeSubmissionDetail &&
                    codingChallengeSubmissionDetail.output}
              </div>
            </SwipeableViews>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
