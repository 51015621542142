import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  checkBoxst:{
    marginTop:'-13px',
    [theme.breakpoints.up("tablet")]: {
      marginTop:'0px',
    }
  }
}))

export default function Checkbox(props) {
  const { name, label, value, onChange, color } = props;
  const classes = useStyles();
  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            color={color || "primary"}
            checked={value}
            onChange={onChange}
            className={classes.checkBoxst}
          />
        }
        label={<span style={{ fontSize: "12px" }}>{label}</span>}
      />
    </FormControl>
  );
}
