import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import topNavIcon from "../../assets/top-nav-icon.png";
import logo from "../../assets/logo.svg";
import authState from "../../redux/actions/auth";
import quiz from "../../redux/actions/quiz";
import closeDrawer from "../../assets/closeDrawer.png";
import Footer from "../../components/Footer";
import EditIcon from "@material-ui/icons/Edit";
import FeedbackIcon from '@material-ui/icons/Feedback';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from "./Button";
import SimpleSnackbar from "../controls/ToastMessage"
import { ImagebaseUrl } from "../../services/globalService";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
  },
  fullList: {
    width: "auto",
  },
  title: {
    flexGrow: 1,
    width: 240,
  },
  login: {
    marginRight: "16px",
  },
  toolBar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  navBar: {
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    backgroundColor: theme.palette.primary.light,
  },
  containerCls: {
    [theme.breakpoints.up("tablet")]: {
      paddingRight: "64px !important",
      paddingLeft: "64px !important",
    },
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginTop: 12,
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  topNavIcon: {
    height: "8px",
    width: "16px",
  },
  swipeableDrawer: {
    width: "100%",
    zIndex: "99999999999999999 !important",
    "& .MuiDrawer-paperAnchorLeft": {
      width: "100%",
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiDrawer-root':{
      zIndex: "99999999999999999 !important",
    }
    
  },
  closeDrawer: {
    width: "14px",
    height: "14px",
    marginLeft: "16px",
    marginTop: "24px",
  },
  drawerContent: {
    margin: "24px auto",
    height: "fit-content",
    maxWidth: 'min-content',
    whiteSpace: 'nowrap',
    '@media (orientation: landscape)': {
      minHeight: "400px",
    }
  },
  editTcon:{
    width:'18px',
    height:'18px',
    color:`${theme.palette.btnPrimary.main}`
  },
  listItemText:{
    justifyContent: 'center !important',
    '& .MuiTypography-root':{
    fontFamily: theme.typography.subtitle2.fontFamily,
    fontSize: theme.typography.b1Medium.fontSize,
    fontStyle: theme.typography.subtitle2.fontStyle,
    fontWeight: theme.typography.b1Medium.fontWeight,
    lineHeight: `${theme.typography.b1Medium.lineHeight} !important`,
    letterSpacing: theme.typography.subtitle2.letterSpacing,
    textalign: "left",
    color:`${theme.palette.btnPrimary.main}`,
    },
    '& .MuiListItemText-root':{
      flex:`none !important`
    }
  },
  editTconDark:{
    width:'18px',
    height:'18px',
    color:`${theme.palette.btnPrimary.tertiaryText}`
  },
  listItemTextDark:{
    justifyContent: 'center !important',
    '& .MuiTypography-root':{
    fontFamily: theme.typography.subtitle2.fontFamily,
    fontSize: theme.typography.b1Medium.fontSize,
    fontStyle: theme.typography.subtitle2.fontStyle,
    fontWeight: theme.typography.b1Medium.fontWeight,
    lineHeight: `${theme.typography.b1Medium.lineHeight} !important`,
    letterSpacing: theme.typography.subtitle2.letterSpacing,
    textalign: "left",
    color:`${theme.palette.btnPrimary.tertiaryText}`,
    },
    '& .MuiListItemText-root':{
      flex:`none !important`
    }
  },
  toolSt:{
    
      paddingLeft:'8px',
      paddingRight:'8px'
    
  }
}));

export default function SwipeableTemporaryDrawer(props) {
  const { children } = props;

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onClickLogout = () => {
    if(navigator.onLine){
    dispatch(authState.clearState());
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    dispatch(quiz.clearState());

    history.push({
      pathname: "/",
    });
  }
  else {
    setOpenSnackBar(true)
  }
  };
  const HandleClickLink = () => {
    if(navigator.onLine){
    window.open("https://www.surveymonkey.com/r/YourTechScore","_blank")
    }
    else {
      setOpenSnackBar(false)
    }
  }
  const NavButtons = (link) => {
    if(navigator.onLine){
    history.push(link)
    }
    else {
      setOpenSnackBar(true)
    }
  }

  const list = (anchor) => (
    <>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div>
          <div>
            <img
              src={`${ImagebaseUrl}assets/closeDrawer.png`}
              className={classes.closeDrawer}
              onClick={toggleDrawer(anchor, false)}
            />
          </div>
          <div className={classes.drawerContent}>
            {!auth.user && (
              <List>
                <ListItem button key={"Log In"}   className={classes.listItemText}>
                  {/* <ListItemText primary={"Log In"} /> */}
                  <Button
                onClick={() => NavButtons("/Login")}
                  color="btnSecondary"
                  width="140px"
                  text="Log In"
                ></Button>
                </ListItem>
                <ListItem
                  button
                  key={"Join Now"}
                  onClick={() => NavButtons("/CreateAccount")}
                  className={classes.listItemTextDark}
                  
                >
                  <ListItemText primary={"Join Now"}  />
                </ListItem>
              </List>
            )}

            {auth.user && (
              <List>
                <ListItem button key={"Edit Profile Name"}   className={classes.listItemText} onClick={() => NavButtons("/EditProfile")}>
                  <ListItemText primary={"Edit Profile Name"}  />
                  <IconButton>
                      <EditIcon  className={classes.editTcon}/>
                   </IconButton>
                </ListItem>

                <ListItem button key={"Give Feedback"}  className={classes.listItemText} onClick={ HandleClickLink }>
                  <ListItemText primary={"Give Feedback"}   />
                  <IconButton>
                      <FeedbackIcon  className={classes.editTcon} />
                   </IconButton>
                </ListItem>

                <ListItem button key={"Invite a Friend"}   className={classes.listItemText} onClick={ props.handleDialog }>
                  <ListItemText primary={"Invite a Friend"} />
                  <IconButton>
                      <MailOutlineIcon  className={classes.editTcon} />
                   </IconButton>
                </ListItem>

                <ListItem
                  button
                  key={"Log Out"}
                  onClick={() => onClickLogout()}
                  className={classes.listItemTextDark} 
                >
                  <ListItemText primary={"Log Out"}  />
                  <IconButton>
                      <ExitToAppIcon  className={classes.editTconDark} />
                   </IconButton>
                </ListItem>
              </List>
            )}
          </div>
          <div className={classes.drawerFooter}>
            <Footer leftDrawer={true}></Footer>
          </div>
        </div>
      </div>
    </>
  );

  const toolbar = (anchor) => (
    <>
      <Toolbar className={classes.toolSt}>
        <Grid container>
          <Grid item xs={4}>
            <img
              src={`${ImagebaseUrl}assets/top-nav-icon.png`}
              className={classes.topNavIcon}
              onClick={toggleDrawer(anchor, true)}
            />
          </Grid>
          <Grid item xs={4} className={`${classes.toolBar} ${classes.center}`}>
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Toolbar>
    </>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <div key={anchor}>
          {toolbar(anchor)}
          <SwipeableDrawer
            className={`${classes.swipeableDrawer}`}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </div>
      ))}
       <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClose={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      
    </div>
  );
}
