import { DoughnutController, PointElement, LineElement, ArcElement } from 'chart.js';

export default class ScoreChart extends DoughnutController {
    draw() {

        super.draw(arguments);

        const meta = this.getMeta();
        //   console.log('Chart Metadata: ', this.chart.config.options.targetScore);
        var ctx = this.chart.ctx;
        //   console.log('Chart ctx: ', ctx, meta);

        var d = meta.data[0];
        var targetScore = this.chart.config.options.targetScore;
        var r = d.innerRadius + (d.outerRadius - d.innerRadius) / 2;
        // console.log('Radius: ', r);
        var totalArcLn = r * Math.PI;
        var arcLn = targetScore * (totalArcLn / this.chart.config.options.totalScore);
        var radian = arcLn / r;

        //consider subtraction as our angle starts from -PI to 0
        var x = d.x - r * Math.cos(radian);
        var y = d.y - r * Math.sin(radian);

        // console.log('theta, X, Y: ', arcLn, radian, degree,  x, y, d.x, d.y);

        var point1 = new PointElement({
            x: x,
            y: y,
            options: {
                 borderWidth: 4,
                 hitRadius: 1,
                 hoverBorderWidth: 1,
                 hoverRadius: 4,
                  pointStyle: 'circle',
                  radius: 12,
                  rotation: 360,
                  backgroundColor: "#787878",
                  borderColor: "#787878",
                  
            }
        });
        point1.draw(ctx);

        var point = new PointElement({
            x: x,
            y: y,
            options: {
                borderWidth: 4,
                hitRadius: 1,
                hoverBorderWidth: 1,
                hoverRadius: 4,
                pointStyle: 'circle',
                radius: 10,
                rotation: 360,
                backgroundColor: "#787878",
                borderColor: "#ffffff"
            }
        });
        point.draw(ctx);

        var r = d.outerRadius;
        var startX = d.x + r * Math.cos(Math.PI);;
        var startY = d.y + r * Math.sin(Math.PI);    
        ctx.beginPath();  
        ctx.moveTo(startX, startY);   
        ctx.lineTo(startX+20, startY); 
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#C4C4C4";
        ctx.stroke(); 

        ctx.font = "14px Roboto";
        ctx.fillStyle = "#C4C4C4";
        ctx.fillText(0, startX+25, startY);

        
        startX = d.x - r * Math.cos(Math.PI);
        startY = d.y - r * Math.sin(Math.PI);
        ctx.beginPath();      
        ctx.moveTo(startX, startY);   
        ctx.lineTo(startX-20, startY); 
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#C4C4C4";
        ctx.stroke();  
        
        ctx.font = "14px Roboto";
        ctx.fillStyle = "#C4C4C4";
        ctx.fillText(this.chart.config.options.totalScore, startX-50, startY);


        // var targetLabel = this.chart.config.options.targetLabel;
        // console.log('targetLabel: ', targetLabel);
        // if (targetLabel) {
        //     ctx.font = "14px Roboto";
        //     ctx.fillText(targetLabel, x - 20, y - 40);
        //     ctx.font = "bold 14px Roboto";
        //     ctx.fillText(targetScore, x - 15, y - 20);
        // }

        var score = this.chart.config.options.score;
        if (score) {
            var d = meta.data[0];
            var r = d.innerRadius + (d.outerRadius - d.innerRadius) / 2;

            //consider subtraction as our angle starts from -PI to 0
            var x = d.x + r * Math.cos(d.endAngle);
            var y = d.y + r * Math.sin(d.endAngle);

            // console.log('theta, X, Y: ', arcLn, radian, degree,  x, y, d.x, d.y);

            var point1 = new PointElement({
                x: x,
                y: y,
                options: {
                    borderWidth: 4,
                    hitRadius: 1,
                    hoverBorderWidth: 1,
                    hoverRadius: 4,
                    pointStyle: 'circle',
                    radius: 12,
                    rotation: 360,
                    backgroundColor: score < targetScore ? "#FF941C" : "#1789FC",
                    borderColor: "#FFFFFF"
                }
            });
            point1.draw(ctx);

            // if (score < targetScore) {
            //     ctx.font = "14px Roboto #FF941C";
            //     if (score < 400) {
            //         ctx.fillText("you're here", x-20, y-20);
            //     } else {
            //         ctx.fillText("you're here", x-80, y-20);
            //     }
            // } else {
            //     ctx.font = "14px Roboto #1789FC";
            //     if (score < 400) {
            //         ctx.fillText("passed your target!", x+20, y+5);
            //     } else if (score > 400) {
            //         ctx.fillText("passed your target!", x-140, y+5);
            //     } else {
            //         ctx.fillText("passed your target!", x-70, y-15);
            //     }
            // }

        }

        // var rangeMinScore = this.chart.config.options.rangeMinScore;
        // var rangeMaxScore = this.chart.config.options.rangeMaxScore;
        // if (rangeMinScore && rangeMaxScore) {
        //     var totalArcLn = r * Math.PI;
        //     var arcLn = (rangeMaxScore - rangeMinScore) * (totalArcLn / meta.total);
        //     var radian = arcLn / r;

        //     console.log('arcLn: ', arcLn);

        //     // var circumference = 
        //     var arcEl = new ArcElement({
        //         circumference: 90,
        //         startAngle: d.startAngle,
        //         endAngle: d.endAngle - 1,
        //         x: d.x,
        //         y: d.y,
        //         innerRadius: d.innerRadius,
        //         outerRadius: d.outerRadius,
        //         // borderWidth: this.borderWidth,
        //         options: {
        //             // offset: 100,
        //             rotation: -90,
        //             backgroundColor: "#ff0000",
        //         }
        //     });
        //     arcEl.draw(ctx);
        // }
    }
}

ScoreChart.id = 'scorechart';
ScoreChart.defaults = DoughnutController.defaults;