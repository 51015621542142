import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { Text } from "../../styles/theme";
import { useHistory } from "react-router-dom";
import GraphScoreMeter from "../../components/GraphScoreMeter/GraphScoreMeter";
import AppConfig from "../../constants/AppConfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Link from "@material-ui/core/Link";
import moment from "moment";
import quiz from "../../redux/actions/quiz";
import authMethod from "../../redux/actions/auth";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  leftBottom: {
    paddingTop: 36,
  },
  scoreLeftGray: {
    color: "#787878",
    textAlign: "center",
    paddingBottom: "12px",
  },
  whatsScore: {
    color: theme.palette.primary.main,
    fontFamily: "Rational",
    fontSize: "32px",
    fontStyle: "normal",
    lineHeight: "40px",
    letterSpacing: "-0.05em",
    textAlign: "center",
    paddingBottom: "12px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "48px",
      ineHeight: "48px",
    },
  },
  score: {
    fontFamily: "Rational",
    fontSize: "32px",
    fontStyle: "normal",
    lineHeight: "40px",
    letterSpacing: "-0.05em",
    textAlign: "center",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "48px",
      ineHeight: "48px",
    },
  },
  scoreArea: {
    border: `0`,
    backgroundColor: "#ffffff",
    alignItems: "center",
    marginBottom: 40,
    paddingTop: 24,
    [theme.breakpoints.up("tablet")]: {
      border: `24px solid ${theme.palette.primary.light}`,
      borderRadius: 4,
      paddingTop: 0,
    },
  },
  scoreLeftArea: {
    padding:8,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      width: "100%",
    },
    "@media (min-width:1450px)": {
     
      padding: 36,
      width: "auto",
    },
    // "@media (max-width:959.95px)": {
    //   padding: 0,
    //   width: "100%",
    // }
   
  },
  scoreMeter: {
    // marginTop: -120,
   
    // [theme.breakpoints.down("sm]")]:{
    //   marginTop: -68,
    //   '@media (orientation: landscape)': {
    //     marginTop:-112
    //   }
    // }
    marginTop: -120,
    [theme.breakpoints.down('xs')]: {
      marginTop: -68,
      '@media (orientation: landscape)':{
        marginTop:-112
        }
    },
  },
  unavailableScoreMeter: {
    marginTop: -68,
    fontFamily: "Rational",
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "24px",
    marginTop: 12,
    // marginBottom: 12,
    wordBreak: "break-word",
    color: theme.palette.neutrals.grayDark,
    paddingLeft: "48px",
    paddingRight: "48px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: 32,
      lineHeight: "32px",
      marginTop: 16,
      marginBottom: 8,
      paddingLeft: "80px",
      paddingRight: "80px",
    },
  },
  blueText: {
    color: "#1789FC",
  },
  unknownScore: {
    color: "#C4C4C4",
  },
  left: {
    borderRight: "0.5px solid #EBEFF8",
  },
  whatsNextLink:{
    fontWeight: theme.typography.b1Medium.fontWeight
  }
}));

function ScoreCard(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const onEditProfile = () => {
    dispatch(authMethod.isEditProfileQuiz(true));
    if(navigator.onLine){
    dispatch(quiz.isFromEditProfile(true));
    history.push({pathname:"/OpeningScreen"});
    }
    else{
      setOpenSnackBar(true)
    }
  };
  const handleClose = () => {
    setOpenSnackBar(false)
  }

  const findScore = (link) => {
    if(navigator.onLine){
      history.push(link)
    }
    else {
      setOpenSnackBar(true)
    }
  }
  
   let line2 = auth.userProfile && auth.userProfile.targetRole || "";
   line2 = line2.replace(' (','<br>(')

  return (
    <div>
      {isNotMobile && (
        <>
          {auth.userProfile.user_level == AppConfig.LEVEL0 && (
            <Grid container className={classes.scoreArea}>
              <Grid item xs={7} className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  threeLineWithLink={true}
                  line1="your target role"
                  line2={line2}
                  line3={`company type: ${
                    auth.userProfile && auth.userProfile.targetCompany
                  }`}
                  link={
                    <Link onClick={onEditProfile} data-rt-L0-editprofileL0 = "editprofileL0">
                      <Typography
                        component={Text}
                        variant2="link2"
                        className={classes.whatsNextLink}
                        align="center"
                      >
                        Edit Profile
                      </Typography>
                    </Link>
                  }
                  targetScore={auth.userProfile && auth.userProfile.targetScore}
                  targetLabel="target"
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
              <Grid item xs={5} className={classes.scoreLeftArea}>
                <div className={classes.leftTop}>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your score*
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="ag"
                    className={classes.whatsScore}
                    align="left"
                  >
                    ???
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="link2"
                    className=""
                    align="center"
                  >
                    <Link
                      className={classes.blueText}
                      onClick={() => findScore("/L1OpeningScreen")}
                    >
                      Find out your score
                    </Link>
                  </Typography>
                </div>
                <div className={classes.leftBottom}>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your target
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="score1"
                    className={`${classes.score} ${classes.scoreLeftGray}`}
                    align="left"
                  >
                    {auth.userProfile && auth.userProfile.targetScore}
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    {auth.userProfile.targetScoreTimeStamp == null ||
                    auth.userProfile.targetScoreTimeStamp == ""
                      ? null
                      : `by ${moment(
                          auth.userProfile.targetScoreTimeStamp
                        ).format("MMMM DD, YYYY")}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}

          {/* {auth.userProfile.user_level == "" && (
            <Grid container className={classes.scoreArea}>
              <Grid item xs={7} className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  fourLine={true}
                  line1="your target role"
                  line2="Unavailable Target Role"
                  line3="selected company type:"
                  line4="Unavailable"
                  scoreMeterTextCls={classes.unavailableScoreMeter}
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
              <Grid item xs={5} className={classes.scoreLeftArea}>
                <div className={classes.leftTop}>
                  <Typography
                    component={Text}
                    variant2="body2"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your score*
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="ag"
                    className={`${classes.whatsScore}  ${classes.unknownScore}`}
                    align="left"
                  >
                    ---
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="body2"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    Unavailable
                  </Typography>
                </div>
                <div className={classes.leftBottom}>
                  <Typography
                    component={Text}
                    variant2="body2"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your target
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="score1"
                    className={`${classes.score} ${classes.scoreLeftGray} ${classes.unknownScore}`}
                    align="left"
                  >
                    ---
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="body2"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    Unavailable
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )} */}

          {auth.userProfile.user_level == AppConfig.LEVEL1 && (
            <Grid container className={classes.scoreArea}>
              <Grid item xs={7} className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  threeLineWithLink={true}
                  line1="your target role"
                  line2={line2}
                  line3={`company type: ${
                    auth.userProfile && auth.userProfile.targetCompany
                  }`}
                  link={
                    <Link onClick={onEditProfile} data-rt-L1-editprofileL1 = "editprofileL1">
                      <Typography
                        component={Text}
                        variant2="link2"
                        className={classes.whatsNextLink}
                        align="center"
                      >
                        Edit Profile
                      </Typography>
                    </Link>
                  }
                  targetScore={auth.userProfile && auth.userProfile.targetScore}
                  targetLabel="target"
                  rangeMinScore={
                    auth.userProfile && auth.userProfile.scoreLowerBound
                  }
                  rangeMaxScore={
                    auth.userProfile && auth.userProfile.scoreUpperBound
                  }
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
              <Grid item xs={5} className={classes.scoreLeftArea}>
                <div className={classes.leftTop}>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your score range*
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="ag"
                    className={classes.whatsScore}
                    align="left"
                  >
                    {auth.userProfile && auth.userProfile.scoreLowerBound}-
                    {auth.userProfile && auth.userProfile.scoreUpperBound}
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    as of{" "}
                    {moment(auth.userProfile.totalScoreTimeStamp).format(
                      "MMMM DD, YYYY"
                    )}
                  </Typography>
                </div>
                <div className={classes.leftBottom}>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your target
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="score1"
                    className={`${classes.score} ${classes.scoreLeftGray}`}
                    align="left"
                  >
                    {auth.userProfile && auth.userProfile.targetScore}
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    {auth.userProfile.targetScoreTimeStamp == null ||
                    auth.userProfile.targetScoreTimeStamp == ""
                      ? null
                      : `by ${moment(
                          auth.userProfile.targetScoreTimeStamp
                        ).format("MMMM DD, YYYY")}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}

          {(auth.userProfile.user_level == AppConfig.LEVEL2 ||
            auth.userProfile.user_level == AppConfig.LEVEL3 ||
            auth.userProfile.user_level == AppConfig.LEVEL3_CODING ||
            auth.userProfile.user_level == AppConfig.LEVEL3_PRESENTATION ||
            auth.userProfile.user_level == AppConfig.LEVEL3_PROFILE ||
            auth.userProfile.user_level == AppConfig.LEVEL3_RESUME) && (
            <Grid container className={classes.scoreArea}>
              <Grid item xs={7} className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  threeLineWithLink={true}
                  line1="your target role"
                  line2={line2}
                  line3={`company type: ${
                    auth.userProfile && auth.userProfile.targetCompany
                  }`}
                  link={
                    <Link onClick={onEditProfile} data-rt-div-editprofile = "editprofile">
                      <Typography
                        component={Text}
                        variant2="link2"
                        className={classes.whatsNextLink}
                        align="center"
                      >
                        Edit Profile
                      </Typography>
                    </Link>
                  }
                  targetScore={auth.userProfile && auth.userProfile.targetScore}
                  targetLabel="target"
                  score={auth.userProfile && auth.userProfile.totalScore}
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
              <Grid item xs={5} className={classes.scoreLeftArea}>
                <div className={classes.leftTop}>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your current score*
                  </Typography>
                  {auth.userProfile &&
                    auth.userProfile.totalScore >=
                      auth.userProfile.targetScore && (
                      <Typography
                        component={Text}
                        variant2="ag"
                        className={`${classes.whatsScore} ${classes.blueText}`}
                        align="left"
                      >
                        {auth.userProfile && auth.userProfile.totalScore}
                      </Typography>
                    )}
                  {auth.userProfile &&
                    auth.userProfile.totalScore <
                      auth.userProfile.targetScore && (
                      <Typography
                        component={Text}
                        variant2="ag"
                        className={`${classes.whatsScore}`}
                        align="left"
                      >
                        {auth.userProfile && auth.userProfile.totalScore}
                      </Typography>
                    )}
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    as of{" "}
                    {moment(auth.userProfile.totalScoreTimeStamp).format(
                      "MMMM DD, YYYY"
                    )}
                  </Typography>
                </div>
                <div className={classes.leftBottom}>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    your target
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="score1"
                    className={`${classes.score} ${classes.scoreLeftGray}`}
                    align="left"
                  >
                    {auth.userProfile && auth.userProfile.targetScore}
                  </Typography>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={classes.scoreLeftGray}
                    align="left"
                  >
                    {auth.userProfile.targetScoreTimeStamp == null ||
                    auth.userProfile.targetScoreTimeStamp == ""
                      ? null
                      : `by ${moment(
                          auth.userProfile.targetScoreTimeStamp
                        ).format("MMMM DD, YYYY")}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {!isNotMobile && (
        <>
          {auth.userProfile.user_level === AppConfig.LEVEL0 && (
            <Grid container className={classes.scoreArea}>
              <Grid container className={classes.scoreLeftArea}>
                <Grid item xs={6}>
                  <div className={classes.left}>
                    <Typography
                      component={Text}
                      variant2="body2"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      current est. score
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="ag"
                      className={classes.whatsScore}
                      align="left"
                    >
                      ???
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="link2"
                      className=""
                      align="center"
                    >
                      <Link
                        className={classes.blueText}
                        onClick={() => findScore("/L1OpeningScreen")}
                      >
                        Find out your score
                      </Link>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.right}>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      your target*
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="score1"
                      className={`${classes.score} ${classes.scoreLeftGray}`}
                      align="left"
                    >
                      {auth.userProfile && auth.userProfile.targetScore}
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      {auth.userProfile.targetScoreTimeStamp == null ||
                      auth.userProfile.targetScoreTimeStamp == ""
                        ? null
                        : `by ${moment(
                            auth.userProfile.targetScoreTimeStamp
                          ).format("MMMM DD, YYYY")}`}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  threeLineWithLink={true}
                  line1="your target role"
                  line2={line2}
                  line3={`company type: ${
                    auth.userProfile && auth.userProfile.targetCompany
                  }`}
                  link={
                    <Link onClick={onEditProfile}>
                      <Typography
                        component={Text}
                        variant2="link2"
                        className={classes.whatsNextLink}
                        align="center"
                      >
                        Edit Profile
                      </Typography>
                    </Link>
                  }
                  targetScore={auth.userProfile && auth.userProfile.targetScore}
                  targetLabel="target"
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
            </Grid>
          )}

          {/* {auth.userProfile.user_level === "" && (
            <Grid container className={classes.scoreArea}>
              <Grid container className={classes.scoreLeftArea}>
                <Grid item xs={6}>
                  <div className={classes.left}>
                    <Typography
                      component={Text}
                      variant2="body2"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      your score*
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="ag"
                      className={`${classes.whatsScore}  ${classes.unknownScore}`}
                      align="left"
                    >
                      ---
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="body2"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      Unavailable
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.right}>
                    <Typography
                      component={Text}
                      variant2="body2"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      your target
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="score1"
                      className={`${classes.score} ${classes.scoreLeftGray} ${classes.unknownScore}`}
                      align="left"
                    >
                      ---
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="body2"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      Unavailable
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  fourLine={true}
                  line1="your target role"
                  line2="Unavailable Target Role"
                  line3="selected company type:"
                  line4="Unavailable"
                  scoreMeterTextCls={classes.unavailableScoreMeter}
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
            </Grid>
          )} */}

          {auth.userProfile.user_level == AppConfig.LEVEL1 && (
            <Grid container className={classes.scoreArea}>
              <Grid container className={classes.scoreLeftArea}>
                <Grid item xs={6}>
                  <div className={classes.left}>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      your score range*
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="ag"
                      className={classes.whatsScore}
                      align="left"
                    >
                      {`${
                        auth.userProfile && auth.userProfile.scoreLowerBound
                      } - ${
                        auth.userProfile && auth.userProfile.scoreUpperBound
                      }`}
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      as of{" "}
                      {moment(auth.userProfile.totalScoreTimeStamp).format(
                        "MMMM DD, YYYY"
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.right}>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      your target
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="score1"
                      className={`${classes.score} ${classes.scoreLeftGray}`}
                      align="left"
                    >
                      {auth.userProfile && auth.userProfile.targetScore}
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      {auth.userProfile.targetScoreTimeStamp == null ||
                      auth.userProfile.targetScoreTimeStamp == ""
                        ? null
                        : `by ${moment(
                            auth.userProfile.targetScoreTimeStamp
                          ).format("MMMM DD, YYYY")}`}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  threeLineWithLink={true}
                  line1="your target role"
                  line2={line2}
                  line3={`company type: ${
                    auth.userProfile && auth.userProfile.targetCompany
                  }`}
                  link={
                    <Link onClick={onEditProfile}>
                      <Typography
                        component={Text}
                        variant2="link2"
                        className={classes.whatsNextLink}
                        align="center"
                      >
                        Edit Profile
                      </Typography>
                    </Link>
                  }
                  targetScore={auth.userProfile && auth.userProfile.targetScore}
                  targetLabel="target"
                  // score="330"
                  rangeMinScore={
                    auth.userProfile && auth.userProfile.scoreLowerBound
                  }
                  rangeMaxScore={
                    auth.userProfile && auth.userProfile.scoreUpperBound
                  }
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
            </Grid>
          )}

          {(auth.userProfile.user_level == AppConfig.LEVEL2 ||
            auth.userProfile.user_level == AppConfig.LEVEL3 ||
            auth.userProfile.user_level == AppConfig.LEVEL3_CODING ||
            auth.userProfile.user_level == AppConfig.LEVEL3_PRESENTATION ||
            auth.userProfile.user_level == AppConfig.LEVEL3_PROFILE ||
            auth.userProfile.user_level == AppConfig.LEVEL3_RESUME) && (
            <Grid container className={classes.scoreArea}>
              <Grid container className={classes.scoreLeftArea}>
                <Grid item xs={6}>
                  <div className={classes.left}>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      your current score*
                    </Typography>
                    {auth.userProfile &&
                      auth.userProfile.totalScore >=
                        auth.userProfile.targetScore && (
                        <Typography
                          component={Text}
                          variant2="ag"
                          className={`${classes.whatsScore} ${classes.blueText}`}
                          align="left"
                        >
                          {auth.userProfile && auth.userProfile.totalScore}
                        </Typography>
                      )}
                    {auth.userProfile &&
                      auth.userProfile.totalScore <
                        auth.userProfile.targetScore && (
                        <Typography
                          component={Text}
                          variant2="ag"
                          className={`${classes.whatsScore}`}
                          align="left"
                        >
                          {auth.userProfile && auth.userProfile.totalScore}
                        </Typography>
                      )}
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      as of{" "}
                      {moment(auth.userProfile.totalScoreTimeStamp).format(
                        "MMMM DD, YYYY"
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.right}>
                    <Typography
                      component={Text}
                      variant2="b2Regular"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      your target
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="score1"
                      className={`${classes.score} ${classes.scoreLeftGray}`}
                      align="left"
                    >
                      {auth.userProfile && auth.userProfile.targetScore}
                    </Typography>
                    <Typography
                      component={Text}
                      variant2="body2"
                      className={classes.scoreLeftGray}
                      align="left"
                    >
                      {auth.userProfile.targetScoreTimeStamp == null ||
                      auth.userProfile.targetScoreTimeStamp == ""
                        ? null
                        : `by ${moment(
                            auth.userProfile.targetScoreTimeStamp
                          ).format("MMMM DD, YYYY")}`}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item className={classes.scoreLeftArea}>
                <GraphScoreMeter
                  threeLineWithLink={true}
                  line1="your target role"
                  line2={line2}
                  line3={`company type: ${
                    auth.userProfile && auth.userProfile.targetCompany
                  }`}
                  link={
                    <Link onClick={onEditProfile}>
                      <Typography
                        component={Text}
                        variant2="link2"
                        className={classes.whatsNextLink}
                        align="center"
                      >
                        Edit Profile
                      </Typography>
                    </Link>
                  }
                  targetScore={auth.userProfile && auth.userProfile.targetScore}
                  targetLabel="target"
                  score={auth.userProfile && auth.userProfile.totalScore}
                  scoreMeterCls={classes.scoreMeter}
                  className={`${classes.scoreMeter}`}
                ></GraphScoreMeter>
              </Grid>
            </Grid>
          )}
        </>
      )}
       <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default ScoreCard;
