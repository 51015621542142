import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import CircleCountdownTimer from "../components/Timer/CircleCountdownTimer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {},
  },
  section: {
    width: "100%",
  }
}));

function TimerTest(props) {
  const classes = useStyles();
  const [colorTransition, setColorTransition] = useState({info: "#28BB3F", warning: "#FF941C", alert: "#DD2E2E"});

  const thresholds = {
    warning: 30,
    alert: 10
  }

  const onWarning = (remainingTime)=> {
    console.log('Hurry up! You have ' + remainingTime, new Date());
  }

  const onTimeout = ()=> {
    console.log('Time up! ', new Date());
  }

  const onElapsedTime = (elapsedTime, remainigTime)=> {
    console.log('Elapsed time: ', elapsedTime, remainigTime);  
  }

  return (
    <div className={classes.root}>
      <CircleCountdownTimer
      size={200} 
      duration={2700}
      strokeWidth={4}
      thresholds={thresholds}
      elapsedTime={0}
      delayedStartDuration={5}
      colors={colorTransition}
      onElapsedTime={onElapsedTime}
      onThresholdStart={onWarning}
      onTimeout={onTimeout}
      >
      </CircleCountdownTimer>
    </div>
  );
}
export default TimerTest;
