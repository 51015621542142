import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Text } from "../styles/theme";
import ScrollButton from "../components/controls/ScrollButton";
import { useSelector, useDispatch } from "react-redux";
import auth from "../redux/actions/auth";
import AppConfig from "../constants/AppConfig";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
  },
  faqdiv: {
    marginTop: "100px",
    width: "100%",
  },
  textGrid: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textGridlast: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  faqText: {
    marginTop: "20px",
    borderBottom: "1px solid #C4C4C4",
    marginBottom: "10px",
  },
  faqparagraph: {
    margin: "12px 0px",
    padding: "0px 10px 6px 10px",
  },
  faqques: {
    padding: "10px",
  },
  textcontact:{
    fontSize:'14px',
    [theme.breakpoints.down("sm")]: {
      marginLeft:"12px"
    }
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginTop: 8,
    marginBottom: "15px",
    cursor: "pointer",
    [theme.breakpoints.up("tablet")]: {
      margin: "0px",
    },
  },
}));

export default function FAQ(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };
  const handleBackButton = () => {
    AppConfig.goBackButton = true;
    history.goBack();
  };
  return (
    <div className={classes.root}>
      <NavBar></NavBar>
      <Grid className={classes.faqdiv}>
        {/* <Grid>
                <Typography>Frequently Asked Questions</Typography>
                <Typography>Return to home</Typography>
                </Grid> */}
        <Grid container>
          {isNotMobile && <Grid item xs={3}></Grid>}
          <Grid item xs={isNotMobile ? 6 : 12} style={isNotMobile ? { padding: "10px 10px 40px" } : { padding: "10px" }}>
            <Grid className={classes.textGrid}>
              <Typography variant="h3">Frequently Asked Questions</Typography>
              <Link onClick={handleBackButton} data-rt-faq-backgo = "backgo">
                <Typography className={classes.labelBlue}>Back</Typography>
              </Link>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                What is YourTechScore?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                YourTechScore is an online evaluation platform to evaluate your
                progress on all aspects of career development, including
                technical skills, coding acumen, online profile, resume
                preparation, and interview or presentation readiness. In the
                end, it provides you with your customized comprehensive career
                score. Members work at their own pace, to their own aspirations,
                while the community inspires, supports and challenges them to
                attain higher mutual outcomes.{" "}
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                How does it work?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Think of a GPS - you need to know where you are, where you are
                going, and how to get there. This is exactly how YourTechScore
                works. You have to answer a few basic questions to determine
                your target profile. Each target profile has a unique target
                score. You will then be asked to take a series of online tests
                to determine your comprehensive career score. This score
                represents your current preparedness for a career level-up.
                Then, we have pathways available for you to progress in the
                direction of your target profile. You have complete control over
                your speed and direction of travel. Your rising score represents
                increased confidence in your outcomes.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                What are the steps involved in getting a Comprehensive Career
                Score?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Once your target role and target score have been identified, we
                ask you to spend about 15 mins to get a rough range of your
                career score. This evaluation covers the basics of technical
                acumen as well as your online profile and presentation
                readiness. Then we present you a customized dashboard which
                shows your target score and where your current range lies. You
                can then take the following evaluations, one at a time - at your
                convenience, at the end of which you will get your comprehensive
                career score.
                <ul>
                  <li>
                    {" "}
                    Profile section - where we review your resume and online
                    profile
                  </li>
                  <li>
                    Technical section - covering multiple-choice questions in
                    your area of expertise
                  </li>
                  <li>
                    Coding section - where you need to solve a few coding
                    challenges in increasing order of difficulty
                  </li>
                  <li>
                    Presentation section - which checks your interview readiness
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                How long does it take to get my Comprehensive Career Score?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Your score range can be obtained after completing the initial
                evaluation which only takes 15 minutes. Subsequently, to get
                your comprehensive career score, you need to complete all four
                sections outlined above. It will take you about 80-90 mins to
                complete all four sections if done together in one sitting.{" "}
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                I see multiple scores. What do these all mean?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                When you create your profile and determine your target role and
                company type, we calculate your target score. This target score
                is generally between 600 and 800, and is based on extensive data
                that we have collected from consultants and hiring managers at
                various companies over the years. Your target score will remain
                fixed and will not change until you change your desired profile
                or company type.{" "}
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Next, your initial evaluation will determine your rough score
                range. This one-time exercise is designed to give you a quick
                feel of where your score will likely be, relative to your
                target. Score range can be anywhere between 0 and 800.
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Finally, your career score is determined by a comprehensive
                evaluation in four areas as described earlier. You need to
                complete all sections to ensure you get an accurate score. Also
                remember to answer all questions in each section that you are
                presented with. Your primary objective is to get your career
                score as close as possible, or better than, your target score.
                We will also show you a tentative target date by which we think
                you may be able to reach your target score based on your current
                pace. Once you reach your target score, it means we consider you
                ready to apply for similar positions as your target profile.
                Good job!
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                How many attempts do I get to improve my Career Score?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                As many as you want. You can retake the 4 evaluation sections as
                many times as you wish.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                How do I maximize my Career Score?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Ensure that you have attempted all four sections. Under each
                section, answer as many questions as possible. Try not to leave
                any question unanswered. Ensure you upload your latest resume
                and share your online profile details. And prepare, prepare,
                prepare prior to taking the quiz and coding challenge.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                How long do I have access to my personalized dashboard?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                As long as you want. After getting your rough score range, and
                prior to obtaining your comprehensive career score and
                dashboard, you need to create an account. This guarantees that
                your evaluation results are properly stored and you can complete
                any pending tests on a future date at your convenience.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                Can I change my target profile in the future?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Absolutely. Just remember that making minor changes, like the
                type of company you're interested in pursuing, may impact your
                target score and timeline. Whereas making major changes, for
                example, like pursuing a Java backend developer role instead of
                a frontend UI engineer role, will clear all the old test results
                and start your evaluation process all over again.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                How do I invite my friends or colleagues to this platform?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                You can invite others by clicking on the "Invite a Friend" link
                at the top of the home page.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                Is there a way I can give feedback regarding my experience with
                your platform?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Yes, by clicking on the "Give Feedback" link at the top of the
                home page or using the “Contact Us” link at the bottom of this
                page.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                What are the benefits of YourTechScore?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Tech skills have a high technical obsolescence rate. To stay
                earning, you have to keep learning. Alone,​ the average Software
                Engineer ​is a poor performer in the ​career market. For the 30%
                that look for a change every single year,​ only 2% of their job
                search efforts will actually work. This is generally caused by
                limited career visibility, mixed guidance, a limted technical
                foundation, average resume ​and amateur interviewing skills in a
                fast-moving market. Being professionally evaluated in all these
                areas gives our members a sense of reassurance and security.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                What is the guarantee that I will get an offer from the desired
                company if I meet my target score?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Your results will depend on your efforts. The more you put in,
                the more you will get out of it. While we cannot offer you any
                specific guarantees, our past data of 3000+ consultants
                collected over nearly 10 years shows us that well-prepared
                candidates have a 10x better chance of succeeding at technical
                interviews relative to those who go unprepared.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                Is there a membership fee for getting my score? Can I cancel my
                account anytime?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                There is no membership fee. And you can cancel your membership
                anytime. We hope you will get more value from being part of this
                learning community, but we understand if your situation changes.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={classes.faqques}
              >
                I’m sold! How do I sign up?
              </Typography>
              <Typography
                component={Text}
                className={classes.faqparagraph}
                variant2="b2Regular"
              >
                Please click “Join Now” to sign up. YourTechScore and career
                community are waiting.
              </Typography>
            </Grid>
            <Grid className={classes.textGridlast}>
              <Typography className={classes.textcontact}>
                Have more questions or feedback?{" "}
                <Link onClick={() => history.push("/Aboutus")}>
                  <span className={classes.labelBlue}>Contact Us</span>
                </Link>
              </Typography>
              <Link onClick={handleBackButton}>
                <Typography className={`${classes.labelBlue} ${classes.textcontact}`}>Back</Typography>
              </Link>
            </Grid>
            <ScrollButton />
          </Grid>
          {isNotMobile && <Grid item xs={3}></Grid>}
        </Grid>
      </Grid>
      <Footer></Footer>
    </div>
  );
}
