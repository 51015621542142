import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../components/controls/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/controls/TextInput";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Text } from "../../styles/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import auth from "../../redux/actions/auth";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ButtonMaterial from "@material-ui/core/Button";
import AppConfig from "../../constants/AppConfig";
import { ImagebaseUrl } from "../../services/globalService";
import Link from "@material-ui/core/Link";
import * as QueryString from "query-string"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light
  },
  section: {
    width: "100%"
  },
  title: {
    flexGrow: 1,
    marginTop: "48px"
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px"
    }
  },
  cardTwo: {
    marginTop: "16px",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  card: {
    marginBottom: 24
  },
  cardSix: {
    marginTop: "100px",
    width: "100%",
    // justifyContent: "space-between",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "100px"
    }
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    cursor: "pointer"
  },
  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  formControl: {
    marginTop: 24
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"
    }
  },
  submit: {
    marginTop: "24px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    "& button": {
      [theme.breakpoints.up("tablet")]: {
        marginLeft: "0",
        marginRight: "0"
      }
    },
  },
  createAccountDescriptionArea: {
    lineHeight: "24px",
    marginTop: "30px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px"
    }
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px"
    }
  },
  contentArea: {
    paddingLeft: 16,
    paddingRight: 16
  },
  passwordText: {
    display: "flex",
    justifyContent: "space-between"
  },
  accountText: {
    color: "#787878"
  }
}));

function SavePassword(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [showErrorMessage, setErrorMessage] = useState("");
  const [isShowSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBar, setShowSnackBarOnSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const queryvalues = QueryString.parse(props.location.search)
  const onSubmit = (values) => {
    values.level = location.level;
    console.log("Form data", values);
    setShowSnackBarOnSubmit(true);
    // dispatch(
    //   auth.createUserWithFirebaseRequested({
    //     password: values.password
    //   })
    // );

    dispatch(auth.updatePassword({
      uid: queryvalues.uid,
      password: values.password
    }))
    setTimeout(() => {
      history.push("/ResetpasswordSuccessScreen")
    }, 500)
  };

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{7,})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/,
        "Strong passwords have at least 6 characters and a mix of letters and numbers"
      ),
    confirmPassword: Yup.string()
      .required("Please confrim your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  // useEffect(() => {
  //   if (
  //     authState.firebaseUserDetails &&
  //     authState.firebaseUserDetails.error &&
  //     showSnackBar
  //   ) {
  //     setShowSnackBar(true);
  //     setErrorMessage(authState.firebaseUserDetails.error);
  //   }
  //   if (
  //     authState.firebaseUserDetails &&
  //     authState.firebaseUserDetails.user &&
  //     showSnackBar
  //   ) {
  //     const { user } = authState.firebaseUserDetails;
  //     if (
  //       authState.firebaseUserDetails &&
  //       authState.firebaseUserDetails.user &&
  //       authState.firebaseUserDetails.additionalUserInfo &&
  //       authState.firebaseUserDetails.additionalUserInfo.isNewUser
  //     ) {
  //       user.sendEmailVerification();
  //       let payload = {
  //         uid: user.uid,
  //         email: user.email,
  //         firstName: formik.values.firstName,
  //         lastName: formik.values.lastName,
  //         displayName:
  //           user.displayName ||
  //           formik.values.firstName + " " + formik.values.lastName,
  //         emailVerified: user?.emailVerified,
  //         AuthMethod: "Native",
  //         profileId: 1,
  //         photoUrl: user?.photoUrl,
  //         phonenumber: user?.phonenumber,
  //         createdAt: user?.metadata.creationTime,
  //         lastLoginAt: user?.metadata.lastSignInTime,
  //         // user_level: quizState?.level,
  //         user_level: authState?.level,
  //         targetScore: quizState?.testResult?.targetScore,
  //         targetRole: quizState?.testResult?.role,
  //         targetCompany: quizState?.testResult?.targetCompany,
  //         primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
  //         scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
  //         scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
  //         skills: quizState?.testResult?.skills,
  //       };

  //       let userMetaData = {
  //         email: user?.email,
  //         code: `${AppConfig?.l0QuestionCode}`,
  //         result: quizState?.userMetaDataReq,
  //       };
  //       dispatch(auth.createProfile(payload, userMetaData));
  //       history.push({
  //         pathname: "/CreateverifySuccess",
  //         state: {
  //           email: formik.values.email,
  //           password: formik.values.password,
  //         },
  //       });
  //     }
  //   }
  // }, [authState.firebaseUserDetails]);
  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={`${classes.logoCls}`} />
      </div>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}

        <Grid item xs={isNotMobile ? 4 : 12} className={classes.contentArea}>
          <div className={`${classes.section} ${classes.cardTwo}`}>
            <Typography
              component={Text}
              variant2={isNotMobile ? "h3" : "h4"}
              align="center"
              className={`${classes.cardTwoH1} ${classes.title}`}
            >
              Reset Password
            </Typography>
          </div>
          <div
            className={`${classes.section}  ${classes.card} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
            >
              Please enter your new password.It cannot be a password you've previously used.
            </Typography>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <Container className={classes.inputCt}>
              <div className={classes.formControl}>
                <label htmlFor="password">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Password {""}
                    <span className={classes.loginAccount}>
                      (min 6 characters)
                    </span>
                  </Typography>
                </label>
                <TextInput
                  name="password"
                  placeholder=""
                  className={`${classes.field} parentclassname`}
                  id="password"
                  onChange={formik.handleChange}
                  //error={Boolean(formik.errors.password)}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  passwordText={"PasswordIcon"}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                ></TextInput>
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.password}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div className={classes.formControl}>
                <label htmlFor="password">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Confirm New Password {""}
                    <span className={classes.loginAccount}>(Must match)</span>
                  </Typography>
                </label>
                <TextInput
                  name="confirmPassword"
                  placeholder=""
                  className={`${classes.field} parentclassname`}
                  id="confirmPassword"
                  onChange={formik.handleChange}
                  //error={Boolean(formik.errors.password)}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  passwordText={"PasswordIcon"}
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                ></TextInput>
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.confirmPassword}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </Container>

            <div
              className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
            >
              <Button
                disabled={
                  !(formik.isValid && formik.dirty)
                }
                type="submit"
                color="btnPrimary"
                width="100%"
                text="Save Password"
                data-rt-createaccount-signup="savePassword"
              ></Button>
            </div>
            <Grid
              container
              className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid className={classes.textGrid}>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                  align="right"
                  className={classes.accountText}
                >
                  Don't have an account? &nbsp;
                  <Link onClick={() => history.push("/CreateAccountWithEmail")} variant={isNotMobile ? "link1" : "link2"}
                    className={`${classes.labelBlue}`} data-rt-resetpassworddetails-resetsignup="resetsignup">
                    Sign Up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={showErrorMessage}
        action={
          <React.Fragment>
            <ButtonMaterial
              color="secondary"
              size="small"
              onClick={handleCloseSnackbar}
            ></ButtonMaterial>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default SavePassword;
