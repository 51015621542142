import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "../../components/controls/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Text } from "../../styles/theme";

// import orImg from "../../assets/Group 145.svg";
// import googleIcon from "../../assets/google_search_icon.png";

import { useSelector, useDispatch } from "react-redux";
import auth from "../../redux/actions/auth";
import quiz from "../../redux/actions/quiz";

// import logo from "../../assets/logo.svg";

import firebase from "@firebase/app";
import "@firebase/auth";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import AppConfig from "../../constants/AppConfig";
import { ImagebaseUrl } from "../../services/globalService";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ButtonMaterial from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    paddingRight: 16,
    paddingLeft: 16,
    [theme.breakpoints.up("tablet")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  applyPadding: {
    padding: "16px",
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
    marginTop: 50,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "24px",
  },

  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: "40px",
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginLeft: 8,
    cursor: "pointer",
  },
  cardTwoH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  inputCt: {
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.up("tablet")]: {
      // width: 460,
    },
  },
  textInput: {
    marginTop: 24,
    minHeight: 46,
    backgroundColor: theme.palette.background.level2,
  },
  whatsNextLink: {
    // fontSize: 24,
    // lineHeight: "24px",
    // fontFamily: "Rational",
    // fontStyle: "normal",
    // marginBottom: 12
  },
  arrowImg: {
    marginLeft: 8,
  },
  googleIcon: {
    // position: "absolute",
    height: 30,
    width: 30,
    marginRight: 12,
  },
  orImg: {
    marginTop: 32,
    marginBottom: 32,
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "auto",
      marginTop: 32,
      marginBottom: 32,
    },
  },
  createAccountDescriptionArea: {
    // width: "424px",
    //fontSize: "14px",
    lineHeight: "24px",
    marginTop: "30px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    },
  },
  field: {
    backgroundColor: theme.palette.background.light,
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  TechScore: {
    fontFamily: "Frank Ruhl Libre",
    fontStyle: " normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
  },
  cardAlign: {
    // position: "fixed",
    /* width: 351px; */
    //height: "60px",
    // left: "12px",
    /* top: 201px; */

    fontFamily: " Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    bottom: "10px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#2F2F2F",
    [theme.breakpoints.up("tablet")]: {
      marginTop: "100px",
    },
  },
  accountText: {
    color: "#787878",
    // fontFamily: "Roboto",
    // fontStyle: "normal",
    // fontWeight: "normal",
    // fontSize: "14px",
    // lineHeight: "20px",
    // [theme.breakpoints.up("tablet")]: {
    //   fontSize: "16px",
    //   lineHeight: "24px",
    // },
  },
}));

const initialValues = {
  email: "",
};

function Login(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const quizState = useSelector((state) => state.quiz);
  const authState = useSelector((state) => state.auth);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [doesUserExists, setDoesUserExists] = useState(false);
  const [isShowSnackBar, setShowSnackBar] = useState(false);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const onClickSignUp = () => {
    if(navigator.onLine){
    history.push({
      pathname: "/LoginWithEmail",
      //level: formik.values,
    });
  }
  else {
    setShowSnackBar(true)
  }
  };
  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required!"),
  });

  const onSubmit = (values) => {
    values.level = location.level;
    console.log("Form data", values);

    history.push({
      pathname: "/CreateAccountWithEmail",
      state: values,
    });
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const signinUserWithGoogle = () => {
    if(navigator.onLine){
    dispatch(auth.beginLoginUser());
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        localStorage.setItem("user_id", "user-id");
        localStorage.setItem("user", JSON.stringify(result.user));

        dispatch(auth.firebaseUserLogin(result));
        dispatch(auth.loginSuccess(result.user));
        setLoggedInUser(result.user);
        setDoesUserExists(false);
        dispatch(auth.getProfile({ email: result.user?.email }));
        //createProfile(result.user);
      })
      .catch(function (error) {
        dispatch(auth.loginError(error));
      });
    }
    else {
      setShowSnackBar(true)
    }
  };
  useEffect(() => {
    if (!doesUserExists && !authState.userProfile) {
      createProfile(loggedInUser);
      setDoesUserExists(true);
    } else if (!doesUserExists && authState.userProfile) {
      dispatch(auth.createProfile({email: authState.userProfile.email, lastLoginAt: loggedInUser?.metadata.lastSignInTime}))
      setDoesUserExists(true);
      dispatch(auth.updateUserExists(true));
    }
  }, [authState.userProfile]);

  useEffect(() => {
    if (authState.userProfile) {
      setTimeout(() => {
        history.push({
          showPopup: true,
          pathname: "/Dashboard",
        });
      }, 1500);
    }
  }, [authState.userProfile]);

  const createProfile = (user) => {
    if (!user) {
      return;
    }
    let name = user.displayName.split(" ");
    const firstName = name[0];
    const lastName = name[1];
    let payload = {
      uid: user?.uid,
      email: user?.email,
      firstName: firstName,
      lastName: lastName,
      displayName: user?.displayName || firstName + " " + lastName,
      AuthMethod: "Google",
      profileId: 1,
      photoUrl: user?.photoURL,
      phonenumber: user?.phoneNumber,
      createdAt: user?.metadata.creationTime,
      lastLoginAt: user?.metadata.lastSignInTime,
      emailVerified: user?.emailVerified,
      user_level: authState?.level,
      targetScore: quizState?.testResult?.targetScore,
      targetRole: quizState?.testResult?.role,
      targetCompany: quizState?.testResult?.targetCompany,
      primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
      scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
      scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
      skills: quizState?.testResult?.skills,
    };
    let userMetaData = {
      email: user?.email,
      code: `${AppConfig?.l0QuestionCode}`,
      result: quizState?.userMetaDataReq,
    };
    dispatch(auth.createProfile(payload, userMetaData));
    // dispatch(quiz.resetTestResult());
  };

  const SignUpLink = (link) => {
    if(navigator.onLine){
      history.push(link)
    }
    else {
      setShowSnackBar(true)
    }
  }
  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img
          alt="logo"
          src={`${ImagebaseUrl}assets/logo.svg`}
          className={classes.logoCls}
        />
      </div>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}

        <Grid item xs={isNotMobile ? 4 : 12}>
          <div className={`${classes.section} ${classes.cardTwo}`}>
            <Typography
              component={Text}
              variant2={isNotMobile ? "h3" : "h4"}
              align="center"
              className={`${classes.cardTwoH1} ${classes.title}`}
            >
              Log In
            </Typography>
          </div>

          <div
            className={`${classes.section}  ${classes.card} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
            >
              Welcome back to your saved score, track progress, and get access
              to training materials, workshops, and our community of
              professionals.
            </Typography>
          </div>

          <div
            className={`${classes.section} ${classes.center}`}
            style={{ boxShadow: "" }}
          >
            {/* <div className={classes.textInput}> */}
            <Button
              startIcon={
                <Avatar
                  className={`${classes.googleIcon}`}
                  src={`${ImagebaseUrl}assets/google_search_icon.png`}
                />
              }
              color="googleButton"
              width="100%"
              text="Continue with Google"
              onClick={signinUserWithGoogle}
              data-rt-login-signupgoogle = "signupgoogle"
            ></Button>

            {/* </div> */}
          </div>

          <div
            className={`${classes.section} ${classes.center} ${classes.orImg}`}
          >
            <img
              className={`${classes.openingScreen}`}
              alt="OR"
              src={`${ImagebaseUrl}assets/Group 145.svg`}
            />
          </div>

          <form>
            <div
              className={`${classes.section} ${classes.center} ${classes.cardTwo} ${classes.submit}`}
            >
              <Button
              data-rt-login-continueEmail = "continueEmail"
                //disabled={!(formik.isValid && formik.dirty)}
                color="btnPrimary"
                width="100%"
                text="Continue with Email"
               // type="submit"
                onClick={() => onClickSignUp()}
              ></Button>
            </div>
          </form>
          <div
            className={`${classes.section} ${classes.center}  ${classes.cardTwo} `}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.cardAlign}
            >
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="right"
                className={classes.accountText}
              >
                Don't have an account? &nbsp;
                <Link
                  onClick={() => SignUpLink("/CreateAccountWithEmail")}
                  variant={isNotMobile ? "link1" : "link2"}
                  className={`${classes.labelBlue}`}
                  data-rt-login-signup = "signup"
                >
                  Sign Up
                </Link>
              </Typography>
            </Grid>
          </div>
        </Grid>

        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <ButtonMaterial
              color="secondary"
              size="small"
              onClick={handleCloseSnackbar}
            ></ButtonMaterial>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default Login;
