import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Toastsuccess from "../../assets/svg/Toastsuccess.svg";
import { makeStyles } from "@material-ui/core/styles";
import { ImagebaseUrl } from '../../services/globalService';
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  Toaster: {
    "&.MuiSnackbar-anchorOriginTopRight": {
      top: "80px !important",
      justifyContent: "flex-end",
    },
  },
}));

export default function SimpleSnackbar(props) {
  const { vertical, horizontal, message, severity, ...other } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.setOpen(false);
  };

  return (
    <>
      {severity != undefined ? (
        <div className={classes.root}>
          <Snackbar
            open={props.open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: vertical,
              horizontal: horizontal,
            }}
          >
            <Alert onClose={handleClose} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div>
        {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
        <Snackbar
        className={classes.Toaster}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={props.open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={
            <div>
              <img src={`${ImagebaseUrl}assets/svg/Toastsuccess.svg`} className="Toastsuccess"/> 
              <span  className="invitemsg">invitation sent</span>
            </div>
          }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
      )}
    </>
  );
}