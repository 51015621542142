import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Text } from "../styles/theme";
import ScrollButton from "../components/controls/ScrollButton";
import Aboutimage from "../assets/svg/Aboutimage.svg";
import Imagebackground from "../assets/svg/Imagebackground.svg";
import AppConfig from "../constants/AppConfig";
import { ImagebaseUrl } from "../services/globalService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
  },
  faqdiv: {
    marginTop: "100px",
    width: "100%",
  },
  textGrid: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textGridlast: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  faqText: {
    marginTop: "20px",
    borderBottom: "1px solid #C4C4C4",
    marginBottom: "10px",
  },
  faqparagraph: {
    margin: "12px 0px",
    padding: "0px 10px 6px 10px",
  },
  faqques: {
    padding: "10px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginTop: 8,
    marginBottom: "15px",
    cursor: "pointer",
    [theme.breakpoints.up("tablet")]: {
      margin: "0px",
    },
  },
  mailto: {
    color: theme.palette.btnPrimary.main,
    // margin: 8,
    cursor: "pointer",
  },
  ImageBackround: {
    display: "flex",
    justifyContent: "center",
  },
  Imagest: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "auto",
    },
  },
  imagecolor: {
    backgroundImage: "url(" + `${ImagebaseUrl}assets/svg/Imagebackground.svg` + ")",
    height: "160px",
  },
  contactus: {
    marginTop: "20px",
  },
  contactText: {
    marginBottom: "20px",
  },
}));

export default function AboutPage(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };
  const handleBack = () => {
    AppConfig.goBackButton = true;
    history.goBack();
  };
  return (
    <div className={classes.root}>
      <NavBar></NavBar>
      <Grid className={classes.faqdiv}>
        <Grid container>
          {isNotMobile && <Grid item xs={3}></Grid>}
          <Grid item xs={isNotMobile ? 6 : 12} style={{ padding: "10px" }}>
            <Grid className={classes.textGrid}>
              <Typography variant="h3">About Us</Typography>
              <Link onClick={handleBack} data-rt-about-backgo = "backgo">
                <Typography className={classes.labelBlue}>Back</Typography>
              </Link>
            </Grid>
            <Typography
              component={Text}
              className={classes.faqparagraph}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
            >
              We don’t drive to a new destination without a GPS. Why drive blind
              into your career changes? YourTechScore believes better
              information can reduce the frustration in software engineering
              career changes. Our Career Score has been built with decades of
              industry experience, and with data from thousands of actual users
              just like you.{" "}
            </Typography>

            {/* <div className={classes.ImageBackround}>
              <img src={`${ImagebaseUrl}assets/svg/Aboutimage.svg`} className={classes.Imagest} />
            </div> */}
            <div>
              <Typography
                component={Text}
                variant="h3"
                // className={classes.contactus}
                className={`${classes.textGrid} ${classes.contactus}`}
              >
                Contact Us
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                className={classes.faqparagraph}
              >
                {" "}
                We appreciate your feedback. Please email{" "}
                <Link
                  className={classes.mailto}
                  href="mailto:info@yourtechscore.com"
                >
                  info@yourtechscore.com
                </Link>{" "}
                with any questions or suggestions.
               {" "}
                Thank you!
              </Typography>
            </div>
          </Grid>
          {isNotMobile && <Grid item xs={3}></Grid>}
        </Grid>
        {/* <div style={{}} className={classes.imagecolor}>
                   
                </div> */}
      </Grid>
      {/* <div className={classes.imagecolor}></div> */}
      <Footer></Footer>
    </div>
  );
}
