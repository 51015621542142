import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import slideOne from "../../assets/score card 1.png";
// import slideTwo from "../../assets/score card 2.png";
// import slideThree from "../../assets/score card 3.png";
// import slideFour from "../../assets/score card 4.png";
import Carousel from "react-material-ui-carousel";
import Typography from "@material-ui/core/Typography";
import { ImagebaseUrl } from "../../services/globalService";

const useStyles = makeStyles((theme, props) => ({
  carouselCls: {
    width: (props) => props.contentWidth || "500px !important",
    "& .MuiButtonBase-root": {
      margin: "0 0px",
      position: "relative",
      backgroundColor: "inherit !important",
      top: "calc(50% - 20px) !important",
      color: "rgba(120, 120, 120, 1) !important",
      fontSize: "30px",
      transition: "800ms",
      cursor: "pointer",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      opacity: "1.0 !important",
      "&:hover": {
        opacity: "1 !important",
        backgroundColor: "inherit !important",
        color: "rgba(120, 120, 120, 1) !important",
      },
      "&:label": {
        width: "13px",
        height: "20px",
        backgroundColor: "inherit !important",
      },

      // Applies to the "next" button wrapper
      "& next": {
        right: "-6px !important",
      },
      // Applies to the "prev" button wrapper
      "& prev": {
        left: "-6px !important",
      },
    },
  },

  sliderImg: {
    filter: "drop-shadow(0px 8.02188px 40.1094px rgba(196, 196, 196, 0.2))",
  },
  App: {
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      margin: "auto",
    },
  },
}));

function ImageCarousel(props) {
  const classes = useStyles(props);

  const { width, height, contentWidth } = props;

  // State to programmatically set active child
  const [activeChild, setActiveChild] = useState(0);

  // Basically items = [1, 2, 3, 4]
  let slideOne = `${ImagebaseUrl}assets/score card 1.png`
  let slideTwo = `${ImagebaseUrl}assets/score card 2.png`
  let slideThree = `${ImagebaseUrl}assets/score card 3.png`
  let slideFour = `${ImagebaseUrl}assets/score card 4.png`
  const items = useMemo(() => [slideOne, slideTwo, slideThree, slideFour], []);

  // The Keypress Event Handler
  const changeChild = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        // If supposed previous child is < 0 set it to last child
        setActiveChild((a) => (a - 1 < 0 ? items.length - 1 : a - 1));
      } else if (e.key === "ArrowRight") {
        // If supposed next child is > length -1 set it to first child
        setActiveChild((a) => (a + 1 > items.length - 1 ? 0 : a + 1));
      }
    },
    [items]
  );

  // Set and cleanup the event listener
  useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  });

  return (
    <div className={classes.App}>
      <Carousel
        index={activeChild} // <-- This controls the activeChild
        autoPlay={true} // <-- You probaly want to disable this for our purposes
        navButtonsAlwaysVisible
        className={`${classes.carouselCls}`}
        animation="fade"
      >
        {items.map((item, i) => {
          return (
            <Typography align="center" key={i}>
              <img
                className={classes.sliderImg}
                src={item}
                alt=""
                width={width || "388px"}
                height={height || "377px"}
              />
            </Typography>
          );
        })}
      </Carousel>
    </div>
  );
}

export default ImageCarousel;
