import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography'
import { Text } from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
    title: {
        //width: '80%',
        height: '20px',
        color: '#787878',
        textAlign: "left",
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        display:'table',

        [theme.breakpoints.up("tablet")]: {
           /// display: 'flex',
            width:"70%"
        },
        [theme.breakpoints.down("sm")]: {
            width:"70%"
            // position: "static",
            // width: '205px',
            // height: '20px',
            // left: '3px',
            // top: '0px'
        }
    },
    circleParentCls: {
        [theme.breakpoints.up("tablet")]: {
            display: 'flex'
        },
        [theme.breakpoints.down("sm")]: {
            display: 'flex',
            // flexDirection: 'row',
            // alignItems: 'flex-start',
            // padding: '0px',
            // width: '108px',
            // height: '12px',
            // left: '208px',
            // top: '4px'
        }
    },
    circleChildCls: {
        height: 12,
        width: 12,
        borderRadius: '50%',
        border: '1px solid #C4C4C4',
        margin: '2px 2px'
    },
    sectionWrapper: {
        width: '100%',
        display: 'flex',
        marginTop: '10px',
        // [theme.breakpoints.up("tablet")]: {
        //     display: 'flex'
        // },
        // [theme.breakpoints.down("sm")]: {
        //     width: '319px',
        //     display: 'flex',
        //     flexDirection: 'row',
        //     justifyContent: 'center',
        //     alignItems: 'center'
        // }
    }
}));
const noOfStars = [...Array(7).keys()];
function Sections(props) {
    const classes = useStyles();

    /**
     * @param {*} result - Result 
     * @returns a color based on Result text
     * @author - Ram Nadendla
     */
    const getColorCode = (result) => {
        if (result === "Strong") {
            return "#28BB3F"
        } else if (result === "Improve") {
            return "#FAC06A"
        } else if (result === "Concern") {
            return "#DD2E2E"
        } else if (result === "Not Started" || result === "To Be Assessed" || result === "To Be Determined" || result === "Not Started / To Be Assessed") {
            return "#0e4cea"
        }
    };
    return (
        props?.data?.map(item => {
            return <div className={classes.sectionWrapper}>
                <Typography
                    component={Text}
                    variant2="link2"
                    align="center"
                    className={classes.title}
                >
                    {item.Topic}
                </Typography>
                <div className={classes.circleParentCls}>
                    {noOfStars?.map((rec, index) => {
                        return <div style={{ backgroundColor: index <= item.Dots - 1 ? getColorCode(item.Result) : '' }} className={classes.circleChildCls}></div>
                    })}
                </div>
            </div>
        })
    );
}
export default Sections;