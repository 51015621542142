import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
  Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Text } from "../../styles/theme";
import { useFormik } from "formik";
import * as Yup from "yup";
import AppConfig from "../../constants/AppConfig";

import Button from "../controls/Button";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/controls/TextInput";
import Divider from '@material-ui/core/Divider';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import auth from "../../redux/actions/auth"
import Snackbar from "@material-ui/core/Snackbar";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
   // padding: "16px 8px",
    position: "absolute",
    //top: theme.spacing(5),
  },
  dialogTitle: {
    // paddingRight: "0px",
    // paddingLeft: "16px",
    padding:'20px'
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "10px",
    color: theme.palette.btnPrimary.tertiaryText,
  },
  content: {
    // paddingLeft: "16px",
    // paddingRight: "16px",
    // paddingTop: "24px",
    padding:'30px',
    borderTop: "1px solid #F8F8F8",
    borderBottom: "1px solid #F8F8F8",
  },
  title: {},
  justifyActions: {
    justifyContent: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "56px",
    marginBottom:'4px',
    "& button":{
      height:'32px'
    }
  },
  description: {
    paddingTop: "16px",
  },
  desContainer: {
    paddingLeft: "0px",
  },
  hint: {
    whiteSpace: 'pre-line'
  },
  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formControl: {
    marginTop: 24,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
    borderRadius: "4px",
  },
  boxText: {
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    padding: "18px",
    marginTop: "15px",
  },
  textInvite: {
    color: ' #787878',
    margin: "10px 0 10px 10px"
  },
  InviteText: {
    color: '#787878',
    margin: '10px'
  },
  textArea:{
    border: "none",
    resize: "none",
    fontSize: "16px",
    fontFamily: 'Roboto',
   outline:'none',
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em"
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    // margin: 8,
    cursor: 'pointer'
},
}));

export default function InviteFriendContent(props) {
  const { title, children, openPopup, setOpenPopup, hint, showButtonArea, ToastClick } =
    props;
  const classes = useStyles();
  const { location } = props;
  const [open, setOpen] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth);
  const LoginUser=authState.userProfile ? `${authState.userProfile.firstName}${" "}${authState.userProfile.lastName} `: "UserName" 
  
   const messageText = `Hello! <br /><br />
             Would you like to know your career score? <br /><br/>
             I tried and loved this free career assessment tool, and you would love it too. 
             YourTechScore took me only fifteen minutes to get started, and about ninety minutes for a 
             comprehensive assessment relative to my target job profile. This includes technical skills,
              coding acumen, online profile, resume preparation, and presentation readiness. <br /><br />
              Try your free assessment and let me know how it goes for you. <br /><br />
              https://yourtechscore.com/ <br /><br />
              If you like it, share it with your other friends as well. <br /><br />
              Good luck! <br /><br />
              ${LoginUser}`
              
  const onSubmit = async (values, { resetForm }) => {
    if(navigator.onLine){
 
      let recipients = values.email.replace(/[&\/\\#;+()$~%'":*?<>{}]/g, ',') || ""
      recipients = recipients.split(",")  
    dispatch(auth.invitefriend(
      {
      email:authState.user.email, 
      recipients:recipients,
      message:messageText,
      subject:`${LoginUser} has sent you an invitation`,
      }
      )
    )
  
    setOpenPopup(false)
    setOpen(true)
    ToastClick()
    resetForm()
    }
    else {
      setOpenSnackBar(true)
    }
    
  };
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().matches(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g,"Invalid email format").required("This field cannot be empty"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handleClick = () => {
    if (isNotMobile) {
      setOpenPopup(false)
      ToastClick()
    }
    else {
      history.push("/Dashboard")
    }
  }
  const CloseBtn = () => {
    // if (isNotMobile) {
    //   setOpenPopup(false)
    // }
    // else {
    //   history.push("/Dashboard")
    // }
    setOpenPopup(false)
  }
  const HandleClickLink = () => {
    window.open("https://yourtechscore.com/","_blank")
  }
  const handleClose = () => {
    setOpenSnackBar(false)
  }
  return (
    <>
    <Dialog
      onBackdropClick="false"
      open={openPopup}
      fullScreen={fullScreen}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}
      onClose={() => {
        setOpenPopup(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        className={classes.dialogTitle}
        id="customized-dialog-title"
        onClose={() => {
          setOpenPopup(false);
        }}
      >
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
          <Typography
            variant2="b1Medium"
            component={Text}
            style={{ flexGrow: 1 }}
          >
            Invite Your Friends
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={CloseBtn}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers className={classes.content}>

          <Container className={classes.desContainer}>
            <div>
              <Typography
                component={Text}
                variant2="b1Medium"
                className={`${classes.title} ${classes.hint}`}
              >
                Would you like to invite your friends to try out this scoring platform?

              </Typography>
              <Typography component={Text} variant2="b1Regular" style={{ color: '#787878' }}>Your personal score shall remain confidential. It will not be shared with them.</Typography>
            </div>

            <div>

              <Container className={classes.inputCt}>
                <div className={classes.formControl}>
                  <label htmlFor="email">
                    <Typography component={Text} variant2="b1Medium" align="left">
                      Email
                    </Typography>
                  </label>
                  {/* {formik.values.email&& formik.values.email.map((email, index) => ( */}
                  
                  <TextInput
                    name="email"
                    placeholder="Ex. example1@example.com , example2@example.com"
                    className={classes.field}
                    id="email"
                    multiple
                    onChange={formik.handleChange}
                    error={formik.touched.email && formik.errors.email ? Boolean(formik.errors.email) : null}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ""}
                  ></TextInput>
                  {/* ))} */}
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">
                      <Typography component={Text} variant2="error" align="left">
                        {formik.errors.email}
                      </Typography>
                    </div>
                  ) : null}
                </div>

                <div className={classes.boxText}>
                  <Typography component={Text} variant2="b1Regular" className={classes.InviteText}>Hello! </Typography>
                  <Typography component={Text} variant2="b1Regular" className={classes.InviteText}>Would you like to know your career score? </Typography>
                  <Typography component={Text} variant2="b1Regular" className={classes.textInvite}>
               {/* <textarea rows="5" cols={isNotMobile ? "100" : "35"} className={`${classes.textInvite} ${classes.textArea}`}> */}
               I tried and loved this free career assessment tool, and you would love it too. 
               YourTechScore took me only fifteen minutes to get started, and about ninety minutes
                for a comprehensive assessment relative to my target job profile. This includes technical skills, 
                coding acumen, online profile, resume preparation, and presentation readiness.
                    {/* </textarea> */}
                    </Typography>
                    {/* <textarea rows="1" cols={isNotMobile ? "100" : "35"} className={`${classes.textInvite} ${classes.textArea}`}> */}
                    <Typography component={Text} variant2="b1Regular" className={classes.textInvite}>
                    Try your free assessment and let me know how it goes for you.
                    </Typography>
                    {/* </textarea> */}
                    <Typography component={Text} variant2="b1Regular" className={classes.textInvite}>
                   <Link className={classes.labelBlue} onClick={() => HandleClickLink()}>https://yourtechscore.com/</Link> 
                    </Typography>

                  <Typography component={Text} variant2="b1Regular" className={classes.textInvite}> 
                  If you like it, share it with your other friends as well.
                    </Typography>
                    <Typography component={Text} variant2="b1Regular" className={classes.textInvite}> 
                    Good luck!
                    </Typography>
                    <Typography component={Text} variant2="b1Regular" className={classes.textInvite}> 
                    {LoginUser}
                    </Typography>
                </div>

              </Container>

            </div>

          </Container>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.justifyActions}>
          <Button
            // disabled={
            //   !(formik.isValid && formik.dirty && formik.values.confirm)
            // }
            type="submit"
            color="btnPrimary"
            width={isNotMobile ? "20%" : "45%"}
            text="Send Invite"
           // onClick={handleClick}
           data-rt-invitefriend-sendinvite = "sendinvite"
          ></Button>
        </DialogActions>
    
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClose={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </Dialog>
     
   </>
  );
 
}
