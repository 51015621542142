import { call, put, takeLatest } from "redux-saga/effects";
import types from "../types/quiz";
import quiz from "../actions/quiz";
import auth from "../actions/auth";
import axios from "../../services/axios";
import AppConfig from "../../constants/AppConfig";

function* getQuestions() {
  try{
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.code}`,
    });
  
    const questions = data.data.qbank.filter((element) => {
      return element && element.Question_ID;
    });
    const config = data.data.config || "";
  
    yield put(quiz.getQuestions(questions, config));
  }
 catch(err){
  yield put(quiz.setApiError(err));
}
}

function* getL0Questions() {
  try{

  const data = yield call(axios.request, {
    url: `/qbank/?code=${AppConfig.l0QuestionCode}`,
  });

  const questions = data.data.qbank.filter((element) => {
    return element && element.Question_ID;
  });

  const config = data.data.config || "";

  yield put(quiz.getQuestions(questions, config));
}
catch(err){
 yield put(quiz.setApiError(err));
}

}

function* getL1Questions() {
  try{
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.l1QuestionCode}`,
    });
  
    let questions = [];
    if (data.data.qbank) {
      questions = data.data.qbank.filter((element) => {
        return element && element.Question_ID;
      });
    }
  
    const config = data.data.config || "";
    yield put(quiz.getQuestions(questions, config));
  }
  catch(err){
    yield put(quiz.setApiError(err));
   }
}

function* getL2Questions() {
  try{
  const data = yield call(axios.request, {
    url: `/qbank/?code=${AppConfig.l2QuestionCode}`,
  });

  const questions = data.data.qbank.filter((element) => {
    return element && element.Question_ID;
  });
  const config = data.data.config || "";

  yield put(quiz.getQuestions(questions, config));
}
catch(err){
  yield put(quiz.setApiError(err));
 }
}

function* getL3PresentationQuestions() {
  try {
  const data = yield call(axios.request, {
    url: `/qbank/?code=${AppConfig.l3QuestionCode}`,
  });

  const questions = data.data.qbank.filter((element) => {
    return element && element.Question_ID;
  });

  const config = data.data.config || "";

  yield put(quiz.getQuestions(questions, config));
}
catch(err){
  yield put(quiz.setApiError(err));
 }
}

function* getL3Questions() {
  try{
  const data = yield call(axios.request, {
    url: `/qbank/?code=${AppConfig.l3QuestionCode}`,
  });

  const questions = data.data.qbank.filter((element) => {
    return element && element.Question_ID;
  });

  const config = data.data.config || "";

  yield put(quiz.getCodingQuestions(questions, config));
}
catch(err){
  yield put(quiz.setApiError(err));
 }
}

function* createSesssion({ payload }) {
  try{
  if (payload) {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/session",
      data: payload,
    });
    yield put(quiz.createSession(res.data));
  }
}
catch(err){
  yield put(quiz.setApiError(err));
 }
}

function* getTracking({ payload }) {
  try{
    const res = yield call(axios.request, {
      method: "POST",
      url: "/tracking/",
      data: payload,
    });
    // no need to wait for the response as per kenny
    yield put(quiz.getTracking(payload));
  }catch(err){
    yield put(quiz.setApiError(err));
  }
 
}

function* submitCodingChallenge({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "POST",
    url: "/codingChallenge/submit",
    data: payload,
  });

  yield put(quiz.codingChallengeSubmitted(JSON.parse(res.data)["id"]));
}
catch(err){
  yield put(quiz.setApiError(err));
 }
}

function* getCodingChallengeSubmissionDetail({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "GET",
    url: "/codingChallenge/submit",
    params: payload,
  });

  yield put(quiz.updateCodingSubmissionDetail(res.data));
}
catch(err){
  yield put(quiz.setApiError(err));
 }
}

function* submitTestResults({ payload }) {
  try{
    const res = yield call(axios.request, {
      method: "PUT",
      url: "/session/update",
      data: payload,
    });
  
    yield put(quiz.testResultsSubmitted(res.data));
    if (payload.email && payload.email != "notregistered") {
      const profileRes = yield call(axios.request, {
        method: "GET",
        url: `user?email=${payload.email}`,
      });
  
      yield put(auth.setProfile(profileRes.data[0]));
    }
  }catch(err){
    yield put(quiz.setApiError(err))
  }

}

function* submitCodingResults({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "POST",
    url: "/codingChallenge/submitTestCases",
    data: payload,
  });

  yield put(quiz.testResultsSubmitted(res.data));
}catch(err){
  yield put(quiz.setApiError(err))
}
}

function* recreateUserAndSubmitResults({
  testResult,
  userProfile,
  userMetaDataReq,
  targetRole,
}) {
  // debugger;
  let finalProfileData = {
    ...userProfile,
  };

  const payload = {
    email: userProfile.email,
    userMetaData: userMetaDataReq,
    userProfile: finalProfileData,
  };

  console.log("recreateUserAndSubmitResults: ", arguments);
  const res = yield call(axios.request, {
    method: "POST",
    url: "/editProfileMajor",
    data: payload,
  });
  yield put(auth.setProfile(res.data[0]));
}

function* quizSaga() {
  yield takeLatest(types.GET_QUESTIONS_REQUESTED, getQuestions);
  yield takeLatest(types.GET_L0_QUESTIONS, getL0Questions);
  yield takeLatest(types.GET_L1_QUESTIONS, getL1Questions);
  yield takeLatest(types.GET_L2_QUESTIONS, getL2Questions);
  yield takeLatest(types.GET_L3_QUESTIONS, getL3Questions);
  yield takeLatest(types.CREATE_SESSION_REQUESTED, createSesssion);
  yield takeLatest(types.GET_TRACKING_REQUESTED, getTracking);
  yield takeLatest(types.SUBMIT_CODING_CHALLENGE, submitCodingChallenge);
  yield takeLatest(
    types.GET_CODING_SUBMISSION_DETAIL,
    getCodingChallengeSubmissionDetail
  );
  yield takeLatest(types.SUBMIT_TEST_RESULTS, submitTestResults);
  yield takeLatest(types.GET_L3_PRESENTATION, getL3PresentationQuestions);
  yield takeLatest(types.SUBMIT_CODING_ANSWERS, submitCodingResults);
  yield takeLatest(
    types.RECREATE_USER_SUBMIT_RESULTS,
    recreateUserAndSubmitResults
  );
}

export { quizSaga };
