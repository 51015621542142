import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { Text } from "../../styles/theme";
import { useHistory } from "react-router-dom";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Scrollbars } from 'react-custom-scrollbars';


const useStyles = makeStyles((theme) => ({
  factorsLink: {
    marginTop: 16,
    fontSize: 14,
    lineHeight: "20px",
    [theme.breakpoints.up('tablet')]: {
      fontSize: 16,
      lineHeight: "24px"
    }
  },

  factors: {
    marginTop: 0,
    [theme.breakpoints.up('tablet')]: {
      marginTop: 24
    }
  },
  factorsTitle: {
    // color: theme.palette.primary.main,
    fontFamily: "Rational",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0em",
    marginBottom: 16,
  },

  grayText: {
    color: "#787878",
    // fontSize: 14,
    // lineHeight: "20px",
    [theme.breakpoints.up('tablet')]: {
      // fontSize: 16,
      // lineHeight: "24px",
    }
  },
  card_width:{
    height:"105px"
  }
}));

const Accordion = withStyles({
  root: {
    borderTop: '1px dashed rgba(0, 0, 0, .125)',
    borderBottom: '1px dashed rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    padding: 0
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0
  },
}))(MuiAccordionDetails);

function AreaOfStrength(props) {
  const { data } = props;
  console.log("AreaOfStrength: ", props, data);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('tablet'));

  return (
    <div className={classes.factors}>
    {isNotMobile && 
      <Card className="" variant="outlined">
        <CardContent className={classes.card_width}>
          <Typography
            component={Text} 
            variant2="h5"
            className={classes.factorsTitle}
            align="left"
          >
            Areas of Strength
          </Typography>
          {Object.keys(data).map((key, i) => (
            <Typography
              component={Text} 
              variant2="b1Regular"
              className={classes.grayText}
              align="left"
              key={i}
            >
              {props.data[key]}
            </Typography>
          ))}
          {/* <Link href="#">
            <Typography
              component={Text}
              variant2="link1"
              className={classes.factorsLink}
            >
              View More
            </Typography>
          </Link> */}
        </CardContent>
      </Card>
    }
    {!isNotMobile && 
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="aos-content"
        >
          <Typography 
          component={Text}
          variant2="b1Medium"
          className={classes.heading}>Areas of Strength</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div>
          {Object.keys(data).map((key, i) => (
            <Typography
              component={Text}
              variant2="b2Regular"
              className={classes.grayText}
              align="left"
              key={i}
            >
              {props.data[key]}
            </Typography>
          ))}
          </div>
        </AccordionDetails>
      </Accordion>
    }
    </div>
  );
}
export default AreaOfStrength;
