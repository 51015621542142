import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import { Text } from "../../styles/theme";
import { useHistory } from "react-router-dom";

import quiz from "../../redux/actions/quiz";
import authMethod from "../../redux/actions/auth";

import Resume from "../../components/Resume";
import uploadProfileImagePlusIcon from "../../assets/uploadProfileImagePlusIcon.svg";

import dummyProfileImage from "../../assets/svg/dummyProfileImage.svg";

import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import edit1 from "../../assets/svg/edit1.svg";
import Popup from "../../components/Popup";
import EditprofileModal from "../EditprofileModel";
import Vector from "../../assets/svg/Vector.svg";
import { removeAnswers } from "../../services/globalService";
import DeleteDialog from "../DeleteModel";
import DeleteIcon from "@material-ui/icons/Delete";
import { firebaseAuthApp } from "../../firebase";
import firebase from "@firebase/app";
import AppConfig from "../../constants/AppConfig";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ImagebaseUrl } from "../../services/globalService";
const useStyles = makeStyles((theme) => ({
  profileActiveSince: {
    color: "#787878",
    marginBottom: 0,
    marginTop: 0,
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      // marginBottom: 16,
      // marginTop: 16,
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  profileImg: {
    marginBottom: 16,
     width: 50,
    height: 50,
    borderRadius: 25,
  },
  profileImgNot: {
    marginBottom: 16,
    width: 50,
    height: 50,
    borderRadius: 25,
    background: "#EBEFF8",
  },
  fileInput: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadProfileImagePlusIcon: {
    marginTop: "34px",
    marginLeft: "-16px",
    height: "18px",
    width: "18px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadProfileImageSection: {
    position: "relative",
    width: "66px",
    height: "60px",
    display: "flex",
  },
  title: {
    flexGrow: 1,
    marginTop: 4,
    marginBottom: 4,
    [theme.breakpoints.up("tablet")]: {
      marginTop: 0,
      marginBottom: 16,
    },
  },
  dotList: {
    display: "flex",
    whiteSpace: "nowrap",
    paddingLeft: 0,
  },
  dotListItem: {
    display: "inline",
    whiteSpace: "nowrap",
    "&:after": {
      content: "&bull;",
    },
    "&:last-child:after": {
      content: "none",
    },
  },
  profile: {
    backgroundColor: theme.palette.primary.light,
    padding: 16,
    [theme.breakpoints.up("tablet")]: {
      backgroundColor: "#ffffff",
      padding: 0,
    },
  },
  dot: {
    height: "4px",
    width: "4px",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "9px",
    marginLeft: "7px",
    marginRight: "7px",
    border: " 0.5px solid gray",
    background: "Gray",
  },
  completeLink: {
    marginTop: 16,
  },
  EditBtn: {
    display: "flex",
    justifyContent: "space-between",
  },
  profileImgIcon: {
    height: "16px",
    width: "16px",
    marginTop: "18px",
    marginLeft: "16px",
  },
  mobileTrash: {
    marginRight: "30px",
    marginTop: "10px",
    color: "#1789FC",
  },
}));

function Profile(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  useEffect(() => {
    dispatch(quiz.resetQuizSummery());
    dispatch(quiz.resetQuizQuestions());
    dispatch(quiz.setSelectedQuesNdAns([]));
    removeAnswers();
  }, []);

  const { location } = props;

  const classes = useStyles();
  const history = useHistory();
  const authState = useSelector((state) => state.auth);
  const [openPopup, setOpenPopup] = useState(false);
  const [deleteopen, setDeleteopen] = useState(false);
  const [nativeProfilePhoto, setNativeProfilePhoto] = useState(authState.userProfile ? authState.userProfile.photoURL : "");

  const [showExtensionErrorMessage, setShowExtensionErrorMessage] =
    useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [fileName, setFirstName] = useState("")

  useEffect(() => {
    authState.user = authState.user || authState.userProfile;
    if (authState.user && !authState.user.displayName) {
      authState.user.displayName = `${authState?.userProfile?.firstName} ${authState?.userProfile?.lastName}`;
    }
  }, [authState]);

  const linkedInUrl = authState.userProfile
    ? authState.userProfile.LinkedIn_Profile
    : null;
  const githubUrl = authState.userProfile ? authState.userProfile.GitHub : null;
  const stackOverflowUrl = authState.userProfile
    ? authState.userProfile.Stack_Overflow
    : null;
  const mediumUrl = authState.userProfile ? authState.userProfile.Medium : null;
  const youtubeUrl = authState.userProfile
    ? authState.userProfile.YouTube
    : null;
  const personalUrl = authState.userProfile
    ? authState.userProfile.Personal_Website
    : null;
  const otherUrl = authState.userProfile
    ? authState.userProfile.Other_Website
    : null;

  var createdOn = authState.userProfile
    ? authState.userProfile.createdAt
    : null;
  if (createdOn) {
    createdOn = moment(createdOn).format("MMMM YYYY");
  }

  var lastLoggedOn = 
     authState.userProfile.previousLoginAt ? authState.userProfile.previousLoginAt
    : authState.userProfile.lastLoginAt;
  if (lastLoggedOn) {
    lastLoggedOn = moment(lastLoggedOn).format("MMMM DD, YYYY h:mm a");
  }

  function uploadProfileImage({ target: { files } }) {
    if(navigator.onLine){
    
    if (files && files[0]) {
      let data = new FormData();
      data.append("file", files[0]);

      let ext = files[0].name.split(".").pop();
      ext = ext.toLowerCase();
      // setFileDetail({
      //   ...fileObj,
      //   fileName: files[0].name,
      // });
      console.log("file name size",files[0])
      setFirstName(files[0])
      if (!AppConfig.validProfilePhotoExtension.includes(ext)) {
        setShowExtensionErrorMessage(true);
      } else {
        const payload = {
          data,
          email: authState.user.email,
        };
        if (data) {
          console.log("payload", payload);
          dispatch(authMethod.profileImageUploadRequested(payload));
        }
      }
    }
  }
  else{
    setOpenSnackBar(true)
  }
  }

  const handleCloseSnackBar = () => {
    setShowExtensionErrorMessage(false);
  };

  const handleDialog = (val) => {
    if(navigator.onLine){
    setOpenPopup(val);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleclick = () => {
    if(navigator.onLine){
    setDeleteopen(true);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleDelete = () => {
    if(navigator.onLine){
    const userDetails = firebaseAuthApp.currentUser;
    if (userDetails) {
      dispatch(
        authMethod.deleteProfileUser({
          email: authState?.userProfile?.email,
          uid: userDetails.uid,
        })
      );
      dispatch(authMethod.clearState());
      localStorage.removeItem("user");
      localStorage.removeItem("user_id");
      dispatch(quiz.clearState());

      history.push({
        pathname: "/",
      });
      setDeleteopen(false);
    }
  }
  else {
    setOpenSnackBar(true)
  }
  };

  const completeProfile = (link) => {
    if(navigator.onLine){
      history.push(link)
    }
    else {
      setOpenSnackBar(true)
    }
  }

  useEffect(()=>{
    if(authState && authState.nativePhotourl){
      if(authState.nativePhotourl.includes("https://bootcamp-profilepics.s3.us-east-2.amazonaws.com")){
        setNativeProfilePhoto(authState.nativePhotourl)
      }
    }
  }, [authState.nativePhotourl])

  return (
    <div>
      {isNotMobile && (
        <Card className={classes.profile} variant="outlined">
          <CardContent>
            <div className={classes.EditBtn}>
              <div className={classes.uploadProfileImageSection}>
                {/* <Tooltip title="Change photo" aria-label="change photo"> */}
                  <input
                     type={navigator.onLine ? "file" : "button"}
                    accept=".png, .jpeg, .jpg"
                    onChange={uploadProfileImage}
                    className={classes.fileInput}
                    title={fileName.size <= 5000000 ? fileName.name : "Change photo"   }
                  />
                {/* </Tooltip> */}
                { authState.userProfile.photoURL  && (
                  <img
                    className={classes.profileImg}
                    alt="profile"
                    src={ nativeProfilePhoto }
                  />
                )}
                { !authState.userProfile.photoURL && authState.photoUrl  && (
                  <img
                    className={classes.profileImg}
                    alt="profile"
                    src={ authState.photoUrl }
                  />
                )}
                {!authState.userProfile.photoURL && !authState.photoUrl && (
                  <div className={classes.profileImgNot}>
                    <img
                      className={classes.profileImgIcon}
                      alt="profile"
                      src={`${ImagebaseUrl}assets/svg/dummyProfileImage.svg`}
                    />
                  </div>
                )}
                <img
                  alt="uploadProfileImage"
                  src={`${ImagebaseUrl}assets/uploadProfileImagePlusIcon.svg`}
                  className={classes.uploadProfileImagePlusIcon}
                />
              </div>
              <Link
              data-rt-div-editprofileicon = "editprofileicon"
                // onClick={() => history.push("/L3ProfileOpeningScreen")}
                onClick={() => handleDialog(true)}
                style={{ cursor: "pointer",marginTop:'10px' }}
              >
                <img src={`${ImagebaseUrl}assets/svg/edit1.svg`} />
              </Link>
            </div>
            <Typography
              component={Text}
              variant2="h3"
              className={classes.title}
              align="left"
            >
              {authState.userProfile && authState.userProfile.firstName}{" "}
              {authState.userProfile && authState.userProfile.lastName}
            </Typography>
            <Typography
              component={Text}
              variant2="b1Regular"
              className={classes.profileActiveSince}
              align="left"
            >
              Active since {createdOn}
            </Typography>
            <div className={classes.EditBtn}>
              <Typography
                component={Text}
                variant2="b1Regular"
                className={classes.profileActiveSince}
                align="left"
              >
                Last active session {lastLoggedOn}
              </Typography>
              <Link onClick={handleclick} style={{ cursor: "pointer" }} data-rt-div-deletebtn = "deletebtn">
                {/* <img src={Vector} style={{width:'15px'}}/> */}
                <DeleteIcon style={{ color: "#1789FC" }} />
              </Link>
            </div>
          </CardContent>
        </Card>
      )}
      {!isNotMobile && (
        <Grid container className={classes.profile}>
          <Grid item xs={8}>
            <Typography
              variant="subtitle2"
              className={classes.profileActiveSince}
              align="left"
            >
              Welcome back! 👋
            </Typography>
            <Typography variant="h3" className={classes.title} align="left">
              {authState.userProfile && authState.userProfile.firstName}{" "}
              {authState.userProfile && authState.userProfile.lastName}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.profileActiveSince}
              align="left"
            >
              Active since {createdOn}
            </Typography>
          </Grid>
          <Grid container xs={4} justifyContent="flex-end">
            <Grid item>
              <div className={classes.uploadProfileImageSection}>
                {/* <Tooltip title="Change photo" aria-label="change photo"> */}
                  <input
                    type={navigator.onLine ? "file" : "button"}
                    onChange={uploadProfileImage}
                    className={classes.fileInput}
                    title={fileName.size <= 5000000 ? fileName.name : "Change photo"   }
                  />
                {/* </Tooltip> */}
                {authState.userProfile.photoURL && (
                  <img
                    className={classes.profileImg}
                    alt="profile"
                    src={nativeProfilePhoto }
                  />
                )}
                {!authState.userProfile.photoURL && authState.photoUrl && (
                  <img
                    className={classes.profileImg}
                    alt="profile"
                    src={ authState.photoUrl}
                  />
                )}
                {!authState.userProfile.photoURL && !authState.photoUrl && (
                  <div className={classes.profileImgNot}>
                    <img
                      className={classes.profileImgIcon}
                      alt="profile"
                      src={`${ImagebaseUrl}assets/svg/dummyProfileImage.svg`}
                    />
                  </div>
                )}
                <img
                  alt="uploadProfileImage"
                  src={`${ImagebaseUrl}assets/uploadProfileImagePlusIcon.svg`}
                  className={classes.uploadProfileImagePlusIcon}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/* {authState.resumeName && <Resume></Resume>} */}
            <div className={classes.EditBtn}>
              <Link onClick={() => completeProfile("/L3ProfileOpeningScreen")} >
                <Typography
                  component={Text}
                  variant2="link2"
                  align="left"
                  className={classes.completeLink}
                >
                  Complete Your Profile
                </Typography>
              </Link>
              <Link onClick={handleclick} style={{ cursor: "pointer" }} data-rt-div-deletebtn = "deletebtn">
                {/* <img src={Vector} className={classes.mobileTrash}/> */}
                <DeleteIcon className={classes.mobileTrash} />
              </Link>
            </div>
          </Grid>
        </Grid>
      )}

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <EditprofileModal
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        ></EditprofileModal>
      </Popup>
      <DeleteDialog
        open={deleteopen}
        OnDelete={handleDelete}
        onClose={() => setDeleteopen(false)}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={showExtensionErrorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message="You can only upload jpeg/png files for profile image"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default Profile;
