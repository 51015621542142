import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "./controls/Button";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    //padding: theme.spacing(2),
    position: "relative",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  close: {
    color: theme.palette.common.black,
  },
}));

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup } = props;
  const classes = useStyles();

  return (
    <Dialog
      onBackdropClick="false"
      open={openPopup}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}
      onClose={() => {
        setOpenPopup(false);
      }}
      aria-labelledby="customized-dialog-title"
    >
      {/* <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={()=>{setOpenPopup(false)}}>
                        <CloseIcon  className={classes.close} />
                    </Button>
                </div>
            </DialogTitle> */}
      {children}
    </Dialog>
  );
}
