import React, { useState, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import { useSelector, useDispatch } from "react-redux";
import CircleCountdownTimer from "./CircleCountdownTimer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import quizActions from "../../redux/actions/quiz";
import { summeryObj , setSummeryObj } from "../../services/quizSummery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  timerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    },
  },
  timer: {
    // fontFamily: theme.typography.timer.fontFamily,
    fontSize: 14,
    fontFamily: "Rational",
    [theme.breakpoints.up("tablet")]: {
      // fontSize: theme.typography.timer.fontSize,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
}));

var renderTimer = false;
function Timer({ timeValue, location, handleOnWarning, handleOnTimeout, prevQuestion, currentQuestion }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  // const [renderTimer, setRenderTimer] = useState(true);
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const quizState = useSelector((state) => state.quiz);
  const [elapsedTm, setElapsedTm] = useState(0);

  const [colorTransition, setColorTransition] = useState({
    info: "#28BB3F",
    warning: "#FF941C",
    alert: "#DD2E2E",
  });

  // const duration = useSelector((state) => state.quiz?.Time_Mins);

  const quiz = useSelector((state) => state.quiz);

  // let timerReset = location?.state?.timerReset;

  // useEffect(() => {
  //   if (quiz.config.Time_Mins == duration && timerReset) {
  //     dispatch(quizActions.resetElapsedTime(0));
  //     // dispatch(quizActions.setElapsedTime(0));
  //     setRenderTimer(false);
  //     setTimeout(() => {
  //       setRenderTimer(true);
  //     }, 300);
  //   }
  // }, [duration, timerReset]);


  useLayoutEffect(() => {
    if ((prevQuestion?.Category && currentQuestion?.Category) && (prevQuestion?.Category != currentQuestion?.Category) && (quizState?.level == "L1")) {
      setElapsedTm(quiz?.elapsedTime);
    }else if(sessionStorage.reloadFlag == "true"){
      setElapsedTm(quiz?.elapsedTime);
      if(sessionStorage?.summeryObj){
        let obj = JSON.parse(sessionStorage.summeryObj);
        setSummeryObj(obj)
      }
      sessionStorage.reloadFlag = false;
      if(sessionStorage?.summeryObj){
        sessionStorage.removeItem('summeryObj');
      }
    } else {
      setElapsedTm(0);
    }
    renderTimer = true;

    return () => {
      renderTimer = false;
      console.log("disableTimer", renderTimer);
      setElapsedTm(0);
    };
  }, []);


  useEffect(() => {
    window.onbeforeunload = function () {
      let remainigTime = +sessionStorage?.remainigTime;
      if (remainigTime) {
        dispatch(quizActions.setElapsedTime(remainigTime));
      }
      sessionStorage.summeryObj = JSON.stringify(summeryObj);
      sessionStorage.reloadFlag = true;      
     setTimeout(() => {
      window.onbeforeunload = null;
     },200)
    };
  }, []);

  useEffect(() => {
    return () => {
      renderTimer = false;
      let remainigTime = +sessionStorage?.remainigTime;
      if (remainigTime) {
        dispatch(quizActions.setElapsedTime(remainigTime));
      }
      setElapsedTm(0);
    };
  }, []);

  const thresholds = () => {
    if (quiz.config.Time_Mins == 45) {
      return {
        warning: 600,
        alert: 180,
      };
    } else if (quiz.config.Time_Mins == 20) {
      return {
        warning: 300,
        alert: 120,
      };
    } else {
      return {
        warning: 120,
        alert: 60,
      };
    }
  };

  const onWarning = (remainingTime) => {
    if (renderTimer) {
      console.log("Hurry up! You have " + remainingTime);
      handleOnWarning(remainingTime);
    }
  };

  const onTimeout = () => {
    if (renderTimer) {
      console.log("Time up!");
      handleOnTimeout();
    }
  };

  const onElapsedTime = (elapsed, remainigTime) => {
    if (renderTimer) {

      sessionStorage.remainigTime = remainigTime;
      renderTime(remainigTime);
      console.log("elapsed", elapsed, remainigTime);
    }
  };

  const renderTime = (remainingTime) => {
    const hours = Math.floor(remainingTime / 3600);
    let minutes = Math.floor((remainingTime % 3600) / 60);
    let seconds = remainingTime % 60;
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    const time = `${minutes}:${seconds}`;
    timeValue(time, minutes, seconds);
  };

  return (
    <>
      {renderTimer == true && (
        <Grid container justifyContent="center">
          <div className={classes.timerWrapper}>
            {isNotMobile && (
              <CircleCountdownTimer
                size={50}
                duration={
                  quiz?.config?.Time_Mins &&
                  Math.floor(quiz?.config?.Time_Mins * 60)
                }
                strokeWidth={4}
                thresholds={thresholds()}
                elapsedTime={elapsedTm}
                delayedStartDuration={elapsedTm > 0 ? 0 : 3}
                colors={colorTransition}
                onElapsedTime={onElapsedTime}
                onThresholdStart={onWarning}
                onTimeout={onTimeout}
              ></CircleCountdownTimer>
            )}

            {!isNotMobile && (
              <CircleCountdownTimer
                size={46}
                duration={
                  quiz?.config?.Time_Mins &&
                  Math.floor(quiz?.config?.Time_Mins * 60)
                }
                strokeWidth={4}
                thresholds={thresholds()}
                elapsedTime={elapsedTm}
                delayedStartDuration={elapsedTm > 0 ? 0 : 3}
                colors={colorTransition}
                onElapsedTime={onElapsedTime}
                onThresholdStart={onWarning}
                onTimeout={onTimeout}
              ></CircleCountdownTimer>
            )}
          </div>
        </Grid>
      )}
    </>
  );
}

export default Timer;
