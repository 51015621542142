import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {},
  },
}));

function LoadingIndicator(props) {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`}>
      <div className="containerForLoading">
        <div className="itemForLoading item-1-for-loading"></div>
        <div className="itemForLoading item-2-for-loading"></div>
        <div className="itemForLoading item-3-for-loading"></div>
        <div className="itemForLoading item-4-for-loading"></div>
      </div>
    </div>
  );
}
export default LoadingIndicator;
