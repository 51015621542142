import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";

// import codingEvaluationImage from "../../assets/Packing product.png";
// import presentationEvaluationImage from "../../assets/Packing product.png";
// import TechnicalEvaluationImage from "../../assets/Packing product.png";
// import GeneratingProfileImage from "../../assets/clip-puzzle 1.png";
// import CalculatingScoreImage from "../../assets/Packing product.png";
// import L2NextOpeningScreen from "../../assets/Packing product.png";

import Grid from "@material-ui/core/Grid";

import { Text } from "../../styles/theme";

import { useHistory } from "react-router-dom";

import logo from "../../assets/logo.svg";
import AppConfig from "../../constants/AppConfig";
import { isNull } from "lodash";
import LoadingIndicator from "../LoadingIndicator";
import RoundedLoadingIndicator from "../RoundedLoadingIndicator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ImagebaseUrl } from "../../services/globalService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    height: "100%",
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "48px",
    zIndex: 9999999,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  cardTwo: {
    marginTop: "104px",
  },
  cardThree: {
    marginTop: 24,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    width: "100%",
    // fontSize: "32px",
    // lineHeight: "40px",
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up("tablet")]: {
      width: "680px",
      // fontSize: "40px",
      // lineHeight: "48px",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  openingScreen: {
    width: 300,
    marginTop: "60px",
    [theme.breakpoints.up("tablet")]: {
      maxWidth: "491px",
    },
  },
  pageInfo: {
    height: "36px",
    width: "fit-content",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  loadingIndicator: {
    position:'absolute',
    left:'50%',
    top:'50%'
  },
}));

function TransitionScreen(props) {
  const history = useHistory();
  const classes = useStyles();
  const { location } = props;
  const quizState = useSelector((state) => state.quiz);
  const authState = useSelector((state) => state.auth);

  const { categoryTheme } = AppConfig;
  const category = props.location.state ? props.location.state.category : null;
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  useEffect(() => {
    let next = props.location.state ? props.location.state.next : "";

    if (location && location.pathname == "/CalculatingScore") {
      next = "/ScoreScreen";
    }
    if (location && location.pathname == "/GeneratingProfile") {
      next = "/ScoreScreen";
    }

    if (location && location.pathname == "/UpdatingDashboard") {

      // if (authState.userProfile.L3_Presentation_Score != 0 &&
      //   authState.userProfile.L3_Profile_Score != 0 &&
      //   authState.userProfile.L3_Coding_Score != 0) {
      //   next = "/Dashboard"
      // }
      // else {
      //   next = "/LNextSection";
      // }
      next = "/LNextSection";
    }

    if (location && location.pathname == "/L2NextOpeningScreen") {
      next = "/LNextSection";
    }

    var fnId = setTimeout(() => {
      history.push(next);
    }, 3000);

    return () => {
      console.log("Will Unmounted");
      clearTimeout(fnId);
    };
  }, [props.location]);

  useEffect(() => {
    if (authState && authState.userProfile) {
      let tempCount = 0;
      if (
        !authState.userProfile.L3_Profile_Score ||
        (authState.userProfile.L3_Profile_Score &&
          authState.userProfile.L3_Profile_Score === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L3_Coding_Score ||
        (authState.userProfile.L3_Coding_Score &&
          authState.userProfile.L3_Coding_Score === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L2_Score_UpdatedDate ||
        (authState.userProfile.L2_Score_UpdatedDate &&
          authState.userProfile.L2_Score_UpdatedDate === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L3_Presentation_Score ||
        (authState.userProfile.L3_Presentation_Score &&
          authState.userProfile.L3_Presentation_Score === 0)
      ) {

        tempCount += 1;
      }
      if (tempCount === 0) {
        if (quizState?.quizSelectionTopic == "Minor" || quizState?.quizSelectionTopic == "Major") {
          history.push("/GeneratingProfile");
        }
        else {
          history.push("/Dashboard");
        }


      }
    }
  }, [authState.userProfile]);

  return (
    <div className={classes.root}>
      {location && location.pathname == "/CodingEvaluation" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div
            className={`${classes.section} ${classes.cardTwo} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2="h2"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Now we want to know your coding skills, how many problems can you
              solve in the next section?
            </Typography>
          </div>

          {category && (
            <Grid
              container
              className={`${classes.section} ${classes.cardThree} ${classes.center}`}
              justifyContent="center"
            >
              <div
                className={`${classes.section} ${classes.pageInfo} ${classes.center}`}
                style={{
                  border: categoryTheme.hasOwnProperty(category)
                    ? `1px solid ${categoryTheme[category].bgColor}`
                    : "",
                }}
              >
                <Typography
                  component={Text}
                  variant2="labelSecondary"
                  align="center"
                  style={{
                    margin: "0px 10px",
                    color: categoryTheme.hasOwnProperty(category)
                      ? `${categoryTheme[category].bgColor}`
                      : "",
                  }}
                >
                  {category} Section
                </Typography>
              </div>
            </Grid>
          )}

          <div
            className={`${classes.section} ${classes.center}  ${classes.cardFour}`}
          >
            <img
              className={`${classes.openingScreen}`}
              alt="codingEvaluationImage"
              src={`${ImagebaseUrl}assets/Packing product.png`}
            />
          </div>
        </div>
      )}
      {location && location.pathname == "/PresentationEvaluation" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div
            className={`${classes.section} ${classes.cardTwo} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2="h2"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Now, let’s evaluate your presentation in the next section
            </Typography>
          </div>
          {category && (
            <Grid
              container
              className={`${classes.section} ${classes.cardThree} ${classes.center}`}
              justifyContent="center"
            >
              <div
                className={`${classes.section} ${classes.pageInfo} ${classes.center}`}
                style={{
                  border: categoryTheme.hasOwnProperty(category)
                    ? `1px solid ${categoryTheme[category].bgColor}`
                    : "",
                }}
              >
                <Typography
                  component={Text}
                  variant2="b2Medium"
                  align="center"
                  style={{
                    margin: "0px 10px",
                    color: categoryTheme.hasOwnProperty(category)
                      ? `${categoryTheme[category].bgColor}`
                      : "",
                  }}
                >
                  {category} Section
                </Typography>
              </div>
            </Grid>
          )}
          <div
            className={`${classes.section} ${classes.center}  ${classes.cardFour}`}
          >
            <img
              className={`${classes.openingScreen}`}
              alt="presentationEvaluationImage"
              src={`${ImagebaseUrl}assets/Packing product.png`}
            />
          </div>
        </div>
      )}
      {location && location.pathname == "/TechnicalEvaluation" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div
            className={`${classes.section} ${classes.cardTwo} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2="h2"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Moving to questions about your technical expertise… what’s Java?
            </Typography>
          </div>
          {category && (
            <Grid
              container
              className={`${classes.section} ${classes.cardThree} ${classes.center}`}
              justifyContent="center"
            >
              <div
                className={`${classes.section} ${classes.pageInfo} ${classes.center}`}
                style={{
                  border: categoryTheme.hasOwnProperty(category)
                    ? `1px solid ${categoryTheme[category].bgColor}`
                    : "",
                }}
              >
                <Typography
                  component={Text}
                  variant2="b2Medium"
                  align="center"
                  style={{
                    margin: "0px 10px",
                    color: categoryTheme.hasOwnProperty(category)
                      ? `${categoryTheme[category].bgColor}`
                      : "",
                  }}
                >
                  {category} Section
                </Typography>
              </div>
            </Grid>
          )}
          <div
            className={`${classes.section} ${classes.center}  ${classes.cardFour}`}
          >
            <img
              className={`${classes.openingScreen}`}
              alt="TechnicalEvaluationImage"
              src={`${ImagebaseUrl}assets/Packing product.png`}
            />
          </div>
        </div>
      )}
      {location && location.pathname == "/GeneratingProfile" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div
            className={`${classes.section} ${classes.cardTwo} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "h2" : "h3"}
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Generating your profile…
            </Typography>
          </div>
          <div className={`${classes.loadingIndicator}`}>
            <RoundedLoadingIndicator></RoundedLoadingIndicator>
          </div>
        </div>
      )}
      {location && location.pathname == "/ProfileOpeningScreen" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div
            className={`${classes.section} ${classes.cardTwo} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2="h2"
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              We’ll need some basic background and profile details from you
            </Typography>
          </div>
          <div
            className={`${classes.section} ${classes.center}  ${classes.cardThree}`}
          >
            <img
              className={`${classes.openingScreen}`}
              alt="TechnicalEvaluationImage"
              src={`${ImagebaseUrl}assets/Packing product.png`}
            />
          </div>
        </div>
      )}
      {location && location.pathname == "/CalculatingScore" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div
            className={`${classes.section} ${classes.cardTwo} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "h2" : "h3"}
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Calculating your score…
            </Typography>
          </div>
          <div className={`${classes.loadingIndicator}`}>
            <RoundedLoadingIndicator></RoundedLoadingIndicator>
          </div>
        </div>
      )}
      {location && location.pathname == "/UpdatingDashboard" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
            <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div
            className={`${classes.section} ${classes.cardTwo} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "h2" : "h3"}
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Updating the dashboard…
            </Typography>
          </div>

          {/* <img
              className={`${classes.openingScreen}`}
              alt="GeneratingProfileImage"
              src={GeneratingProfileImage}
            /> */}
          <div className={`${classes.loadingIndicator}`}>
            <RoundedLoadingIndicator></RoundedLoadingIndicator>
          </div>
        </div>
      )}
    </div>
  );
}
export default TransitionScreen;
