import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "./controls/Button";
import CustomizedProgressBars from "./controls/CustomizedProgressBars";
import { Text } from "../styles/theme";
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import quiz from "../redux/actions/quiz";

import { withStyles } from "@material-ui/core/styles";
import MuiDrawer from "@material-ui/core/Drawer";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// import completeIcon from "../assets/Vector.svg";
// import warnIcon from "../assets/warning - icon.svg";
// import closeDrawer from "../assets/closeDrawer.png";

import { ImagebaseUrl } from "../services/globalService";

import { removeAnswers, getUnique } from "../services/globalService";

import ExitDialog from "./Exitmodel"

const useStyles = makeStyles((theme, props) => ({
  title: {
    marginTop: 32,
  },
  section: {
    width: "100%",
    marginBottom: 10,
  },
  questionText: {
    width: "253px",
    height: "40px",
    marginBottom: 24,
    "&:hover": {
      cursor: "pointer",
    },
    "@media screen and (min-width:360px) and (max-width:780px)": {
      width: "227px",
    },
  },
  answerText: {
    width: "80px",
    height: "40px",
    marginBottom: 24,
    fontSize: "12px",
    color: "#787878 ",
  },
  unanswerText: {
    width: "80px",
    height: "40px",
    marginBottom: 24,
    fontSize: "12px",
  },
  statusIcon: {
    marginLeft: 8,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  expectationWarning: {
    color: "#DD2E2E",
    paddingTop: 4,
    paddingBottom: 0,
  },
  progressArea: {
    marginBottom: 15,
    paddingRight: 24,
    marginTop: 15
  },
  summaryArea: {
    height: "100%",
    paddingBottom: '45px',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: '24px'
  },
  submit: {
    marginBottom: 16,
  },
  progressBar: {
    marginTop: 6,
  },
  expandIcon: {
    color: "#1789FC",
  },
  flatList: {
    "&:first-child": {
      borderTop: "1px solid #EBEFF8",
      marginTop: 24,
      paddingTop: 24,
    },
  },
  bottomArea: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    bottom: 0,
    paddingTop: 16,
    paddingBottom: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("tablet")]: {
      width: 400,
    },
  },
  quizSummery: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "8px",
    paddingRight: 24
  },
  closeDrawer: {
    width: "14px",
    height: "14px",
    marginLeft: "16px",
    marginTop: "24px",
  },
  sidebarContainer: {
    paddingRight: '0px'
  },
  font16: {
    color: "#787878"
  },
}));

const SwipeableDrawer = withStyles((theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: 400,
    },
  },
}))(MuiDrawer);

const Accordion = withStyles({
  root: {
    borderTop: "1px solid #EBEFF8",
    borderBottom: "1px solid #EBEFF8",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    backgroundColor: "#ffffff",
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    paddingBottom: "16px",
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

export default function SideBar(props) {
  const { group, level, ...other } = props;
  const anchor = "right";
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const quizState = useSelector((state) => state.quiz);
  const [exitopen, setExitopen] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const data = quizState.quizSummery;
  const truncate = (str) => {
    if (isNotMobile) {
      return str.length > 60 ? str.substring(0, 60) + "..." : str;
    } else {
      return str.length > 54 ? str.substring(0, 54) + "..." : str;
    }

  };

  const onQuestionClick = (question) => {
    console.log("Question clicked: ", question);

    history.push({
      pathname: "/QuizQuestions/" + quizState.level + "/" + question.seq,
      state: {
        isFromQuizSummery: true,
      },
    });
    dispatch(quiz.updateShowSummary(false));
  };

  const handleExit = () => {
    removeAnswers();
    dispatch(quiz.setSelectedQuesNdAns([]));
    dispatch(quiz.clearState());
    history.push("/");
    setExitopen(false);
  };
  const handleCloseOnExitDialog = () => {
    console.log('handleCloseOnExitDialog');
    dispatch(quiz.updateShowSummary(false));
    setExitopen(false);
  };

  const generateCategorySummary = (questions) => {
    if (questions) {
      return (
        <div className={classes.section}>
          {questions.map((value) => (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                component={Text}
                variant2="b2Medium"
                className={`${classes.questionText}`}
                align="left"
                id="no-copy"
                onClick={() => onQuestionClick(value)}
              >
                {value.seq}.{" "}
                <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {" "}
                  {truncate(value.question)}{" "}
                </span>
              </Typography>
              {value.isAnswered && (
                <Typography
                  component={Text}
                  variant2="link2"
                  className={`${classes.answerText}`}
                  align="right"
                  onClick={() => onQuestionClick(value)}
                >
                  Answered
                </Typography>
              )}
              {!value.isAnswered && (
                <Typography
                  component={Text}
                  variant2="link2"
                  className={`${classes.unanswerText}`}
                  align="right"
                  onClick={() => onQuestionClick(value)}
                >
                  Unanswered
                </Typography>
              )}
            </Grid>
          ))}
        </div>
      );
    }
  };

  const generateSummary = (summary, group) => {
    return summary.map((value) => (
      <>
        {group && (
          <Accordion square >
            <AccordionSummary
              id={`${value.id}-header`}
              expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}

            >
              <Typography
                component={Text}
                variant2="body3"
                className=""
                align="left"
              >
                {value.category} ({value.answeredQuestions}/
                {value.totalQuestions})
              </Typography>
              {value.status == "complete" && (
                <img src={`${ImagebaseUrl}assets/Vector.svg`} className={classes.statusIcon} />
              )}
              {value.status == "incomplete" && (
                <img src={`${ImagebaseUrl}assets/warning - icon.svg`} className={classes.statusIcon} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              {generateCategorySummary(value.questions)}
            </AccordionDetails>
          </Accordion>
        )}
        {!group && (
          <div className={classes.flatList}>
            {generateCategorySummary(value.questions)}
          </div>
        )}
      </>
    ));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (!open) {
      dispatch(quiz.updateShowSummary(false));
    }

    setState({ ...state, [anchor]: open });
  };

  const onSubmitClick = () => {
    if (navigator.onLine) {
      dispatch(quiz.submitAnswers(true));
    }
    else {
      setOpenSnackBar(true)
    }

  };

  const onClickExit = () => {
    history.push({
      pathname: "/",
    });
  };

  const handleClose = () => {
    setOpenSnackBar(false)
  }

  return (
    <Container className={`${classes.footer} ${classes.containerCls}`}>
      <ExitDialog
        open={exitopen}
        OnExit={handleExit}
        onClose={handleCloseOnExitDialog}
        TimeClose={() => setOpenSnackBar(true)}
      >
      </ExitDialog>

      <SwipeableDrawer
        anchor={anchor}
        open={quizState.showSummary}
        width={400}
        // onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <Container className={classes.sidebarContainer}>
          <div className={classes.quizSummery}>
            <Typography
              component={Text}
              variant2="h5"
              className={`${classes.title}`}
              align="left"
            >
              Quiz Summary
            </Typography>
            <img
              src={`${ImagebaseUrl}assets/closeDrawer.png`}
              className={classes.closeDrawer}
              onClick={toggleDrawer(anchor, false)}
            />
          </div>
          <Grid container className={classes.progressArea}>
            <Grid item xs={6}>
              <Typography
                component={Text}
                variant2="body2"
                className={`${classes.progess}`}
                align="left"
              >
                You’ve answered {data.answeredQuestions} of{" "}
                {data.totalQuestions}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.progressBar}>
              <CustomizedProgressBars
                value={(data.answeredQuestions / data.totalQuestions) * 100}
                color={data.expectedAnswers > data.answeredQuestions ? '#C4C4C4' : '#1a90ff'}
              ></CustomizedProgressBars>
            </Grid>
          </Grid>
          <div className={classes.summaryArea}>
            {generateSummary(data.summary, props.group)}
          </div>
        </Container>
        <div className={classes.bottomArea}>
          {data.expectedAnswers > data.answeredQuestions && (
            <div
              className={`${classes.section} ${classes.center} ${classes.actionLink} ${classes.submit}`}
            >
              <Typography
                component={Text}
                variant2="body2"
                className={`${classes.expectationWarning}`}
                align="left"
              >
                Please answer a minimum of {data.expectedAnswers} questions to
                submit
              </Typography>
            </div>
          )}
          <div
            className={`${classes.section} ${classes.center} ${classes.actionLink} ${classes.submit}`}
          >
            {data.answeredQuestions < quizState.Min_Questions ?
              <Button
                color="btnTransparent"
                text="Submit Answers"
                width="343px"
                style={{ marginTop: "3px" }}
                data-rt-quizsummary-submitanswers="submitanswers"
              ></Button> :
              <Button
                color="btnPrimary"
                text="Submit Answers"
                width="343px"
                style={{ marginTop: "3px" }}
                // disabled={quesRes.length == qIndex ? true : false}
                // disabled={
                //   data.answeredQuestions < quizState.Min_Questions ? true : false
                // }
                onClick={() => onSubmitClick()}
                data-rt-quizsummary-submitanswers="submitanswers"
              ></Button>
            }
          </div>
          <div
            className={`${classes.section} ${classes.center} ${classes.actionLink} ${classes.submit}`}
          >
            <Link data-rt-quizsummary-exitsubmit="exitsubmit">
              <Typography
                component={Text}
                className={classes.font16}
                variant2="link2"
                align="left"
                //onClick={() => onClickExit()}
                onClick={() => setExitopen(true)}
              >
                Exit without submitting
              </Typography>
            </Link>
          </div>
        </div>
      </SwipeableDrawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Container>
  );
}
