import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useParams, useLocation } from "react-router-dom";

import "../../home/Home.css";
import QuizContainer from "../../../components/quiz/QuizContainter/QuizContainer";
import { useSelector, useDispatch } from "react-redux";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";
import QuizSummarySideBar from "../../../components/QuizSummarySideBar";
import * as globalService from "../../../services/globalService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function QuizQuestions(props) {
  const { location } = props;

  let locationRef = useLocation();
  let isFromQuizSummery = locationRef?.state?.isFromQuizSummery || false;
  let selectedQuesNdAns = useSelector((state) => state?.quiz?.selectedQuesNdAns);

  const classes = useStyles(props);
  const theme = useTheme();

  const [value, setState] = useState({
    quesObject: {},
  });

  const routeParams = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const quesRes = useSelector((state) => state.quiz.questions);
  const quizState = useSelector((state) => state.quiz);

  const authState = useSelector((state) => state.auth);
  const myRef = useRef()

  onkeydown = e => {
    if (e.key === 'r' && e.ctrlKey) {
      e.preventDefault();
      console.log('ctrl + r')
    }
  }
  useEffect(() => {
    console.log("state", value);
  }, [quesRes]);

  useEffect(() => {
    if (quizState && quizState.questions) {
      window.addEventListener("load", function () {
        // TARGET THIS SECTION ONLY
        var target = document.getElementById("no-copy");

        // PREVENT CONTEXT MENU FROM OPENING
        target.addEventListener("contextmenu", function (evt) {
          evt.preventDefault();
        }, false);

        // PREVENT CLIPBOARD COPYING
        target.addEventListener("copy", function (evt) {
          // Change the copied text if you want
          evt.clipboardData.setData("text/plain", "Copying is not allowed on this webpage");
          // Prevent the default copy action
          evt.preventDefault();
        }, false);
      });
    }
  }, [quizState.questions])
  useEffect(() => {
    if (
      authState?.userProfile &&
      authState?.userProfile?.email &&
      authState?.userMetaData &&
      authState?.userMetaData[0]?.result &&
      routeParams.path == AppConfig.LEVEL0 &&
      routeParams.qIndex == "1"
    ) {
      //globalService.setQuesNdAnsArray(JSON.parse(authState?.userMetaData[0]?.result[0]));
    }
    return () => {
      if (routeParams.path == AppConfig.LEVEL0) {
        let req = JSON.stringify(selectedQuesNdAns);
        dispatch(quiz.setUserMetaDataReq(req));
      }
    };
  }, []);

  useEffect(() => {
    console.log("Did Mounted", routeParams);

    if (routeParams.path == AppConfig.LEVEL0 && +routeParams.qIndex - 1 == 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL0));
    }

    if (routeParams.path == AppConfig.LEVEL1 && +routeParams.qIndex - 1 == 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL1));
    }

    if (routeParams.path == AppConfig.LEVEL2 && +routeParams.qIndex - 1 == 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL2));
    }

    if (routeParams.path == AppConfig.LEVEL3 && +routeParams.qIndex - 1 == 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL3));
    }

    if (
      (routeParams.path == AppConfig.LEVEL0 ||
        routeParams.path == AppConfig.LEVEL1 ||
        routeParams.path == AppConfig.LEVEL2 ||
        routeParams.path == AppConfig.LEVEL3) &&
      quesRes.length != 0
    ) {
      var idx = +routeParams.qIndex - 1;
      let quesObject = quesRes[idx];

      //do we need to load an opening screen for change in category?
      var category = quesObject.Category;
      console.log(
        "Comparing categories: ",
        quizState.prevCategory,
        category,
        idx
      );
      if (quizState.prevCategory != "" && quizState.prevCategory != category) {
        dispatch(quiz.updatePrevCategory(category));
        var transitionScreen =
          AppConfig.stepToWelcomeScreenMap[quizState.level][category];
        if (transitionScreen && !isFromQuizSummery) {
          history.push({
            pathname: transitionScreen,
            state: {
              category: category,
              next: `/QuizQuestions/${quizState.level}/${idx + 1}`,
            },
          });
          return;
        }
      }

      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }

    if (routeParams.path == "L1" && quesRes.length != 0) {
      let quesObject = quesRes[+routeParams.qIndex - 1];
      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }
    return () => console.log("Will Unmounted");
  }, [routeParams]);

  useEffect(() => {
    if (quesRes) {
      console.log("Did Mounted", quesRes);
      if (
        routeParams.path == AppConfig.LEVEL0 ||
        routeParams.path == AppConfig.LEVEL1 ||
        routeParams.path == AppConfig.LEVEL2 ||
        routeParams.path == AppConfig.LEVEL3
      ) {
        let quesObject = quesRes[+routeParams.qIndex - 1];
        setState({
          ...value,
          quesObject: { ...quesObject },
        });
      }
    }
    return () => console.log("Will Unmounted");
  }, [quesRes]);

  return (
    <div>
      {quizState.level != AppConfig.LEVEL0 && (
        <QuizSummarySideBar
          group={quizState.level == AppConfig.LEVEL1}
          className={classes.sidebar}
          level={routeParams.path}
        ></QuizSummarySideBar>
      )}
      <QuizContainer
        quizProp={value}
        routeParams={routeParams}
        qIndex={+routeParams.qIndex}
      ></QuizContainer>
    </div>
  );
}
export default QuizQuestions;
