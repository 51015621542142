import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import "../../../pages/home/Home.css";
import Button from "../../controls/Button";
import Timer from "../../Timer/Timer";
import { Text } from "../../../styles/theme";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";
import Popup from "../../Popup";
import LOneExampleContent from "../../dialogContent/LOneExampleContent";
import Backdrop from "@material-ui/core/Backdrop";

import Select from "../../controls/Select";
import _ from "lodash";

import CodeEditor from "../../CodeEditor";
import auth from "../../../redux/actions/auth";

import {
  getObject,
  removeAnswers,
  getObjectThatContains,
  ImagebaseUrl,
} from "../../../services/globalService";

import logo from "../../../assets/logo.svg";
import RoundedLoadingIndicator from "../../RoundedLoadingIndicator";
import ExitDialog from "../../Exitmodel";
import SubmitConfirmationDialog from "../../../components/SubmitConfirmationDialog";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  paddinght: {
    //paddingBottom:'85px'
  },
  paddingdt: {
    paddingBottom: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px",
      paddingRight: "64px",
    },
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  main: {
    marginLeft: "auto",
    marginRight: "auto",
    // width: "60%",
  },
  item1: {
    height: "60px",
    // position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  item2: {
    marginTop: "16px",
    paddingBottom: "16px",
    borderBottom: "0.5px solid #EBEFF8",
    height: 48,
  },
  item3: {
    // marginTop: "32px",
  },
  item4: {
    marginTop: "16px",
  },
  answer: {
    marginTop: "24px",
    marginBottom: "30px",
  },
  info: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  item5: {
    height: "80px",
    backgroundColor: "#ffffff",
  },
  pageInfo: {
    height: "36px",
    width: "145px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
    marginTop: "36px",
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  next: {
    "& button": {
      float: "right",
    },
  },
  progess: {
    color: theme.palette.btnPrimary.tertiaryText,
    marginTop: "24px",
    marginBottom: "8px",
  },
  font16: {
    fontSize: theme.typography.link1.fontSize,
    color: theme.palette.btnPrimary.tertiaryText,
  },
  questionArea: {
    display: "flex",
  },
  qIndex: {
    // flex: "10%"
  },
  qTitle: {
    flex: "90%",
  },
  sidebar: {
    width: 500,
  },
  actionLink: {
    marginTop: 16,
  },
  questionDesc: {
    whiteSpace: "pre-wrap",
  },
  stickToBottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  justifyButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  nextBtn: {
    marginLeft: "5px",
    "& button": {
      //float: "left",
      height: 40,
    },
  },
  alignItemsCls: {
    alignItems: "center",
  },
  codeEditor: {
    //paddingTop: "0px !important",
    borderLeft: "0.5px solid #EBEFF8",
    paddingLeft: "16px",
  },
  logoCls: {
    height: "30px",
  },
  exit: {
    color: theme.palette.neutrals.grayDark,
  },
  grayText: {
    color: theme.palette.neutrals.grayDark,
  },
  loader: {
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: "50%",
  },
}));

function CodingContainer(props) {
  const { quizProp, routeParams, qIndex, ...other } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [exitopen, setExitopen] = useState(false);
  const counterRef = useRef();
  const [langId, setLangId] = useState("");
  const [userAns, setUserAns] = useState({});
  const [isPageChange, setPageChange] = useState(false);

  const { stepToWelcomeScreenMap, categoryTheme } = AppConfig;
  const { l1 } = stepToWelcomeScreenMap;
  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useTheme();

  const classes = useStyles();

  const quesRes = useSelector((state) => state.quiz.questions);
  const qTrackRes = useSelector((state) => state.quiz.tracking);
  const authState = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  const selectedLanguage = authState.userProfile.primaryCodingLanguage;
  const [showCodingDialog, setShowCodingDialog] = useState(false);
  const [answeredQuestions, setAnsweredQuestion] = useState(0);
  const [totalTimetaken, setTotalTimetaken] = useState(0)

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openshowBar, setOpenshowBar] = useState(false);

  const [submitAnswer, setSubmitAnswer] = useState(false);
  const codingChallengeSubmissionDetail = useSelector(
    (state) => state.quiz.codingChallengeSubmissionDetail
  );
  const monacoRef = useRef(null);
  // const [timerValue, setTimerValue] = useState(0);
  const [timerMin, setTimerMin] = useState();

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [fullSubmit, setFullSubmit] = useState(false);
  let message = `Your session is about to expire, hurry!`;
  let category = "Coding";
  let value = `${qIndex} of ${quesRes.length}`;
  let question = quizProp.quesObject.Question_Name;
  let questionDesc = quizProp.quesObject.Question_Description;
  // const alertDialogContent = `Your session has expired. You have unaswered ${answeredQuestions} questions. Do you want to submit your answers as it stands?`;

  const [timeoutMessage, setTimeoutMessage] = useState("");

  // let codeTpl = quizState.codingText ||
  //   quizProp.quesObject[
  //     AppConfig.compilerToTplFieldMap[authState.codingLang.lang]
  //   ];

  let questionID = quizProp.quesObject.Question_ID;
  let difficulty = quizProp.quesObject.Difficulty;
  let questionsDescription = quizProp.quesObject.Question_Description;

  let isLastQuestion = qIndex == quesRes.length;
  const themes = [
    { id: 1, value: "vs-dark", label: "Dark" },
    { id: 2, value: "light", label: "Light" },
  ];
  const langs = [
    { id: 10, value: "java", label: "Java" },
    { id: 56, value: "javascript", label: "JavaScript" },
    { id: 116, value: "python", label: "Python 3" },
  ];

  useEffect(() => {
    if (quizState && quizState.questions) {
      window.addEventListener("load", function () {
        // TARGET THIS SECTION ONLY
        var target = document.getElementById("no-copy");

        // PREVENT CONTEXT MENU FROM OPENING
        target.addEventListener(
          "contextmenu",
          function (evt) {
            evt.preventDefault();
          },
          false
        );

        // PREVENT CLIPBOARD COPYING
        target.addEventListener(
          "copy",
          function (evt) {
            // Change the copied text if you want
            evt.clipboardData.setData(
              "text/plain",
              "Copying is not allowed on this webpage"
            );
            // Prevent the default copy action
            evt.preventDefault();
          },
          false
        );
      });
    }
  }, [quizState.questions]);
  useEffect(() => {
    let msg;
    let unansweredQuestions =
      quizState?.config?.Total_Questions - answeredQuestions;
    //let unansweredQuestions = quizState?.config?.Total_Questions - quizState.selectedQuesNdAns.length
    console.log("answers", answeredQuestions);
    if (unansweredQuestions > 0) {
      msg = `You have run out of time and cannot make any more changes. You have not answered ${unansweredQuestions} questions. Do you wish to submit your answers now?`;
    } else {
      msg = `You have run out of time and cannot make any more changes. You have answered all questions. Do you wish to submit your answers now?`;
    }
    setTimeoutMessage(msg);
  }, [answeredQuestions]);

  useEffect(() => {
    let lang = getObjectThatContains("label", selectedLanguage, langs);
    let theme = getObject("label", "Dark", themes);
    if (lang) {
      setLangId(lang.id);
      dispatch(
        auth.setCodingLang({
          lang: lang.value,
          langId: lang.id,
          cid: lang.id,
          tpl: quizProp.quesObject[AppConfig.compilerToTplFieldMap[lang.value]],
        })
      );
    }
    if (theme) {
      dispatch(
        auth.setCodingTheme({
          theme: theme.value,
          themeId: theme.id,
        })
      );
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (quesRes && quesRes.length > qIndex && isPageChange == true) {
      dispatch(quiz.resetCoding());
      dispatch(quiz.resetCodingSubmissionDetail());
      history.push(`/CodingQuestions/${qIndex + 1}`);
    }
    setPageChange(false);
  }, [isPageChange]);
  useEffect(() => {
    if (qIndex == 1) {
      dispatch(quiz.setPrevTime(quizState?.config?.Time_Mins * 60));
    }
  }, []);

  useEffect(() => {
    if (quizState.testResult && quesRes.length == 0) {
      dispatch(quiz.resetCoding());
      dispatch(quiz.resetCodingSubmissionDetail());
      dispatch(auth.getProfile({ email: authState.userProfile.email }));
      history.push({
        pathname: "/UpdatingDashboard",
        state: {
          next: "/LNextSection",
          title: "Doing calculations…\nComparing answers…",
        },
      });
      // globalService.resetData();
    }
    setLoadingIndicator(false);
  }, [quizState.testResult]);

  useEffect(() => {
    if (quizState.apiError && loadingIndicator) {
      setLoadingIndicator(false);
    }
  }, [quizState.apiError]);

  useEffect(() => {
    if (authState && authState.userProfile) {
      let tempCount = 0;
      if (
        !authState.userProfile.L3_Profile_Score ||
        (authState.userProfile.L3_Profile_Score &&
          authState.userProfile.L3_Profile_Score === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L3_Coding_Score ||
        (authState.userProfile.L3_Coding_Score &&
          authState.userProfile.L3_Coding_Score === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L2_Score_UpdatedDate ||
        (authState.userProfile.L2_Score_UpdatedDate &&
          authState.userProfile.L2_Score_UpdatedDate === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L3_Presentation_Score ||
        (authState.userProfile.L3_Presentation_Score &&
          authState.userProfile.L3_Presentation_Score === 0)
      ) {
        tempCount += 1;
      }
      if (tempCount === 0) {
        history.push("/CodingQuestions/1");
      }
    }
  }, [authState.userProfile]);

  const onYesContinue = () => {
    setFullSubmit(true);
    var ansLn = quizState.selectedAnswers.length;
    var result = [];
    for (var i = 0; i < ansLn; i++) {
      var o = quizState.selectedAnswers[i];
      result.push(o);
    }

    setLoadingIndicator(true);
    dispatch(
      quiz.submitCodingResults({
        code: quizState.sessionByLevel,
        email: authState.userProfile.email,
        result: result,
      })
    );
  };

  const calcTimeTaken = () => {
    let timeRemainingSec = quizState?.prevTime;
    if (timerMin) {
      let currentTimeArray = timerMin.split(".")
      timeRemainingSec = (Number(currentTimeArray[0]) * 60) + Number(currentTimeArray[1]);
      console.log("values",timeRemainingSec)
    }
    let timeTakenSec = quizState?.prevTime - timeRemainingSec;
    let timeTaken = timeTakenSec / 60;
    dispatch(quiz.setPrevTime(timeRemainingSec));
    return timeTaken;
  }

  const onClickNext = () => {
    if (navigator.onLine) {
      monacoRef.current.setScrollPosition({ scrollTop: 0 });
      setPageChange(true);
      if (questionID) {
        let ansObj = {
          Question_ID: questionID,
          compilerId: authState.codingLang.langId,
          source: quizState.codingText,
          input: quizState.codingInput,
          language: authState.codingLang.lang,
          difficulty,
          questionsDescription,
        };

        dispatch(quiz.pushAnswer(ansObj));
      }

      if (quesRes.length == qIndex) {
        let Timetaken = calcTimeTaken()
        console.log("hghjdfg",Timetaken)
        setFullSubmit(true);
        var ansLn = quizState.selectedAnswers.length;
        var result = [];
        for (var i = 0; i < ansLn; i++) {
          var o = quizState.selectedAnswers[i];
          result.push(o);
        }

        setLoadingIndicator(true);
        dispatch(
          quiz.submitCodingResults({
            code: quizState.sessionByLevel,
            email: authState.userProfile.email,
            result: result,
          })
        );
      }
    } else {
      setOpenshowBar(true);
    }
  };

  const onClickPrev = () => {
    if (navigator.onLine) {
      monacoRef.current.setScrollPosition({ scrollTop: 0 });
      dispatch(quiz.resetCoding());
      dispatch(quiz.resetCodingSubmissionDetail());
      if (quesRes && qIndex > 1) {
        if (questionID) {
          let ansObj = {
            Question_ID: questionID,
            compilerId: authState.codingLang.langId,
            source: quizState.codingText,
            input: quizState.codingInput,
            language: authState.codingLang.lang,
            difficulty,
            questionsDescription,
          };
          dispatch(quiz.pushAnswer(ansObj));
        }
        history.push(`/CodingQuestions/${qIndex - 1}`);
      }
    } else {
      setOpenshowBar(true);
    }
  };

  const handleExit = () => {
    if (navigator.onLine) {
      removeAnswers();
      dispatch(quiz.setSelectedQuesNdAns([]));
      dispatch(quiz.resetCoding());
      dispatch(quiz.resetCodingSubmissionDetail());
      history.push("/");
      setExitopen(false);
    } else {
      setOpenshowBar(true);
    }
  };
  const handleclick = () => {
    if (navigator.onLine) {
      setExitopen(true);
    } else {
      setOpenshowBar(true);
    }
  };

  const onThemeChange = (e) => {
    var val = e.target.value;
    var o = getObject("id", val, themes);
    dispatch(
      auth.setCodingTheme({
        theme: o.value,
        themeId: o.id,
      })
    );
  };

  const handleClose = () => { };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const onLangChange = (e) => {
    dispatch(quiz.updateCodingSubmissionDetail(null));
    setLangId("");
    var val = e.target.value;
    var o = getObject("id", val, langs);
    var tpl = quizProp.quesObject[AppConfig.compilerToTplFieldMap[o.value]];
    dispatch(
      auth.setCodingLang({
        lang: o.value,
        langId: o.id,
        cid: o.id,
        tpl: tpl,
      })
    );
  };

  const compileAndRun = () => {
    if (navigator.onLine) {
      setLoadingIndicator(true);
      dispatch(
        quiz.submitCodingChallenge({
          compilerId: authState.codingLang.cid,
          source: quizState.codingText,
          input: quizState.codingInput,
        })
      );
    } else {
      setOpenshowBar(true);
    }
  };

  const timeValue = (time, minutes, seconds) => {
    if (time) {
      let sec = +seconds;
      let val = `${minutes}.${sec}`;
      setTimerMin(val);
    }
  };

  useEffect(() => {
    if (openSnackBar == true || submitAnswer == true) {
      if (questionID) {
        let ansObj = {
          Question_ID: questionID,
          compilerId: authState.codingLang.langId,
          source: quizState.codingText,
          input: quizState.codingInput,
        };
        dispatch(quiz.pushAnswer(ansObj));
      }
      setSubmitAnswer(false);
    }
  }, [openSnackBar, submitAnswer]);

  const handleOnWarning = (remainingTime) => {
    setOpenSnackBar(true);
  };
  const handleOnTimeout = () => {
    setSubmitAnswer(true);
    setTimeout(() => {
      if (!loadingIndicator) {
        if (quizState && quizState.selectedAnswers.length === 0) {
          history.push("/TimeOutCaptureScreen");
        } else if (
          quizState &&
          quizState.selectedAnswers.length >= quizState.Min_Questions
        ) {
          let tempUnQues = quizState.questions.length;
          if (
            quizState.selectedAnswers &&
            quizState.selectedAnswers.length > 0
          ) {
            tempUnQues = quizState.selectedAnswers.length;
          }
          // let tempunanswer = tempUnQues <=2 ? tempUnQues - 1 : tempUnQues
          setAnsweredQuestion(tempUnQues);
          setShowCodingDialog(true);
        }
      }
    }, 600);
  };
  useEffect(() => {
    if (codingChallengeSubmissionDetail) {
      setLoadingIndicator(false);
    }
  }, [codingChallengeSubmissionDetail]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        scrollerHt = window.innerHeight - 80 - 80;
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }
  const { height, width } = useWindowDimensions();
  let scrollerHt = window.innerHeight - 80 - 80;
  const onClickContinue = () => {
    if (navigator.onLine) {
      if (
        quizState &&
        quizState.selectedAnswers.length >= quizState.Min_Questions
      ) {
        setShowCodingDialog(false);
        onYesContinue();
      }
    } else {
      setOpenSnackBar(true);
    }
  };

  const handleDialogExit = () => {
    if (navigator.onLine) {
      setShowCodingDialog(false);
      quizState.selectedAnswers = [];
      history.push("/");
    } else {
      setOpenshowBar(true);
    }
  };

  const handleCloseToast = () => {
    setOpenshowBar(false);
  };

  return (
    <div>
      <div className={`${classes.root}`}>
        {/* {authState.isLoading && 
         <div className={classes.loader}>
           <RoundedLoadingIndicator></RoundedLoadingIndicator>
           </div>
        } */}
        <div className={`${classes.item1}   ${classes.center} `}>
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.header}>
              <Grid container className="Home-hero">
                <Grid item xs={4}>
                  <img
                    alt="logo"
                    src={`${ImagebaseUrl}assets/logo.svg`}
                    className={classes.logoCls}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Timer
                    timeValue={timeValue}
                    handleOnWarning={handleOnWarning}
                    handleOnTimeout={handleOnTimeout}
                  ></Timer>
                </Grid>
                <Grid item xs={4} className={classes.exitBtn}>
                  <Link onClick={handleclick}>
                    <Typography
                      component={Text}
                      variant2="link2"
                      align="right"
                      className={classes.exit}
                    >
                      Exit
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container
          maxWidth="xl"
          className={classes.container}
        // style={{ paddingRight: "0px" }}
        >
          <Grid container alignItems="stretch">
            <Grid
              item
              xs={5}
              className={classes.questionDesc}
            //style={{ marginBottom: "36px" }}
            >
              {/* <Scrollbars style={{ height: 700 }}> */}
              <div className="Scroll" style={{ height: `${scrollerHt}px` }}>
                <div style={{ paddingRight: "16px" }}>
                  <div className={classes.item3}>
                    <div
                      className={`${classes.pageInfo} ${classes.center}`}
                      style={{
                        border: categoryTheme.hasOwnProperty(category)
                          ? `1px solid ${categoryTheme[category].bgColor}`
                          : "",
                      }}
                    >
                      <Typography
                        component={Text}
                        variant2="b2Medium"
                        align="center"
                        style={{
                          color: categoryTheme.hasOwnProperty(category)
                            ? `${categoryTheme[category].bgColor}`
                            : "",
                          margin: "0px 10px",
                        }}
                      >
                        {category} Section
                      </Typography>
                    </div>
                  </div>

                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={`${classes.progess}`}
                    align="left"
                  >
                    Problem {`${value}`}
                  </Typography>

                  <div className={classes.question}>
                    {/* <span>{qNo}</span> */}
                    {/* <Typography component={Text} variant2="question" align="left"> */}
                    <div className={classes.questionArea}>
                      <div className={classes.qText}>
                        <Typography
                          component={Text}
                          variant2="b1Medium"
                          align="left"
                          id="no-copy"
                        >
                          {question}
                        </Typography>
                      </div>
                    </div>
                    {/* </Typography> */}
                  </div>

                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    className={`${classes.title}   ${classes.answer}`}
                    align="left"
                    id="no-copy"
                  >
                    {questionDesc} <br />
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={7} className={`${classes.codeEditor}`}>
              <Grid
                container
                className={classes.item2}
                style={{ paddingRight: "3px" }}
              >
                <Grid
                  container
                  xs={12}
                  justifyContent="flex-end"
                  className={`${classes.alignItemsCls}`}
                >
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    align="left"
                    className={classes.grayText}
                  >
                    Selected language:
                  </Typography>
                  <Select
                    options={langs}
                    displayField="label"
                    label="Selected language"
                    value={langId ? langId : authState.codingLang.langId}
                    onChange={onLangChange}
                  ></Select>
                  <Typography
                    component={Text}
                    variant2="b2Regular"
                    align="left"
                    style={{ marginLeft: "32px" }}
                    className={classes.grayText}
                  >
                    Selected theme:
                  </Typography>
                  <Select
                    options={themes}
                    displayField="label"
                    label="Selected theme"
                    value={authState.codingTheme.themeId}
                    onChange={onThemeChange}
                  ></Select>
                </Grid>
              </Grid>
              <CodeEditor
                isFullSubmit={fullSubmit}
                question={quizProp.quesObject}
                quizState={quizState}
                height={height}
                width={width}
                monacoRef={monacoRef}
              ></CodeEditor>
            </Grid>
          </Grid>
        </Container>
        <div
          className={`${classes.item5} ${classes.center} ${classes.stickToBottom}`}
        >
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.main}>
              <Grid
                container
                direction="row "
                className="Home-hero"
                justifyContent="flex-end"
                spacing={4}
              >
                <Grid item xs={5}>
                  {qIndex && qIndex > 1 ? (
                    <Link>
                      <Typography
                        component={Text}
                        className={classes.font16}
                        variant2="link2"
                        align="left"
                        onClick={() => onClickPrev()}
                        data-rt-div-codeprevious="codeprevious"
                      >
                        Previous
                      </Typography>
                    </Link>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={3}>
                  <div className={`${classes.nextBtn}`}>
                    <Button
                      color={
                        quizState.codingChallengeSubmissionDetail?.status ===
                          "accepted"
                          ? "btnSecondary"
                          : "btnPrimary"
                      }
                      text="Compile & Run Test"
                      width="200px"
                      onClick={compileAndRun}
                      data-rt-div-compileandrun="compileandrun"
                    ></Button>
                  </div>
                </Grid>
                <Grid item xs={4} className={`${classes.justifyButtons}`}>
                  <div className={`${classes.nextBtn}`}>
                    <Button
                      color={
                        quizState?.codingChallengeSubmissionDetail?.status ===
                          "accepted"
                          ? "btnPrimary"
                          : "btnSecondary"
                      }
                      text={!isLastQuestion ? "Next" : "Submit All Solutions"}
                      width="200px"
                      disabled={false}
                      onClick={() => onClickNext()}
                      data-rt-div-codenext="codenext"
                    ></Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <LOneExampleContent
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        ></LOneExampleContent>
      </Popup>
      <Backdrop
        className={classes.backdrop}
        open={loadingIndicator}
        onClick={handleClose}
      >
        {loadingIndicator && (
          <div className={`${classes.roundedLoader}`}>
            <RoundedLoadingIndicator></RoundedLoadingIndicator>
          </div>
        )}
      </Backdrop>
      <ExitDialog
        open={exitopen}
        OnExit={handleExit}
        onClose={() => setExitopen(false)}
        category={category}
        TimeClose={() => setOpenshowBar(true)}
      ></ExitDialog>
      <SubmitConfirmationDialog
        open={showCodingDialog}
        text={timeoutMessage}
        succesBtnLabel="Yes, submit"
        onSubmit={onClickContinue}
        onClose={handleDialogExit}
        TimeClose={() => setOpenshowBar(true)}
      ></SubmitConfirmationDialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openshowBar}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseToast}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default CodingContainer;
