import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Button from "../../../components/controls/Button";
import Typography from "@material-ui/core/Typography";

import image from "../../../assets/Group 105.svg";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import GraphScoreMeter from "../../../components/GraphScoreMeter/GraphScoreMeter";
import AppConfig from "../../../constants/AppConfig";
import quiz from "../../../redux/actions/quiz";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { Text } from "../../../styles/theme";

import logo from "../../../assets/logo.svg";
import auth from "../../../redux/actions/auth";
import { ImagebaseUrl } from "../../../services/globalService";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {},
  },
  applyPadding: {
    padding: "16px",
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    zIndex: 1,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "18px",
    marginBottom: "20px",
  },
  cardThree: {
    marginTop: 24,
    marginBottom: 24,
    [theme.breakpoints.up("tablet")]: {
      marginTop: 24,
      marginBottom: 24,
    },
  },
  cardFour: {
    marginTop: 48,
    marginBottom: 48,
    [theme.breakpoints.up("tablet")]: {
      marginTop: 48,
      marginBottom: 48,
    },
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    marginLeft: "0px",
    marginRight: "0px",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "25%",
      marginRight: "25%",
    },
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {
      // marginLeft: "35%",
      // marginRight: "35%",
    },
  },
  jobsCard: {
    // borderWidth: 1,
    // borderColor: "#EBEFF8",
    // borderStyle: "solid",
    alignItems: "center",
    // borderRadius: 4,
    //marginBottom: theme.spacing(2),
    //marginRight: theme.spacing(2)
  },
  factors: {
    marginTop: 24,
  },
  factorsTitle: {
    color: theme.palette.primary.main,
    fontFamily: "Rational",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0em",
    marginBottom: 16,
  },
  jobsScore: {
    backgroundColor: theme.palette.primary.light,
    alignItems: "center",
    padding: 12,
    borderRadius: 4,
  },
  jobScoreCard: {
    color: theme.palette.primary.main,
    fontFamily: "Rational",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  jobScoreCardText: {
    fontFamily: "Rational",
    color: "#787878",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  grayText: {
    color: "#787878",
  },
  roleCard: {
    width: 424,
    marginTop: 24,
  },
  scoreMeter: {
    marginTop: -88,
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "500px",
      marginTop: -98,
    },
  },
  scoreMeterText: {
    fontSize: 48,
    lineHeight: "48px",
    color: "#FF941C",
    marginTop: "16px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: 64,
      lineHeight: "64px",
    },
  },
  l0scoreMeterText: {
    fontSize: 48,
    lineHeight: "36px",
    marginTop: "16px",
    marginBottom: "8px",
    color: "#787878",
    [theme.breakpoints.up("tablet")]: {
      fontSize: 64,
      lineHeight: "48px",
      marginTop: "16px",
      marginBottom: "8px",
    },
  },
  scoreDescriptionText: {
    [theme.breakpoints.up("tablet")]: {
      width: "420px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
}));

function ScoreScreen(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const quizState = useSelector((state) => state.quiz);
  const authState = useSelector((state) => state.auth);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const gotoDashboard = () => {
    if (navigator.onLine) {
      if (authState?.userProfile && authState?.userProfile?.email) {
        if (
          quizState.level == AppConfig.LEVEL0 &&
          quizState?.isFromEditProfile == true &&
          quizState?.quizSelectionPopupDisable == true
        ) {
          let userMetaDataReq = {
            email: authState.userProfile.email,
            code: `${AppConfig?.l0QuestionCode}`,
            result: quizState?.userMetaDataReq,
          };
          let user = {
            ...authState?.userProfile,
            user_level: quizState?.level,
            targetScore: quizState?.testResult?.targetScore,
            targetRole: quizState?.testResult?.role,
            targetCompany: quizState?.testResult?.targetCompany,
            primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
            scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
            scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
            skills: quizState?.testResult?.skills,
          };

          let testResult = {
            email: authState.userProfile?.email,
            code: quizState?.testSubmissionReq?.code,
            result: quizState?.testSubmissionReq?.result,
          };
          //re-create user and then submit results
          dispatch(
            quiz.recreateUserAndSubmitResults(
              testResult,
              user,
              userMetaDataReq,
              quizState?.testResult?.role
            )
          );
          dispatch(quiz.isFromEditProfile(false));
        } else {
          updateProfile(authState?.userProfile);
        }
        setTimeout(() => {
          history.push("/Dashboard");
        }, 1800)
      }
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const updateProfile = (user) => {
    if (!user) {
      return;
    }

    let payload = {
      uid: user?.uid,
      email: user?.email,
      user_level: quizState?.level,
      targetScore: quizState?.testResult?.targetScore,
      targetRole: quizState?.testResult?.role,
      targetCompany: quizState?.testResult?.targetCompany,
      primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
      scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
      scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
      skills: quizState?.testResult?.skills,
    };

    if (quizState.quizSelectionTopic == "Minor") {
      delete payload.user_level
    }

    let userMetaData;
    if (quizState?.level == AppConfig.LEVEL0) {
      userMetaData = {
        email: user?.email,
        code: `${AppConfig?.l0QuestionCode}`,
        result: quizState?.userMetaDataReq,
      };
      dispatch(auth.postUserMetaDataReq(userMetaData))
    }

    dispatch(auth.createProfile(payload, userMetaData));
  };

  const handleLink = (link) => {
    if (navigator.onLine) {
      history.push(link)
    }
    else {
      setOpenSnackBar(true)
    }
  }

  const handleClose = () => {
    setOpenSnackBar(false)
  }

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
      </div>
      <div className={classes.applyPadding}>
        <div className={`${classes.section} ${classes.cardTwo}`}>
          {quizState.level == AppConfig.LEVEL0 && (
            <Typography
              component={Text}
              variant2={isNotMobile ? "h2" : "h3"}
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Great start!
              <br />
              Here’s your target profile
            </Typography>
          )}
          {quizState.level == AppConfig.LEVEL1 && (
            <Typography
              component={Text}
              variant2={isNotMobile ? "h2" : "h3"}
              align="center"
              className={`${classes.cardTwoH1}`}
            >
              Well Done!
              <br />
              We now have your score range
            </Typography>
          )}
        </div>
        <div
          className={`${classes.section} ${classes.center}  ${classes.cardThree}`}
        >
          {quizState.level == AppConfig.LEVEL0 && (
            <GraphScoreMeter
              fourLine={true}
              line1="your target score"
              line2={
                (quizState?.testResult && quizState?.testResult?.targetScore) ||
                (authState?.userProfile && authState?.userProfile?.targetScore)
              }
              line3={quizState?.testResult && quizState?.testResult?.role}
              line4={`company type: ${quizState?.testResult?.targetCompany ||
                authState?.userProfile?.targetCompany
                }`}
              targetScore={
                (quizState?.testResult && quizState?.testResult?.targetScore) ||
                (authState?.userProfile && authState?.userProfile?.targetScore)
              }
              targetLabel={`target`}
              className={`${classes.scoreMeter}`}
              scoreMeterCls={classes.scoreMeter}
              scoreMeterTextCls={classes.l0scoreMeterText}
            ></GraphScoreMeter>
          )}
          {quizState.level == AppConfig.LEVEL1 && (
            <GraphScoreMeter
              fourLine={true}
              line1="your score range"
              line2={
                <Typography
                  component={Text}
                  variant2="dataViz1"
                  className={classes.scoreMeterText}
                >
                  {`${quizState?.testResult &&
                    quizState?.testResult?.estimatedScoreLowerBound
                    }-${quizState?.testResult &&
                    quizState?.testResult?.estimatedScoreUpperBound
                    }`}
                </Typography>
              }
              line3={`your target: ${(quizState?.testResult && quizState?.testResult?.targetScore) ||
                authState?.userProfile?.targetScore
                }`}
              line4={`company type: ${quizState?.testResult?.targetCompany ||
                authState?.userProfile?.targetCompany
                }`}
              targetScore={
                (quizState?.testResult && quizState?.testResult?.targetScore) ||
                authState?.userProfile?.targetScore
              }
              targetLabel={`target`}
              rangeMinScore={
                quizState?.testResult &&
                quizState?.testResult?.estimatedScoreLowerBound
              }
              rangeMaxScore={
                quizState?.testResult &&
                quizState?.testResult?.estimatedScoreUpperBound
              }
              className={`${classes.scoreMeter}`}
              scoreMeterCls={classes.scoreMeter}
              scoreMeterTextCls={classes.scoreMeterText}
            ></GraphScoreMeter>
          )}
        </div>
        <div
          className={`${classes.section}  ${classes.cardFour} ${classes.center}`}
        >
          {quizState.level == AppConfig.LEVEL0 && (
            <Typography
              component={Text}
              variant2="b1Regular"
              align="center"
              className={`${classes.cardFourH1} ${classes.scoreDescriptionText} `}
            >
              Register your account to save your target score. Or continue to
              next quiz to find out where you stand compared to your target
              score.
            </Typography>
          )}
          {!authState.user && quizState.level == AppConfig.LEVEL1 && (
            <Typography
              component={Text}
              variant2="b1Regular"
              align="center"
              className={`${classes.cardFourH1} ${classes.scoreDescriptionText} `}
            >
              Save your profile by registering an account.
              <br />
              View details on your score from your dashboard.
              <br />
              Get going to next steps!
            </Typography>
          )}
          {authState.user && quizState.level == AppConfig.LEVEL1 && (
            <Typography
              component={Text}
              variant2="b1Regular"
              align="center"
              className={`${classes.cardFourH1} ${classes.scoreDescriptionText} `}
            >
              Save your profile by registering an account.
              <br />
              View details on your score from your dashboard. <br />
              Get going to next steps!
            </Typography>
          )}
        </div>
        <div
          className={`${classes.section} ${classes.center}   ${classes.cardFive}`}
        >
          {authState.user && (
            <Button
              onClick={gotoDashboard}
              color="btnPrimary"
              width="424px"
              text="Go to Dashboard"
              data-rt-Score-gotodashboard="gotodashboard"
            ></Button>
          )}
          {!authState.user && (
            <Button
              onClick={() => handleLink("/CreateAccount")}
              color="btnPrimary"
              width="424px"
              text="Register an Account"
              data-rt-Score-registeraccount="registeraccount"
            ></Button>
          )}
        </div>
        <div className={`${classes.section}   ${classes.cardSix}`}>
          {quizState.level == AppConfig.LEVEL0 && !authState.userMetaData && (
            <Link onClick={() => handleLink("/L1OpeningScreen")} data-rt-Score-skipscore="skipscore">
              <Typography
                component={Text}
                variant2="link1"
                align="center"
                className={`${classes.labelBlue}`}
              >
                Skip and continue to next quiz
              </Typography>
            </Link>
          )}
          {!authState.user && quizState.level == AppConfig.LEVEL1 && (
            <Link onClick={() => handleLink("/")} data-rt-Score-exittohome="exittohome">
              <Typography
                component={Text}
                variant2="link1"
                align="center"
                className={`${classes.labelBlue}`}
              >
                Exit to Home
              </Typography>
            </Link>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default ScoreScreen;
