import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Button from "../../components/controls/Button";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/controls/TextInput";
import { Container } from "@material-ui/core";
import Checkbox from "../../components/controls/Checkbox";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Text } from "../../styles/theme";
// import backArrowImg from "../../assets/Arrow 10-2.png";
// import successcheck from "../../assets/successcheck.svg";
// import logo from '../../assets/logo.svg'

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ImagebaseUrl } from "../../services/globalService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
    marginTop: "20px",
    [theme.breakpoints.up("tablet")]: {
      marginTop: "40px"
    }
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
  },
  cardSix: {
    marginTop: "80px",
    width: "100%",
    justifyContent: "space-between",
    // paddingBottom: "126px",
    // marginLeft: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      // width: 458,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "200px",
    },

    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    cursor: 'pointer'
  },
  cardTwoH1: {
    [theme.breakpoints.up("tablet")]: {
    },
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {
    },
  },

  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formControl: {
    marginTop: 24,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
  },
  telField: {
    minHeight: 48,
    marginTop: 8,
    margin: "8px 0px 0px 0px",
    backgroundColor: theme.palette.background.light,
  },
  phonewithCode: {
    display: "flex",
    alignItems: "flex-end",
  },
  phoneNo: {
    width: "100%",
  },
  countryCode: {
    backgroundColor: theme.palette.background.level2,
  },
  submit: {
    marginTop: "34px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("tablet")]: {
      // width: 500,
    },
    "& button": {
      [theme.breakpoints.up("tablet")]: {
        marginLeft: "0",
        marginRight: "0",
      },
    },
  },
  arrowImg: {
    marginLeft: 8,
  },
  backArrowImg: {
    marginRight: 8,
  },
  phoneErr: {
    marginLeft: "23%",
    marginTop: "2%",
  },
  createAccountDescriptionArea: {

    fontSize: "13px",
    lineHeight: "24px",
    marginTop: "30px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    }
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  font15: {
    fontSize: "12px",
  },
  policycolor: {
    color: theme.palette.btnPrimary.main,
  },
  contentArea: {
    paddingLeft: 16,
    paddingRight: 16
  },
  passwordText: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  charvalue: {
    color: "#787878"
  },

  TechScore: {
    fontFamily: "Frank Ruhl Libre",
    fontStyle: " normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.03em"
  },
  imgStyle: {
    textAlign: 'center',
    marginTop: '80px',
    [theme.breakpoints.up("tablet")]: {
      marginTop: "40px"
    }
  }
}));

function SuccessScreen(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const onSubmit = (values) => {
    values.level = location.level;
    console.log("Form data", values);

    history.push({
      pathname: "/LoginWithEmail",
    });
  };


  return (
    <div className={classes.root}>

      <div>
        <div
          className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
        >
          <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
        </div>


        <Grid container>
          {isNotMobile &&
            <Grid item xs={4}></Grid>
          }
          {location && location.pathname === "/EmailSuccessScreen" &&
            <Grid item xs={isNotMobile ? 4 : 12} className={classes.contentArea}>
              <div className={`${classes.section} ${classes.cardTwo}`}>
                <div className={classes.imgStyle}>
                  <img src={`${ImagebaseUrl}assets/successcheck.svg`} />
                </div>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "h3" : "h4"}
                  align="center"
                  className={`${classes.cardTwoH1} ${classes.title}`}
                >
                  Email Sent!
                </Typography>
              </div>
              <div className={`${classes.section}  ${classes.card} ${classes.center}`}>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                  align="center"
                  className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
                >
                  Please check your email for instructions to reset your password. It can take up to a few minutes to receive it.
                </Typography>
              </div>

              <form>

                <div
                  className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
                >
                  <Button
                    onClick={onSubmit}
                    type="submit"
                    color="btnPrimary"
                    width="100%"
                    text="Back to Log In"
                  ></Button>
                </div>

                <div className={`${classes.section} ${classes.center}  ${classes.cardSix}`}>
                  <Grid
                    container

                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >

                    <Link onClick={() => history.push("/")}>

                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                        align="center"
                        className={classes.labelBlue}
                      >
                        Return to Home
                      </Typography>
                    </Link>

                  </Grid>
                </div>
              </form>
            </Grid>
          }

          {location && location.pathname === "/ResetpasswordSuccessScreen" &&
            <Grid item xs={isNotMobile ? 4 : 12} className={classes.contentArea}>
              <div className={`${classes.section} ${classes.cardTwo}`}>
                <div className={classes.imgStyle}>
                  <img src={`${ImagebaseUrl}assets/successcheck.svg`} />
                </div>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "h3" : "h4"}
                  align="center"
                  className={`${classes.cardTwoH1} ${classes.title}`}
                >
                  Password Reset Successfully
                </Typography>
              </div>
              <div className={`${classes.section}  ${classes.card} ${classes.center}`}>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                  align="center"
                  className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
                >
                  Please use your new password and email to login to our systems from now on.
                </Typography>
              </div>

              <form>

                <div
                  className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
                >
                  <Button
                    onClick={onSubmit}
                    type="submit"
                    color="btnPrimary"
                    width="100%"
                    text="Back to Log In"
                  ></Button>
                </div>

                <div className={`${classes.section} ${classes.center}  ${classes.cardSix}`}>
                  <Grid
                    container

                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >

                    <Link onClick={() => history.push("/")}>

                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                        align="center"
                        className={classes.labelBlue}
                      >
                        Return to Home
                      </Typography>
                    </Link>

                  </Grid>
                </div>
              </form>
            </Grid>
          }
          {isNotMobile &&
            <Grid item xs={4}></Grid>
          }
        </Grid>
      </div>
    </div>
  );
}
export default SuccessScreen;
