import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Button from "../components/controls/Button";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../components/controls/TextInput";
import { Container } from "@material-ui/core";
import Checkbox from "../components/controls/Checkbox";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Text } from "../styles/theme";

import arrowImg from "../assets/Arrow 10-3.png";
import BackArrow from "../assets/svg/BackArrow.svg";
import logo from "../assets/logo.svg";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import auth from "../redux/actions/auth";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ButtonMaterial from "@material-ui/core/Button";
import AppConfig from "../constants/AppConfig";
import { ImagebaseUrl } from "../services/globalService";
import moment from "moment";
import { date } from "yup/lib/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
    marginTop: "48px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkSeclect: {
    display: "flex",
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
  },
  cardSix: {
    marginTop: "30px",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: "32px",
    // marginLeft: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      // width: 458,
      marginLeft: "auto",
      marginRight: "auto",
    },

    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {},
  },

  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formControl: {
    marginTop: 24,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  telField: {
    minHeight: 48,
    marginTop: 8,
    margin: "8px 0px 0px 0px",
    backgroundColor: theme.palette.background.light,
  },
  phonewithCode: {
    display: "flex",
    alignItems: "flex-end",
  },
  phoneNo: {
    width: "100%",
  },
  countryCode: {
    backgroundColor: theme.palette.background.level2,
  },
  submit: {
    marginTop: "24px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("tablet")]: {
      // width: 500,
    },
    "& button": {
      [theme.breakpoints.up("tablet")]: {
        marginLeft: "0",
        marginRight: "0",
      },
    },
  },
  arrowImg: {
    marginLeft: 8,
  },
  backArrowImg: {
    marginRight: 8,
  },
  phoneErr: {
    marginLeft: "23%",
    marginTop: "2%",
  },
  createAccountDescriptionArea: {
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.up("tablet")]: {
      // width: "424px",
      // height: "72px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  font15: {
    fontSize: "12px",
  },
  policycolor: {
    color: theme.palette.btnPrimary.main,
    cursor: "pointer",
  },
  contentArea: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  accountText: {
    color: "#787878",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  checkText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "20px",
    marginLeft: "-10px",
    color: "#787878",
  },
  cursorClass: {
    cursor: "pointer",
    fontSize: "14px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    },
  },
  loginAccount: {
    color: "#787878",
  },
  checkSt: {
    marginTop: "10px",
  },
}));

function CreateAccountWithEmail(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [showErrorMessage, setErrorMessage] = useState("");
  const [isShowSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBar, setShowSnackBarOnSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = (values) => {
    values.level = location.level;
    localStorage.setItem("userpassword", values.password);
    setShowSnackBarOnSubmit(true);
    // dispatch(
    //   auth.createUserWithFirebaseRequested({
    //     email: values.email,
    //     password: values.password,
    //   })
    // );
    let date = new Date();
    let payload = {
      // uid: user.uid,
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      displayName: values.firstName + " " + values.lastName,
      emailVerified: false,
      AuthMethod: "Native",
      profileId: 1,
      // photoUrl: user?.photoUrl,
      // phonenumber: user?.phonenumber,
      createdAt: date,
      lastLoginAt: date,
      // user_level: quizState?.level,
      user_level: authState?.level,
      targetScore: quizState?.testResult?.targetScore,
      targetRole: quizState?.testResult?.role,
      targetCompany: quizState?.testResult?.targetCompany,
      primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
      scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
      scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
      skills: quizState?.testResult?.skills,
    };

    let userMetaData = {
      email: values.email,
      code: `${AppConfig?.l0QuestionCode}`,
      result: quizState?.userMetaDataReq,
    };
    dispatch(auth.createProfile(payload, userMetaData));
  };

  useEffect(() => {
    if (authState.userProfile)
      history.push({
        pathname: "/CreateverifySuccess",
        state: {
          email: formik.values.email,
          password: formik.values.password,
        },
      });
  }, [authState.userProfile]);

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  const termsText = (
    <p>
      By creating an account, you agree to our{" "}
      <span>
        <Link
          onClick={() => history.push("/Privacypolicy")}
          className={`${classes.policycolor}`}
        >
          Privacy Policy
        </Link>
      </span>{" "}
      and{" "}
      <span>
        <Link
          onClick={() => history.push("/Terms&Conditions")}
          className={`${classes.policycolor}`}
        >
          Terms of Use.
        </Link>
      </span>
    </p>
  );

  const initialValues = {
    email: location?.state ? location?.state.email : "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    countryCode: "",
    confirm: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{6,})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/,
        "Strong passwords have at least 6 characters and a mix of letters and numbers"
      ),
    confirmPassword: Yup.string()
      .required("Please confrim your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    countryCode: Yup.string(),
    confirm: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (
      authState.firebaseUserDetails &&
      authState.firebaseUserDetails.error &&
      showSnackBar
    ) {
      setShowSnackBar(true);
      setErrorMessage(authState.firebaseUserDetails.error);
    }
    if (
      authState.firebaseUserDetails &&
      authState.firebaseUserDetails.user &&
      showSnackBar
    ) {
      const { user } = authState.firebaseUserDetails;
      if (
        authState.firebaseUserDetails &&
        authState.firebaseUserDetails.user &&
        authState.firebaseUserDetails.additionalUserInfo &&
        authState.firebaseUserDetails.additionalUserInfo.isNewUser
      ) {
        // user.sendEmailVerification();
        // let payload = {
        //  // uid: user.uid,
        //   email: user.email,
        //   firstName: formik.values.firstName,
        //   lastName: formik.values.lastName,
        //   displayName:
        //     formik.values.firstName + " " + formik.values.lastName,
        //   emailVerified: false,
        //   AuthMethod: "Native",
        //   profileId: 1,
        //   // photoUrl: user?.photoUrl,
        //   // phonenumber: user?.phonenumber,
        //   createdAt: user?.metadata.creationTime,
        //   lastLoginAt: user?.metadata.lastSignInTime,
        //   // user_level: quizState?.level,
        //   user_level: authState?.level,
        //   targetScore: quizState?.testResult?.targetScore,
        //   targetRole: quizState?.testResult?.role,
        //   targetCompany: quizState?.testResult?.targetCompany,
        //   primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
        //   scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
        //   scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
        //   skills: quizState?.testResult?.skills,
        // };
        // let userMetaData = {
        //   email: user?.email,
        //   code: `${AppConfig?.l0QuestionCode}`,
        //   result: quizState?.userMetaDataReq,
        // };
        // dispatch(auth.createProfile(payload, userMetaData));
        // history.push({
        //   pathname: "/CreateverifySuccess",
        //   state: {
        //     email: formik.values.email,
        //     password: formik.values.password,
        //   },
        // });
      }
    }
  }, [authState.firebaseUserDetails]);

  const handleBackButton = () => {
    dispatch(auth.setProfile(null));
    AppConfig.goBackButton = true;
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img
          alt="logo"
          src={`${ImagebaseUrl}assets/logo.svg`}
          className={`${classes.logoCls}`}
        />
      </div>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}

        <Grid item xs={isNotMobile ? 4 : 12} className={classes.contentArea}>
          <div className={`${classes.section} ${classes.cardTwo}`}>
            <Typography
              component={Text}
              variant2={isNotMobile ? "h3" : "h4"}
              align="center"
              className={`${classes.cardTwoH1} ${classes.title}`}
            >
              Create an Account
            </Typography>
          </div>
          <div
            className={`${classes.section}  ${classes.card} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
            >
              Create your profile to save your score, track progress, and get
              access to training materials, workshops, and our community of
              professionals.
            </Typography>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <Container className={classes.inputCt}>
              <div className={classes.formControl}>
                <label htmlFor="email">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Email*
                  </Typography>
                </label>
                <TextInput
                  name="email"
                  placeholder="hellofy@gmail.com"
                  className={classes.field}
                  id="email"
                  onChange={formik.handleChange}
                  //  error={Boolean(formik.errors.email)}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                ></TextInput>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.email}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div className={classes.formControl}>
                <label htmlFor="password">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Password {""}
                    <span className={classes.loginAccount}>
                      (min 6 characters)
                    </span>
                  </Typography>
                </label>
                <TextInput
                  name="password"
                  placeholder=""
                  className={`${classes.field} parentclassname`}
                  id="password"
                  onChange={formik.handleChange}
                  //error={Boolean(formik.errors.password)}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  passwordText={"PasswordIcon"}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                ></TextInput>
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.password}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div className={classes.formControl}>
                <label htmlFor="password">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Confirm New Password {""}
                    <span className={classes.loginAccount}>(Must match)</span>
                  </Typography>
                </label>
                <TextInput
                  name="confirmPassword"
                  placeholder=""
                  className={`${classes.field} parentclassname`}
                  id="confirmPassword"
                  onChange={formik.handleChange}
                  //error={Boolean(formik.errors.password)}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  passwordText={"PasswordIcon"}
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                ></TextInput>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.confirmPassword}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div className={classes.formControl}>
                <label htmlFor="firstName">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    First Name
                  </Typography>
                </label>
                <TextInput
                  name="firstName"
                  placeholder="Ex. Alexander"
                  className={classes.field}
                  id="firstName"
                  onChange={formik.handleChange}
                  // error={Boolean(formik.errors.firstName)}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                ></TextInput>
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.firstName}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div className={classes.formControl}>
                <label htmlFor="lastName">
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                    align="left"
                  >
                    Last Name
                  </Typography>
                </label>
                <TextInput
                  name="lastName"
                  placeholder="Ex. Smith"
                  className={classes.field}
                  id="lastName"
                  onChange={formik.handleChange}
                  //error={Boolean(formik.errors.lastName)}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                ></TextInput>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.lastName}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </Container>

            <div
              className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
            >
              <Button
                disabled={
                  !(formik.isValid && formik.dirty && formik.values.confirm)
                }
                type="submit"
                color="btnPrimary"
                width="100%"
                text="Sign Up"
                data-rt-createaccount-signup="signup"
              ></Button>
            </div>
            <div
              className={`${classes.section} ${classes.center} ${classes.checkSt}`}
            >
              <Checkbox
                id="confirm"
                color="common"
                onChange={formik.handleChange}
                name="confirm"
                value={formik.values.confirm}
                label={termsText}
                className={classes.optIn}
              ></Checkbox>
              {/* <p className={classes.checkText}>{termsText}</p> */}
            </div>

            <Grid
              container
              className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link
                  onClick={handleBackButton}
                  data-rt-createaccount-backbtn="backbtn"
                >
                  <Typography
                    component={Text}
                    variant2={isNotMobile ? "link1" : "link2"}
                    align="left"
                    className={`${classes.labelBlue}`}
                  >
                    <span className={classes.backArrowImg}>
                      <img
                        alt="backArrowImage"
                        src={`${ImagebaseUrl}assets/svg/BackArrow.svg`}
                      />
                    </span>
                    Back
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Typography
                  variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                  component={Text}
                  className={classes.loginAccount}
                >
                  Already have an account? {""}
                  <Link
                    onClick={() => history.push("/LoginWithEmail")}
                    className={`${classes.labelBlue} ${classes.cursorClass}`}
                    data-rt-createaccount-loginwithemail="loginwithemail"
                  >
                    Log In
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={showErrorMessage}
        action={
          <React.Fragment>
            <ButtonMaterial
              color="secondary"
              size="small"
              onClick={handleCloseSnackbar}
            ></ButtonMaterial>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default CreateAccountWithEmail;
