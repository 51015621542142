import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import "../../../pages/home/Home.css";
import Button from "../../../components/controls/Button";
import CustomizedProgressBars from "../../../components/controls/CustomizedProgressBars";
import Timer from "../../../components/Timer/Timer";
import Answers from "../Answers/Answers";
import { Text } from "../../../styles/theme";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";
import Popup from "../../../components/Popup";
import LOneExampleContent from "../../../components/dialogContent/LOneExampleContent";
import * as globalService from "../../../services/globalService";
import logo from "../../../assets/logo.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import auth from "../../../redux/actions/auth";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ButtonMaterial from "@material-ui/core/Button";
import TimeoutDialog from "../../TimeoutDialog";
import HelpOutline from "@material-ui/icons/HelpOutline";

import SubmitConfirmationDialog from "../../../components/SubmitConfirmationDialog";

import { removeAnswers, getUnique, ImagebaseUrl } from "../../../services/globalService";
import RoundedLoadingIndicator from "../../RoundedLoadingIndicator";

import hintImg from "../../../assets/questionsInformationIcon.svg";
import ExitDialog from "../../Exitmodel";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {},
  },
  container: {
    padding: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px",
      paddingRight: "64px",
    },
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  main: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "60%",
    },
  },
  header: {
    width: "100%",
  },
  item1: {
    height: "60px",
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  item2: {
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      marginRight: "10px",
    },
  },
  item3: {
    marginTop: "32px",
  },
  item4: {
    marginTop: "8px",
  },
  answers: {
    marginTop: "24px",
  },
  info: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    paddingBottom: "140px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  item5: {
    height: "80px",
    backgroundColor: "#FFFFFF",
  },
  pageInfo: {
    height: "36px",
    width: "fit-content",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  next: {
    "& button": {
      float: "right",
      height: 40,
    },
  },
  progess: {
    color: theme.palette.btnPrimary.tertiaryText,
    [theme.breakpoints.up("tablet")]: {},
  },
  progressArea: {
    marginTop: "20px",
    color: theme.palette.btnPrimary.tertiaryText,
    [theme.breakpoints.up("tablet")]: {
      marginTop: "24px",
    },
  },
  font16: {
    fontSize: theme.typography.link2.fontSize,
  },
  questionArea: {
    display: "flex",
  },
  qIndex: {},
  qTitle: {
    flex: "90%",
  },
  sidebar: {
    width: 500,
  },
  actionLink: {
    marginTop: 16,
  },
  qText: {
    whiteSpace: "pre-wrap",
  },
  stickToBottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  exit: {
    color: "#787878",
  },
  previous: {
    color: "#787878",
  },
  previousLink: {
    marginRight: "20px",
    [theme.breakpoints.up("tablet")]: {
      marginRight: "40px",
    },
  },

  addFlex: {
    display: "flex",
  },
  timeExceedDialog: {
    width: "443px",
    height: "403px",
    margin: "auto",
  },
  hintIconCls: {
    verticalAlign: "center",
    marginTop: "-2px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  containerCls: {
    overflowY: "auto",
    paddingBottom: 100,
  },
  Loader: {
    position: 'absolute',
    zIndex: 1,
    left: '50%',
    top: '50%'
  }
}));

function QuizContainer(props) {
  const { quizProp, routeParams, qIndex, location, ...other } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [exitopen, setExitopen] = useState(false);
  let locationRef = useLocation();

  let isFromQuizSummery = locationRef?.state?.isFromQuizSummery || false;

  let selectedQuesNdAns = useSelector((state) => state?.quiz?.selectedQuesNdAns);
  const counterRef = useRef();

  const [userAns, setUserAns] = useState({});
  const [totalTimetaken, setTotalTimetaken] = useState(0)

  const [alertDialogContent, setAlertDialogContent] = useState("");

  const [isAlertWithChangeType, setAlertWithChangeType] = useState(false);

  const [ansFromUserMetaData, setAnsFromUserMetaData] = useState("");

  const [actionInfo, setActionInfo] = useState("");

  // const [timerValue, setTimerValue] = useState(0);

  const { stepToWelcomeScreenMap, categoryTheme } = AppConfig;
  const { l1 } = stepToWelcomeScreenMap;
  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const classes = useStyles();

  const quesRes = useSelector((state) => state.quiz.questions);
  const qTrackRes = useSelector((state) => state.quiz.tracking);
  const authObj = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  const [isPageChange, setPageChange] = useState(false);

  const [isShowSnackBar, setShowSnackBar] = useState(false);
  const [isShowTimeoutDialog, setShowTimeoutDialog] = useState(false);
  const [timeoutFlag, setTimeoutFlag] = useState(false);
  const [timeoutMessage, setTimeoutMessage] = useState("");
  const [isShowSubmitDialog, setShowSubmitDialog] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [submitDialogContent, setSubmitDialogContent] = useState("");
  const unansweredQuestions = useSelector(
    (state) => state.quiz.unansweredQuestions
  );

  const [timerMin, setTimerMin] = useState();

  let value = `${qIndex} of ${quesRes.length}`;
  let question = quizProp.quesObject.Question_Description;
  let questionID = quizProp.quesObject.Question_ID;
  let nextQuestion = qIndex < quesRes.length + 1 ? quesRes[qIndex] : null;

  let currentQuestion = qIndex < quesRes.length ? quesRes[qIndex - 1] : null;

  let prevQuestion = qIndex < quesRes.length ? quesRes[qIndex - 2] : null;
  let answers = [
    quizProp.quesObject.Answer_A,
    quizProp.quesObject.Answer_B,
    quizProp.quesObject.Answer_C,
    quizProp.quesObject.Answer_D,
    quizProp.quesObject.Answer_E,
  ];
  let category =
    quizProp.quesObject.Category == ""
      ? "Profile Details"
      : quizProp.quesObject.Category;

  useEffect(() => {
    console.log("Setting unansweredQuestions: ", unansweredQuestions);
    let unanswered
    if (quizState.level == AppConfig.LEVEL0 && quizState.selectedQuesNdAns.length > quizState.Min_Questions) {
      unanswered = 0
    }
    else {
      unanswered = unansweredQuestions
    }
    let msg;
    if (unanswered > 0) {
      msg = `You have run out of time and cannot make any more changes. You have not answered ${unanswered} questions. Do you wish to submit your answers now?`;
    } else {
      msg = `You have run out of time and cannot make any more changes. You have answered all questions. Do you wish to submit your answers now?`;
    }
    setTimeoutMessage(msg);
  }, [unansweredQuestions]);

  useEffect(() => {
    if (quizState.testResult && quizState.level == AppConfig.LEVEL0) {
      dispatch(quiz.setL0TestResult(quizState.testResult));
      history.push({
        pathname: "/GeneratingProfile",
      });
    }

    if (quizState.testResult && quizState.level == AppConfig.LEVEL1) {
      history.push({
        pathname: "/CalculatingScore",
      });
    }
    if (quizState.testResult && quizState.level == AppConfig.LEVEL3) {
      history.push({
        pathname: "/UpdatingDashboard",
        state: {
          next: "/LNextSection",
        },
      });
    }

    if (quizState.testResult && quizState.level == AppConfig.LEVEL2) {
      dispatch(auth.getProfile({ email: authObj.user.email }));
      history.push({
        pathname: "/UpdatingDashboard",
        state: {
          next: "/LNextSection",
        },
      });
    }
  }, [quizState.testResult]);


  useEffect(() => {
    if (qIndex == 1) {
      dispatch(quiz.setPrevTime(quizState?.config?.Time_Mins * 60));
    }
    if (quizState && quizState.isPlaying == false) {
      dispatch(quiz.timerPlaying(true));
    }
  }, []);

  const submitAllAnswers = () => {
    //console.log("ghgfdhfg",totalTimetaken)
    if (navigator.onLine) {
      var ansLn = quizState.selectedAnswers.length;
      var preLn = quizState.selectedQuesNdAns.length
      var result = [];
      // let totalTime = 0
      // let timeLatest = calcTimeTaken();
      // for (var i = 1; i < preLn; i++){
      //  totalTime +=  timeLatest
      //  console.log("gsdfghfsdg",totalTime)
      // }
      if (quizState.level == AppConfig.LEVEL0 && (quizState.selectedQuesNdAns.length > quizState.Min_Questions)) {
        for (var i = 1; i < preLn; i++) {
          var o = quizState.selectedQuesNdAns[i];
          var p = o.value
          console.log("mgmfg", o.value)
          // let answer =
          //    p.map((item) => item);
          let answer =
            typeof p.answer == "string"
              ? [p.answer]
              : p.answer.map((item) => item.answer);
          if (o?.Question_ID) {
            result.push({
              Question_ID: o.Question_ID,
              User_Answers: answer,
            });
          }
        }
      }

      else {
        // let totalTime = 0
        // let timeLatest = quizState.tracking.timeTaken;
        // for(var i=0; i < ansLn; i++){
        
        //  totalTime +=  timeLatest
        //  //console.log("gsdfghfsdg",totalTime)
        // }
        // console.log("ewerewre",totalTime)
        for (var i = 0; i < ansLn; i++) {
          var o = quizState.selectedAnswers[i];
          console.log("o.answer", o.answer)
          let answer =
            typeof o.answer == "string"
              ? [o.answer]
              : o.answer.map((item) => item.answer);
          if (o?.ansId && o?.questionID) {
            result.push({
              Question_ID: o.questionID,
              User_Answers: answer,
            });
          }
        }
      }

      result = getUnique(result, "Question_ID");

      dispatch(
        quiz.submitTestResults({
          email: authObj.userProfile
            ? authObj.userProfile.email
            : "notregistered",
          code: quizState.sessionByLevel,
          result: result,
        })
      );
      dispatch(quiz.submitAnswers(false));
    }
    else {
      setOpenSnackBar(true)
    }
  };

  useEffect(() => {
    console.log("submitAnswers: ", quizState.submitAnswers);

    if (quizState.submitAnswers) {
      if (unansweredQuestions > 0) {
        dispatch(quiz.submitAnswers(false));
        let contentText = `You have ${unansweredQuestions} unanswered question${unansweredQuestions == 1 ? "" : "s"
          }. Your score will be reflective of it. Are you sure you want to submit?`;
        setSubmitDialogContent(contentText);
        setShowSubmitDialog(true);
        return;
      } else {
        let contentText = `Are you sure you want to submit?`;
        setSubmitDialogContent(contentText);
        setShowSubmitDialog(true);
        return;
      }
    }
  }, [quizState.submitAnswers]);

  useEffect(() => {
    if (timeoutFlag == true) {
      console.log("userAns", userAns);
      setPageChange(false);
      if (timerMin) {
        dispatch(quiz.setTimer(timerMin));
      }
      handleCalcQuizSummery();
      handleTrackingApiReq();

      dispatch(quiz.timerPlaying(false));
      setExitopen(false);
      if (
        (quizState.quizSummery &&
          quizState.quizSummery.answeredQuestions >= quizState.Min_Questions) || quizState.selectedQuesNdAns.length > quizState.Min_Questions
      ) {
        setTimeout(() => {
          setShowTimeoutDialog(true);
          setTimeoutFlag(false);
        }, 500);
      } else {
        setTimeoutFlag(false);
        history.push("/TimeOutCaptureScreen");
      }
    }
  }, [timeoutFlag]);

  useEffect(() => {
    if (
      quesRes &&
      quesRes.length > qIndex &&
      qTrackRes &&
      isPageChange == true
    ) {
      if (category) {
        dispatch(quiz.updatePrevCategory(category));
      }
      history.push(`/QuizQuestions/${authObj.level}/${qIndex + 1}`);
      counterRef.current.setDefaultIndex();
    }

    return () => console.log("Will Unmounted");
  }, [qTrackRes]);

  useEffect(() => {
    if (
      quizState.level == AppConfig.LEVEL0 &&
      ["Major", "Minor"].includes(quizProp?.quesObject?.Topic)
    ) {
      if (
        authObj?.userProfile &&
        authObj?.userProfile?.email &&
        authObj?.userMetaData &&
        authObj?.userMetaData[0]?.result
      ) {
        let initialAns = selectedQuesNdAns[qIndex];
        setAnsFromUserMetaData(initialAns);
      }
    }

    return () => console.log("Will Unmounted");
  }, [qIndex, quizProp?.quesObject]);

  const calcTimeTaken = () => {
    let timeRemainingSec = quizState?.prevTime;
    if (timerMin) {
      let currentTimeArray = timerMin.split(".")
      timeRemainingSec = (Number(currentTimeArray[0]) * 60) + Number(currentTimeArray[1]);
    }
    let timeTakenSec = quizState?.prevTime - timeRemainingSec;
    let timeTaken = timeTakenSec / 60;
    dispatch(quiz.setPrevTime(timeRemainingSec));
     console.log("hghjdfg",timeTaken)
    return timeTaken;
   
  }

  const handleTrackingApiReq = () => {
    let timeTaken = calcTimeTaken();
    if (quizProp.quesObject.Question_Type == "Single-Select") {
      let qTrackReq = {
        Question_ID: quizProp.quesObject.Question_ID,
        code: `${AppConfig.code}`,
        timeTaken: timeTaken,
        level: authObj.level.charAt(1),
        isAnswered:
          userAns && userAns.ansObj && userAns.ansObj.ansId ? "Yes" : "No",
        uid: authObj.userProfile ? authObj.userProfile.uid : "notregistered",
        email: authObj.userProfile
          ? authObj.userProfile.email
          : "notregistered",
        userAns:
          userAns && userAns.ansObj && userAns.ansObj.answer
            ? [userAns.ansObj.answer]
            : [],
        score: "0",
      };
      dispatch(quiz.getTrackingRequested(qTrackReq));
    } else {
      let qTrackReq = {
        Question_ID: quizProp.quesObject.Question_ID,
        code: `${AppConfig.code}`,
        timeTaken: timeTaken,
        level: authObj.level.charAt(1),
        isAnswered:
          userAns && userAns.ansObj && userAns.ansObj.ansId ? "Yes" : "No",
        uid: authObj.userProfile ? authObj.userProfile.uid : "notregistered",
        email: authObj.userProfile
          ? authObj.userProfile.email
          : "notregistered",
        userAns:
          userAns && userAns?.ansObj && userAns?.ansObj?.answer?.length != 0
            ? userAns?.ansObj?.answer.map((item) => item.answer)
            : [],
        score: "0",
      };

      dispatch(quiz.getTrackingRequested(qTrackReq));
     
      // further func will come
    }
    setTotalTimetaken(totalTimetaken + timeTaken)
  };
  const createUserProfile = (user) => {
    let name = user.displayName.split(" ");
    const firstName = name[0];
    const lastName = name[1];
    let payload = {
      uid: user?.uid,
      email: user?.email,
      firstName: firstName,
      lastName: lastName,
      displayName: user.displayName,
      AuthMethod: "Native",
      profileId: 1,
      photoUrl: user?.photoURL,
      phonenumber: user?.phoneNumber,
      createdAt: new Date(), //user?.creationTime || user?.createdAt
      lastLoginAt: user?.lastSignInTime || user?.lastLoginAt,
      user_level: quizState?.level,
      targetScore: quizState?.testResult?.targetScore,
      targetRole: quizState?.testResult?.role,
      targetCompany: quizState?.testResult?.targetCompany,
      primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
      scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
      scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
      skills: quizState?.testResult?.skills,
    };
    let userMetaData = {
      email: authObj?.userProfile?.email,
      code: `${AppConfig.code}`,
      result: JSON.stringify(selectedQuesNdAns),
      level: authObj.level.charAt(1),
    };

    dispatch(
      auth.deleteUserReq({
        email: authObj?.userProfile?.email,
        createUser: true,
        userPayload: payload,
        createMetaData: true,
        userMetaData,
      })
    );
  };

  const handleOnClickNextBtn = (eventData) => {
    let val = eventData?.val;
    let e = eventData?.e;
    if (timerMin) {
      dispatch(quiz.setTimer(timerMin));
    }
    if (val == "NextSection") {
      dispatch(quiz.timerPlaying(false));
    }
    setPageChange(true);
    console.log("timer", userAns);
    handleCalcQuizSummery();

    if (quesRes.length == qIndex) {
      var ansLn = quizState.selectedAnswers.length;
      var result = [];
      for (var i = 0; i < ansLn; i++) {
        var o = quizState.selectedAnswers[i];
        let answer =
          typeof o.answer == "string"
            ? [o.answer]
            : o.answer.map((item) => item.answer);
        if (o?.ansId && o?.questionID) {
          result.push({
            Question_ID: o.questionID,
            User_Answers: answer,
          });
        }
      }
      result = getUnique(result, "Question_ID");

      const tempLevelInfo = JSON.parse(quizState?.sessionReq);
      if (
        e?.target?.innerText == "Submit" &&
        tempLevelInfo.level == 3 &&
        tempLevelInfo.levelInfo == "presentation"
      ) {
        dispatch(quiz.submitAnswers(true));
        return;
      }

      dispatch(
        quiz.submitTestResults({
          email: authObj.userProfile
            ? authObj.userProfile.email
            : "notregistered",
          code: quizState.sessionByLevel,
          result: result,
        })
      );


    }

    handleTrackingApiReq();

    if (quesRes.length == qIndex && quizState.level != AppConfig.LEVEL0) {
      // globalService.resetData();
    }
    if (question === "What is your primary programming language?") {
      //dispatch(quiz.selectLanguage(userAns.ansObj));
    }
  };

  const onClickNext = (val, e) => {
    let eventData = {
      val,
      e,
    };
    if (navigator.onLine) {
      handleOnClickNextBtn(eventData);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const onClickPrev = () => {
    if (navigator.onLine) {
      setPageChange(true);
      let disableTracking = true;
      if (timerMin) {
        dispatch(quiz.setTimer(timerMin));
      }
      handleCalcQuizSummery();
      if (quesRes && qIndex == 2) {
        history.push({
          pathname: `/QuizQuestions/${authObj.level}/${qIndex - 1}`,
          state: {
            timerReset: false,
          },
        });
      } else if (quesRes && qIndex > 1) {
        history.push({
          pathname: `/QuizQuestions/${authObj.level}/${qIndex - 1}`,
          state: {
            timerReset: true,
          },
        });
      }
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const userAnsFn = (ansObj) => {
    setUserAns({ ansObj });
  };

  const handleDialog = (val) => {
    if (navigator.onLine) {
      setOpenPopup(val);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleTopicDialog = (event) => {
    setActionInfo(event);
    if (quizProp?.quesObject?.Topic == "Major") {
      let content =
        "You are about to make significant changes to your profile that will reset all your existing scores and account history. Do you wish to continue?";
      setAlertDialogContent(content);
    } else if (quizProp?.quesObject?.Topic == "Minor") {
      let content =
        "You are about to make changes to your profile that may impact your target score and timeline. Do you wish to continue?";
      setAlertDialogContent(content);
    }
    if (quizState.quizSelectionPopupDisable == false) {
      dispatch(quiz.quizSelectionPopupStatus(true));
    }
  };
  const onReviewAndSubmitClick = () => {
    if (navigator.onLine) {
      handleTrackingApiReq();
      setPageChange(true);
      handleCalcQuizSummery();

      dispatch(quiz.updateShowSummary(true));
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  const handleClose = () => {
    setOpenSnackBar(false)
  }
  const timeValue = (time, minutes, seconds) => {
    if (time) {
      let sec = +seconds;
      let val = `${minutes}.${sec}`;
      setTimerMin(val);
    }
  };

  const handleOnWarning = (remainingTime) => {
    setShowSnackBar(true);
  };

  const handleOnTimeout = () => {
    setTimeoutFlag(true);
  };
  const onSubmitWhileTimout = () => {
    if (navigator.onLine) {
      submitAllAnswers();
      setShowTimeoutDialog(false);
      setShowSubmitDialog(false);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleCalcQuizSummery = () => {
    let userAnswers = (userAns && userAns.ansObj) || { ansId: "", answer: "" };
    userAnswers["questionID"] = questionID && questionID;
    if (quizProp.quesObject.Question_Type == "Single-Select") {
      let payload = {
        level: routeParams.path.charAt(1),
        quesObject: quizProp.quesObject,
        userAns: userAns,
        questionNo: qIndex,
        questions: quesRes,
        categorySequence: quizState.categorySequence,
        quizState: quizState,
      };
      dispatch(quiz.calculateQuizSummery(payload));
      dispatch(quiz.updateAnswers(userAnswers));
      globalService.insertAnswer(userAnswers, quesRes.length);
    } else {
      let myUserObj = { ansObj: {} };
      let multiAnswer = counterRef?.current?.getMultiAnswer();
      myUserObj.ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      userAnsFn(myUserObj.ansObj);
      let multiSelection = myUserObj.ansObj || { ansId: "", answer: "" };
      multiSelection["questionID"] = questionID && questionID;
      let payload = {
        level: routeParams.path.charAt(1),
        quesObject: quizProp.quesObject,
        userAns: myUserObj,
        questionNo: qIndex,
        questions: quesRes,
        categorySequence: quizState.categorySequence,
        quizState: quizState,
      };
      dispatch(quiz.calculateQuizSummery(payload));

      dispatch(quiz.updateAnswers(multiSelection));
      globalService.insertAnswer(multiSelection, quesRes.length);

      // further func will come
    }
  };

  const handleViewProgress = () => {
    if (navigator.onLine) {
      setPageChange(false);
      if (timerMin) {
        dispatch(quiz.setTimer(timerMin));
      }
      handleCalcQuizSummery();
      handleTrackingApiReq();

      dispatch(quiz.updateShowSummary(true));
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleExit = () => {
    if (navigator.onLine) {
      removeAnswers();
      dispatch(quiz.setSelectedQuesNdAns([]));
      dispatch(quiz.clearState());
      history.push("/");
      setExitopen(false);
    }
    else {
      setOpenSnackBar(true)
    }

  };

  const handleclick = () => {
    if (navigator.onLine) {
      setExitopen(true);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const onClickContinue = (eventData) => {
    if (navigator.onLine) {
      dispatch(quiz.quizSelectionPopupStatus(false));
      if (quizProp?.quesObject?.Topic == "Major") {
        dispatch(quiz.quizSelectionPopupDisable(true));
      }
      dispatch(quiz.quizSelectionTopic(quizProp?.quesObject?.Topic));
      counterRef.current.callBackOnClickAns(eventData);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const cancelConfirmDialog = () => {
    if (navigator.onLine) {
      const tempLevelInfo = JSON.parse(quizState?.sessionReq);
      if (tempLevelInfo.level == 3 && tempLevelInfo.levelInfo == "presentation") {
        //dispatch(quiz.resetQuizSummery());
        //history.push("/");
        setShowSubmitDialog(false);
        dispatch(quiz.submitAnswers(false));
      } else {
        setShowSubmitDialog(false);
        dispatch(quiz.submitAnswers(false));
      }
    }
    else {
      setOpenSnackBar(true)
    }
  };


  return (
    <div>
      <div className={classes.root}>

        <div className={`${classes.item1}   ${classes.center} `}>
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.header}>
              <Grid container className="Home-hero">
                <Grid item xs={4}>
                  <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
                </Grid>
                <Grid item xs={4}>
                  <Timer
                    prevQuestion={prevQuestion}
                    currentQuestion={currentQuestion}
                    timeValue={timeValue}
                    location={locationRef}
                    handleOnWarning={() => handleOnWarning()}
                    handleOnTimeout={() => handleOnTimeout()}
                  ></Timer>
                </Grid>
                <Grid item xs={4} className={classes.exitBtn}>
                  <Link onClick={handleclick} data-rt-div-quizexit="quizexit">
                    <Typography
                      component={Text}
                      variant2="link2"
                      align="right"
                      className={classes.exit}
                    >
                      Exit
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container
          maxWidth="xl"
          className={`${classes.container} ${classes.containerCls}`}
        >

          <Grid container>
            {isNotMobile && <Grid item xs={3}></Grid>}
            <Grid item xs={isNotMobile ? 6 : 12}>
              <div className={classes.item3}>
                <div
                  className={`${classes.pageInfo} ${classes.center}`}
                  style={{
                    border: categoryTheme.hasOwnProperty(category)
                      ? `1px solid ${categoryTheme[category].bgColor}`
                      : "",
                  }}
                >
                  <Typography
                    component={Text}
                    variant2="b2Medium"
                    align="center"
                    style={{
                      color: categoryTheme.hasOwnProperty(category)
                        ? `${categoryTheme[category].bgColor}`
                        : "",
                      margin: "0px 10px",
                    }}
                  >
                    {category} Section
                  </Typography>
                </div>
              </div>
              <Grid
                container
                className={classes.progressArea}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  component={Text}
                  variant2="b2Medium"
                  className={`${classes.progess}`}
                  align="left"
                >
                  Question {`${value}`}
                </Typography>
              </Grid>
            </Grid>
            {isNotMobile && <Grid item xs={3}></Grid>}
          </Grid>

          <Grid container>
            {isNotMobile && <Grid item xs={3}></Grid>}
            <Grid item xs={isNotMobile ? 6 : 12}>
              <div className={classes.item4}>
                <div className={classes.question}>
                  <div className={classes.questionArea}>
                    <div className={classes.qText}>
                      <Typography
                        component={Text}
                        variant2="b1Medium"
                        align="left"
                        id="no-copy"
                      >
                        {question}{" "}
                        {quizProp &&
                          quizProp.quesObject &&
                          quizProp.quesObject.Hint && (
                            <span>
                              <img
                                alt="hintImg"
                                src={`${ImagebaseUrl}assets/questionsInformationIcon.svg`}
                                className={classes.hintIconCls}
                                onClick={() => handleDialog(true)}
                              />
                            </span>
                          )}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className={classes.answers}>
                  <Answers
                    answers={answers}
                    userAnsFn={userAnsFn}
                    quesObject={quizProp.quesObject}
                    ref={counterRef}
                    qIndex={qIndex}
                    handleTopicDialog={handleTopicDialog}
                  ></Answers>
                </div>
              </div>
            </Grid>
            {isNotMobile && <Grid item xs={3}></Grid>}
          </Grid>
        </Container>
        <div
          className={`${classes.item5} ${classes.center} ${classes.stickToBottom}`}
        >
          <Container maxWidth="xl" className={classes.container}>
            <Grid container>
              {isNotMobile && <Grid item xs={3}></Grid>}
              <Grid item xs={isNotMobile ? 6 : 12}>
                <Grid
                  container
                  justifyContent={
                    qIndex && qIndex > 1
                      ? "space-between"
                      : quizState.level == AppConfig.LEVEL0
                        ? "flex-end"
                        : "space-between"
                  }
                  alignItems="center"
                >
                  {(quizState.level == AppConfig.LEVEL1 ||
                    quizState.level == AppConfig.LEVEL2 ||
                    quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                    quesRes.length != qIndex && (
                      <>
                        {isFromQuizSummery == true ? (
                          <Button
                            color="btnPrimary1"
                            text="View Summary"
                            width={isNotMobile ? "202px" : "172px"}
                            onClick={handleViewProgress}
                            data-rt-div-viewsummarybtn="viewsummarybtn"
                          ></Button>
                        ) : (
                          <Link
                            data-rt-div-viewsummary="viewsummary"
                          >
                            <Typography
                              component={Text}
                              variant2={isNotMobile ? "link1" : "link2"}
                              align="center"
                              className={classes.previous}
                              onClick={handleViewProgress}
                            >
                              View Summary
                            </Typography>
                          </Link>
                        )}
                      </>
                    )}

                  {(quizState.level == AppConfig.LEVEL0) && (
                    <>
                      {qIndex && qIndex > 1 ? (
                        <Link data-rt-div-previousL0="previousL0">
                          <Typography
                            component={Text}
                            variant2={isNotMobile ? "link1" : "link2"}
                            align="left"
                            className={classes.previous}
                            onClick={() => onClickPrev()}
                          >
                            Previous
                          </Typography>
                        </Link>
                      ) : quizState.level == AppConfig.LEVEL3 ? (
                        <Typography />
                      ) : null}
                    </>
                  )}
                  {(quizState.level == AppConfig.LEVEL1 ||
                    quizState.level == AppConfig.LEVEL2 ||
                    quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                    quesRes.length == qIndex && (
                      <>
                        {qIndex && qIndex > 1 ? (
                          <Link data-rt-div-previousbtn="previousbtn">
                            <Typography
                              component={Text}
                              variant2={isNotMobile ? "link1" : "link2"}
                              align="left"
                              className={classes.previous}
                              onClick={() => onClickPrev()}
                            >
                              Previous
                            </Typography>
                          </Link>
                        ) : quizState.level == AppConfig.LEVEL3 ? (
                          <Typography />
                        ) : null}
                      </>
                    )}

                  <Grid
                    justifyContent={
                      qIndex && qIndex > 1
                        ? "space-between"
                        : // : quizState.level == AppConfig.LEVEL0
                        // ? "flex-end"
                        "space-between"
                    }
                    style={{ display: "flex" }}
                    alignItems="center"
                  >
                    {(quizState.level == AppConfig.LEVEL1 ||
                      quizState.level == AppConfig.LEVEL2 ||
                      quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                      quesRes.length != qIndex && (
                        <>
                          {qIndex && qIndex > 1 ? (
                            <Link data-rt-div-previousl3="previousl3">
                              <Typography
                                component={Text}
                                variant2={isNotMobile ? "link1" : "link2"}
                                align="left"
                                className={`${classes.previous} ${classes.previousLink}`}
                                onClick={() => onClickPrev()}
                              >
                                Previous
                              </Typography>
                            </Link>
                          ) : quizState.level == AppConfig.LEVEL3 ? (
                            <Typography />
                          ) : null}
                        </>
                      )}

                    {!(
                      (quizState.level == AppConfig.LEVEL1 ||
                        quizState.level == AppConfig.LEVEL2 ||
                        quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                      quesRes.length == qIndex
                    ) && (
                        <>
                          {isFromQuizSummery == true ? (
                            <Link data-rt-div-next="nextlink">
                              <Typography
                                component={Text}
                                variant2={isNotMobile ? "link1" : "link2"}
                                align="center"
                                className={classes.previous}
                                onClick={() =>
                                  onClickNext(
                                    nextQuestion &&
                                      nextQuestion.Category != category
                                      ? "NextSection"
                                      : "Next" || "Next"
                                  )
                                }
                              >
                                {nextQuestion && nextQuestion.Category != category
                                  ? "Next Section"
                                  : "Next" || "Next"}
                              </Typography>
                            </Link>
                          ) : (
                            <Button
                              disabled={
                                routeParams.path == AppConfig.LEVEL0 &&
                                  (!userAns ||
                                    !userAns.ansObj ||
                                    !userAns.ansObj.ansId ||
                                    !userAns.ansObj.answer ||
                                    userAns.ansObj.ansId.length == 0 ||
                                    userAns.ansObj.answer.length == 0)
                                  ? true
                                  : false
                              }
                              color="btnPrimary1"
                              text={
                                quesRes.length == qIndex
                                  ? "Submit"
                                  : "Next" || "Next"
                              }
                              width={isNotMobile ? "202px" : "160px"}
                              onClick={(e) =>
                                onClickNext(
                                  nextQuestion &&
                                    nextQuestion.Category != category
                                    ? "NextSection"
                                    : "Next" || "Next",
                                  e
                                )
                              }
                              data-rt-div-nextbtn="nextbtn"
                            ></Button>
                          )}
                        </>
                      )}

                    {(quizState.level == AppConfig.LEVEL1 ||
                      quizState.level == AppConfig.LEVEL2 ||
                      quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                      quesRes.length == qIndex && (
                        <Button
                          color="btnPrimary1"
                          text="Review & Submit"
                          width={isNotMobile ? "202px" : "172px"}
                          disabled={
                            routeParams.path == AppConfig.LEVEL0 &&
                              (!userAns ||
                                !userAns.ansObj ||
                                !userAns.ansObj.ansId ||
                                !userAns.ansObj.answer ||
                                userAns.ansObj.ansId.length == 0 ||
                                userAns.ansObj.answer.length == 0)
                              ? true
                              : false
                          }
                          onClick={() => onReviewAndSubmitClick()}
                          data-rt-div-Reviewsubmit="Reviewsubmit"
                        ></Button>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              {isNotMobile && <Grid item xs={3}></Grid>}
            </Grid>
          </Container>
        </div>
      </div>

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <LOneExampleContent
          hint={quizProp.quesObject.Hint}
          showButtonArea={false}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          id="no-copy"
        ></LOneExampleContent>
      </Popup>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Your session is about to expire, hurry!"
        action={
          <React.Fragment>
            <ButtonMaterial
              color="secondary"
              size="small"
              onClick={handleCloseSnackbar}
            ></ButtonMaterial>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <TimeoutDialog
        open={isShowTimeoutDialog}
        onClose={() => setShowTimeoutDialog(false)}
        text={timeoutMessage}
        onSubmit={(e) => onSubmitWhileTimout()}
        TimeClose={() => setOpenSnackBar(true)}
      ></TimeoutDialog>

      <SubmitConfirmationDialog
        open={isShowSubmitDialog}
        text={submitDialogContent}
        action=""
        onSubmit={submitAllAnswers}
        onClose={cancelConfirmDialog}
        TimeClose={() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>

      <SubmitConfirmationDialog
        open={quizState.quizSelectionPopupStatus}
        text={alertDialogContent}
        action={actionInfo}
        succesBtnLabel="Yes, continue"
        onSubmit={(event) => onClickContinue(actionInfo)}
        onClose={() => dispatch(quiz.quizSelectionPopupStatus(false))}
        TimeClose={() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>
      <ExitDialog
        open={exitopen}
        OnExit={handleExit}
        onClose={() => setExitopen(false)}
        category={category}
        TimeClose={() => setOpenSnackBar(true)}
      ></ExitDialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default QuizContainer;
