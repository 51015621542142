
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ImagebaseUrl } from "../../services/globalService";
import Link from "@material-ui/core/Link";
import { Text } from "../../styles/theme";
import auth from "../../redux/actions/auth";
import quiz from "../../redux/actions/quiz"
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px",
      paddingRight: "64px",
    },
  },
  item1: {
    marginBottom: "32px",
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  exit: {
    color: theme.palette.neutrals.grayDark,
  },
  labelGray: {
    color: theme.palette.btnPrimary.tertiaryText,
    fontWeight: theme.typography.b1Medium.fontWeight
  },
  iconImg: {
    marginLeft: 8,
  },
  logoutText: {
    display: 'flex'
  },
  viewDetails: {
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "12px"
    }
  },
  nameprofile: {
    width: "40%"
  },
  valueprofile: {
    width: "30%"
  }

}))

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



export default function AdminViewDetails() {
  const classes = useStyles();
  const authviewState = useSelector((state) => state.auth.adminviewdetails)
  const authemail = useSelector((state) => state.auth.adminviewemail)
  const dispatch = useDispatch()
  const [keyView, setKeyView] = React.useState([])
  const history = useHistory()

  const onClickLogout = () => {
    dispatch(auth.clearState());
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    dispatch(quiz.clearState());

    history.push({
      pathname: "/",
    });
  };

  //  React.useEffect(() => {
  //   let myjson = JSON.stringify(authviewState.candidateProfile)
  //   let keyView = Object.keys(myjson)
  //   console.log("values", keyView)
  //   setKeyView(keyView)

  // }, [])

  React.useEffect(() => {
    dispatch(auth.adminViewDetails(authemail))
  }, [])

  // console.log("text score", authviewState)
  // let authview 
  // if(authviewState === authemail) {
  //   authview = []
  // }
  // else {
  //   authview = authviewState || []
  // }
  React.useEffect(() => {
    setKeyView(authviewState.candidateProfile)

  }, [authviewState])
  //console.log("authview.CandidateProfile.firstName",authview.CandidateProfile.firstName)
  let myjson = JSON.stringify(authviewState.codingChallenge)
let createdOn = authviewState.candidateProfile
  ? authviewState.candidateProfile.createdAt
  : null;
if (createdOn) {
  createdOn = moment(createdOn).format("MMMM DD, YYYY h:mm a");
}

let lastLoggedOn = 
authviewState?.candidateProfile?.lastLoginAt ? authviewState?.candidateProfile?.lastLoginAt
  : null;
if (lastLoggedOn) {
  lastLoggedOn = moment(lastLoggedOn).format("MMMM DD, YYYY h:mm a");
}


  let candidateprofile = keyView === undefined || keyView === [] ? [] : keyView
  let keyprofile = Object.keys(candidateprofile)
  let OnlineProfile = authviewState.onlineProfile === undefined || authviewState.onlineProfile === [] ? [] : authviewState.onlineProfile
  let onlinekey = Object.keys(OnlineProfile)
  let ResumeMatch = authviewState.resumeMatch === undefined || authviewState.resumeMatch === [] ? [] : authviewState.resumeMatch
  let keyResume = Object.keys(ResumeMatch)
  let codingchallenge = authviewState.codingChallenge === undefined || authviewState.codingChallenge === [] ? [] : authviewState.codingChallenge
  let codingkeys = Object.keys(codingchallenge)
  let initialEvaluation = authviewState.initialEvaluation === undefined || authviewState.initialEvaluation === [] ? [] : authviewState.initialEvaluation
  let keyinitial = Object.keys(initialEvaluation)
  let presentationReadiness = authviewState.presentationReadiness === undefined || authviewState.presentationReadiness === [] ? [] : authviewState.presentationReadiness
  let keypresentation = Object.keys(presentationReadiness)
  let technicalExpertise = authviewState.technicalExpertise === undefined || authviewState.technicalExpertise === [] ? [] : authviewState.technicalExpertise
  let keytechnical = Object.keys(technicalExpertise)
  return (
    <div className={classes.root}>

      <div className={`${classes.item1} ${classes.center} `}>
        <Container
          maxWidth="xl"
          className={classes.container}
        //   style={{ width: "70%" }}
        >
          <div className={classes.main}>
            <Grid container className="Home-hero" alignItems="center">
              <Grid item xs={4}>
                <img
                  src={`${ImagebaseUrl}assets/logo.svg`}
                  className={classes.logoCls}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4} className={classes.exitBtn}>
                <Link onClick={onClickLogout}>
                  {/* <Typography
                      component={Text}
                      variant2="link2"
                      align="right"
                      className={classes.exit}
                    >
                      Exit
                    </Typography> */}
                  <Grid alignItems="center" className={classes.logoutText}>
                    <Typography
                      component={Text}
                      variant2="link1"
                      align="center"
                      className={`${classes.labelGray}`}
                    >
                      Log Out
                    </Typography>
                    <span className={classes.iconImg}>
                      <img src={`${ImagebaseUrl}assets/logout.svg`} />
                    </span>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <div >
        <Grid container className={classes.viewDetails}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {/* <p>Candidate Profile</p> */}
            <TableContainer >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Candidate Profile</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.nameprofile}>Label</TableCell>
                    <TableCell className={classes.valueprofile}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => (
            <TableRow
              key={row.firstName}

              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.firstName}
              </TableCell>
              <TableCell align="right">{row.LastName}</TableCell>
              <TableCell align="right">{moment(row.memberSince).format("DD-MM-YYYY")}</TableCell>
            
            </TableRow>
          ))} */}
                  {/* <TableRow>
          <TableCell align="right">{}</TableCell>
          </TableRow> */}
                  {/* <TableCell align="right">{moment(row.memberSince).format("DD-MM-YYYY")}</TableCell> */}
                  {keyprofile.map((row) => 
                    {
                    if(row === "createdAt")
                    
                    return  <TableRow>
                      <TableCell >{row}</TableCell>
                      <TableCell>{createdOn}</TableCell>
                    </TableRow>
                    else if(row === "lastLoginAt") 
                    return <TableRow>
                      <TableCell >{row}</TableCell>
                      <TableCell>{lastLoggedOn}</TableCell>
                    </TableRow> 
                    else 
                   return <TableRow>
                      <TableCell >{row}</TableCell>
                      <TableCell>{candidateprofile[row]}</TableCell>
                    </TableRow>
                    }
                  )}
                  {/* 
                <TableRow>
                  
                  <TableCell>
                   
                   <p>CreatedAt</p>
                   <p>Email</p>
                   <p>FirstName</p>
                   <p>LastLoginAt</p>
                   <p>PrimaryCodingLanguage</p>
                   <p>ScoreLowerBound</p>
                   <p>ScoreUpperBound</p>
                   <p>TargetCompany</p>
                   <p>TargetRole</p>
                   <p>TargetScore</p>
                   <p>TotalScore</p>
                  </TableCell>
                  <TableCell>
                   
                    <p>{moment(candidateprofile.createdAt).format("DD-MM-YYYY")}</p>
                    <p>{candidateprofile.email}</p>
                    <p>{candidateprofile.firstName}</p>
                    <p>{moment(candidateprofile.lastLoginAt).format("DD-MM-YYYY")}</p>
                    <p>{candidateprofile.primaryCodingLanguage}</p>
                    <p>{candidateprofile.scoreLowerBound} </p>
                    <p> {candidateprofile.scoreUpperBound}</p>
                    <p> {candidateprofile.targetCompany}</p>
                    <p>{candidateprofile.targetRole}</p>
                    <p> {candidateprofile.targetScore}</p>
                    <p> {candidateprofile.totalScore}</p>
                

                  </TableCell>
                 
                </TableRow> */}
                  {/* <TableRow>
                  <TableCell >Coding Challenge</TableCell>
                  <TableCell>{JSON.stringify(authviewState.codingChallenge)}</TableCell>
                </TableRow> */}
                  {/* <TableRow>
                  <TableCell >Initial Evaluation</TableCell>
                  <TableCell>{JSON.stringify(authviewState.initialEvaluation)}</TableCell>
                </TableRow> */}
                  {/* <TableRow>
                  <TableCell >Online Profile</TableCell>
                  <TableCell>
                    {JSON.stringify(authviewState.onlineProfile)}
                  </TableCell>
                </TableRow> */}
                  {/* <TableRow>
                  <TableCell >Presentation Readiness</TableCell>
                  <TableCell>{JSON.stringify(authviewState.presentationReadiness)}</TableCell>
                </TableRow> */}
                  {/* <TableRow>
                  <TableCell >Resume Match</TableCell>
                  <TableCell>
                    <p>AverageMonthsPerEmployerScore: {ResumeMatch.AverageMonthsPerEmployerScore}</p>
                    <p>JobTitleScore: {ResumeMatch.JobTitleScore}</p>
                    <p>TotalPoints: {ResumeMatch.TotalPoints}</p>
                    <p>educationScore: {ResumeMatch.educationScore}</p>
                    <p>resumeurl: {ResumeMatch.resumeurl}</p>
                    <p>skillsScore: {ResumeMatch.skillsScore}</p>
                    <p>updatedAt: {moment(ResumeMatch.updatedAt).format("DD-MM-YYYY")}</p>
                    {JSON.stringify(authviewState.resumeMatch)}
                  </TableCell>
                </TableRow> */}
                  {/* <TableRow>
                  <TableCell >Technical Expertise</TableCell>
                  <TableCell>{JSON.stringify(authviewState.technicalExpertise)}</TableCell>
                </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* <Grid container style={{ padding: '50px' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell >Values</TableCell>
                </TableRow>
              </TableHead>
              <TableRow>
                
                    <TableCell >OnlineProfile</TableCell>
                  <TableCell>
                    <p>AverageMonthsPerEmployerScore: {ResumeMatch.AverageMonthsPerEmployerScore}</p>
                    <p>JobTitleScore: {ResumeMatch.JobTitleScore}</p>
                    <p>TotalPoints: {ResumeMatch.TotalPoints}</p>
                    <p>educationScore: {ResumeMatch.educationScore}</p>
                    <p>resumeurl: {ResumeMatch.resumeurl}</p>
                    <p>skillsScore: {ResumeMatch.skillsScore}</p>
                    <p>updatedAt: {moment(ResumeMatch.updatedAt).format("DD-MM-YYYY")}</p>
                  </TableCell>
                  </TableRow>
              </Table>
              </TableContainer>
              </Paper>
              </Grid> */}
        <Grid container className={classes.viewDetails}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Resume Match</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.nameprofile}>Label</TableCell>
                    <TableCell className={classes.valueprofile}>Value</TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableRow>
                    <TableCell>
                    <p>AverageMonthsPerEmployerScore</p>
                    <p>JobTitleScore</p>
                    <p>MaxPoint</p>
                    <p>educationScore</p>
                    <p>Resumeurl</p>
                    <p>SkillsScore</p>
                    <p>PointsScoredbyCandidate</p>
                    <p>updatedAt</p>
                  </TableCell>
                  <TableCell>
                    <p>{ResumeMatch.averageMonthsPerEmployerScore}</p>
                    <p> {ResumeMatch.jobTitleScore}</p>
                    <p> {ResumeMatch.maxPoint}</p>
                    <p> {ResumeMatch.educationScore}</p>
                    <p> {ResumeMatch.resumeurl}</p>
                    <p> {ResumeMatch.skillsScore}</p>
                    <p>{ResumeMatch.pointsScoredbyCandidate}</p>
                    <p> {moment(ResumeMatch.updatedAt).format("DD-MM-YYYY")}</p>

                  </TableCell>
                  </TableRow> */}
                {keyResume.map((row) => (

                  <TableRow>
                    <TableCell >{row}</TableCell>
                    <TableCell>{ResumeMatch[row]}</TableCell>
                  </TableRow>

                ))}
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid container className={classes.viewDetails}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Online Profile</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.nameprofile}>Label</TableCell>
                    <TableCell className={classes.valueprofile}>Value</TableCell>
                  </TableRow>
                </TableHead>

                {/* <TableRow>
                
                    <TableCell >
                    <p>gitHub</p>
                    <p>linkedIn</p>
                    <p>maxPoint</p>
                    <p>medium</p>
                    <p>other</p>
                    <p>personalWebsite</p>
                    <p>pointsScoredbyCandidate</p>
                    <p>stackOverflow</p>
                    <p>youTube</p>
                    </TableCell>
                  <TableCell>
                    <p>{OnlineProfile.gitHub}</p>
                    <p>{OnlineProfile.linkedIn}</p>
                    <p> {OnlineProfile.maxPoint}</p>
                    <p> {OnlineProfile.medium}</p>
                    <p> {OnlineProfile.other}</p>
                    <p> {OnlineProfile.personalWebsite}</p>
                    <p> {OnlineProfile.pointsScoredbyCandidate}</p>
                    <p> {OnlineProfile.stackOverflow}</p>
                    <p> {OnlineProfile.youTube}</p>
                   
                  </TableCell>
                  </TableRow> */}
                {onlinekey.map((row) => (

                  <TableRow>
                    <TableCell >{row}</TableCell>
                    <TableCell>{OnlineProfile[row]}</TableCell>
                  </TableRow>

                ))}
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid container className={classes.viewDetails}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Coding Challenge</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.nameprofile}>Label</TableCell>
                    <TableCell className={classes.valueprofile}>Value</TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableRow>
             
                    <TableCell >
                    <p>pointsScoredbyCandidate</p>
                    <p>sessionStartDate</p>
                    </TableCell>
                  <TableCell>
                    <p>{initialEvaluation.pointsScoredbyCandidate}</p>
                    <p> {moment(initialEvaluation.sessionStartDate).format("DD-MM-YYYY")}</p>
                  </TableCell>
                  </TableRow> */}
              

{/* <TableCell >{row}</TableCell> */}
<TableRow> 
  <TableCell></TableCell>
<TableCell style={{whiteSpace: "pre", }}>

{codingkeys.map((row) => (

  <TableRow> 

  {JSON.stringify(codingchallenge[row],null, 4)}
  </TableRow>
   ))}
   
  
  </TableCell>
  
  </TableRow>

             
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid container className={classes.viewDetails}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Initial Evaluation</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.nameprofile}>Label</TableCell>
                    <TableCell className={classes.valueprofile}>Value</TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableRow>

                  <TableCell >
                    <p>pointsScoredbyCandidate</p>
                    <p>sessionStartDate</p>
                  </TableCell>
                  <TableCell>
                    <p>{initialEvaluation.pointsScoredbyCandidate}</p>
                    <p> {moment(initialEvaluation.sessionStartDate).format("DD-MM-YYYY")}</p>
                  </TableCell>
                </TableRow> */}

{keyinitial.map((row) => (

<TableRow>
  <TableCell >{row}</TableCell>
  <TableCell style={{whiteSpace: "pre", }}>{JSON.stringify(initialEvaluation[row],null, 4)}</TableCell>
</TableRow>

))}

              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid container className={classes.viewDetails}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Presentation Readiness</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.nameprofile}>Label</TableCell>
                    <TableCell className={classes.valueprofile}>Value</TableCell>
                  </TableRow>
                </TableHead>
            

{keypresentation.map((row) => (

<TableRow>
  <TableCell >{row}</TableCell>
  <TableCell style={{whiteSpace: "pre", }}>{JSON.stringify(presentationReadiness[row],null,4)}</TableCell>
</TableRow>

))}

              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid container className={classes.viewDetails}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Technical Expertise</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.nameprofile}>Name</TableCell>
                    <TableCell className={classes.valueprofile}>Value</TableCell>
                  </TableRow>
                </TableHead>
            

{keytechnical.map((row) => (

<TableRow>
  <TableCell >{row}</TableCell>
  <TableCell style={{whiteSpace: "pre", }}>{JSON.stringify(technicalExpertise[row],null,4)}</TableCell>
</TableRow>

))}

              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </div>
    </div>
  );
}
