import AppConfig from "../../constants/AppConfig";
import authTypes from "../types/auth";

export const initialState = {
  user: localStorage.getItem("user"),
  email: "",
 // password:"",
  firstName: "",
  lastName: "",
  fullName: "",
  photoUrl: "",
  userId: null,
  score: null, //[520] or [230,460] - can be a single score or a range
  level: null,
  levelStatus: "complete", //complete/incomplete/started
  error: "",
  isLoading: false,
  resumeUrl: "",
  resumeName: "",
  resumeSize: 0,
  resumeScore:0,
  resumeBlob: null,
  aoi: null,
  aos: null,
  recommendations: null,
  sampleJobs: null,
  content: null,
  codingTheme: {
    theme: "vs-dark",
    themeId: 1,
  },
  codingLang: {
    cid: 10,
    lang: "java",
    langId: 10,
  },
  user: null,
  userProfile: null,
  scoreHistory: null,
  timeExceedsClick: {
    value: "",
  },
  scoreHistory: [],
  authenticated:
    localStorage.getItem("user") && localStorage.user.length != 0
      ? true
      : false,
  firebaseUserDetails: null,
  resetPasswordDetails: null,
  firebaseLoginDetails: null,
  resumeUrlError: {},
  isContinueWithEmailSubmitted: "",
  userExists: false,
  deleteuser: [],
  apiError: "",
  isEditProfileQuiz: false,
  nativePhotourl: "",
  admindetails:[],
  adminviewdetails:[],
  adminviewemail:"",
  forgotpassword:'',
  verifyemail:'',
  resendverify:"",
  areaOfStrengthsAndImprovements:{}

};

function auth(state = initialState, action) {
  // console.log("@reducer auth", action.type);
  switch (action.type) {
    case authTypes.LOGIN_USER:
      return { ...state, isLoading: true };
    case authTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        photoUrl: action.payload.photoURL,
        authenticated:
          action.payload && action.payload.length != 0 ? true : false,
      };
    case authTypes.LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        password: "",
        error: action.payload,
      };
    case authTypes.UPDATE_LEVEL:
      return { ...state, isLoading: false, level: action.payload };
    case authTypes.CREATE_PROFILE:
      return {
        ...state,
        isLoading: true,
        // userProfile: action?.payload,
        // resumeUrl: action?.payload?.resumeurl || "",
        // resumeName: action?.payload?.resumeurl || "",
        // resumeSize: action?.payload?.resumeSize || "",
      };
    case authTypes.CREATE_PROFILE_ERROR:
      return { ...state, isLoading: false, userProfile: null };
    case authTypes.RESUME_UPLOAD_REQUESTED:
      return { ...state, isLoading: true, resumeRequest: action.payload };
    case authTypes.RESUME_UPLOAD:
      return {
        ...state,
        isLoading: false,
        resumeUrl: action.payload.location,
        resumeName: action.payload.originalname,
        resumeSize: action.payload.size,
        resumeScore:action.payload.score
      };
    case authTypes.DELETE_RESUME:
      return {
        ...state,
        resumeName: "",
      };
    case authTypes.RESUME_UPLOAD_FAILURE:
      return { ...state, isLoading: false, resumeUrlError: action.payload };
    case authTypes.PROFILE_IMAGE_UPLOAD:
      return { ...state, isLoading: false, photoUrl: action.payload.location, nativePhotourl : action.payload.location};
    case authTypes.PROFILE_IMAGE_UPLOAD_REQUESTED:
      return {
        ...state,
        isLoading: true,
        profileImageRequest: action.payload,
      };
    case authTypes.PROFILE_IMAGE_UPLOAD_FAILURE:
      return { ...state, isLoading: false, photoUrl: null };
    case authTypes.DOWNLOAD_RESUME:
      return {
        ...state,
        isLoading: true,
        downloadResumeRequest: action.payload,
      };
    case authTypes.RESUME_DOWNLOADED:
      return { ...state, isLoading: false, resumeBlob: action.payload };
    case authTypes.GET_AOSI:
      return { ...state, isLoading: true, aosiRequest: action.payload };
    case authTypes.GET_AOSI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        aos: action.payload["strengths"],
        aoi: action.payload["improvements"],
        areaOfStrengthsAndImprovements: action?.payload ,
      };
    case authTypes.GET_RECOMMENDATIONS:
      return {
        ...state,
        isLoading: true,
        recommendationsRequest: action.payload,
      };
    case authTypes.GET_RECOMMENDATIONS_SUCCESS:
      return { ...state, isLoading: false, recommendations: action.payload };
    case authTypes.GET_SAMPLEJOBS:
      return { ...state, isLoading: true, jobsRequest: action.payload };
    case authTypes.GET_SAMPLEJOBS_SUCCESS:
      return { ...state, isLoading: false, sampleJobs: action.payload };
    case authTypes.GET_CONTENT:
      return { ...state, isLoading: true, contentRequest: action.payload };
    case authTypes.GET_CONTENT_SUCCESS:
      return { ...state, isLoading: false, content: action.payload };
    case authTypes.SET_CODING_THEME:
      return { ...state, codingTheme: action.payload };
    case authTypes.SET_CODING_LANG:
      return { ...state, codingLang: action.payload };
    case authTypes.GET_PROFILE:
      return { ...state, isLoading: true, email: action.payload };
    case authTypes.GOT_PROFILE:
      return { ...state, isLoading: false, userProfile: action.payload };
    case authTypes.GET_SCORE_HISTORY:
      return { ...state, isLoading: true, email: action.payload };
    case authTypes.SCORE_HISTORY_SUCCESS:
      return { ...state, isLoading: false, scoreHistory: action.payload };
    case authTypes.TIMEOUT_DIALOG_SUBMIT:
      return { ...state, isLoading: false, timeExceedsClick: action.payload };
    case authTypes.TIMEOUT_DIALOG_EXIT:
      return { ...state, isLoading: false, timeExceedsClick: action.payload };
    case authTypes.SET_AUTH_STATE:
      return { ...state, authenticated: action.payload };

    case authTypes.CREATE_USER_WITH_FIREBASE_REQUESTED:
      return { ...state, isLoading:true, userRequest: action.payload };
    case authTypes.CREATE_USER_WITH_FIREBASE_ERROR:
      return { ...state, isLoading:false, firebaseUserDetails: action.payload };
    case authTypes.CREATE_USER_WITH_FIREBASE_SUCCESS:
      return { ...state, isLoading:false, firebaseUserDetails: action.payload };

    case authTypes.RESET_PASSWORD_REQUESTED:
      return { ...state, resetPasswordRequest: action.payload };

    case authTypes.RESET_PASSWORD:
      return { ...state, resetPasswordDetails: action.payload };

    case authTypes.RESET_PASSWORD_ERROR:
      return { ...state, resetPasswordDetails: action.payload };

    case authTypes.FIREBASE_USER_LOGIN_REQUESTED:
      return { ...state, isLoading:true, loginRequest: action.payload };

    case authTypes.FIREBASE_USER_LOGIN:
      return { ...state, isLoading:false, firebaseLoginDetails: action.payload };

    case authTypes.FIREBASE_USER_LOGIN_ERROR:
      return { ...state, isLoading:false, firebaseLoginDetails: action.payload };

    case authTypes.DELETE_RESUME_REQUESTED:
      return { ...state, isLoading:true, deleteRequest: action.payload };

    case authTypes.CONTINUE_WITH_EMAIL_SUBMITTED:
      return { ...state, isContinueWithEmailSubmitted: action.payload };
    case authTypes.DELETE_RESUME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resumeUrl: "",
        resumeName: "",
        resumeSize: "",
        resumeScore:10,
      };

    case authTypes.INVITE_FRIEND:
      return {
        ...state,
        isLoading:true,
      };
    case authTypes.INVITE_FRIEND_SUCCESS:
      return {
        ...state,
        isLoading:false,
      }

    case authTypes.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    case authTypes.User_Meta_Data:
      return {
        ...state,
        userMetaData: action.payload || "",
      };
    case authTypes.DELETE_USER_META_DATA:
      return {
        ...state,
      };
    case authTypes.POST_USER_META_DATA:
      return {
        ...state,
      };
    case authTypes.DELETE_USER:
      return {
        ...state,
      };
    case authTypes.API_ERROR:
      return {
        ...state,
        isLoading: false,
        apiError: action.payload,
      };
    case authTypes.DELETE_USER_META_DATA_REQ:
      return {
        ...state,
      };
    case authTypes.POST_USER_META_DATA_REQ:
      return {
        ...state,
      };
    case authTypes.DELETE_USER_REQ:
      return {
        ...state,
      };
    case authTypes.USER_EXISTS:
      return {
        ...state,
        userExists: action.payload,
      };
    case authTypes.DELETE_PROFILE_USER:
      return {
        ...state,
        deleteuser: action.payload,
        isLoading:true,
      };
    case authTypes.DELETE_PROFILE_USER_SUCCESS:
      return {
        ...state,
        deleteuser: action.payload,
        isLoading:false,
      };
    case authTypes.DELETE_PROFILE_USER_ERROR:
      return {
        ...state,
        deleteuser: action.payload,
        isLoading:false
      };
    case authTypes.IS_EDIT_PROFILE_QUIZ:
      return {
        ...state,
        isEditProfileQuiz: action.payload,
      };
    
    case authTypes.ADMIN_DETAILS:
      return {
        ...state,
        admindetails: action.payload,
        isLoading:true
      };
    
    case authTypes.ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading:false,
        admindetails: action.payload,
      };
    
    case authTypes.ADMIN_VIEW_DETAILS:
      return {
        ...state,
        isLoading:true,
        adminviewdetails: action.payload

      };
      
    case authTypes.ADMIN_VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading:false,
        adminviewdetails:action.payload
      }  
    
      case authTypes.ADMIN_VIEW_EMAIL:
        return {
          ...state,
          adminviewemail:action.payload
        }

      case authTypes.VERIFY_EMAIL:{
        return {
          ...state,
          verifyemail:action.payload,
          isLoading:true
        }
      }

      case authTypes.VERIFY_EMAIL_REQUEST:{
        return {
          ...state,
          isLoading:false,
          verifyemail: action.payload
        }
      }
      
      case authTypes.FORGOT_PASSWORD:
        return {
          ...state,
          isLoading:true,
          forgotpassword:action.payload
        }
      
      case authTypes.RESEND_VERIFICATION:
        return {
          ...state,
          isLoading:true,
          resendverify:action.payload
        }  
      
      case authTypes.RESEND_VERIFICATION_REQUEST:
        return {
          ...state,
          isLoading:false,
          resendverify:action.payload
        }
      
    default:
      return { ...state };
  }
}

export default auth;
