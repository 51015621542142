import { fork, call, put, takeLatest, join, cancel } from "redux-saga/effects";
import authTypes from "../types/auth";
import auth from "../actions/auth";
import axios from "../../services/axios";
import AppConfig from "../../constants/AppConfig";
import { firebaseAuthApp } from "../../firebase";

function* createProfile({ payload, userMetaData }) {
  try{
    let metaData = userMetaData;
    const res = yield call(axios.request, {
      method: "POST",
      url: "/user",
      data: payload,
    });
  
    if (res.status == 0) {
      if (payload && payload.email) {
        const profileRes = yield call(axios.request, {
          method: "GET",
          url: `user?email=${payload.email}`,
        });
        yield put(auth.setProfile(profileRes.data[0]));
      }
    } else {
      yield put(auth.createProfileError(res.data));
    }
    if (res.status == 0 && metaData) {
      yield put(auth.postUserMetaDataReq(userMetaData));
    }
  }
  catch(err){
    yield put(auth.apiError(err));
  }

}

function* resumeUpload({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "POST",
    url: `resume/upload?email=${payload.email}`,
    data: payload.data,
  });
  if (res && res.data) {
    yield put(auth.resumeUpload(res.data));
  } else {
    yield put(auth.resumeUploadError(res));
  }
}
  catch(err){
    yield put(auth.apiError(err));
  }
}

function* profileImageUpload({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "POST",
    url: `resume/uploadPhoto?email=${payload.email}`,
    data: payload.data,
  });
if(res && res.data){
  yield put(auth.profileImageUpload(res.data));

}
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* downloadResume({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "GET",
    url: `resume/downloadResume?email=${payload.email}`,
  });

  yield put(auth.resumeDownloaded(res));
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* getAoSI({ payload }) {
  try{
  if (payload && payload.email) {
    const res = yield call(axios.request, {
      method: "GET",
      url: `aiml/areaOfStrengthsAndImprovements?email=${payload.email}`,
    });
    yield put(auth.updateAoSI(res.data));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* getRecommendations({ payload }) {
  try{
  if (payload && payload.email) {
    const res = yield call(axios.request, {
      method: "GET",
      url: `aiml/recommandations?email=${payload.email}`,
    });
    yield put(auth.updateRecommendations(res.data["recommandations"]));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* getSampleJobs({ payload }) {
  try{
  if (payload && payload.email) {
    const res = yield call(axios.request, {
      method: "GET",
      url: `aiml/sampleJobs?email=${payload.email}`,
    });
    yield put(auth.updateSampleJobs(res.data["sampleJob"]));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* getContent({ payload }) {
  try{
  if (payload && payload.email) {
    const res = yield call(axios.request, {
      method: "GET",
      url: `aiml/content?email=${payload.email}`,
    });
    yield put(auth.updateContent(res.data));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* getProfile({ payload }) {
  try{
  yield put(auth.conitinueWithEmailSubmitted(false));
  if (payload && payload.email) {
    const res = yield call(axios.request, {
      method: "GET",
      url: `user?email=${payload.email}`,
    });
    yield put(auth.setProfile(res.data[0]));
    if (res.status === 1) {
      yield put(auth.conitinueWithEmailSubmitted(true));
    } else {
      yield put(auth.conitinueWithEmailSubmitted(false));
    }
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* getScoreHistory({ payload }) {
  try{
  if (payload && payload.email) {
    const res = yield call(axios.request, {
      method: "GET",
      url: `auditTrail?email=${payload.email}`,
    });
    yield put(auth.updateScoreHistory(res.data));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* getUserMetaData({ payload }) {
  try{
  if (payload && payload.email) {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/userMetaData?email=${payload.email}`,
    });
    yield put(auth.userMetaData(res.data));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}
function* createUserWithFirebaseRequested(payload) {
  try {
    const result = yield call(
      [firebaseAuthApp, firebaseAuthApp.createUserWithEmailAndPassword],
      payload.userRequest.email,
      payload.userRequest.password
    );
    yield put(auth.createUserWithFirebaseSuccess(result));
  } catch (error) {
    yield put(auth.createUserWithFirebaseError({ error: error.message }));
  }
}

function* resetPasswordRequested(payload) {
  try {
    const result = yield call(
      firebaseAuthApp.sendPasswordResetEmail(payload.resetPasswordRequest.email)
    );
    yield put(auth.resetPassword(result));
  } catch (error) {
    yield put(auth.resetPasswordError({ error: error.message }));
  }
}

function* firebaseUserLoginRequested(payload) {
  try {
    const result = yield call(
      [firebaseAuthApp, firebaseAuthApp.signInWithEmailAndPassword],
      payload.loginRequest.email,
      payload.loginRequest.password
    );
    yield put(auth.firebaseUserLogin(result));
  } catch (error) {
    yield put(auth.firebaseUserLoginError({ error: error.message }));
  }
}

function* deleteResumeRequested({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "DELETE",
    url: `resume?email=${payload.email}`,
    data: payload.data,
  });
  yield put(auth.deleteResumeSuccess(res.data));
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* deleteUserMetaDataReq({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "DELETE",
    url: `/userMetaData?email=${payload.email}`,
  });

  yield put(auth.deleteUserMetaData(res.data));
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* postUserMetaDataReq({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "POST",
    url: `/userMetaData`,
    data: payload,
  });

  yield put(auth.postUserMetaData(res.data));
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* inviteFriend({ payload }) {
  try{
  if (
    payload.email &&
    payload.subject &&
    payload.recipients &&
    payload.message
  ) {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/utility/sendInvite`,
      data: payload,
    });
    yield put(auth.invitefriendSuccess(res.data));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* deleteUserReq({ payload }) {
  try{
  let reqPayload = payload;
  let userMetaData =
    (reqPayload?.createMetaData == true && reqPayload.userMetaData) || null;

  const res = yield call(axios.request, {
    method: "DELETE",
    url: `/user?email=${payload.email}`,
  });
  if (res.status == 0 && reqPayload?.createUser == true) {
    yield put(auth.createProfile(reqPayload.userPayload, userMetaData));
  } else {
    yield put(auth.deleteUser(res.data));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* deleteProfileUser({ payload }) {
  try{
  const res = yield call(axios.request, {
    method: "DELETE",
    url: `/deleteUser?email=${payload.email}&uid=${payload.uid}`,
  });
  if (res && res.data) {
    yield put(auth.deleteUserProfileSuccess(res));
  } else {
    yield put(auth.deleteUserProfileError(res.data));
  }
}
catch(err){
  yield put(auth.apiError(err));
}
}

function* adminDetails({payload}){
  try{
    const res = yield call(axios.request, {
      method:"GET",
      url:`/report/admin`
    });
    yield put(auth.adminDetailsRequest(res.data));
    console.log("report state",res)
  }
  catch(err){
    yield put(auth.apiError(err));
  }
}

function* adminViewDetails({payload}) {
  try {
    const res = yield call(axios.request, {
      method:"GET",
      url:`/report/candidate?email=${payload}`
    })
    if(res && res.data){
    yield put(auth.adminViewDetailRequest(res.data))
    }
  }
  catch(err){
    yield put(auth.apiError(err));
  }
}

function* verifyEmail({payload}){
  try {
    const res = yield call(axios.request, {
      method:"POST",
      url:`/user/verifyEmail`,
      data: payload
    })
    if(res && res.status == 0){
      yield put(auth.verifyEmailRequest(res))
  }
  }
  catch(err){
    yield put(auth.apiError(err))
  }
}

function* forgotPassword({payload}){
  try {
    const res = yield call(axios.request, {
      method:"POST",
      url:`user/forgotPassword`,
      data:payload
    })
    if(res && res.data){
      yield put(auth.forgotPasswordRequest(res.data))
    }
  }
  catch(err){
    yield put(auth.apiError(err))
  }
}

function* updatePassword({payload}){
  try {
    const res = yield call(axios.request, {
      method:"POST",
      url:`/user/updatePassword`,
      data:payload

    })
    if(res && res.data){
      yield put(auth.updatePasswordRequest(res.data))
    }
  }
  catch(err){
    yield put(auth.apiError(err))
  }
}

function* resendVerification({payload}) {
  try {
   
    const res = yield call(axios.request, {
      method:"POST",
      url:`/user/resendVerification`,
      data:payload
    })
    if(res && res.status == 0){
      yield put(auth.resendVerificationRequest(res))
    }
    }
  
  catch(err){
    yield put(auth.apiError(err))
  }
}

function* authSaga() {
  yield takeLatest(authTypes.CREATE_PROFILE, createProfile);
  yield takeLatest(authTypes.RESUME_UPLOAD_REQUESTED, resumeUpload);
  yield takeLatest(authTypes.DOWNLOAD_RESUME, downloadResume);
  yield takeLatest(
    authTypes.PROFILE_IMAGE_UPLOAD_REQUESTED,
    profileImageUpload
  );
  yield takeLatest(authTypes.GET_AOSI, getAoSI);
  yield takeLatest(authTypes.GET_RECOMMENDATIONS, getRecommendations);
  yield takeLatest(authTypes.GET_SAMPLEJOBS, getSampleJobs);
  yield takeLatest(authTypes.GET_CONTENT, getContent);
  yield takeLatest(authTypes.GET_PROFILE, getProfile);
  yield takeLatest(authTypes.GET_SCORE_HISTORY, getScoreHistory);
  yield takeLatest(authTypes.User_Meta_Data_REQ, getUserMetaData);
  yield takeLatest(authTypes.RESET_PASSWORD_REQUESTED, resetPasswordRequested);
  yield takeLatest(
    authTypes.CREATE_USER_WITH_FIREBASE_REQUESTED,
    createUserWithFirebaseRequested
  );
  yield takeLatest(
    authTypes.FIREBASE_USER_LOGIN_REQUESTED,
    firebaseUserLoginRequested
  );
  yield takeLatest(authTypes.DELETE_RESUME_REQUESTED, deleteResumeRequested);
  yield takeLatest(authTypes.USER_META_DATA_REQ, getUserMetaData);
  yield takeLatest(authTypes.DELETE_USER_META_DATA_REQ, deleteUserMetaDataReq);
  yield takeLatest(authTypes.DELETE_USER_REQ, deleteUserReq);
  yield takeLatest(authTypes.POST_USER_META_DATA_REQ, postUserMetaDataReq);
  yield takeLatest(authTypes.INVITE_FRIEND, inviteFriend);
  yield takeLatest(authTypes.DELETE_PROFILE_USER, deleteProfileUser);
  yield takeLatest(authTypes.ADMIN_DETAILS,adminDetails);
  yield takeLatest(authTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
  yield takeLatest(authTypes.VERIFY_EMAIL,verifyEmail);
  yield takeLatest(authTypes.FORGOT_PASSWORD,forgotPassword);
  yield takeLatest(authTypes.UPDATE_PASSWORD,updatePassword);
  yield takeLatest(authTypes.RESEND_VERIFICATION,resendVerification)
}

export { authSaga };
