import React, { useState } from "react";
import Button from "./controls/Button";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Text } from "../styles/theme";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  next: {
    "& button": {
      height: "40px",
    },
  },
}));

const Dialog = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: "24px !important",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: "24px",
  },
}))(MuiDialogActions);

export default function SubmitConfirmationDialog(props) {
  const { open, text, succesBtnLabel, actionInfo, ...other } = props;
  const classes = useStyles();

  const onClickSubmit = (event) => {
    props?.onSubmit(actionInfo);
  };

  const closeModel = () => {
    if (navigator.onLine) {
      props.onClose()
    }
    else {
      props.TimeClose()
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        // onClose={props.onClose}
        onBackdropClick="false"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: `${props?.width || "343px"}`,
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Typography
            component={Text}
            variant2="b1Regular"
            className=""
            align="center"
          >
            {text}
          </Typography>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            color="btnSecondary"
            text="Cancel"
            width="136px"
            onClick={closeModel}
            data-rt-confirmation-cancelalert="cancelalert"
          ></Button>
          <Grid className={classes.next}>
            <Button
              color="btnPrimary"
              text={succesBtnLabel || "Yes, Submit"}
              width={`${props?.yesBtnWidth || "136px"}`}
              onClick={(e) => onClickSubmit(e)}
              data-rt-confirmation-submitalert="submitalert"
            ></Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
