import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Button from "../components/controls/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../components/controls/TextInput";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Text } from "../styles/theme";

import arrowImg from "../assets/Arrow 10-3.png";
import orImg from "../assets/Group 145.svg";
import googleIcon from "../assets/google_search_icon.png";

import { useSelector, useDispatch } from "react-redux";
import auth from "../redux/actions/auth";
import quiz from "../redux/actions/quiz";
import AppConfig from "../constants/AppConfig";

import logo from "../assets/logo.svg";

import firebase from "@firebase/app";
import "@firebase/auth";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  removeAnswers,
  selectedQuesNdAns,
  ImagebaseUrl,
} from "../services/globalService";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ButtonMaterial from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    paddingRight: 16,
    paddingLeft: 16,
    [theme.breakpoints.up("tablet")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  applyPadding: {
    padding: "16px",
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
    marginTop: 100,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "24px",
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginLeft: 8,
  },
  cardTwoH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  inputCt: {
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.up("tablet")]: {
      // width: 460,
    },
  },
  textInput: {
    marginTop: 2,
    minHeight: 46,
    backgroundColor: theme.palette.background.level2,
  },
  whatsNextLink: {
    // fontSize: 24,
    // lineHeight: "24px",
    // fontFamily: "Rational",
    // fontStyle: "normal",
    // marginBottom: 12
  },
  arrowImg: {
    marginLeft: 8,
  },
  googleIcon: {
    // position: "absolute",
    height: 30,
    width: 30,
    marginRight: 12,
  },
  orImg: {
    marginTop: 32,
    marginBottom: 12,
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "auto",
      marginTop: 32,
      marginBottom: 12,
    },
  },
  createAccountDescriptionArea: {
    // width: "424px",
    //  fontSize: "16px",
    //lineHeight: "24px",
    marginTop: "30px",
  },
  field: {
    backgroundColor: theme.palette.background.light,
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  openingScreen: {
    width: "100%",
  },
  textGrid: {
    // position:"fixed",
    // bottom:"10px"
    marginTop: "40px",
    paddingBottom: "20px",
  },
  accountText: {
    color: "#787878",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
}));

const initialValues = {
  email: "",
};

function CreateAccount(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const quizState = useSelector((state) => state.quiz);
  const authState = useSelector((state) => state.auth);

  const [isSubmitted, setSubmitted] = useState(false);
  const [isShowSnackBar, setShowSnackBar] = useState(false);
  const [isGoogleSignIn, setGOogleSignIn] = useState(false);
  const [doesUserExists, setDoesUserExists] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const errorMsg = ``;
  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(quiz.setSelectedQuesNdAns([]));
      removeAnswers();
    };
  }, []);

  const onClickSignUp = () => {};

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required!"),
  });

  const onSubmit = (values) => {
    if (navigator.onLine) {
      setDoesUserExists(false);
      dispatch(auth.getProfile({ email: formik.values.email }));
      values.level = location.level;
      setSubmitted(true);
    } else {
      setShowSnackBar(true);
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (isSubmitted && !authState.userProfile) {
      history.push({
        pathname: "/CreateAccountWithEmail",
        state: formik.values,
      });
    } else if (isSubmitted && authState.userProfile) {
      dispatch(auth.setProfile(null));
      history.push({
        pathname: "/LoginWithEmail",
        state: formik.values,
      });
    }
  }, [authState.userProfile, authState.isContinueWithEmailSubmitted]);

  const signinUserWithGoogle = () => {
    if (navigator.onLine) {
      setGOogleSignIn(true);
      dispatch(auth.beginLoginUser());
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          localStorage.setItem("user_id", "user-id");
          localStorage.setItem("user", JSON.stringify(result.user));
          dispatch(auth.loginSuccess(result.user));
          dispatch(auth.firebaseUserLogin(result));

          setLoggedInUser(result.user);
          setDoesUserExists(false);
          dispatch(auth.getProfile({ email: result.user?.email }));
          // createProfile(result.user);
        })
        .catch(function (error) {
          dispatch(auth.loginError(error));
        });
    } else {
      setShowSnackBar(true);
    }
  };

  useEffect(() => {
    if (!doesUserExists && !authState.userProfile) {
      createProfile(loggedInUser);
      setDoesUserExists(true);
    } else if (!doesUserExists && authState.userProfile) {
      
      setDoesUserExists(true);
      dispatch(auth.updateUserExists(true));
     // dispatch(auth.createProfile({email: authState.userProfile.email, lastLoginAt: loggedInUser?.metadata.lastSignInTime}))
    }
    else if(!doesUserExists){
      dispatch(auth.createProfile({email: authState.userProfile.email, lastLoginAt: loggedInUser?.metadata.lastSignInTime}))
    }
  }, [authState.userProfile]);

  useEffect(() => {
    if (doesUserExists && authState.userProfile) {
      history.push("/Dashboard");
    }
  }, [doesUserExists, authState.userProfile]);

  const createProfile = (user) => {
    if (!user) {
      return;
    }

    let name = user.displayName.split(" ");
    const firstName = name[0];
    const lastName = name[1];
    let payload = {
      uid: user?.uid,
      email: user?.email,
      firstName: firstName,
      lastName: lastName,
      displayName: user?.displayName || firstName + " " + lastName,
      emailVerified: user?.emailVerified,
      AuthMethod: "Google",
      profileId: 1,
      photoUrl: user?.photoURL,
      phonenumber: user?.phoneNumber,
      createdAt: user?.metadata?.creationTime,
      lastLoginAt: user?.metadata?.lastSignInTime,
      user_level: authState?.level,
      targetScore: quizState?.testResult?.targetScore,
      targetRole: quizState?.testResult?.role,
      targetCompany: quizState?.testResult?.targetCompany,
      primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
      scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
      scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
      skills: quizState?.testResult?.skills,
    };

    let userMetaData = {
      email: user?.email,
      code: `${AppConfig?.l0QuestionCode}`,
      result: quizState?.userMetaDataReq,
    };
    dispatch(auth.createProfile(payload, userMetaData));
    // dispatch(quiz.resetTestResult());
  };

  const NavigateRoot = () => {
    if (quizState?.testResult == null) {
      history.push("/OpeningScreen");
    } else {
      history.push("/L1OpeningScreen");
    }
  };
  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img
          alt="logo"
          src={`${ImagebaseUrl}assets/logo.svg`}
          className={classes.logoCls}
        />
      </div>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}

        <Grid item xs={isNotMobile ? 4 : 12}>
          <div className={`${classes.section} ${classes.cardTwo}`}>
            <Typography
              component={Text}
              variant2={isNotMobile ? "h3" : "h4"}
              align="center"
              className={`${classes.cardTwoH1} ${classes.title}`}
            >
              Create an Account
            </Typography>
          </div>

          <div
            className={`${classes.section}  ${classes.card} ${classes.center}`}
          >
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
            >
              Create your profile to save your score, track progress, and get
              access to training materials, workshops, and our community of
              professionals.
            </Typography>
          </div>

          <div className={`${classes.section} ${classes.center}`}>
            {/* <div className={classes.textInput}> */}
            <Button
              startIcon={
                <Avatar
                  className={`${classes.googleIcon}`}
                  src={`${ImagebaseUrl}assets/google_search_icon.png`}
                />
              }
              color="googleButton"
              width="100%"
              text="Continue with Google"
              onClick={signinUserWithGoogle}
              data-rt-createaccount-googlesignup="googlesignup"
            ></Button>

            {/* </div> */}
          </div>

          <div
            className={`${classes.section} ${classes.center} ${classes.orImg}`}
          >
            <img
              className={`${classes.openingScreen}`}
              alt="OR"
              src={`${ImagebaseUrl}assets/Group 145.svg`}
            />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <Container className={classes.inputCt}>
              <div className={classes.textInput}>
                <TextInput
                  name="email"
                  placeholder="Enter Email*"
                  className={classes.field}
                  id="email"
                  onChange={formik.handleChange}
                  //error={Boolean(formik.errors.email)}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                ></TextInput>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.email}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </Container>
            <div
              className={`${classes.section} ${classes.center} ${classes.cardTwo} ${classes.submit}`}
            >
              <Button
                disabled={!(formik.isValid && formik.dirty)}
                color="btnPrimary"
                width="100%"
                text="Continue with Email"
                type="submit"
                onClick={() => onClickSignUp()}
                data-rt-createaccount-continueEmail="continueEmail"
              ></Button>
            </div>
          </form>
          <div
            className={`${classes.section} ${classes.center}  ${classes.cardTwo}`}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              //className={classes.textGrid}
            >
              <Typography
                component={Text}
                // variant2="b1Medium"
                // align="left"
                className={classes.accountText}
              >
                Already have an account?
              </Typography>
              <Link
                onClick={() => history.push("/LoginWithEmail")}
                data-rt-createaccount-loginwithemail="loginwithemail"
              >
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "link1" : "link2"}
                  // align="left"
                  className={`${classes.labelBlue}`}
                >
                  Log In
                </Typography>
              </Link>
            </Grid>
          </div>
          <div
            className={`${classes.section} ${classes.center}  ${classes.cardTwo}`}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.textGrid}
            >
              {quizState.level == AppConfig.LEVEL0 &&
                authState.levelStatus == "complete" && (
                  <Link
                    onClick={NavigateRoot}
                    data-rt-createaccount-continueaccount="continueaccount"
                  >
                    <Typography
                      component={Text}
                      variant2="link1"
                      className={classes.whatsNextLink}
                      align="center"
                    >
                      Continue to next steps without an account
                      <span className={classes.arrowImg}>
                        <img src={`${ImagebaseUrl}assets/Arrow 10-3.png`} />
                      </span>
                    </Typography>
                  </Link>
                )}
              {!authState.user && quizState.level == AppConfig.LEVEL1 && (
                <Link
                  onClick={() => history.push("/")}
                  data-rt-createaccount-exitaccount="exitaccount"
                >
                  <Typography
                    component={Text}
                    variant2="link1"
                    align="center"
                    className={`${classes.labelBlue}`}
                  >
                    Exit without an account
                  </Typography>
                </Link>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid>{isNotMobile && <Grid item xs={4}></Grid>}</Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <ButtonMaterial
              color="secondary"
              size="small"
              onClick={handleCloseSnackbar}
            ></ButtonMaterial>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default CreateAccount;
