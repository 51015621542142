import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Link from "@material-ui/core/Link";
import { Text } from "../../styles/theme";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LineChart from "../GraphScoreMeter/LineChart";

const useStyles = makeStyles((theme) => ({
  historyTitle: {
    marginBottom: 16,
    [theme.breakpoints.up("tablet")]: {
      marginBottom: 24,
    },
  },
  title: {
    flexGrow: 1,
  },
  factors: {
    marginTop: 0,
    [theme.breakpoints.up("tablet")]: {
      marginTop: 24,
    },
  },
  factorsLink: {
    fontSize: 14,
    lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  grayText: {
    color: "#787878",
    // fontSize: 14,
    // lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      // fontSize: 16,
      // lineHeight: "24px",
    },
  },
  event: {
    marginBottom: 24,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
}));

function PersonalHistory(props) {
  const { data } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  return (
    <div className={classes.factors}>
      <Card
        className=""
        variant={isNotMobile ? "outlined" : "none"}
        elevation={isNotMobile ? 0 : 0}
      >
        <CardContent className={classes.content}>
          <Typography
            component={Text}
            variant2="h4"
            className={`${classes.title} ${classes.historyTitle}`}
            align="left"
          >
            Latest Progress
          </Typography>
           {data && (             
             <LineChart data = {data}/>
            )}
          {data.length ?
            data.map((rec, i) => (
              
              <div className={classes.event} key={rec._id}>
                <Typography
                  component={Text}
                  variant2="b2Medium"
                  className={classes.grayText}
                  align="left"
                >
                  {rec.deltaScore < 0 && (
                    <div>
                      {rec.event_name} 
                      <div>
                      Total Score: {rec.totalScore}{" "}
                      <span
                      style={{
                        color: theme.palette.notifications.lightRed
                      }}
                      >{`(${rec.deltaScore})`}</span>
                      </div>
                    </div>
                  )}
                  {rec.deltaScore == 0 && (
                    <div>
                      {rec.event_name} 
                      <div>
                       Total Score: {rec.totalScore}{" "} 
                      <span
                      style={{
                        color: theme.palette.neutrals.grayDark
                      }}
                      >{`(Unchanged)`}</span>
                    </div>
                    </div>
                  )}
                  {rec.deltaScore > 0 && (
                    <div>
                      {rec.event_name} 
                      <div>
                      Total Score: {rec.totalScore}{" "} 
                      <span
                      style={{
                        color: theme.palette.notifications.greenSuccess
                      }}
                      >{`(+${rec.deltaScore})`}</span>
                    </div>
                    </div>
                  )}
                </Typography>
                <Typography
                  component={Text}
                  variant2="b2Regular"
                  className={classes.grayText}
                  align="left"
                >
                  {moment(rec.event_timestamp).format("MMMM D, YYYY") +
                    " at " +
                    moment(rec.event_timestamp).format("h:mm a z")}
                </Typography>
              </div>
            )) 
            : null}

          {/* <Link href="#">
            <Typography
              component={Text}
              variant2="link1"
              className={classes.factorsLink}
            >
              View All History
            </Typography>
          </Link> */}
        </CardContent>
      </Card>
    </div>
  );
}
export default PersonalHistory;
