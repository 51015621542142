import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Button from "../components/controls/Button";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../components/controls/TextInput";
import { Container } from "@material-ui/core";
import Checkbox from "../components/controls/Checkbox";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Text } from "../styles/theme";
import backArrowImg from "../assets/Arrow 10-2.png";
import successcheck from "../assets/successcheck.svg";
import logo from "../assets/logo.svg";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ImagebaseUrl } from "../services/globalService";

import auth from "../redux/actions/auth";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
    marginTop: "20px",
    [theme.breakpoints.up("tablet")]: {
      marginTop: "40px",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
  },
  cardSix: {
    marginTop: "80px",
    width: "100%",
    justifyContent: "space-between",
    // paddingBottom: "126px",
    // marginLeft: "16px",
    // marginRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      // width: 458,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "200px",
    },

    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    cursor: "pointer",
  },
  cardTwoH1: {
    [theme.breakpoints.up("tablet")]: {},
  },
  cardFourH1: {
    [theme.breakpoints.up("tablet")]: {},
  },

  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formControl: {
    marginTop: 24,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
  },
  telField: {
    minHeight: 48,
    marginTop: 8,
    margin: "8px 0px 0px 0px",
    backgroundColor: theme.palette.background.light,
  },
  phonewithCode: {
    display: "flex",
    alignItems: "flex-end",
  },
  phoneNo: {
    width: "100%",
  },
  countryCode: {
    backgroundColor: theme.palette.background.level2,
  },
  submit: {
    marginTop: "34px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("tablet")]: {
      // width: 500,
    },
    "& button": {
      [theme.breakpoints.up("tablet")]: {
        marginLeft: "0",
        marginRight: "0",
      },
    },
  },
  arrowImg: {
    marginLeft: 8,
  },
  backArrowImg: {
    marginRight: 8,
  },
  phoneErr: {
    marginLeft: "23%",
    marginTop: "2%",
  },
  createAccountDescriptionArea: {
    fontSize: "14px",
    lineHeight: "24px",
    marginTop: "30px",
    // paddingRight:'23px',
    // paddingLeft:'23px',
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
      // paddingLeft:'12px',
      // paddingRight:"12px",
      // paddingLeft:'64px',
      // paddingRight:"64px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  font15: {
    fontSize: "12px",
  },
  policycolor: {
    color: theme.palette.btnPrimary.main,
  },
  contentArea: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  passwordText: {
    display: "flex",
    justifyContent: "space-between",
  },
  charvalue: {
    color: "#787878",
  },
  accountText: {
    color: "#787878",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  TechScore: {
    fontFamily: "Frank Ruhl Libre",
    fontStyle: " normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
  },
  imgStyle: {
    textAlign: "center",
    marginTop: "80px",
    [theme.breakpoints.up("tablet")]: {
      marginTop: "40px",
    },
  },
}));

function CreateVerifyScreen(props) {
  const { location } = props;

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const authState = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState(false);

  const onSubmit = async (values) => {
    values.level = location.level;
    console.log("Form data", values);
    if (location && location.pathname == "/CreateEmailSuccess") {
      history.push({
        pathname: "/CreateAccountWithEmail",
        //state: values,
      });
    }
    if (location && location.pathname == "/CreateverifySuccess") {
      if (location.state) {
         //dispatch(auth.firebaseUserLoginRequested(location.state));
      }
    }
  };

  useEffect(() => {
    if (authState.firebaseLoginDetails && authState.firebaseLoginDetails.user) {
      const { user } = authState.firebaseLoginDetails;
      let payload = {
        uid: user?.uid,
        email: user?.email,
        firstName: authState.userProfile.firstName,
        lastName: authState.userProfile.lastName,
        displayName: user?.displayName || (authState.userProfile.firstName + ' ' + authState.userProfile.lastName),
        emailVerified: user?.emailVerified,
        AuthMethod: "Native",
        profileId: 1,
        photoUrl: user?.photoURL,
        phonenumber: user?.phoneNumber,
        createdAt: user?.metadata?.creationTime,
        lastLoginAt: user?.metadata?.lastSignInTime,
        user_level: authState?.level,
        targetScore: quizState?.testResult?.targetScore,
        targetRole: quizState?.testResult?.role,
        targetCompany: quizState?.testResult?.targetCompany,
        primaryCodingLanguage: quizState?.testResult?.primaryCodingLanguage,
        scoreLowerBound: quizState?.testResult?.estimatedScoreLowerBound,
        scoreUpperBound: quizState?.testResult?.estimatedScoreUpperBound,
        skills: quizState?.testResult?.skills,
      };
      dispatch(auth.createProfile(payload));
    }
  }, [authState.firebaseLoginDetails]);

  const onGotoDashboard = () => {
    if (authState.userProfile) {
      dispatch(auth.firebaseUserLoginRequested(location.state));
    } else {
      setOpenSnackBar(true);
      setMessage("User profile not available");
    }
  };

  useEffect(() => {
    if (authState.firebaseLoginDetails && authState.firebaseLoginDetails.user) {
      history.push("/Dashboard");
    }
  }, [authState.userProfile]);

  const onResendClick = () => {
    // if (authState.firebaseUserDetails && authState.firebaseUserDetails.user) {
    //   const { user } = authState.firebaseUserDetails;
    //   const { emailVerified } = user;
    //   if (!emailVerified) {
    //     user
    //       .sendEmailVerification()
    //       .then((res) => {
    //         setOpenSnackBar(true);
    //         setMessage("Email re-send successfully");
    //       })
    //       .catch((error) => {
    //         setOpenSnackBar(true);
    //         setMessage(error?.message);
    //       });
    //   } else {
    //     setMessage("Already Verified");
    //   }
    // }

    dispatch(auth.resendVerification({
      uid:authState?.userProfile.uid
    }))
    // if(authState.apiError){
    //   setOpenSnackBar(true)
    //   setMessage(authState.apiError.message)
    // }
    // else {
    //   setOpenSnackBar(true)
    //   setMessage("Email re-send successfully")
    // }
  };

  useEffect(() => {
    if(authState.resendverify.status == 0){
      setOpenSnackBar(true)
      setMessage(authState.resendverify.message)
    }
  },[authState.resendverify.status])

  const handleClick = () => {
    setOpenSnackBar(true);
  };

  const handleClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  return (
    <div className={classes.root}>
      {location && location.pathname == "/CreateEmailSuccess" && (
        <div>
          <div
            className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
          >
              <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>

          <Grid container>
            {isNotMobile && <Grid item xs={4}></Grid>}

            <Grid
              item
              xs={isNotMobile ? 4 : 12}
              className={classes.contentArea}
            >
              <div className={`${classes.section} ${classes.cardTwo}`}>
                <div className={classes.imgStyle}>
                  <img src={`${ImagebaseUrl}assets/successcheck.svg`} />
                </div>
                <Typography
                  component={Text}
                  variant2="h3"
                  align="center"
                  className={`${classes.cardTwoH1} ${classes.title}`}
                >
                  Email Verification Sent
                </Typography>
              </div>
              <div
                className={`${classes.section}  ${classes.card} ${classes.center}`}
              >
                <Typography
                  component={Text}
                  variant2="b1Regular"
                  align="center"
                  className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
                >
                  Please check your email for instructions to verify your email
                  to continue to next steps. It can take up to a few minutes to
                  receive it.
                </Typography>
              </div>

              <div
                className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
              >
                <Button
                  // disabled={
                  //   !(formik.isValid && formik.dirty && formik.values.confirm)
                  // }
                  onClick={onSubmit}
                  type="submit"
                  color="btnPrimary"
                  width="100%"
                  text="Complete Account Registration"
                  data-rt-createemailsuccess-completeregistration = "completeregistration"
                ></Button>
              </div>
              <div
                className={`${classes.section} ${classes.center}  ${classes.cardTwo} `}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.cardAlign}
                >
                  <Typography
                    component={Text}
                    //variant2="b1Medium"
                    // align="left"
                    className={classes.accountText}
                  >
                    Didn’t received the email? &nbsp;
                  </Typography>
                  <Link  data-rt-createemailsuccess-resendnow = "resendnow">
                    <Typography
                      component={Text}
                      variant2="link1"
                      align="left"
                      className={`${classes.labelBlue}`}
                      onClick={onResendClick}
                    >
                      Resend now
                    </Typography>
                  </Link>
                </Grid>
              </div>
              <div
                className={`${classes.section} ${classes.center}  ${classes.cardTwo} `}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.cardAlign}
                >
                  <Typography
                    component={Text}
                    variant2="b1Medium"
                    align="left"
                    className={classes.accountText}
                  >
                    or
                  </Typography>
                  <Link data-rt-createemailsuccess-reenter = "reenter">
                    <Typography
                      component={Text}
                      variant2="link1"
                      align="left"
                      className={`${classes.labelBlue}`}
                    >
                      Re-enter your email
                    </Typography>
                  </Link>
                </Grid>
              </div>

              <div
                className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link onClick={() => history.push("/")} data-rt-createemailsuccess-returnhome = "returhome">
                    <Typography
                      component={Text}
                      // variant2="link1"
                      align="center"
                      className={classes.labelBlue}
                    >
                      Return to Home
                    </Typography>
                  </Link>
                </Grid>
              </div>
            </Grid>
            {isNotMobile && <Grid item xs={4}></Grid>}
          </Grid>
        </div>
      )}
      {location && location.pathname == "/CreateverifySuccess" && (
        <div>
          <div>
            <div
              className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
            >
                <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
            </div>

            <Grid container>
              {isNotMobile && <Grid item xs={4}></Grid>}

              <Grid
                item
                xs={isNotMobile ? 4 : 12}
                className={classes.contentArea}
              >
                <div className={`${classes.section} ${classes.cardTwo}`}>
                  <div className={classes.imgStyle}>
                    <img src={`${ImagebaseUrl}assets/successcheck.svg`} />
                  </div>
                  <Typography
                    component={Text}
                    variant2="h3"
                    align="center"
                    className={`${classes.cardTwoH1} ${classes.title}`}
                  >
                    Verify Your Account
                  </Typography>
                </div>
                <div
                  className={`${classes.section}  ${classes.card} ${classes.center}`}
                >
                  <Typography
                    component={Text}
                    variant2="b1Regular"
                    align="center"
                    className={`${classes.cardFourH1} ${classes.createAccountDescriptionArea}`}
                  >
                    {/* Please check your email for instructions to verify your
                    email to continue to next steps. It can take up to a few
                    minutes to receive it. */}
                    {/* Your email address will be authenticated using Google's FireBase application.
                    Please check your email for instructions from FireBase to verify your account.
                    In case you do not see the email in your inbox, please check your junk and spam folders too. */}
                    An email has been sent from Google FireBase 
                     for YourTechScore account authentication. 
                    Please check your inbox and spam folders.
                  </Typography>
                </div>
                
                <div
                  className={`${classes.section} ${classes.center}  ${classes.cardTwo}  ${classes.submit}`}
                >
                  <Button
                    onClick={onGotoDashboard}
                    type="submit"
                    color="btnPrimary"
                    width="100%"
                    text="Take me to the dashboard"
                    data-rt-createverifysuccess-takemetodashboard = "takemetodashboard"
                  ></Button>
                </div>
                <div
                  className={`${classes.section} ${classes.center}  ${classes.cardTwo} `}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.cardAlign}
                  >
                    <Typography
                      component={Text}
                      variant2="b1Medium"
                      align="left"
                      className={classes.accountText}
                    >
                      Didn’t receive the email? &nbsp;
                    </Typography>
                    <Link data-rt-createverifysuccess-resendnow = "resendnow">
                      <Typography
                        component={Text}
                        variant2="link1"
                        align="left"
                        className={`${classes.labelBlue}`}
                        onClick={onResendClick}
                      >
                        Resend now
                      </Typography>
                    </Link>
                  </Grid>
                </div>
              </Grid>
              {isNotMobile && <Grid item xs={4}></Grid>}
            </Grid>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default CreateVerifyScreen;
