import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "../home/Home.css";

import { useHistory } from "react-router-dom";
import Button from "../../components/controls/Button";
import Link from "@material-ui/core/Link";
import { Text } from "../../styles/theme";

import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/controls/TextInput";
import quiz from "../../redux/actions/quiz";
import AppConfig from "../../constants/AppConfig";
import ResumeUpload from "../../components/ResumeUpload";

import logo from "../../assets/logo.svg";
import { getObject, ImagebaseUrl } from "../../services/globalService";
import auth from "../../redux/actions/auth";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SubmitConfirmationDialog from "../../components/SubmitConfirmationDialog";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExitDialog from "../../components/Exitmodel";
import RoundedLoadingIndicator from "../../components/RoundedLoadingIndicator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px",
      paddingRight: "64px",
    },
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "100px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
  },
  cardTwo: {
    marginTop: "24px",
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
    textAlign: "left",
    [theme.breakpoints.up("tablet")]: {},
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardFourH1: {
    margin: "auto",
  },
  info: {
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
  },
  sectionInfo: {
    marginTop: "2%",
  },
  rightArrowIcon: {
    height: "10px",
    marginLeft: "2.4%",
    width: "16px",
  },
  statusIcon: {
    position: "absolute",
  },
  main: {},
  item1: {
    marginBottom: "32px",
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  item2: {
    marginTop: "32px",
  },
  item3: {
    marginTop: "32px",
  },
  item4: {
    marginTop: "16px",
  },
  answers: {
    marginTop: "24px",
  },
  item5: {
    height: "80px",
    backgroundColor: "#ffffff",
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  next: {
    "& button": {
      height: "40px",
    },
  },
  progess: {
    color: theme.palette.btnPrimary.tertiaryText,
  },
  font16: {
    // fontSize: theme.typography.link3.fontSize,
  },
  questionArea: {
    display: "flex",
  },
  qIndex: {
    // flex: "10%"
  },
  qTitle: {
    flex: "90%",
  },
  sidebar: {
    width: 500,
  },
  actionLink: {
    marginTop: 16,
  },
  cloudUploadIcon: {
    width: "24px",
    height: "16px",
    margin: "auto",
    marginTop: "20px",
  },
  uploadedImageSection: {
    width: "100%",
    height: "120px",
    margin: "auto",
    marginTop: "40px",
    background: "#FFFFFF",
    borderRadius: "4px",
  },
  uploadImageSection: {
    border: "1px dotted gray",
    width: "100%",
    height: "120px",
    margin: "auto",
    marginTop: "40px",
    position: "relative",
  },
  fileInput: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  uploadIconSection: {
    background: "#EBEFF8",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
  },
  errorIconSection: {
    background: "#DD2E2E",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
  },
  sucessIconSection: {
    background: "#61A2E4",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
  },
  commonUploadSection: {
    width: "646px",
    margin: "auto",
    marginTop: "30px",
    background: "#FFFFFF",
    height: "91px",
  },
  uploadProgress: {
    width: "646px",
    margin: "auto",
  },
  marginTop20: {
    marginTop: "20px",
  },
  marginLeft30: {
    marginLeft: "30px",
  },

  dangerIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "3%",
  },
  height100Percent: {
    height: "100%",
  },
  alignBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  colorGray: {
    color: "gray",
  },
  cloudUploadIconSection: {
    margin: "auto",
    width: "500px",
    marginTop: "10px",
    lineHeight: "20px",
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  uploadIconText: {
    color: "gray",
    marginTop: "6px",
  },
  nextBtn: {
    background: "rgba(235, 239, 248, 1)",
    height: "130px",
    width: "100%",
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    marginBottom: "0px",
  },
  reUploadFile: {
    color: "#1789FC",
    fontWeight: "700",
    textAlign: "center",
    marginTop: "6px",
    fontSize: "16px",
  },
  reUploadSection: {
    position: "relative",
    height: "35px",
    width: "200px",
    border: "1.5px solid #1789FC",
    borderRadius: "5px",
    marginTop: "35px",
  },
  actionBtn: {
    marginTop: "24px",
  },
  stickToBottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  resumeArea: {
    marginTop: "32px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardFourH1: {
    marginLeft: "35%",
    marginRight: "35%",
  },

  inputCt: {
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.up("tablet")]: {
      //width: "646px",
    },
  },
  formControl: {
    marginTop: 25,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
  },
  submit: {
    marginTop: "48px",
    width: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
  arrowImg: {
    marginLeft: 8,
  },
  backArrowImg: {
    marginRight: 8,
  },
  phoneErr: {
    marginLeft: "23%",
    marginTop: "2%",
  },
  fontSize24: {
    fontSize: "18px",
  },
  formCls: {
    paddingBottom: "140px",
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  exit: {
    color: theme.palette.neutrals.grayDark,
  },
  pageInfo: {
    height: "36px",
    width: "145px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  submitText: {
    marginTop: "12px",
    fontSize: theme.typography.b3Regular.fontSize,
  },
  policycolor: {
    color: theme.palette.btnPrimary.main,
    cursor: "pointer",
  },
  Loader:{
    position:'absolute',
    zIndex:1,
    left:'50%',
    top:'50%'
  }
}));

function ProfileQuestions(props) {
  const { location } = props;
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const resumeName = useSelector((state) => state.auth.resumeName);
  const quizState = useSelector((state) => state.quiz);
  const userProfile = useSelector((state) => state.auth.userProfile);
  const [exitopen, setExitopen] = useState(false);

  const [isShowSubmitDialog, setShowSubmitDialog] = useState(false);

  const { stepToWelcomeScreenMap, categoryTheme } = AppConfig;
  let category = "Profile";

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const [uploadStatus, setUploadStatus] = useState(
    AppConfig.UploadStatus.INITIAL
  );

  const [uploadPercentage, setPerCentageValue] = useState(0);

  const resumeDetails = {
    resumeName: authState.resumeName,
    resumeSize: authState.resumeSize,
    resumeUrl: authState.resumeSize,
  };

  useEffect(() => {
    if (resumeName) {
      setPerCentageValue(100);
      setUploadStatus(AppConfig.UploadStatus.UPLOADED);
    }
  }, [resumeName]);

  useEffect(() => {
    if (quizState.testResult) {
      console.log("Fetching userProfile...");
      dispatch(auth.getProfile({ email: authState.userProfile.email }));

      let obj = {
        level: (location && location.level && location.level) || "",
        ...formik.values,
      };

      history.push({
        pathname: "/UpdatingDashboard",
        state: {
          next: "/LNextSection",
          title:
            "Putting together your profile…\nAdding things to your dashboard…",
          ...obj,
        },
      });
    }
  }, [quizState.testResult]);

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const onSubmit = () => {
    console.log("Form data", formik.values);
    if(navigator.onLine){

    const currentResumeDetails = {
      resumeName: authState.resumeName || authState.userProfile.resumeName,
      resumeSize: authState.resumeSize || authState.userProfile.resumeSize,
      resumeUrl: authState.resumeSize || authState.userProfile.resumeUrl,
    };

    if (
      authState &&
      !authState.resumeUrl &&
      !authState.resumeName &&
      !authState.resumeSize &&
      !authState.userProfile.resumeUrl &&
      !authState.userProfile.resumeName &&
      !authState.userProfile.resumeSize
    ) {
      setShowSubmitDialog(true);
    } else if (!objectsEqual(initialValues, formik.values)) {
      createProfile(formik.values);
    } else if (!objectsEqual(resumeDetails, currentResumeDetails)) {
      createProfile(formik.values);
      dispatch(auth.getProfile({ email: authState.userProfile.email }));
      history.push("/Dashboard");
    } else {
      dispatch(auth.getProfile({ email: authState.userProfile.email }));
      history.push("/Dashboard");
    }
  }
  else {
    setOpenSnackBar(true)
  }
  };

  const createProfile = (data) => {
    var result = [];
    if (authState.resumeUrl) {
      var o = getObject("Question_ID", 50001, quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: authState.resumeUrl,
        });
      }
    }

    if (data.linkedInUrl) {
      var o = getObject(
        "Question_Label",
        "LinkedIn Profile",
        quizState.questions
      );
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.linkedInUrl,
        });
      }
    }

    if (data.githubUrl) {
      var o = getObject("Question_Label", "GitHub", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.githubUrl,
        });
      }
    }else{
      var o = getObject("Question_Label", "GitHub", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.stackoverflowUrl) {
      var o = getObject(
        "Question_Label",
        "Stack Overflow",
        quizState.questions
      );
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.stackoverflowUrl,
        });
      }
    }else{
      var o = getObject(
        "Question_Label",
        "Stack Overflow",
        quizState.questions
      );
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.mediumUrl) {
      var o = getObject("Question_Label", "Medium", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.mediumUrl,
        });
      }
    }else{
      var o = getObject("Question_Label", "Medium", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.youtubeUrl) {
      var o = getObject("Question_Label", "YouTube", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.youtubeUrl,
        });
      }
    }else{
      var o = getObject("Question_Label", "YouTube", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.personalUrl) {
      var o = getObject(
        "Question_Label",
        "Personal Website or Portfolio",
        quizState.questions
      );
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.personalUrl,
        });
      }
    }else{
      var o = getObject(
        "Question_Label",
        "Personal Website or Portfolio",
        quizState.questions
      );
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.otherUrl) {
      var o = getObject("Question_Label", "Other", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.otherUrl,
        });
      }
    }else{
      var o = getObject(
        "Question_Label",
        "Other",
        quizState.questions
      );
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    let payload = {
      code: quizState.sessionByLevel,
      email: authState.userProfile
        ? authState.userProfile.email
        : "notregistered",
      result: result,
    };

    console.log("createProfile payload: ", payload);

    dispatch(quiz.submitTestResults(payload));
    history.push({
      pathname: "/UpdatingDashboard",
      state: {
        next: "/LNextSection",
        title:
          "Putting together your profile…\nAdding things to your dashboard…",
      },
    });
  };

  const initialValues = {
    linkedInUrl: userProfile.LinkedIn_Profile || "",
    githubUrl: userProfile.GitHub || "",
    mediumUrl: userProfile.Medium || "",
    youtubeUrl: userProfile.YouTube || "",
    stackoverflowUrl: userProfile.Stack_Overflow || "",
    personalUrl: userProfile.Personal_Website || "",
    otherUrl: userProfile.Other || "",
  };

  const validationSchema = Yup.object().shape({
    linkedInUrl: Yup.string()
      .matches(
        /(ftp|http|https):\/\/?((www|\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        "Enter correct URL!"
      )
      .required("Please enter a url"),
    githubUrl: Yup.string().matches(
      /(ftp|http|https):\/\/?((www|\w\w)\.)?(github.com|gitlab.com)(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      "Enter correct URL!"
    ),
    stackoverflowUrl: Yup.string().matches(
      /(ftp|http|https):\/\/?((www|\w\w)\.)?stackoverflow.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      "Enter correct url!"
    ),
    mediumUrl: Yup.string().matches(
      /(ftp|http|https):\/\/?((www|\w\w)\.)?medium.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      "Enter correct URL!"
    ),
    youtubeUrl: Yup.string().matches(
      /(ftp|http|https):\/\/?((www|\w\w)\.)?youtube.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      "Enter correct URL!"
    ),
    personalUrl: Yup.string().matches(
      /([a-z0-9-]+\:\/+)([^\/\s]+)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#\r\n]*)#?([^ \#\r\n]*)/,
      "Enter correct URL!"
    ),
    otherUrl: Yup.string().matches(
      /([a-z0-9-]+\:\/+)([^\/\s]+)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#\r\n]*)#?([^ \#\r\n]*)/,
      "Enter correct URL!"
    ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const onClickSave = (val) => {
    history.push({
      pathname: "/UpdatingDashboard",
      state: {
        next: "/LNextSection",
        title:
          "Putting together your profile…\nAdding things to your dashboard…",
      },
    });
  };

  const onConfirm = () => {
    if(navigator.onLine){
    if (!objectsEqual(initialValues, formik.values)) {
      createProfile(formik.values);
    } else {
      dispatch(auth.getProfile({ email: authState.userProfile.email }));
      history.push("/Dashboard");
    }
  }
  else {
    setOpenSnackBar(true)
  }
  };

  useEffect(() => {
    if (authState && authState.userProfile) {
      let tempCount = 0;
      if (
        !authState.userProfile.L3_Profile_Score ||
        (authState.userProfile.L3_Profile_Score &&
          authState.userProfile.L3_Profile_Score === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L3_Coding_Score ||
        (authState.userProfile.L3_Coding_Score &&
          authState.userProfile.L3_Coding_Score === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L2_Score_UpdatedDate ||
        (authState.userProfile.L2_Score_UpdatedDate &&
          authState.userProfile.L2_Score_UpdatedDate === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L3_Presentation_Score ||
        (authState.userProfile.L3_Presentation_Score &&
          authState.userProfile.L3_Presentation_Score === 0)
      ) {
        tempCount += 1;
      }
      if (tempCount === 0) {
        history.push("/ProfileQuestions");
      }
    }
  }, [authState.userProfile]);

  const handleclick = () => {
    if(navigator.onLine){
    setExitopen(true);
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleExit = () => {
    if(navigator.onLine){
    history.push("/Dashboard");
    setExitopen(false);
    }
    else {
      setOpenSnackBar(true)
    }
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto",
        /* you can also use 'auto' behaviour
                  in place of 'smooth' */
      });
    }, 500)
  };
  return (
    <div>
      {/* {authState.isLoading  &&
      <div className={classes.Loader}>
        <RoundedLoadingIndicator></RoundedLoadingIndicator>
        </div>
      } */}
      <div className={classes.root}>
        <div className={`${classes.item1} ${classes.center} `}>
          <Container
            maxWidth="xl"
            className={classes.container}
          //   style={{ width: "70%" }}
          >
            <div className={classes.main}>
              <Grid container className="Home-hero" alignItems="center">
                <Grid item xs={4}>
                  <img
                    src={`${ImagebaseUrl}assets/logo.svg`}
                    className={classes.logoCls}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} className={classes.exitBtn}>
                  <Link onClick={handleclick}>
                    <Typography
                      component={Text}
                      variant2="link2"
                      align="right"
                      className={classes.exit}
                    >
                      Exit
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container maxWidth="xl" className={`${classes.container}`}>
          <Grid container justifyContent="center">
            {isNotMobile && <Grid item xs={3}></Grid>}
            <Grid item xs={isNotMobile ? 6 : 12}>
              <Grid
                container
                className={`${classes.section} ${classes.card}`}
                justifyContent="flex-start"
              >
                <div
                  className={`${classes.section} ${classes.pageInfo} ${classes.center}`}
                  style={{
                    border: categoryTheme.hasOwnProperty(category)
                      ? `1px solid ${categoryTheme[category].bgColor}`
                      : "",
                  }}
                >
                  <Typography
                    component={Text}
                    variant2="b2Medium"
                    align="center"
                    style={{
                      margin: "0px 10px",
                      color: categoryTheme.hasOwnProperty(category)
                        ? `${categoryTheme[category].bgColor}`
                        : "",
                    }}
                  >
                    {category} Section
                  </Typography>
                </div>
              </Grid>

              <ResumeUpload></ResumeUpload>
              <div className={`${classes.section} ${classes.cardTwo}`}>
                <Typography
                  component={Text}
                  variant2={isNotMobile ? "h4" : "h5"}
                  align="left"
                  className={`${classes.cardTwoH1} ${classes.title}`}
                >
                  Add your networking profile links
                </Typography>
              </div>
              <div className={`${classes.section}  ${classes.card}`}>
                <Typography
                  variant="subtitle2"
                  align="center"
                  className={`${classes.cardFourH1}`}
                ></Typography>
              </div>

              <form onSubmit={formik.handleSubmit} className={classes.formCls}>
                <Container className={classes.inputCt}>
                  <div className={classes.formControl}>
                    <label htmlFor="linkedInUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                      >
                        LinkedIn Profile
                      </Typography>
                    </label>
                    <TextInput
                      name="linkedInUrl"
                      placeholder="Ex. https://www.linkedin.com/in/xxx-xxx-xxx"
                      className={classes.field}
                      id="linkedInUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.linkedInUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.linkedInUrl}
                    ></TextInput>
                    {formik.touched.linkedInUrl && formik.errors.linkedInUrl ? (
                      <div className="error">
                        <Typography
                          component={Text}
                          variant2="error"
                          align="left"
                        >
                          {formik.errors.linkedInUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.formControl}>
                    <label htmlFor="githubUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                      >
                        GitHub
                      </Typography>
                    </label>
                    <TextInput
                      name="githubUrl"
                      placeholder="Ex. https://github.com/username"
                      className={classes.field}
                      id="githubUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.githubUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.githubUrl}
                      type="githubUrl"
                    ></TextInput>
                    {formik.touched.githubUrl && formik.errors.githubUrl ? (
                      <div className="error">
                        <Typography
                          component={Text}
                          variant2="error"
                          align="left"
                        >
                          {formik.errors.githubUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.formControl}>
                    <label htmlFor="stackoverflowUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                      >
                        Stack Overflow
                      </Typography>
                    </label>
                    <TextInput
                      name="stackoverflowUrl"
                      placeholder="Ex. https://stackoverflow.com/users/xxxxx"
                      className={classes.field}
                      id="stackoverflowUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.stackoverflowUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.stackoverflowUrl}
                    ></TextInput>
                    {formik.touched.stackoverflowUrl &&
                      formik.errors.stackoverflowUrl ? (
                      <div className="error">
                        <Typography
                          component={Text}
                          variant2="error"
                          align="left"
                        >
                          {formik.errors.stackoverflowUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.formControl}>
                    <label htmlFor="mediumUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                      >
                        Medium
                      </Typography>
                    </label>
                    <TextInput
                      name="mediumUrl"
                      placeholder="Ex. https://username.medium.com/"
                      className={classes.field}
                      id="mediumUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.mediumUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.mediumUrl}
                    ></TextInput>
                    {formik.touched.mediumUrl && formik.errors.mediumUrl ? (
                      <div className="error">
                        <Typography
                          component={Text}
                          variant2="error"
                          align="left"
                        >
                          {formik.errors.mediumUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="youtubeUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                      >
                        YouTube
                      </Typography>
                    </label>
                    <TextInput
                      name="youtubeUrl"
                      placeholder="Ex. https://www.youtube.com/c/username"
                      className={classes.field}
                      id="youtubeUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.youtubeUrl}
                    ></TextInput>
                    {formik.touched.youtubeUrl && formik.errors.youtubeUrl ? (
                      <div className="error">
                        <Typography
                          component={Text}
                          variant2="error"
                          align="left"
                        >
                          {formik.errors.youtubeUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="personalUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                      >
                        Personal Website or Portfolio
                      </Typography>
                    </label>
                    <TextInput
                      name="personalUrl"
                      placeholder="Ex. https://www.yourwebsite.com/"
                      className={classes.field}
                      id="personalUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.personalUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.personalUrl}
                    ></TextInput>
                    {formik.touched.personalUrl && formik.errors.personalUrl ? (
                      <div className="error">
                        <Typography
                          component={Text}
                          variant2="error"
                          align="left"
                        >
                          {formik.errors.personalUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="otherUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                      >
                        Other
                      </Typography>
                    </label>
                    <TextInput
                      name="otherUrl"
                      placeholder="Ex. https://www.yourwebsite.com/"
                      className={classes.field}
                      id="otherUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.otherUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.otherUrl}
                    ></TextInput>
                    {formik.touched.otherUrl && formik.errors.otherUrl ? (
                      <div className="error">
                        <Typography
                          component={Text}
                          variant2="error"
                          align="left"
                        >
                          {formik.errors.otherUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </Container>
                <Typography
                  component={Text}
                  align="center"
                  className={classes.submitText}
                >
                  By submitting your profile information, you agree to our{" "}
                  <span>
                    <Link
                      onClick={() => history.push("/Privacypolicy")}
                      className={classes.policycolor}
                    >
                      Privacy Policy
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link
                      className={classes.policycolor}
                      onClick={() => history.push("/Terms&Conditions")}
                    >
                      Terms of Use
                    </Link>
                  </span>
                  .
                </Typography>
              </form>
            </Grid>
            {isNotMobile && <Grid item xs={3}></Grid>}
          </Grid>
        </Container>

        <div
          className={`${classes.item5} ${classes.center} ${classes.stickToBottom}`}
        >
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.main}>
              <Grid
                container
                className="Home-hero"
                justifyContent="center"
              //className={classes.next}
              >
                <Button
                  color="btnPrimary1"
                  disabled={!(formik.isValid)}
                  text="Done"
                  width={isNotMobile ? "300px" : "160px"}
                  onClick={() => onSubmit()}
                  data-rt-profile-submit = "submit"
                ></Button>
              </Grid>
            </div>
          </Container>
        </div>
      </div>

      <SubmitConfirmationDialog
        open={isShowSubmitDialog}
        text="Saving changes without a resume can significantly impact your scores. Do you want to continue with the changes?"
        action=""
        onSubmit={onConfirm}
        onClose={() => setShowSubmitDialog(false)}
        TimeClose = {() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>
      <ExitDialog
        open={exitopen}
        OnExit={handleExit}
        onClose={() => setExitopen(false)}
        category={category}
        TimeClose = {() => setOpenSnackBar(true)}
      ></ExitDialog>
       <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default ProfileQuestions;
