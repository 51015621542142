import React, { useEffect } from "react";
import { Chart, DoughnutController, ArcElement } from "chart.js";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Text } from "../../styles/theme";
import ScoreChart from "./ScoreChart";
import { useSelector, useDispatch } from "react-redux";

Chart.register(DoughnutController, ArcElement, ScoreChart);

const useStyles = makeStyles((theme) => ({
  containerCls: {
    // width: "100%",
    // height: '40vh',
    backgroundColor: theme.palette.background.paper,
  },
  textAreaLineWithLink: {
    textAlign: "center",
    position: "relative",
    marginTop: -226,
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      position: "relative",
      marginTop: -226,
      '@media (orientation: landscape)':{
        marginTop:-323,
        }
    },
    [theme.breakpoints.down("md")]:{
     
    },
    "@media (min-width:1250px)": {
      marginTop: -286,
    }
  },
line2: {
  fontSize: 20,
  lineHeight: "20px",
  marginTop: 8,
  marginBottom: 4,
  paddingLeft: "64px",
  paddingRight: "64px",
  color: theme.palette.neutrals.grayDark,
      [theme.breakpoints.down('sm')]: {
        fontFamily: "Rational",
      fontStyle: "normal",
      fontSize: 24,
      lineHeight: "24px",
      marginTop: 12,
      marginBottom: 4,
      wordBreak: "break-word",
      color: theme.palette.neutrals.grayDark,
      paddingLeft: "42px",
      paddingRight: "42px",
      },
      "@media (min-width:1350px)": {
        fontSize: 30,
        lineHeight: "32px",
        marginTop: 16,
        marginBottom: 4,
        paddingLeft: "64px",
        paddingRight: "64px",
        color: theme.palette.neutrals.grayDark,
      },

      [theme.breakpoints.down('md')]:{
       // fontSize:20
      }
  },
  textAreaFourLine: {
    textAlign: "center",
    position: "relative",
    marginTop: -226,
    [theme.breakpoints.up('tablet')]: {
      marginTop: -286,
    }
  },
  textAreaTwoLine: {
    textAlign: "center",
    position: "relative",
    marginTop: -176,
    [theme.breakpoints.up('tablet')]: {
      marginTop: -236,
    }
  },
  scoreMeter: {
    marginTop: -64,
  },
  text: {
    color: theme.palette.text.subtitle,
    // fontSize: "14px",
    // lineHeight: "20px",
    [theme.breakpoints.up('tablet')]: {
      // fontSize: "16px",
      // lineHeight: "24px",
    }
  },
  threeLine2: {
    fontFamily: "Rational",
    fontStyle: "normal",
    fontSize: 48,
    lineHeight: "36px",
    marginTop: 12,
    marginBottom: 10,
    wordBreak: "break-word",
    color: theme.palette.neutrals.grayDark,
    paddingLeft: "24px",
    paddingRight: "24px",
    [theme.breakpoints.up('tablet')]: {
      fontSize: 64,
      lineHeight: "64px",
      marginTop: 16,
      marginBottom: 8,
      paddingLeft: "48px",
      paddingRight: "48px"
    }
  },
  threeLine3: {
    fontSize: "14px",
    lineHeight: "24px",
    paddingLeft:"53px",
    paddingRight:"53px",
    color: theme.palette.neutrals.grayDark,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.neutrals.grayDark,
      fontSize: "14px",
      lineHeight: "20px",
    },
    "@media (min-width:1350px)": {
      fontSize: "16px",
      lineHeight: "24px",
      color: theme.palette.neutrals.grayDark,
    },
  },
  threeLine4: {
    color: theme.palette.neutrals.grayDark,
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.up('tablet')]: {
      fontSize: "16px",
      lineHeight: "24px",
    }
  },
}));

export default function GraphScoreMeter(props) {
  const {
    threeLineWithLink,
    link,
    twoLine,
    fourLine,
    line1,
    line2,
    line3,
    line4,
    targetScore,
    targetLabel,
    rangeMinScore,
    rangeMaxScore,
    score,
  } = props;
  const classes = useStyles();
  let chart = null;
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const totalScore = 800;
     const ctx = document.getElementById("scroreMeterGraphId");
    //const ctx = document.getElementsByTagName("canvas")[0];
    var gradient = ctx.getContext("2d").createLinearGradient(0, 0, 500, 0);
    gradient.addColorStop(0, "#FF941C");
    gradient.addColorStop(1, "#FFC5AD");

    var data = [totalScore],
      bg = ["#EBEFF8"];

    if (!targetScore) {
      data = [totalScore];
      bg = ["#EBEFF8"];
    }
    
    if (!score && !rangeMinScore && !rangeMaxScore && targetScore) {
      data = [targetScore, totalScore - targetScore];
      var targetGradient = ctx.getContext("2d").createLinearGradient(0, 0, 500, 0);
      targetGradient.addColorStop(0, "#E1E0FF");
      targetGradient.addColorStop(1, "#61A2E4");
      bg = [targetGradient, "#EBEFF8"];
    }

    if (score) {
      data = [score, totalScore - score];
      if (score < targetScore) {
        bg = [gradient, "#EBEFF8"];
      } else {
        var highScoreGradient = ctx
          .getContext("2d")
          .createLinearGradient(0, 0, 500, 0);
        highScoreGradient.addColorStop(0, "#28BB3F");
        highScoreGradient.addColorStop(1, "#1789FC");
        bg = [highScoreGradient, "#EBEFF8"];
      }
    }

    if (rangeMinScore && rangeMaxScore) {
      var rangeGradient = ctx.getContext("2d").createLinearGradient(500 * rangeMinScore / 800, 0, 500 * rangeMaxScore / 800, 0);
      // var rangeGradient = ctx.getContext("2d").createLinearGradient(0, 0, 500, 0);
      rangeGradient.addColorStop(0, "#FFC5AD");
      rangeGradient.addColorStop(1, "#FF941C");
      data = [rangeMinScore, rangeMaxScore - rangeMinScore, totalScore - rangeMaxScore];
      bg = ["#EBEFF8", rangeGradient, "#EBEFF8"];
    }

    chart = new Chart(ctx, {
      // type: 'scorechart',
      data: {
        labels: ["Score"],
        datasets: [
          {
            type: "scorechart",
            label: "Target Score",
            data: data,
            backgroundColor: bg,
          },
        ],
      },
      options: {
        borderWidth: 0,
        cutout: "95%",
        circumference: 180,
        rotation: -90,
        totalScore: totalScore,
        targetScore: targetScore,
        targetLabel: targetLabel,
        rangeMinScore: rangeMinScore,
        rangeMaxScore: rangeMaxScore,
        score: score,
        plugins: {
          tooltip: {enabled: false},
          legend: {
              display: false,
          }
      },
        layout: {
          padding: 15
        }
      },
    });

    return function cleanup() {
      // var el = document.getElementById("scoreChart108");
      if (chart) {
        chart.destroy();
      }
    };
  },[score,targetScore]);
  

  return (
    <div className={props.scoreMeterCls || classes.scoreMeter}>
      <canvas id="scroreMeterGraphId" width="500" height="500" />
      {threeLineWithLink &&
        <div className={classes.textAreaLineWithLink}>
          <Typography
            component={Text}
            variant2="b1Regular"
            className={classes.text}
          >
            {line1}
          </Typography>
          {/* {text.map((value) => ( */}
          <Typography
            component={Text}
            variant2="h2"
            className={classes.line2}
            dangerouslySetInnerHTML={ { __html: line2} }
          >
             {/* {line2} */}
          </Typography>
          {/* ))} */}
          <Typography
            component={Text}
            variant2="b1Regular"
            className={classes.threeLine3}
          >
            {line3}
          </Typography>
            {link}
        </div>
      }
      {twoLine && (
        <div className={classes.textAreaTwoLine}>
          <Typography
            component={Text}
            variant2="b1Regular"
            className={classes.text}
          >
            {line1}
          </Typography>
          <Typography
            component={Text}
            variant2="dataViz1"
            className={`${props.scoreMeterTextCls}`}
          >
            {line2}
          </Typography>
        </div>
      )}
      {fourLine && (
        <div className={classes.textAreaFourLine}>
          <Typography
            component={Text}
            variant2="b1Regular"
            className={classes.text}
          >
            {line1}
          </Typography>
          <Typography
            component={Text}
            variant2="dataViz1"
            className={props.scoreMeterTextCls || classes.threeLine2}
          >
            {line2}
          </Typography>
          <Typography
            component={Text}
            variant2="b1Regular"
            className={classes.threeLine3}
          >
            {line3}
          </Typography>
          <Typography
            component={Text}
            variant2="b1Regular"
            className={classes.threeLine4}
          >
            {line4}
          </Typography>
        </div>
      )}
    </div>
  );
}
