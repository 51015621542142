import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

function ColoredLinearProgress(props) {
  
    const { classes } = props;
    return <LinearProgress {...props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>
}

const styles = props => ({
  colorPrimary: {
    backgroundColor: 'rgb(23, 137, 252)',
  },
  barColorPrimary: {
    backgroundColor: '#c7e5f3',
  }
});

export default  withStyles(styles)(ColoredLinearProgress);