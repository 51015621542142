import { combineReducers } from 'redux'
import quiz from './reducers/quiz'
import auth from './reducers/auth'

const appReducer = combineReducers({
  quiz,
  auth
})


// reset the state of a redux store
const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    state = undefined;
    localStorage.clear();
  }
  return appReducer(state, action)
}

export default rootReducer;



