const authTypes = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  UPDATE_LEVEL: "UPDATE_LEVEL",
  CREATE_PROFILE: "CREATE_PROFILE",
  CREATE_PROFILE_ERROR: "CREATE_PROFILE_ERROR",
  CLEAR_STATE: "CLEAR_STATE",
  RESUME_UPLOAD: "RESUME_UPLOAD",
  DELETE_RESUME: "DELETE_RESUME",
  RESUME_UPLOAD_REQUESTED: "RESUME_UPLOAD_REQUESTED",
  PROFILE_IMAGE_UPLOAD: "PROFILE_IMAGE_UPLOAD",
  RESUME_UPLOAD_FAILURE: "RESUME_UPLOAD_FAILURE",
  PROFILE_IMAGE_UPLOAD_REQUESTED: "PROFILE_IMAGE_UPLOAD_REQUESTED",
  PROFILE_IMAGE_UPLOAD_FAILURE: "PROFILE_IMAGE_UPLOAD_FAILURE",
  DOWNLOAD_RESUME: "DOWNLOAD_RESUME",
  RESUME_DOWNLOADED: "RESUME_DOWNLOADED",
  RESUME_DOWNLOAD_FAILED: "RESUME_DOWNLOAD_FAILED",
  GET_AOSI: "GET_AOSI",
  GET_AOSI_SUCCESS: "GET_AOSI_SUCCESS",
  GET_AOSI_FAILURE: "GET_AOSI_FAILURE",
  GET_RECOMMENDATIONS: "GET_RECOMMENDATIONS",
  GET_RECOMMENDATIONS_SUCCESS: "GET_RECOMMENDATIONS_SUCCESS",
  GET_RECOMMENDATIONS_FAILURE: "GET_RECOMMENDATIONS_FAILURE",
  GET_SAMPLEJOBS: "GET_SAMPLEJOBS",
  GET_SAMPLEJOBS_SUCCESS: "GET_SAMPLEJOBS_SUCCESS",
  GET_SAMPLEJOBS_FAILURE: "GET_SAMPLEJOBS_FAILURE",
  GET_CONTENT: "GET_CONTENT",
  GET_CONTENT_SUCCESS: "GET_CONTENT_SUCCESS",
  GET_CONTENT_FAILURE: "GET_CONTENT_FAILURE",
  SET_CODING_THEME: "SET_CODING_THEME",
  SET_CODING_LANG: "SET_CODING_LANG",
  GET_PROFILE: "GET_PROFILE",
  GOT_PROFILE: "GOT_PROFILE",
  GET_SCORE_HISTORY: "GET_SCORE_HISTORY",
  SCORE_HISTORY_SUCCESS: "SCORE_HISTORY_SUCCESS",
  SCORE_HISTORY_FAILURE: "SCORE_HISTORY_FAILURE",
  TIMEOUT_DIALOG_SUBMIT: "TIMEOUT_DIALOG_SUBMIT",
  TIMEOUT_DIALOG_EXIT: "TIMEOUT_DIALOG_EXIT",
  SET_AUTH_STATE: "SET_AUTH_STATE",
  User_Meta_Data: "User_Meta_Data",
  User_Meta_Data_REQ: "User_Meta_Data_REQ",
  CREATE_USER_WITH_FIREBASE_REQUESTED: "CREATE_USER_WITH_FIREBASE_REQUESTED",
  CREATE_USER_WITH_FIREBASE_ERROR: "CREATE_USER_WITH_FIREBASE_ERROR",
  CREATE_USER_WITH_FIREBASE_SUCCESS: "CREATE_USER_WITH_FIREBASE_SUCCESS",
  RESET_PASSWORD_REQUESTED: "RESET_PASSWORD_REQUESTED",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  FIREBASE_USER_LOGIN_REQUESTED: "FIREBASE_USER_LOGIN_REQUESTED",
  FIREBASE_USER_LOGIN: "FIREBASE_USER_LOGIN",
  FIREBASE_USER_LOGIN_ERROR: "FIREBASE_USER_LOGIN_ERROR",
  DELETE_RESUME_REQUESTED: "DELETE_RESUME_REQUESTED",
  DELETE_RESUME_SUCCESS: "DELETE_RESUME_SUCCESS",
  USER_META_DATA_REQ: "USER_META_DATA_REQ",
  DELETE_USER_META_DATA: "DELETE_USER_META_DATA",
  POST_USER_META_DATA: "POST_USER_META_DATA",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_META_DATA_REQ: "DELETE_USER_META_DATA_REQ",
  POST_USER_META_DATA_REQ: "POST_USER_META_DATA_REQ",
  DELETE_USER_REQ: "DELETE_USER_REQ",
  INVITE_FRIEND: "INVITE_FRIEND",
  CONTINUE_WITH_EMAIL_SUBMITTED: "CONTINUE_WITH_EMAIL_SUBMITTED",
  USER_EXISTS: "USER_EXISTS",
  DELETE_PROFILE_USER: "DELETE_PROFILE_USER",
  DELETE_PROFILE_USER_SUCCESS: "DELETE_PROFILE_USER_SUCCESS",
  DELETE_PROFILE_USER_ERROR: "DELETE_PROFILE_USER_ERROR",
  API_ERROR:"API_ERROR",
  INVITE_FRIEND_SUCCESS:"INVITE_FRIEND_SUCCESS",
  IS_EDIT_PROFILE_QUIZ: "IS_EDIT_PROFILE_QUIZ",
  SET_API_ERROR: "SET_API_ERROR",
  ADMIN_DETAILS:"ADMIN_DETAILS",
  ADMIN_DETAILS_SUCCESS:"ADMIN_DETAILS_SUCCESS",
  ADMIN_VIEW_DETAILS:"ADMIN_VIEW_DETAILS",
  ADMIN_VIEW_DETAILS_SUCCESS:"ADMIN_VIEW_DETAILS_SUCCESS",
  ADMIN_VIEW_EMAIL:"ADMIN_VIEW_EMAIL",
  VERIFY_EMAIL:"VERIFY_EMAIL",
  VERIFY_EMAIL_REQUEST:"VERIFY_EMAIL_REQUEST",
  FORGOT_PASSWORD:"FORGOT_PASSWORD",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  UPDATE_PASSWORD:"UPDATE_PASSWORD",
  UPDATE_PASSWORD_REQUEST:"UPDATE_PASSWORD_REQUEST",
  RESEND_VERIFICATION:"RESEND_VERIFICATION",
  RESEND_VERIFICATION_REQUEST:"RESEND_VERIFICATION_REQUEST"
};

export default authTypes;
