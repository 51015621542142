import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
  Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Text } from "../styles/theme";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "../components/controls/Button";

import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import TextInput from "../components/controls/TextInput"
import Divider from '@material-ui/core/Divider';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import auth from "../redux/actions/auth";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: "16px 8px",
    position: "absolute",
    top: theme.spacing(5),
    width:'50%'
  },
  dialogTitle: {
    // paddingRight: "0px",
    // paddingLeft: "16px",
    padding:'20px'
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "10px",
    color: theme.palette.btnPrimary.tertiaryText,
  },
  content: {
    // paddingLeft: "16px",
    // paddingRight: "16px",
    // paddingTop: "24px",
    padding:'30px',
    borderTop: "1px solid #F8F8F8",
    borderBottom: "1px solid #F8F8F8",
    [theme.breakpoints.down("sm")]:{
      paddingBottom:'100px',
      backgroundColor:'#FFFBF6'
    }
  },
  title: {},
  justifyActions: {
    justifyContent: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "56px",
    "& button":{
      height:'32px'
    }
  },
  description: {
    paddingTop: "16px",
  },
  desContainer: {
    paddingLeft: "0px",
  },
  hint: {
    whiteSpace: 'pre-line'
  },
  inputCt: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formControl: {
    marginTop: 24,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
  },
  boxText: {
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    padding: "18px",
    marginTop: "15px",
  },
  textInvite: {
    color: ' #787878',
    margin: "10px 0 10px 10px"
  },
  InviteText: {
    color: '#787878',
    margin: '10px'
  },
  textArea:{
    border: "none",
    resize: "none",
    fontSize: "16px",
    fontFamily: 'Roboto',
   outline:'none',
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em"
  },
  savechange:{
    position:'fixed',
    bottom:"0px",
    width:'100%',
    backgroundColor:'#FFFBF6'
  }
}));

export default function EditprofileModal(props) {
  const { title, children, openPopup, setOpenPopup, hint, showButtonArea, ToastClick } =
    props;
  const classes = useStyles();
  const { location } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const history = useHistory();
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const onSubmit = (values) => {
    if(navigator.onLine){
    // values.level = location.level;
     if (isNotMobile) {
         setOpenPopup(false)
      }
      else {
        history.push("/Dashboard")
      }
    console.log("value change",values.firstName)
    dispatch(auth.createProfile({
        email: authState?.userProfile?.email,
        firstName: values.firstName,
        lastName: values.lastName,

    }))
  }
  else {
    setOpenSnackBar(true)
  }
    //setOpenPopup(false);
  
  };
  const initialValues = {
    firstName:  authState?.userProfile?.firstName || "",
    lastName: authState?.userProfile?.lastName || ""
  };
  
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field cannot be empty"),
    lastName: Yup.string().required("This field cannot be empty"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const handleClick = () => {
   
    if (isNotMobile) {
        setOpenPopup(false)
        
      }
      else {
        history.push("/Dashboard")
      }
  }
  const CloseBtn = () => {
    if (isNotMobile) {
        setOpenPopup(false)
     
      }
      else {
        history.push("/Dashboard")
      }
  }

  return (
    <>
      <DialogTitle
        className={classes.dialogTitle}
        id="customized-dialog-title"
        onClose={() => {
          setOpenPopup(false);
        }}
      >
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
          <Typography
            variant2="b1Medium"
            component={Text}
            style={{ flexGrow: 1 }}
          >
            Your Profile Name
          </Typography>

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={CloseBtn}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers className={classes.content}>

          <Container className={classes.desContainer}>
            <div>
              <Typography component={Text} variant2="b1Regular" style={{ color: '#787878' }}>This name will be displayed on your dashboard</Typography>
            </div>
            <div>

              <Container className={classes.inputCt}>
              <div className={classes.formControl}>
                <label htmlFor="firstName">
                  <Typography component={Text} variant2="b1Medium" align="left">
                    First Name
                  </Typography>
                </label>
                <TextInput
                  name="firstName"
                  placeholder="Ex. Alexander"
                  className={classes.field}
                  id="firstName"
                  onChange={formik.handleChange}
                  // error={Boolean(formik.errors.firstName)}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                ></TextInput>
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.firstName}
                    </Typography>
                  </div>
                ) : null}
              </div>

              <div className={classes.formControl}>
                <label htmlFor="lastName">
                  <Typography component={Text} variant2="b1Medium" align="left">
                    Last Name
                  </Typography>
                </label>
                <TextInput
                  name="lastName"
                  placeholder="Ex. Smith"
                  className={classes.field}
                  id="lastName"
                  onChange={formik.handleChange}
                  //error={Boolean(formik.errors.lastName)}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                ></TextInput>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="error">
                    <Typography component={Text} variant2="error" align="left">
                      {formik.errors.lastName}
                    </Typography>
                  </div>
                ) : null}
              </div>
              </Container>
            </div>
          </Container>
        </DialogContent>
        <div  className={!isNotMobile ? `${classes.savechange}` : null}>
        <Divider />
        <DialogActions className={classes.justifyActions}>
          <Button
            // disabled={
            //   !(formik.isValid && formik.dirty && formik.values.confirm)
            // }
            type="submit"
            color="btnPrimary"
            width={"160px"}
            text="Save Changes"
            data-rt-editprofile-savechange = "savechange"
          ></Button>
        </DialogActions>
        </div>

      </form>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClose={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}
