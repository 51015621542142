import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import logo from "../assets/logo.svg";
import TimeOutCaptureIcon from "../assets/error_outline.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {},
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "48px",
    zIndex: 9999999,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "100px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  cardTwo: {
    marginTop: "40px",
  },
  cardThree: {},
  card: {
    marginBottom: 40,
    "&>button": {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up("tablet")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    width: 346,
    lineHeight: "28px",
    [theme.breakpoints.up("tablet")]: {
      width: "fit-content",
    },
  },
  openingScreenDescription2: {
    width: "424px",
    height: "72px",
    color: "#2F2F2F",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "@media (max-width:420px)": {
      width: "340px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    "@media screen and (min-width:500px) and (max-width:780px)": {
      width: "525px",
      marginTop: "5%",
    },
  },
  marginTop40: {
    marginTop: "40px",
  },
  lineHeight24: {
    lineHeight: "24px",
  },
  openingScreen: {
    width: 256,
    [theme.breakpoints.up("tablet")]: {
      width: 373,
    },
  },
  errorScreen: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "@media (max-width:420px)": {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  serverErrorIcon: {
    width: "100%",
    height: "40px",
    top: "4px",
    left: "4px",
  },
  fontDescription: {
    fontFamily: "Rational",
    lineHeight: "48px",
    fontSize: "40px",
    letterSpacing: "0.02em",
    "@media (max-width:420px)": {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  exit: {
    color: "#007eff",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Roboto",
  },
  titleText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "888px",
    height: "96px",
    marginTop: "2%",
    "@media (max-width:420px)": {
      width: "358px",
      marginTop: "5%",
    },
    "@media screen and (min-width:500px) and (max-width:780px)": {
      width: "525px",
    },
  },
  ErrorScreenIconArea: {
    height: "40px",
    width: "100%",
  },
  descriptionText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 24,
    "@media (max-width:420px)": {
      width: "358px",
      height: "84px",
      marginTop: "10%",
    },
  },
  footerArea: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "@media (max-width:420px)": {
      height: "150px",
      marginTop: "3%",
    },
  },
  retakeButton: {
    width: "424px",
    height: "48px",
    borderRadius: "4px",
    "@media (max-width:420px)": {
      width: "351px",
      height: "40px",
    },
  },
  linkArea: {
    marginTop: "2%",
    color: "#1789FC",
    "@media (max-width:420px)": {
      marginTop: "6%",
    },
  },
  browserList: {
    // color: "#33333",
    marginTop: 24
  }
}));

function UnsupportedBrowser(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const onClickRetake = () => { };

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section} ${classes.cardOne} ${classes.center}`}
      >
        <img alt="logo" src={logo} className={classes.logoCls} />
      </div>
      <div className={`${classes.errorScreen}`}>
        <div className={`${classes.ErrorScreenIconArea}`}>
          <img
            alt="TimeOutCaptureIcon"
            src={TimeOutCaptureIcon}
            className={` ${classes.logoCls} ${classes.serverErrorIcon}`}
          />
        </div>
        <div className={` ${classes.titleText}`}>
          <Typography
            variant="subtitle2"
            align="center"
            className={`${classes.fontDescription}`}
          >
            Sorry, browser not supported
          </Typography>
        </div>
        <div className={`${classes.descriptionText}`}>
          <Typography
            variant="subtitle2"
            align="center"
            className={`${classes.openingScreenDescription2}`}
          >
            It looks like you’re running an older version of a browser. To get the best experience using our application we recommend that you use a newer version:

        {isNotMobile &&
          <div className={classes.browserList}>
              Chrome 85+<br />
              Edge 18+<br />
              Firefox 69+<br />
              Opera 67+<br />
              Safari 11+<br />
            </div>
        }
        {!isNotMobile &&
          <div className={classes.browserList}>
              Android 7+<br/>
              iOS 11+
            </div>
        }
        </Typography>
        </div>
      </div>
    </div>
  );
}
export default UnsupportedBrowser;
