import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core';
import uploadSucessIcon from "../assets/uploadSuccessIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import auth from "../redux/actions/auth";
import { Text } from "../styles/theme";

import {saveAs} from "file-saver";
import { ImagebaseUrl } from "../services/globalService";

const useStyles = makeStyles(theme => ({
    commonUploadSection: {
        // width: "968px",
        margin: "auto",
        marginTop: "0px",
        background: "#FFFFFF",
        height: "64px",
        border: "1px solid #EBEFF8",
        borderRadius: 4,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#EBEFF8"
        },
        [theme.breakpoints.up('tablet')]: {
            marginTop: "30px",
        }
    },
    height100Percent: {
        height: "100%",
    },
    sucessIconSection: {
        background: "#61A2E4",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "70px",
    },
    marginLeft30: {
        marginLeft: "16px",
    },
    colorGray: {
        color: "gray",
    },
    uploadSuccessIcon: {
        height: 36
    }
}));

export default function Resume(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);
    const resumeBlob = useSelector((state) => state.auth.resumeBlob);

    const onResumeDownloadClick = ()=> {
        dispatch(auth.downloadResume({
            email: authState.user.email
        }));
    }

    useEffect(() => {
        if (resumeBlob) {
          const blob = new Blob([resumeBlob], {type: "application/octet-stream"});
        saveAs(blob, authState.resumeName);
        }
      }, [resumeBlob]);

    const getFileSize = (inBytes)=> {
        var inKb = inBytes/1024;
        if (inKb.toFixed(1) < 1000.0) {
            return inKb.toFixed(1) + ' KB';
        }

        var inMb = inKb/1024;
        return inMb.toFixed(1) + 'MB';
    }

    return (
        <div className={`${classes.commonUploadSection}`} onClick={onResumeDownloadClick}>
            <Grid container className={`${classes.height100Percent}`}>
                <Grid item>
                    <div className={`${classes.sucessIconSection}`}>
                        <img alt="uploadSucessIcon" src={`${ImagebaseUrl}assets/uploadSuccessIcon.svg`} className={classes.uploadSuccessIcon}/>
                    </div>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid
                        item
                        xs
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={6}
                    >
                        <Grid item xs>
                            <Typography
                                component={Text}
                                className={`${classes.marginLeft30}`}
                                variant2="body2"
                            >
                                {authState.resumeName}
                            </Typography>

                            <Typography
                                component={Text}
                                className={`${classes.marginLeft30} ${classes.colorGray}`}
                                variant2="body2"
                            >
                                {getFileSize(authState.resumeSize)}
                  </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}