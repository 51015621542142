import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import { useHistory } from "react-router-dom";
import Button from "../../../src/components/controls/Button";
import Link from "@material-ui/core/Link";
import rightArrowIcon from "../../assets/Arrow 10.png";
import completeIcon from "../../assets/VectorWithWhiteBackground.png";

import logo from "../../assets/logo.svg";
import { Text } from "../../styles/theme";
import Grid from "@material-ui/core/Grid";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import quiz from "../../redux/actions/quiz";
import { ImagebaseUrl } from "../../services/globalService";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import auth from "../../redux/actions/auth";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: `${theme.palette.primary.light}!important`,
    height: "100%",
  },
  section: {
    width: "100%",
    // backgroundColor: `${theme.palette.primary.light}!important`,
    // [theme.breakpoints.up("tablet")]: {
    //   width: "426px",
    // }
  },
  title: {
    flexGrow: 1,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    zIndex: 1,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  cardTwo: {
    marginTop: "64px",
  },
  cardFive: {
    backgroundColor: theme.palette.primary.light,
    paddingTop: "24px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
    backgroundColor: `${theme.palette.primary.light}!important`,
  },
  card: {
    // marginTop: "48px",
    // marginBottom: 24,
    textAlign: "left",
    // backgroundColor: theme.palette.primary.light,
  },
  cardSix: {
    // marginTop: "24px",
    backgroundColor: `${theme.palette.primary.light}!important`,
  },
  incompleSection: {
    paddingLeft: 16,
    paddingRight: 16,
    // marginTop: 8
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    marginLeft: "33%",
    marginRight: "33%",
    width: "650px",
    marginTop: "4%",
    fontSize: "40px",
    lineHeight: "48px",
    backgroundColor: `${theme.palette.primary.light}!important`,
  },
  cardFourH1: {
    margin: "auto",
    // backgroundColor: `${theme.palette.primary.light}!important`,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up("tablet")]: {
      // width: "868px",
      padding: 0,
    },
  },
  info: {
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
  },
  sectionInfo: {
    marginTop: "2%",
  },
  rightArrowIcon: {
    height: "10px",
    marginLeft: "2.4%",
    width: "16px",
  },
  LNextText: {
    marginLeft: "35%",
    marginRight: "35%",
    marginTop: "2%",
    // width: "424px",
    // height: "72px",
  },
  statusIcon: {
    position: "absolute",
  },
  cardFour: {
    marginBottom: "0px",
    paddingBottom: "24px",
  },
  completeSection: {
    padding: "16px",
    margin: "auto",
    background: "#FFFFFF",
    // marginTop: "24px",
    borderRadius: "4px",
    [theme.breakpoints.up("tablet")]: {
      // width: "426px",
      height: "56px",
    },
    // marginTop: "8px",
    "&:not(last-child)": {
      // marginBottom: "8px",
      [theme.breakpoints.up("tablet")]: {
        marginBottom: "24px",
        marginTop: "24px",
      },
    },
  },
  cardfinalBtn: {
    paddingTop: "16px",
  },
  LNextDescriptionArea: {
    height: "72px",
    lineHeight: "24px",
    fontSize: "16px",
    marginTop: "24px",
    [theme.breakpoints.up("tablet")]: {
      width: "424px",
      height: "72px",
      lineHeight: "24px",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "400",
      margin: "auto",
      marginTop: "24px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  completedSection: {
    marginTop: "24px",
    paddingRight: 16,
    paddingLeft: 16,
    [theme.breakpoints.up("tablet")]: {
      marginTop: "24px",
    },
  },
  completed: {
    padding: 16,
    margin: "auto",
    backgroundColor: theme.palette.primary.mid,
    borderRadius: 4,
    [theme.breakpoints.up("tablet")]: {
      // width: "426px",
      height: "56px",
    },
    "&:not(last-child)": {
      marginBottom: "8px",
      [theme.breakpoints.up("tablet")]: {
        marginBottom: "24px",
      },
    },
  },
  whiteText: {
    color: "#FFFFFF",
  },
}));

function LNextSection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);

  let firstName;
  if (authState.user && authState.user.displayName) {
    firstName = authState.user.displayName.split(" ")[0];
  }

  const [codingAccessNotAllowed, setCodingAccessNotAllowed] = useState(false);
  const [isShowSnackBar, setShowSnackBar] = useState(false);
  const [uncompletedSectionCount, setUnCompletedSectionCount] = useState(0);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  useEffect(() => {
    if (authState && authState.userProfile) {
      let tempCount = 0;
      if (
        !authState.userProfile.L3_Profile_Score ||
        (authState.userProfile.L3_Profile_Score &&
          authState.userProfile.L3_Profile_Score === 0)
      ) {
        tempCount += 1;
      }
      // if (
      //   !authState.userProfile.L3_Coding_Score ||
      //   (authState.userProfile.L3_Coding_Score &&
      //     authState.userProfile.L3_Coding_Score === 0)
      // ) {
      //   tempCount += 1;
      // }
      if(
        !authState.userProfile.L3_Coding_Score_UpdatedDate ||
        (authState.userProfile.L3_Coding_Score_UpdatedDate &&
          authState.userProfile.L3_Coding_Score_UpdatedDate === 0)
      ){
        tempCount += 1;
      }
      if (
        !authState.userProfile.L2_Score_UpdatedDate ||
        (authState.userProfile.L2_Score_UpdatedDate &&
          authState.userProfile.L2_Score_UpdatedDate === 0)
      ) {
        tempCount += 1;
      }
      if (
        !authState.userProfile.L3_Presentation_Score ||
        (authState.userProfile.L3_Presentation_Score &&
          authState.userProfile.L3_Presentation_Score === 0)
      ) {
        tempCount += 1;
      }
      if (tempCount === 0) {
        history.push("/Dashboard");
      } else {
        setUnCompletedSectionCount(tempCount);
      }
    }
  }, [authState.userProfile]);

  const moveTo = function (path) {
    if(navigator.onLine){
    dispatch(quiz.resetQuizSummery());
    dispatch(quiz.resetQuizQuestions());
    dispatch(quiz.resetTestResult());
    history.push(path);
    }
    else {
      setShowSnackBar(true)
    }
  };

  const onStartCodingChallenge = function () {
    if(navigator.onLine){
    if (isNotMobile) {
      moveTo("/L3CodingOpeningScreen");
    } else {
      moveTo("/CodingSectionUnavailable");
    }
  }
    else {
      setShowSnackBar(true)
    }
  };

  const backToDashboard = () => {
    if(navigator.onLine){
    dispatch(auth.getProfile({ email: authState.userProfile.email }));
    history.push("/Dashboard");
    }
    else {
      setShowSnackBar(true)
    }
  };

  const handleClose = () => {
    setShowSnackBar(false)
  }

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section}   ${classes.cardOne}  ${classes.center}`}
      >
        <img src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
      </div>

      <Grid container>
        {isNotMobile && <Grid item xs={2}></Grid>}
        <Grid
          item
          xs={isNotMobile ? 8 : 12}
          className={`${classes.section} ${classes.cardTwo}`}
        >
          <Typography
            component={Text}
            variant2={isNotMobile ? "h2" : "h3"}
            align="center"
            className={`${classes.cardFourH1}`}
          >
            You’re on a roll!
            <br />
            Lets continue to the next section, {uncompletedSectionCount} more to
            go.
          </Typography>
        </Grid>
        {isNotMobile && <Grid item xs={2}></Grid>}
      </Grid>

      <Grid container className={`${classes.completedSection}`}>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid
          item
          xs={isNotMobile ? 4 : 12}
          className={`${classes.section} ${classes.card}`}
        >
          {authState.userProfile && (authState.userProfile.L3_Profile_Score > 0  || authState.userProfile.L3_Profile_Score_UpdatedDate) && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.completed}`}
            >
              <Typography
                component={Text}
                variant2="b1Medium"
                align="left"
                className={classes.whiteText}
              >
                Profile Section
              </Typography>
              &nbsp;
              <img src={`${ImagebaseUrl}assets/VectorWithWhiteBackground.png`} className={classes.statusIconCls} />
            </Grid>
          )}
          {authState.userProfile && (authState.userProfile.L2_Score > 0 || authState.userProfile.L2_Score_UpdatedDate) && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.completed}`}
            >
              <Typography
                component={Text}
                variant2="b1Medium"
                align="left"
                className={classes.whiteText}
              >
                Technical Section
              </Typography>
              &nbsp;
              <img src={`${ImagebaseUrl}assets/VectorWithWhiteBackground.png`} className={classes.statusIconCls} />
            </Grid>
          )}
          {authState.userProfile && (authState.userProfile.L3_Coding_Score > 0 || authState.userProfile.L3_Coding_Score_UpdatedDate) && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.completed}`}
            >
              <Typography
                component={Text}
                variant2="b1Medium"
                align="left"
                className={classes.whiteText}
              >
                Coding Section
              </Typography>
              &nbsp;
              <img src={`${ImagebaseUrl}assets/VectorWithWhiteBackground.png`} className={classes.statusIconCls} />
            </Grid>
          )}
          {authState.userProfile &&
            (authState.userProfile.L3_Presentation_Score > 0 || authState.userProfile.L3_Presentation_Score_UpdatedDate) && (
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={`${classes.completed}`}
              >
                <Typography
                  component={Text}
                  variant2="b1Medium"
                  align="left"
                  className={classes.whiteText}
                >
                  Presentation Section
                </Typography>
                &nbsp;
                <img src={`${ImagebaseUrl}assets/VectorWithWhiteBackground.png`} className={classes.statusIconCls} />
              </Grid>
            )}
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      <Grid container className={`${classes.incompleSection}`}>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid
          item
          xs={isNotMobile ? 4 : 12}
          className={`${classes.section} ${classes.cardSix}`}
        >
          {authState.userProfile && (!authState.userProfile.L3_Profile_Score && !authState.userProfile.L3_Profile_Score_UpdatedDate) && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.info} ${classes.completeSection}`}
            >
              <Typography component={Text} variant2="b1Medium" align="left">
                Profile Section - &nbsp;
              </Typography>

              <Link onClick={() => moveTo("/L3ProfileOpeningScreen")} data-rt-lnext-profile = "profile">
                <Typography component={Text} variant2="link1" align="left">
                  Complete Now
                </Typography>
              </Link>
              <img
                src={`${ImagebaseUrl}assets/Arrow 10.png`}
                className={`${classes.rightArrowIcon}`}
              />
            </Grid>
          )}
          {authState.userProfile && (!authState.userProfile.L2_Score && !authState.userProfile.L2_Score_UpdatedDate) &&(
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.info} ${classes.completeSection}`}
            >
              <Typography component={Text} variant2="b1Medium" align="left">
                Technical Section - &nbsp;
              </Typography>

              <Link onClick={() => moveTo("/L2OpeningScreen")} data-rt-lnext-technical = "technical">
                <Typography component={Text} variant2="link1" align="left">
                  Complete Now
                </Typography>
              </Link>
              <img
                src={`${ImagebaseUrl}assets/Arrow 10.png`}
                className={`${classes.rightArrowIcon}`}
              />
            </Grid>
          )}
          {authState.userProfile && (!authState.userProfile.L3_Coding_Score && !authState.userProfile.L3_Coding_Score_UpdatedDate) && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.info} ${classes.completeSection}`}
            >
              <Typography component={Text} variant2="b1Medium" align="left">
                Coding Section - &nbsp;
              </Typography>

              <Link onClick={onStartCodingChallenge} data-rt-lnext-coding = "coding">
                <Typography component={Text} variant2="link1" align="left">
                  Complete Now
                </Typography>
              </Link>
              <img
                src={`${ImagebaseUrl}assets/Arrow 10.png`}
                className={`${classes.rightArrowIcon}`}
              />
            </Grid>
          )}
          {authState.userProfile &&
            (!authState.userProfile.L3_Presentation_Score && !authState.userProfile.L3_Presentation_Score_UpdatedDate) && (
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={`${classes.info}  ${classes.completeSection}`}
              >
                <Typography component={Text} variant2="b1Medium" align="left">
                  Presentation Section - &nbsp;
                </Typography>
                <Link onClick={() => moveTo("/L3PresentationOpeningScreen")} data-rt-lnext-presentation = "presentation">
                  <Typography component={Text} variant2="link1" align="left">
                    Complete Now
                  </Typography>
                </Link>
                <img
                  src={`${ImagebaseUrl}assets/Arrow 10.png`}
                  className={`${classes.rightArrowIcon}`}
                />
              </Grid>
            )}
        </Grid>

        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid
          item
          xs={isNotMobile ? 4 : 12}
          className={`${classes.section} ${classes.cardFour} ${classes.card} ${classes.LNextDescriptionArea}`}
        >
          <Typography
            component={Text}
            variant2="b1Regular"
            className={`${classes.title}`}
            align="center"
          >
            All the above sections must be completed to get an accurate score.
            You may attempt them now, or complete them later from your
            dashboard.
          </Typography>
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      <Grid container>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid item xs={isNotMobile ? 4 : 12} className={`${classes.cardFive}`}>
          <Button
            color="btnPrimary"
            text="Back to home"
            width="100%"
            onClick={backToDashboard}
            data-rt-lnext-backtohome = "backtohome"
          ></Button>
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default LNextSection;
