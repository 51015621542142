import authTypes from "../types/auth";

function beginLoginUser() {
  return {
    type: authTypes.LOGIN_USER,
  };
}

function loginSuccess(payload) {
  return {
    type: authTypes.LOGIN_USER_SUCCESS,
    payload,
  };
}

function loginError(payload) {
  return {
    type: authTypes.LOGIN_USER_FAILURE,
    payload,
  };
}

function updateQuizLevel(payload) {
  return {
    type: authTypes.UPDATE_LEVEL,
    payload,
  };
}

function createProfile(payload, userMetaData) {
  return {
    type: authTypes.CREATE_PROFILE,
    payload,
    userMetaData,
  };
}

function createProfileError(payload) {
  return {
    type: authTypes.CREATE_PROFILE_ERROR,
    payload,
  };
}

function resumeUpload(payload) {
  return {
    type: authTypes.RESUME_UPLOAD,
    payload,
  };
}

function deleteResume(payload) {
  return {
    type: authTypes.DELETE_RESUME,
    payload,
  };
}

function resumeUploadRequested(resumeRequest) {
  return {
    type: authTypes.RESUME_UPLOAD_REQUESTED,
    payload: resumeRequest,
  };
}

function resumeUploadError(payload) {
  return {
    type: authTypes.RESUME_UPLOAD_FAILURE,
    payload,
  };
}

function profileImageUpload(payload) {
  return {
    type: authTypes.PROFILE_IMAGE_UPLOAD,
    payload,
  };
}

function profileImageUploadRequested(payload) {
  return {
    type: authTypes.PROFILE_IMAGE_UPLOAD_REQUESTED,
    payload: payload,
  };
}

function profileImageUploadError(payload) {
  return {
    type: authTypes.PROFILE_IMAGE_UPLOAD_FAILURE,
    payload,
  };
}

function downloadResume(payload) {
  return {
    type: authTypes.DOWNLOAD_RESUME,
    payload,
  };
}

function resumeDownloaded(payload) {
  return {
    type: authTypes.RESUME_DOWNLOADED,
    payload,
  };
}

function clearState() {
  return {
    type: authTypes.CLEAR_STATE,
  };
}

function getAoSI(payload) {
  return {
    type: authTypes.GET_AOSI,
    payload,
  };
}

function updateAoSI(payload) {
  return {
    type: authTypes.GET_AOSI_SUCCESS,
    payload,
  };
}

function getRecommendations(payload) {
  return {
    type: authTypes.GET_RECOMMENDATIONS,
    payload,
  };
}

function updateRecommendations(payload) {
  return {
    type: authTypes.GET_RECOMMENDATIONS_SUCCESS,
    payload,
  };
}

function getSampleJobs(payload) {
  return {
    type: authTypes.GET_SAMPLEJOBS,
    payload,
  };
}

function updateSampleJobs(payload) {
  return {
    type: authTypes.GET_SAMPLEJOBS_SUCCESS,
    payload,
  };
}

function getContent(payload) {
  return {
    type: authTypes.GET_CONTENT,
    payload,
  };
}

function updateContent(payload) {
  return {
    type: authTypes.GET_CONTENT_SUCCESS,
    payload,
  };
}

function setCodingTheme(payload) {
  return {
    type: authTypes.SET_CODING_THEME,
    payload,
  };
}

function setCodingLang(payload) {
  return {
    type: authTypes.SET_CODING_LANG,
    payload,
  };
}

function getProfile(payload) {
  return {
    type: authTypes.GET_PROFILE,
    payload,
  };
}

function setProfile(payload) {
  return {
    type: authTypes.GOT_PROFILE,
    payload,
  };
}

function getScoreHistory(payload) {
  return {
    type: authTypes.GET_SCORE_HISTORY,
    payload,
  };
}

function updateScoreHistory(payload) {
  return {
    type: authTypes.SCORE_HISTORY_SUCCESS,
    payload,
  };
}

function onTimeoutDialogSubmit(payload) {
  return {
    type: authTypes.TIMEOUT_DIALOG_SUBMIT,
    payload,
  };
}

function onTimeoutDialogExit(payload) {
  return {
    type: authTypes.TIMEOUT_DIALOG_EXIT,
    payload,
  };
}

function setAuthState(payload) {
  return {
    type: authTypes.SET_AUTH_STATE,
    payload,
  };
}

function userMetaDataReq(payload) {
  return {
    type: authTypes.USER_META_DATA_REQ,
    payload,
  };
}
function createUserWithFirebaseRequested(userRequest) {
  return {
    type: authTypes.CREATE_USER_WITH_FIREBASE_REQUESTED,
    userRequest,
  };
}

function createUserWithFirebaseError(payload) {
  return {
    type: authTypes.CREATE_USER_WITH_FIREBASE_ERROR,
    payload,
  };
}

function userMetaData(payload) {
  return {
    type: authTypes.User_Meta_Data,
    payload,
  };
}
function createUserWithFirebaseSuccess(payload) {
  return {
    type: authTypes.CREATE_USER_WITH_FIREBASE_SUCCESS,
    payload,
  };
}

function resetPasswordRequested(resetPasswordRequest) {
  return {
    type: authTypes.RESET_PASSWORD_REQUESTED,
    resetPasswordRequest,
  };
}

function resetPassword(payload) {
  return {
    type: authTypes.RESET_PASSWORD,
    payload,
  };
}

function resetPasswordError(payload) {
  return {
    type: authTypes.RESET_PASSWORD_ERROR,
    payload,
  };
}

function firebaseUserLoginRequested(loginRequest) {
  return {
    type: authTypes.FIREBASE_USER_LOGIN_REQUESTED,
    loginRequest,
  };
}

function firebaseUserLogin(payload) {
  return {
    type: authTypes.FIREBASE_USER_LOGIN,
    payload,
  };
}

function firebaseUserLoginError(payload) {
  return {
    type: authTypes.FIREBASE_USER_LOGIN,
    payload,
  };
}

function deleteResumeRequested(payload) {
  return {
    type: authTypes.DELETE_RESUME_REQUESTED,
    payload,
  };
}

function deleteResumeSuccess(payload) {
  return {
    type: authTypes.DELETE_RESUME_SUCCESS,
    payload,
  };
}

function deleteUserMetaData(payload) {
  return {
    type: authTypes.DELETE_USER_META_DATA,
    payload,
  };
}

function postUserMetaData(payload) {
  return {
    type: authTypes.POST_USER_META_DATA,
    payload,
  };
}

function deleteUser(payload) {
  return {
    type: authTypes.DELETE_USER,
    payload,
  };
}

function deleteUserMetaDataReq(payload) {
  return {
    type: authTypes.DELETE_USER_META_DATA_REQ,
    payload,
  };
}

function postUserMetaDataReq(payload) {
  return {
    type: authTypes.POST_USER_META_DATA_REQ,
    payload,
  };
}

function deleteUserReq(payload) {
  return {
    type: authTypes.DELETE_USER_REQ,
    payload,
  };
}

function deleteProfileUser(payload) {
  console.log("delete profile",payload)
  return{
    type:authTypes.DELETE_PROFILE_USER,
    payload,
  }
}
function deleteUserProfileSuccess(payload) {
  return{
    type:authTypes.DELETE_PROFILE_USER_SUCCESS,
    payload,
  }
}
function deleteUserProfileError(payload) {
 
  return{
    type:authTypes.DELETE_PROFILE_USER_ERROR,
    payload,
  }
}

function invitefriend(payload) {
  return {
    type: authTypes.INVITE_FRIEND,
    payload,
  };
}
function invitefriendSuccess(payload){
  return {
    type:authTypes.INVITE_FRIEND_SUCCESS,
    payload
  }
}
function conitinueWithEmailSubmitted(payload) {
  return {
    type: authTypes.CONTINUE_WITH_EMAIL_SUBMITTED,
    payload,
  };
}

function updateUserExists(payload) {
  return {
    type: authTypes.USER_EXISTS,
    payload,
  };
}

function apiError(payload) {
  return {
    type: authTypes.API_ERROR,
    payload,
  };
}

function isEditProfileQuiz(payload) {
  return {
    type: authTypes.IS_EDIT_PROFILE_QUIZ,
    payload,
  };
}

function adminDetails(payload) {
  return {
    type: authTypes.ADMIN_DETAILS,
    payload
  }
}

function adminDetailsRequest(payload) {
  return {
    type: authTypes.ADMIN_DETAILS_SUCCESS,
    payload
  }
}

function adminViewDetails(payload){
  return {
    type: authTypes.ADMIN_VIEW_DETAILS,
    payload
  }
}

function adminViewDetailRequest(payload){
  return {
    type: authTypes.ADMIN_VIEW_DETAILS_SUCCESS,
    payload
  }
}

function adminViewEmail(payload){
  return {
    type: authTypes.ADMIN_VIEW_EMAIL,
    payload
  }
}

function verifyEmail(payload){
  return {
    type: authTypes.VERIFY_EMAIL,
    payload
  }
}

function verifyEmailRequest(payload){
  return {
    type: authTypes.VERIFY_EMAIL_REQUEST,
    payload
  }
}

function forgotPassword(payload){
  return {
    type: authTypes.FORGOT_PASSWORD,
    payload
  }
}

function forgotPasswordRequest(payload){
  return {
    type: authTypes.FORGOT_PASSWORD_REQUEST,
    payload
  }
}

function updatePassword(payload){
  return {
    type: authTypes.UPDATE_PASSWORD,
    payload
  }
}

function updatePasswordRequest(payload){
  return {
    type: authTypes.UPDATE_PASSWORD_REQUEST,
    payload
  }
}

function resendVerification(payload){
  return {
    type: authTypes.RESEND_VERIFICATION,
    payload
  }
}

function resendVerificationRequest(payload){
  return {
    type: authTypes.RESEND_VERIFICATION_REQUEST,
    payload
  }
}

export default {
  updateUserExists,
  getScoreHistory,
  updateScoreHistory,
  getProfile,
  setProfile,
  setCodingTheme,
  setCodingLang,
  beginLoginUser,
  loginSuccess,
  loginError,
  updateQuizLevel,
  createProfile,
  createProfileError,
  clearState,
  resumeUpload,
  deleteResume,
  resumeUploadRequested,
  profileImageUpload,
  profileImageUploadError,
  resumeUploadError,
  profileImageUploadRequested,
  downloadResume,
  resumeDownloaded,
  getAoSI,
  updateAoSI,
  getRecommendations,
  updateRecommendations,
  getSampleJobs,
  updateSampleJobs,
  getContent,
  updateContent,
  onTimeoutDialogSubmit,
  onTimeoutDialogExit,
  setAuthState,
  userMetaData,
  userMetaDataReq,
  createUserWithFirebaseRequested,
  createUserWithFirebaseError,
  createUserWithFirebaseSuccess,
  resetPasswordRequested,
  resetPassword,
  resetPasswordError,
  firebaseUserLoginRequested,
  firebaseUserLogin,
  firebaseUserLoginError,
  invitefriend,
  invitefriendSuccess,
  deleteResumeRequested,
  deleteResumeSuccess,
  deleteUserMetaData,
  deleteUser,
  deleteProfileUser,
  deleteUserProfileSuccess,
  deleteUserProfileError,
  postUserMetaData,
  deleteUserMetaDataReq,
  deleteUserReq,
  postUserMetaDataReq,
  conitinueWithEmailSubmitted,
  apiError,
  isEditProfileQuiz,
  adminDetailsRequest,
  adminDetails,
  adminViewDetails,
  adminViewDetailRequest,
  adminViewEmail,
  verifyEmail,
  verifyEmailRequest,
  forgotPassword,
  forgotPasswordRequest,
  updatePassword,
  updatePasswordRequest,
  resendVerification,
  resendVerificationRequest
};
