import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { useHistory } from "react-router-dom";
import logo from "../assets/logo.svg";
import serverErrorIcon from "../assets/serverErrorIcon.svg";
import { ImagebaseUrl } from "../services/globalService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {},
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    zIndex: 1,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "100px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  cardTwo: {
    marginTop: "40px",
  },
  cardThree: {},
  card: {
    marginBottom: 40,
    "&>button": {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up("tablet")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    width: 346,
    lineHeight: "28px",
    [theme.breakpoints.up("tablet")]: {
      width: "fit-content",
    },
  },
  openingScreenDescription: {
    width: "560px",
    height: "48px",
    color: "#000100bd",
    fontSize: "16px",
    fontFamily: "Roboto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  openingScreenDescription2: {
    width: "375px",
    height: "96px",
    color: "#000100bd",
    fontFamily: "Roboto",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  marginTop40: {
    marginTop: "40px",
  },
  lineHeight24: {
    lineHeight: "24px",
  },
  openingScreen: {
    width: 256,
    [theme.breakpoints.up("tablet")]: {
      width: 373,
    },
  },
  errorScreen: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "@media (max-width:420px)": {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  serverErrorIcon: {
    width: "100%",
    height: "40px",
    top: "4px",
    left: "4px",
  },
  fontDescription: {
    fontFamily: "Rational",
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "0.02em",
  },
  exit: {
    color: "#007eff",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Roboto",
    cursor: "pointer",
  },
  titleText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "868px",
    height: "40px",
    marginTop: "3%",
    "@media (max-width:420px)": {
      width: "343px",
      marginTop: "10%",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  ErrorScreenIconArea: {
    height: "40px",
    width: "100%",
  },
  descriptionText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width:420px)": {
      paddingLeft: "16px",
      paddingRight: "16px",
      width: "343px",
      marginTop: "12%",
    },
  },
  linkArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function ErrorScreen(props) {
  const history = useHistory();
  const classes = useStyles();
  const { location } = props;

  return (
    <div className={classes.root}>
      {location && location.pathname == "/ServerError" && (
        <div>
          <div
            className={`${classes.section} ${classes.cardOne} ${classes.center}`}
          >
            <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div className={`${classes.errorScreen}`}>
            <div className={`${classes.ErrorScreenIconArea}`}>
              <img
                alt="serverErrorIcon"
                src={`${ImagebaseUrl}assets/serverErrorIcon.svg`}
                className={` ${classes.logoCls} ${classes.serverErrorIcon}`}
              />
            </div>
            <div className={` ${classes.titleText}`}>
              <Typography
                variant="subtitle2"
                align="center"
                className={`${classes.fontDescription}`}
              >
                Server Error
              </Typography>
            </div>
            <div className={`${classes.descriptionText}`}>
              <Typography
                variant="subtitle2"
                align="center"
                className={`${classes.openingScreenDescription2}`}
              >
                The page you requested could not be loaded. Please refresh the
                page or check back later.
              </Typography>
            </div>
            <div className={`${classes.linkArea}`}>
              <Link onClick={() => history.push("/")}>
                <Typography
                  variant2="link2"
                  align="right"
                  className={classes.exit}
                >
                  Back to home
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      )}
      {location && location.pathname !== "/ServerError" && (
        <div>
          <div
            className={`${classes.section} ${classes.cardOne} ${classes.center}`}
          >
            <img alt="logo" src={`${ImagebaseUrl}assets/logo.svg`} className={classes.logoCls} />
          </div>
          <div className={`${classes.errorScreen}`}>
            <div className={`${classes.ErrorScreenIconArea}`}>
              <img
                alt="serverErrorIcon"
                src={`${ImagebaseUrl}assets/serverErrorIcon.svg`}
                className={` ${classes.logoCls} ${classes.serverErrorIcon}`}
              />
            </div>
            <div className={` ${classes.titleText}`}>
              <Typography
                variant="subtitle2"
                align="center"
                className={`${classes.fontDescription}`}
              >
                Oops, something went wrong
              </Typography>
            </div>
            <div className={`${classes.descriptionText}`}>
              <Typography
                variant="subtitle2"
                align="center"
                className={`${classes.openingScreenDescription2}`}
              >
                The page you requested could not be loaded. Please refresh the
                page or check back later.
              </Typography>
            </div>
            <div className={`${classes.linkArea}`}>
              <Link onClick={() => history.push("/")}>
                <Typography
                  variant2="link2"
                  align="right"
                  className={classes.exit}
                >
                  Back to home
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ErrorScreen;
