import React from "react";
import Button from "./controls/Button";
import MuiDialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Text } from "../styles/theme";
import Typography from "@material-ui/core/Typography";
import auth from "../redux/actions/auth";
import AppConfig from "../constants/AppConfig";
import quiz from "../redux/actions/quiz";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

const Dialog = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: "23px !important",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: "20px",
  },
}))(MuiDialogActions);

export default function TimeoutDialog(props) {
  const { open, onClose, onSubmit, text, ...other } = props;
  const dispatch = useDispatch();
  const quizState = useSelector((state) => state.quiz);
  const history = useHistory();

  const handleExit = () => {
    if (navigator.onLine) {
      dispatch(quiz.setTimer(quizState.config.Time_Mins));
      dispatch(quiz.resetElapsedTime(0));
      // setTimeout(() => {
      //   dispatch(quiz.timerPlaying(true));
      // }, 3000);
      // dispatch(auth.onTimeoutDialogExit({ value: AppConfig.TimerExceedsExit }));
      history.push("/");
    }
    else {
      props.TimeClose()
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    // dispatch(
    //   auth.onTimeoutDialogSubmit({ value: AppConfig.TimerExceedsSubmitted })
    // );
    onSubmit();
  };

  return (
    <div>
      <Dialog
        onBackdropClick="false"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "343px",
            height: "203px",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Typography
            component={Text}
            variant2="b1Regular"
            className=""
            align="center"
          >
            {text}
          </Typography>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            color="btnSecondary"
            text="Cancel"
            width="136px"
            onClick={handleExit}
            data-rt-timeout-timoutcancel="timeoutcancel"
          ></Button>
          <Button
            color="btnPrimary"
            text="Yes, submit"
            width="136px"
            onClick={handleSubmit}
            data-rt-timeout-timoutsubmit="timeoutsubmit"
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
