import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

import { useHistory } from "react-router-dom";
import AppConfig from "../../constants/AppConfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Text } from "../../styles/theme";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


import moment from "moment";
import Sections from "../../components/section/Sections";
const useStyles = makeStyles((theme) => ({
  scoreCard: {
    backgroundColor: theme.palette.primary.light,
    height:'100%'
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  link: {
    color: "#1789FC",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
  },
  scoresCalculatedByCardTitle: {
    marginBottom: 8,
  },
  sectionTitle: {
    //marginBottom: 6,
    padding:'4px',
    // fontSize: "16px",
    // lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "24px",
      // lineHeight: "32px"
      marginBottom: 2,
      padding:'2px',
    },
  },
  grayText: {
    color: "#787878",
  },
  subTitle:{
    marginBottom:'6px',
    padding:"4px",
    [theme.breakpoints.down("sm")]:{
    marginBottom:'3px',
    padding:"3px"
    }
  },
  incompleteTxt: {
    fontWeight: "normal",
  },
  linkCursor:{
    cursor:'pointer'
  },
  sectionChildContainer: {
    display: 'flex',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  roundedCircle: {
    height: '12px',
    width: '12px',
    margin: '2px 3px 2px 14px',
    border : '1px solid #C4C4C4',
    borderRadius: '50%'
  },
  roundedText : {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color:"#787878"
  },
  sectionMainContainer: {
    height: '20px',
    display : 'flex',
    margin: '0px 0px 14px -10px',
    [theme.breakpoints.down("sm")]:{
      margin: '0px 0px 14px -10px',
    }
  }

}));

function ScoreCalculationDimension(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const auth = useSelector((state) => state.auth);
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const onStartCodingChallenge = function () {
    if(navigator.onLine){
    if (isNotMobile) {
      history.push("/L3CodingOpeningScreen");
    } else {
      history.push("/CodingSectionUnavailable");
    }
  }
  else {
    setOpenSnackBar(true)
  }
  };

  const handleClose = () => {
    setOpenSnackBar(false)
  }

  const handleLink = (link) => {
    if(navigator.onLine){
      history.push(link)
    }
    else {
      setOpenSnackBar(true)
    }
  }

  return (
    <div className={classes.scoresCalculatedBy}>
      <Typography
        component={Text}
        variant2="h4"
        className={classes.sectionTitle}
        align="left"
      >
       The following sections determine your comprehensive career score
      </Typography>
      <Typography  component={Text}
                variant2="b2Regular"
                className={`${classes.grayText} ${classes.subTitle}`}>To get an accurate score, ensure all four sections have been completed. Retake if necessary.</Typography>
      {isNotMobile &&
        <div className={classes.sectionMainContainer}>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "#28BB3F" }}></span>
            <span className={classes.roundedText}>Strong</span>
          </div>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "#FAC06A" }}></span>
            <span className={classes.roundedText}>To Improve</span>
          </div>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "#DD2E2E" }}></span>
            <span className={classes.roundedText}>Concern</span>
          </div>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "white" }}></span>
            <span className={classes.roundedText}>Not Started / To Be Assessed</span>
          </div>
        </div>
      }
      {!isNotMobile &&
        <>
          <div className={classes.sectionMainContainer}>
            <div className={classes.sectionChildContainer}>
              <span className={classes.roundedCircle} style={{ background: "#28BB3F" }}></span>
              <span className={classes.roundedText}>Strong</span>
            </div>
            <div className={classes.sectionChildContainer} style={{ marginLeft: '30px' }}>
              <span className={classes.roundedCircle} style={{ background: "#FAC06A" }}></span>
              <span className={classes.roundedText}>To Improve</span>
            </div>
          </div>

          <div className={classes.sectionMainContainer}>
            <div className={classes.sectionChildContainer}>
              <span className={classes.roundedCircle} style={{ background: "#DD2E2E" }}></span>
              <span className={classes.roundedText}>Concern</span>
            </div>
            <div className={classes.sectionChildContainer}>
              <span className={classes.roundedCircle} style={{ background: "white",marginLeft : '30px' }}></span>
              <span className={classes.roundedText}>Not Started / To Be Assessed</span>
            </div>
          </div>
        </>
      }

      <ImageList rowHeight={"auto"} cols={isNotMobile ? 2 : 1} 
      //spacing={12} 
      gap={12}>
        <ImageListItem>
          <Card className={classes.scoreCard} elevation={0}>
            <CardContent>
              <Typography
                component={Text}
                variant2="b2Medium"
                className={classes.scoresCalculatedByCardTitle}
                align="left"
              >
                Profile Section:{" "}
                {(auth.userProfile.user_level == "" || auth.userProfile.user_level == null || auth.userProfile.user_level == AppConfig.LEVEL0) && (
                  <span className={classes.link}>(available after Initial Evaluation)</span>
                )}
                {(auth.userProfile.user_level !== "" && auth.userProfile.user_level != null && auth.userProfile.user_level != AppConfig.LEVEL0) &&
                  auth.userProfile &&
                  auth.userProfile.L3_Profile_Score_UpdatedDate && (
                    <span className={classes.primaryText}>
                      Last Updated{" "}
                      {moment(
                        auth.userProfile.L3_Profile_Score_UpdatedDate
                      ).format("MM/DD/YY")}
                    </span>
                  )}
                {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L3_Profile_Score_UpdatedDate && (
                    <>
                      {/* <span className={classes.incompleteTxt}>
                        Incomplete.{" "}
                      </span> */}
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                           className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() =>
                            handleLink("/L3ProfileOpeningScreen")
                            
                          }
                          data-rt-div-completesectionprofile = "completesectionprofile"
                        >
                          {/* Update Profile */}
                          Complete Section Now
                        </Link>
                      </span>
                    </>
                  )}
              </Typography>
              <Typography
                component={Text}
                variant2="b2Regular"
                className={classes.grayText}
                align="left"
              >
                The section consists of quality of your resume and your digital
                contributions, such as networking sites like LinkedIn, GitHub,
                or Medium. Get an improved score by uploading a new resume or
                adding profile links.{" "}
                {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  auth.userProfile.L3_Profile_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                           className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() =>
                            handleLink("/L3ProfileOpeningScreen")
                          }
                          data-rt-div-UpdateprofileL3 = "updateprofileL3"
                        >
                          Update Profile
                        </Link>
                      </span>
                    </>
                  )}
                  {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L3_Profile_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                           className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() =>
                            handleLink("/L3ProfileOpeningScreen")
                          }
                          data-rt-div-updateprofile = "updateprofile"
                        >
                          Update Profile
                        </Link>
                      </span>
                    </>
                  )}
                  {auth?.areaOfStrengthsAndImprovements?.Profile && (
                    <Sections data={auth.areaOfStrengthsAndImprovements.Profile}></Sections>
                  )}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>
        <ImageListItem>
          <Card className={classes.scoreCard} elevation={0}>
            <CardContent>
              <Typography
                component={Text}
                variant2="b2Medium"
                className={classes.scoresCalculatedByCardTitle}
                align="left"
              >
                Technical Section:{" "}
                {(auth.userProfile.user_level == "" || auth.userProfile.user_level == null || auth.userProfile.user_level == AppConfig.LEVEL0) && (
                  <span className={classes.link}>(available after Initial Evaluation)</span>
                )}
                {(auth.userProfile.user_level !== "" && auth.userProfile.user_level != null && auth.userProfile.user_level != AppConfig.LEVEL0) &&
                  auth.userProfile &&
                  auth.userProfile.L2_Score_UpdatedDate && (
                    <span className={classes.primaryText}>
                      Last Updated{" "}
                      {moment(auth.userProfile.L2_Score_UpdatedDate).format(
                        "MM/DD/YY"
                      )}
                    </span>
                  )}
                {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L2_Score_UpdatedDate && (
                    <>
                      {/* <span className={classes.incompleteTxt}>
                        Incomplete.{" "}
                      </span> */}
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                          className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() => handleLink("/L2OpeningScreen")}
                          data-rt-div-completesectiontechnical = "completesectiontechnical"
                        >
                          {/* Take Quiz Now */}
                          Complete Section Now
                        </Link>
                      </span>
                    </>
                  )}
              </Typography>
              <Typography
                component={Text}
                variant2="b2Regular"
                className={classes.grayText}
                align="left"
              >
                Here you’re evaluated with a series of  multiple choice
                questions regarding your technical knowledge based on the target
                job you’ve selected. Improve this area by re-taking the quiz
                when you feel ready.{" "}
                {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  auth.userProfile.L2_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                          className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() => handleLink("/L2OpeningScreen")}
                          data-rt-div-retakequiztechnical = "retakequiztechnical"
                        >
                          Retake Quiz Now
                        </Link>
                      </span>
                    </>
                  )}
                  
                  {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L2_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                          className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() => handleLink("/L2OpeningScreen")}
                          data-rt-div-takequiztechnical = "takequiztechnical"
                        >
                          Take Quiz Now
                        </Link>
                      </span>
                    </>
                  )}
                  {auth?.areaOfStrengthsAndImprovements?.Technical && (
                    <Sections data={auth.areaOfStrengthsAndImprovements.Technical}></Sections>
                  )}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>

        <ImageListItem>
          <Card className={classes.scoreCard} elevation={0}>
            <CardContent>
              <Typography
                component={Text}
                variant2="b2Medium"
                className={classes.scoresCalculatedByCardTitle}
                align="left"
              >
                Coding Section:{" "}
                {(auth.userProfile.user_level == "" || auth.userProfile.user_level == null || auth.userProfile.user_level == AppConfig.LEVEL0) && (
                  <span className={classes.link}>(available after Initial Evaluation)</span>
                )}
                {(auth.userProfile.user_level !== "" && auth.userProfile.user_level != null && auth.userProfile.user_level != AppConfig.LEVEL0) &&
                  auth.userProfile &&
                  auth.userProfile.L3_Coding_Score_UpdatedDate && (
                    <span className={classes.primaryText}>
                      Last Updated{" "}
                      {moment(
                        auth.userProfile.L3_Coding_Score_UpdatedDate
                      ).format("MM/DD/YY")}
                    </span>
                  )}
                {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L3_Coding_Score_UpdatedDate && (
                    <>
                      {/* <span className={classes.incompleteTxt}>
                        Incomplete.{" "}
                      </span> */}
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                           className={`${classes.link} ${classes.linkCursor}`}
                          onClick={onStartCodingChallenge}
                          data-rt-div-completecode = "completecode"
                        >
                          {/* Start Coding Challenge */}
                          Complete Section Now
                        </Link>
                      </span>
                    </>
                  )}
              </Typography>
              <Typography
                component={Text}
                variant2="b2Regular"
                className={classes.grayText}
                align="left"
              >
                You’ll be asked to solve a coding problem, where we’re
                challenging your language mastery, object-oriented concepts, programming constructs, and more.{" "}
                {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  auth.userProfile.L3_Coding_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                          className={`${classes.link} ${classes.linkCursor}`}
                          onClick={onStartCodingChallenge}
                          data-rt-div-startnewcode = "startnewcode"
                        >
                          Start New Coding Challenge
                        </Link>
                      </span>
                    </>
                  )}
                  {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L3_Coding_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                           className={`${classes.link} ${classes.linkCursor}`}
                          onClick={onStartCodingChallenge}
                          data-rt-div-startcode = "startcode"
                        >
                          Start Coding Challenge
                        </Link>
                      </span>
                    </>
                  )}
                  {auth?.areaOfStrengthsAndImprovements?.Coding && (
                    <Sections data={auth.areaOfStrengthsAndImprovements.Coding}></Sections>
                  )}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>

        <ImageListItem>
          <Card className={classes.scoreCard} elevation={0}>
            <CardContent>
              <Typography
                component={Text}
                variant2="b2Medium"
                className={classes.scoresCalculatedByCardTitle}
                align="left"
              >
                Presentation Section:{" "}
                {(auth.userProfile.user_level == "" || auth.userProfile.user_level == null || auth.userProfile.user_level == AppConfig.LEVEL0) && (
                  <span className={classes.link}>(available after Initial Evaluation)</span>
                )}
                {(auth.userProfile.user_level !== "" && auth.userProfile.user_level != null && auth.userProfile.user_level != AppConfig.LEVEL0) &&
                  auth.userProfile &&
                  auth.userProfile.L3_Presentation_Score_UpdatedDate && (
                    <span className={classes.primaryText}>
                      Last Updated{" "}
                      {moment(
                        auth.userProfile.L3_Presentation_Score_UpdatedDate
                      ).format("MM/DD/YY")}
                    </span>
                  )}
                { auth.userProfile.user_level != "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L3_Presentation_Score_UpdatedDate && (
                    <>
                      {/* <span className={classes.incompleteTxt}>
                        Incomplete.{" "}
                      </span> */}
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                           className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() =>
                           handleLink("/L3PresentationOpeningScreen")
                          }
                          data-rt-div-completePresentation = "completePresentation"
                        >
                          {/* Take Quiz Now */}
                          Complete Section Now
                        </Link>
                      </span>
                    </>
                  )}
              </Typography>
              <Typography
                component={Text}
                variant2="b2Regular"
                className={classes.grayText}
                align="left"
              >
                With a series of background questions, you’re evaluated in
                different areas, like interview readiness, presentation skills,
                 management tasks, and recommendations.{" "}
                { auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  auth.userProfile.L3_Presentation_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                          className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() =>
                            handleLink("/L3PresentationOpeningScreen")
                          }
                          data-rt-div-retakepresentation = "retakepresentation"
                        >
                          Retake Quiz Now
                        </Link>
                      </span>
                    </>
                  )}
                  {auth.userProfile.user_level !== "" && auth.userProfile.user_level !== AppConfig.LEVEL0 &&
                  !auth.userProfile.L3_Presentation_Score_UpdatedDate && (
                    <>
                      <span
                        component={Text}
                        variant2="link2"
                        className={classes.link}
                      >
                        <Link
                          className={`${classes.link} ${classes.linkCursor}`}
                          onClick={() =>
                            handleLink("/L3PresentationOpeningScreen")
                          }
                          data-rt-div-takepresentation = "takepresentation"
                        >
                          Take Quiz Now
                        </Link>
                      </span>
                    </>
                  )}
                  {auth?.areaOfStrengthsAndImprovements?.Presentation && (
                    <Sections data={auth.areaOfStrengthsAndImprovements.Presentation}></Sections>
                  )}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>
      </ImageList>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default ScoreCalculationDimension;
