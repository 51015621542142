import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
  root: {
    "&.Mui-focused": {
      border: `1px solid ${theme.palette.btnPrimary.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "&.Mui-error": {
      border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"
  }
  },
  textField: {
    marginTop: "0px",
    marginBottom: "0px",
    minHeight: "56px",
  },
  input: {
    fontFamily: theme.typography.input.fontFamily,
    fontSize: theme.typography.input.fontSize,
    fontStyle: theme.typography.input.fontStyle,
    fontWeight: theme.typography.input.fontWeight,
    lineHeight: theme.typography.input.lineHeight,
    letterSpacing: theme.typography.input.letterSpacing,
    textalign: theme.typography.input.textalign,
    color: theme.typography.input.color,
    "&::placeholder": {
      color: theme.typography.input.placeholderColor,
    },
  },
  IconVisible:{
    background: "#F8F8F8",
    padding: "14.9px",
    borderRadius:" 0px 4px 4px 0px",
    "& .MuiIconButton-root:hover":{
      backgroundColor:'#F8F8F8'
    }
  
  },
  IconColor:{
    color:"#C4C4C4"
  }

}));

const TextInput = (props) => {
  const {
    name,
    value,
    error = null,
    onChangeText,
    placeholder,
    id,
    type,
    showPassword,
    ...other
  } = props;

 
  const handleClickShowPassword = () => {
    props?.setShowPassword(!showPassword)
  };
  const handleMouseDownPassword = () => {
    props?.setShowPassword(true)
  }

  const classes = useStyles(props);

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        autoComplete='off'
        fullWidth
        id={id}
        name={name}
        value={value}
        type={type}
        className={classes.textField}
        onChange={onChangeText}
        placeholder={placeholder}
        {...other}
        {...(error && { error: true, helperText: error })}
        InputProps={{
          className: classes.root,
          classes: { notchedOutline: classes.noBorder, input: classes.input },
          endAdornment: (
            props?.passwordText ? 
            <InputAdornment position="end">
              <IconButton
              className={classes.IconVisible}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
               // onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility style={{color:'black'}}/> : value ? <Visibility style={{color:'#1789FC'}}/> : <Visibility className={classes.IconColor}/> }
              </IconButton>
            </InputAdornment> : null
          )
        }}
      />
    </>
  );
};

export default TextInput;
