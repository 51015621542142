import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, makeStyles } from '@material-ui/core';
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(0.5),
    },
    label: {
      textTransform: "none",
    },
    selectCls: {
        width: 160,
        height: 32,
        color: "#787878",
        backgroundColor: "white !important",
        '& div':{
            paddingTop:'0px',
            paddingBottom:'0px'
        }
    },
    formControlCls: {
        marginLeft: "8px"
    },
    labelCls: {
        color: "#787878"
    }
}));

export default function Select(props) {

    const { name, label, value, error = null, onChange, options, showNone, displayField } = props;
    const classes = useStyles(props);
    const borderSelectClasses = useBorderSelectStyles();
    const iconComponent = (props) => {
        return (
          <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon}/>
        )};
        const menuProps = {
            classes: {
              list: borderSelectClasses.list
            },
            anchorOrigin: {
              vertical: "bottom",
                horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
                horizontal: "left"
            },
            getContentAnchorEl: null
          };
    
    return (
        <FormControl
            className={classes.formControlCls}
            variant="outlined"
            {...(error && { error: true })}>
            <MuiSelect
                // label={label}
                name={name}
                value={value}
                onChange={onChange}
                IconComponent={iconComponent}
                className={classes.selectCls}
                MenuProps={menuProps}
               // classes={{ root: borderSelectClasses.select, }}
                >
                {showNone &&
                    <MenuItem value="">None</MenuItem>
                }
                {
                    options.map(
                        item => (<MenuItem key={item.id} value={item.id}>{item[displayField] || item.value}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
