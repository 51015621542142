import React, { useState } from "react";
import Button from "./controls/Button";
import MuiDialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Text } from "../styles/theme";
import Typography from "@material-ui/core/Typography";
import auth from "../redux/actions/auth";
import AppConfig from "../constants/AppConfig";
import quiz from "../redux/actions/quiz";
import { useSelector, useDispatch } from "react-redux";
import { CallMissedSharp } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  next: {
    "& button": {
      height: "40px",
    },
  },
}));
const Dialog = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: "23px !important",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: "20px",
  },
}))(MuiDialogActions);

export default function ExitDialog(props) {
  const { open, text, succesBtnLabel, actionInfo, ...other } = props;
  const classes = useStyles();

  const onClickSubmit = () => {
    props?.OnExit();
  };
  const closeModel = () => {
    if(navigator.onLine){
      props.onClose()
    }
    else {
       props.TimeClose()
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick="false"
        PaperProps={{
          style: {
            width: "330px",
            //height: "203px",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Typography
            component={Text}
            variant2="b1Regular"
            className=""
            align="center"
          >
            {/* {text} */}
            Your answers will not be saved if you exit now. Do you wish to
            leave?
          </Typography>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            color="btnSecondary"
            text="Cancel"
            width="120px"
            onClick={closeModel}
            data-rt-div-exitcancel = "exitcancel"
          ></Button>
          <Grid className={classes.next}>
            <Button
              color="btnPrimary"
              text={"Yes, exit"}
              width="120px"
              onClick={() => onClickSubmit()}
              data-rt-div-exitsubmit = "exitsubmit"
            ></Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
