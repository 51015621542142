import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ECF0F1",
    [theme.breakpoints.up("tablet")]: {},
  },
}));

function RoundedLoadingIndicator(props) {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`}>
      <div className="roundedLoad">
        <hr />
        <hr />
        <hr />
        <hr />
      </div>
    </div>
  );
}
export default RoundedLoadingIndicator;

