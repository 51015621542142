import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";

import "../../home/Home.css";
import CodingContainer from "../../../components/quiz/CodingContainer/CodingContainer";
import { useSelector, useDispatch } from "react-redux";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function CodingQuestions(props) {
  const { location } = props;

  const classes = useStyles(props);
  const theme = useTheme();

  const [value, setState] = useState({
    quesObject: {},
  });

  const routeParams = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const quesRes = useSelector((state) => state.quiz.questions);
  const quizState = useSelector((state) => state.quiz);

  useEffect(() => {
    console.log("state", value);
  }, [quesRes]);

  useEffect(() => {
    console.log("Did Mounted", routeParams);
    if (+routeParams.qIndex - 1 == 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL3));
    }

    if (quesRes.length != 0) {
      var idx = +routeParams.qIndex - 1;
      let quesObject = quesRes[idx];

      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }

    return () => console.log("Will Unmounted");
  }, [routeParams]);

  useEffect(() => {
    if (quesRes) {
      console.log("Did Mounted", quesRes);

      let quesObject = quesRes[+routeParams.qIndex - 1];
      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }
    return () => console.log("Will Unmounted");
  }, [quesRes]);

  return (
    <div>
      <CodingContainer
        quizProp={value}
        routeParams={routeParams}
        qIndex={+routeParams.qIndex}
      ></CodingContainer>
    </div>
  );
}
export default CodingQuestions;
