import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";

import workshop1Img from "../assets/video.svg";
import playbutton from '../assets/svg/playbutton.svg'
import { ImagebaseUrl } from "../services/globalService";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  lightbox: {
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("tablet")]: {
    },
  },
  workshopImg: {
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "19vh",
   
    textAlign:'center',
    [theme.breakpoints.down("xs")]:{
      height:'18vh',
      '@media (orientation: landscape)':{
      height: "60vh",
      }
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height:'23vh',
    },
  },
  playIcon: {
    width: 64,
  },
  videoResponsive: {
    overflow: "hidden",
    height: "100%",
    "& iframe": {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      height: "50%",
      width: "50%",
      position: "absolute",
    },
  },
  videoIcon:{
  //   marginTop:'6vh',
  // height: "39.26px",
  height: "33.26px",
  marginTop:'5vh',
 [theme.breakpoints.down("xs")]:{
  marginTop:'6vh',
  //      position: "absolute",  
  //    right: "38.94%",
  //     bottom: "56.88%",
   height: "39.26px",
  '@media (orientation: landscape)':{
    height: "47.26px",
    marginTop:" 25vh"
  }
},
[theme.breakpoints.up("lg")]: {
  height:'57.17px',
  marginTop:'8vh',
  // bottom: "53.93%",
  // right: "37.9%",
}
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  return <Zoom ref={ref} {...props} />;
});

export default function YouTubeLightBox(props) {
  const {url} = props;
  const videoId = url.split("=")[1];

  console.log('videoId: ', videoId);

  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = React.useState(false)

  const handleClickOpen = () => {
    if(navigator.onLine){
    window.open(url, "_blank");
    }
    else {
      setOpenSnackBar(true)
    }
  };

  const handleClose = () => {
    setOpenSnackBar(false)
  }

  return (
      <div className={classes.lightbox}>
        {/* <img
          alt="workshop"
          src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
          className={classes.workshopImg}
          onClick={handleClickOpen}
        /> */}
        <div style={{ 
      backgroundImage: `url(https://img.youtube.com/vi/${videoId}/0.jpg)` 
    }}
    className={classes.workshopImg}
     onClick={handleClickOpen}
     data-rt-youtube-youtubebtn = "youtubebtn"

    >
       <img  className={classes.videoIcon} src={`${ImagebaseUrl}assets/svg/playbutton.svg`}/>
    </div>


       <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </div>
  );
}
