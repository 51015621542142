import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector, useDispatch } from "react-redux";

const BorderLinearProgress = withStyles((theme, props) => ({
  root: {
    height: 6,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.btnPrimary.light,
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  progressBar:{
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: (props)=> { return props.color},
    },
  }
});
export default function CustomizedProgressBars(props) {
  const classes = useStyles(props);

  const { variant, value,  ...other } = props
  return (
    <div className={classes.root}>
      {/* <FacebookCircularProgress />
      <br /> */}
      <BorderLinearProgress variant={variant || 'determinate'} value={value || 0} className={`${classes.progressBar}`} />
    </div>
  );
}
